import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "GlobalStyle.js";
import {
    Body3,
    PageTitleWrapper,
    Body_2XS_Reg,
    Body_XS_Bold,
} from "common/global-styled-components";
import { usePageTitle } from "common/utils";
import { Label, BodyWrapper, ActionWrapper } from "./index.js";
import Icon from "common/components/Icon";
import { Sound, Music, Translate, Smallcaps, ExportSquare, Stop } from "iconsax-react";
import CustomDropdown from "common/components/CustomDropdown";
import ChipInput from "common/components/ChipInput.js";
import Play from "common/assets/Play";
import CopilotService from "services/copilot.service.js";
import LoaderIcon from "common/components/LoaderIcon";

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--Size-Gap-XL);
    flex-wrap: wrap;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const IconWrapper = styled.div`
    width: 44px;
    background: var(--Color-Background-Subtle);
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Size-CornerRadius-XXXL);
    padding: var(--Size-Padding-M);
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70px;
    gap: var(--Size-Gap-S);
`;

const SubText = styled(Body_2XS_Reg)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const BrandWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-XL);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-XL);
    padding: var(--Size-Padding-XL);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
        max-width: 100%;
    }
`;
const BrandWords = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    @media (max-width: ${Breakpoints.mobile}px) {
        max-width: 100%;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Redirect = styled(Body_2XS_Reg)`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: var(--Size-Gap-M);
    background: var(--Color-Background-Subtle);
    border-radius: var(--Size-CornerRadius-M);
    padding: var(--Size-Padding-S) var(--Size-Padding-M);
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const Voicelanguages = [
    { "label": "English (United States)", "value": "en-US" },
    { "label": "English (India)", "value": "en-IN" },
    { "label": "English (United Kingdom)", "value": "en-GB" },
    { "label": "German (Germany)", "value": "de-DE" },
    { "label": "Spanish (Spain)", "value": "es-ES" },
    { "label": "Spanish (Latin America)", "value": "es-419" },
    { "label": "Hindi (India)", "value": "hi-IN" },
    { "label": "Japanese (Japan)", "value": "ja-JP" },
    { "label": "Portuguese (Portugal)", "value": "pt-PT" },
    { "label": "Portuguese (Brazil)", "value": "pt-BR" },
    { "label": "French (France)", "value": "fr-FR" },
];

const VoicesSound = [
    { "label": "None", "value": null },
    { "label": "Coffee Shop", "value": "coffee-shop" },
    { "label": "Convention Hall", "value": "convention-hall" },
    { "label": "Summer Outdoor", "value": "summer-outdoor" },
    { "label": "Mountain Outdoor", "value": "mountain-outdoor" },
    { "label": "Static Noise", "value": "static-noise" },
];

const VoiceSettings = ({ register, errors, allValues, setValue }) => {
    usePageTitle(`Copilot Platform - Voice Settings`);
    const history = useHistory();
    const { agentId, orgId } = useParams();
    const [voices, setVoices] = useState([]);
    const [loader, setLoader] = useState(true);
    const [currentVoice, setCurrentVoice] = useState("");
    const [play, setPlay] = useState(false);
    useEffect(() => {
        CopilotService.aiVoices()
            .then((res) => {
                let voices = res?.data?.map((voice) => ({
                    label: voice.voiceName,
                    value: voice.voiceId,
                    url: voice.previewVoiceUrl,
                    avatar: voice.avatarUrl,
                }));
                setVoices(voices);
                let voicetoPlay =
                    voices.find((val) => val.value === allValues?.voiceAgent?.voiceId) || voices[0];
                setCurrentVoice(voicetoPlay?.url);
            })
            .catch((err) => {
                console.error({ err });
            })
            .finally(() => {
                setLoader(false);
            });
    }, []);

    useEffect(() => {
        let voicetoPlay = voices.find((val) => val.value === allValues?.voiceAgent?.voiceId);
        setCurrentVoice(voicetoPlay?.url);
    }, [allValues?.voiceAgent?.voiceId]);

    const playingAudio = (id) => {
        const currentlyPlayingAudio = document.getElementById("myAudio");
        if (currentlyPlayingAudio?.paused) {
            currentlyPlayingAudio.play();
            setPlay(true);
        } else {
            currentlyPlayingAudio.pause();
            setPlay(false);
        }
    };
    return (
        <>
            <BodyWrapper id="call-settings">
                {loader ? (
                    <Loader>
                        <LoaderIcon />
                    </Loader>
                ) : (
                    <>
                        <PageTitleWrapper>
                            <HeaderWrapper>
                                <Label>AI Voice</Label>
                                <Redirect
                                    onClick={() =>
                                        history.push(`/org/${orgId}/cp/${agentId}/deploy`)
                                    }
                                >
                                    Tip : Setup "Phone Call" deployment to streamline customer
                                    inquiries
                                    {Icon(ExportSquare, { size: 16 })}
                                </Redirect>
                            </HeaderWrapper>
                            <Body3>Configure your copilot voice settings from here</Body3>
                        </PageTitleWrapper>
                        <Wrapper>
                            <ActionWrapper>
                                <IconWrapper>{Icon(Sound, { size: 24 })}</IconWrapper>
                                <TitleWrapper>
                                    <Body_XS_Bold>Select Voice</Body_XS_Bold>
                                    <SubText>
                                        Choose from wide range of voice models available. Note that
                                        certain voices do not work too well with certain words, so
                                        it's recommended to expeirment before adding any words.
                                    </SubText>
                                </TitleWrapper>
                                <HeaderWrapper>
                                    <IconWrapper
                                        style={{
                                            background: "var(--Color-Background-Accent-Action)",
                                            width: "32px",
                                            height: "32px",
                                            cursor: "pointer",
                                        }}
                                        onClick={playingAudio}
                                    >
                                        {Icon(play ? Stop : Play, {
                                            color: "var(--Color-Icon-Action)",
                                        })}
                                    </IconWrapper>
                                    <audio
                                        id={`myAudio`}
                                        controls
                                        src={currentVoice}
                                        // autoPlay={firstMessage}
                                        controlsList="nodownload"
                                        style={{ display: "none" }}
                                        onEnded={() => setPlay(!play)}
                                    >
                                        <track kind="captions" />
                                    </audio>
                                    <CustomDropdown
                                        role="tooltip"
                                        data-tip
                                        data-tooltip-id={"dropdown"}
                                        options={voices}
                                        placeholder={"Choose your voice"}
                                        showArrow={false}
                                        defaultValue={voices[0]}
                                        wrapperWidth="220px"
                                        value={voices.find(
                                            (val) => val.value === allValues?.voiceAgent?.voiceId,
                                        )}
                                        onChange={(e) => {
                                            setValue("voiceAgent.voiceId", e.value);
                                        }}
                                        register={register}
                                        type="voiceId"
                                        label="voiceId"
                                    />
                                </HeaderWrapper>
                            </ActionWrapper>
                            <ActionWrapper>
                                <IconWrapper>{Icon(Music, { size: 24 })}</IconWrapper>
                                <TitleWrapper>
                                    <Body_XS_Bold>Ambient Sound</Body_XS_Bold>
                                    <SubText>
                                        If set, will add ambient environment sound to the call to
                                        make experience more realistic.
                                    </SubText>
                                </TitleWrapper>
                                <HeaderWrapper>
                                    {/* <IconWrapper
                                        style={{
                                            background: "var(--Color-Background-Accent-Action)",
                                            width: "32px",
                                            height: "32px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {Icon(Play, {
                                            color: "var(--Color-Icon-Action)",
                                        })}
                                    </IconWrapper> */}
                                    <CustomDropdown
                                        role="tooltip"
                                        data-tip
                                        data-tooltip-id={"dropdown"}
                                        options={VoicesSound}
                                        placeholder={"Choose your voice sound"}
                                        showArrow={false}
                                        defaultValue={VoicesSound[0]}
                                        wrapperWidth="220px"
                                        value={VoicesSound.find(
                                            (val) =>
                                                val.value === allValues?.voiceAgent?.ambientSound,
                                        )}
                                        onChange={(e) =>
                                            setValue("voiceAgent.ambientSound", e.value)
                                        }
                                        register={register}
                                        type="ambientSound"
                                        label="ambientSound"
                                    />
                                </HeaderWrapper>
                            </ActionWrapper>
                            <ActionWrapper>
                                <IconWrapper>{Icon(Translate, { size: 24 })}</IconWrapper>
                                <TitleWrapper>
                                    <Body_XS_Bold>
                                        Language{" "}
                                        <span style={{ color: "var(--Color-Text-Action)" }}>
                                            (Beta)
                                        </span>
                                    </Body_XS_Bold>
                                    <SubText>
                                        Set language of the copilot. Specifies what language (and
                                        dialect) the speech recognition will operate in. For
                                        instance, selecting en-GB optimizes speech recognition for
                                        British English.
                                    </SubText>
                                </TitleWrapper>
                                <HeaderWrapper>
                                    <CustomDropdown
                                        role="tooltip"
                                        data-tip
                                        data-tooltip-id={"dropdown"}
                                        options={Voicelanguages}
                                        placeholder={"Choose your language"}
                                        showArrow={false}
                                        defaultValue={Voicelanguages[0]}
                                        wrapperWidth="220px"
                                        value={Voicelanguages.find(
                                            (val) => val.value === allValues?.voiceAgent?.language,
                                        )}
                                        onChange={(e) => setValue("voiceAgent.language", e.value)}
                                        register={register}
                                        type="language"
                                        label="language"
                                    />
                                </HeaderWrapper>
                            </ActionWrapper>
                        </Wrapper>
                        <BrandWrapper>
                            <BrandWords>
                                <IconWrapper>{Icon(Smallcaps, { size: 24 })}</IconWrapper>
                                <TitleWrapper>
                                    <Body_XS_Bold>Boosted Keywords</Body_XS_Bold>
                                    <SubText>
                                        Provide a customized list of keywords to bias the
                                        transcriber model, so that these words are more likely to
                                        get transcribed. Commonly used for names, brands, etc.
                                    </SubText>
                                </TitleWrapper>
                            </BrandWords>
                            <ChipInput
                                cb={(value) => setValue("voiceAgent.boostedWords", value)}
                                chipValues={allValues?.voiceAgent?.boostedWords || []}
                            />
                        </BrandWrapper>
                    </>
                )}
            </BodyWrapper>
        </>
    );
};

export default VoiceSettings;
