import React from "react";

function SuccessIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="72"
            fill="none"
            viewBox="0 0 73 72"
            {...props}
        >
            <path
                fill="#1FBE5F"
                fillRule="evenodd"
                d="M36.5 66c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm14.171-34.49a2.556 2.556 0 00.06-3.712c-1.049-1.041-2.776-1.067-3.857-.057L31.898 41.718l-5.772-5.387c-1.082-1.009-2.808-.983-3.857.057a2.556 2.556 0 00.06 3.712l7.67 7.16a2.804 2.804 0 003.797 0l16.875-15.75z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default SuccessIcon;
