import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import List from "common/components/List";
import { useParams, useHistory } from "react-router-dom";
import CopilotService from "services/copilot.service";
import { useForm } from "react-hook-form";
import { InputLabel, Body_XS_Reg, Body_XS_Med } from "common/global-styled-components";
import { toast } from "react-toastify";
import CustomDropdown from "common/components/CustomDropdown";
import { PROVIDERS } from "../../Settings/Providers/Providers";
import BackButton from "common/components/BackButton";
import { getModels } from "../../../common/redux/actions/keyActions";
import { usePageTitle } from "common/utils";

const DialogWrapper = styled.div`
    padding: 0 20px;
    max-width: 805px;
    margin: 40px;
`;

const HeaderWrapper = styled.div`
    // margin-left: 20px;
    // margin-top: 20px;
`;

const ExtensionRedirect = styled.a`
    display: flex;
    padding: 10px 12px;
    align-self: end;
    align-items: center;
    gap: var(--Size-Gap-M);
    border-radius: var(--Size-CornerRadius-L);
    border: 1px solid var(--Color-Border-Disabled);
    margin: 1px 20px;
    cursor: pointer;
    text-decoration: none; // removes the underline
    color: inherit; // makes the link color the same as the surrounding text
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    margin: var(--Size-Padding-XL);
    justify-content: space-between;
    gap: var(--Size-Gap-XXL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Disabled);
`;

const InputWrapper = styled.div`
    width: 100%;
`;

const Label = styled(Body_XS_Reg)`
    color: var(--Color-Text-Default);
`;

const BackButtonWrapper = styled(BackButton)`
    margin-left: 20px;
`;

const VcCodeSurface = ({ token, type, oldAllValues, oldSetValue }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { orgId, agentId } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control,
        setError,
        reset,
    } = useForm({
        mode: "all",
        defaultValues: {},
    });

    const allValues = watch();
    usePageTitle(`${oldAllValues?.name} vs-code extension`);
    useEffect(() => {
        CopilotService.getCopilotSurface(orgId, agentId, type)
            .then((val) => {
                let res = val.data;
                let models = oldAllValues.models || [];
                let allowModelSelection = false;
                if (res.active) {
                    const provider =
                        oldAllValues.providers?.find((i) => i.value === res?.config?.providerId) ||
                        null;
                    if (provider) {
                        allowModelSelection =
                            PROVIDERS[provider?.provider]?.allowModelSelection || false;
                    }
                    if (oldAllValues.providerId !== res?.config?.providerId) {
                        models = fetchModels(res?.config?.providerId);
                    }
                    reset({
                        "providerId": res?.config?.providerId || "",
                        "model": res?.config?.model || "",
                        "allowModelSelection": allowModelSelection,
                        "models": models,
                    });
                } else {
                    const provider =
                        oldAllValues.providers?.find((i) => i.value === oldAllValues.providerId) ||
                        null;
                    if (provider) {
                        allowModelSelection =
                            PROVIDERS[provider?.provider]?.allowModelSelection || false;
                    }
                    reset({
                        "providerId": oldAllValues.providerId || "",
                        "model": oldAllValues.chatModelName || "",
                        "models": models,
                        "allowModelSelection": allowModelSelection,
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error Mapping Domains", {
                    autoClose: 2000,
                });
            });

        return () => {
            oldSetValue("isOtherSubmit", null);
        };
    }, []);

    const fetchModels = (providerId) => {
        if (providerId)
            getModels(orgId, providerId, dispatch)
                .then((res) => {
                    let list = res?.models?.chat || [];
                    // if (list.length) list.unshift({ label: "Select Model", value: null });
                    setValue("models", list);
                })
                .catch((error) => {
                    console.log(error);
                });
    };

    useEffect(() => {
        oldSetValue(
            "isOtherSubmit",
            handleSubmit(() =>
                CopilotService.updateCopilotSurface(orgId, agentId, type, {
                    "active": true,
                    "config": {
                        "model": allValues?.model,
                        "providerId": allValues?.providerId,
                    },
                })
                    .then((val) => {
                        toast.success("Vscode surface updated successfully");
                    })
                    .catch((e) => {
                        console.error(e);
                        toast.error(e?.response?.data?.message || "Error Mapping Domains", {
                            autoClose: 2000,
                        });
                    }),
            ),
        );
        return () => {
            oldSetValue("isOtherSubmit", null);
        };
    }, [allValues?.model]);

    return (
        <>
            <BackButtonWrapper
                data-testid="back-button"
                onClick={() => history.push(`/org/${orgId}/cp/${agentId}/deploy`)}
            />
            <DialogWrapper data-testid="edit-skills">
                <HeaderWrapper>
                    <List.ComponentHeader
                        title="Configure Vscode Extension"
                        subtitle="You will be able to chat with this App in Copilot.Live Extension"
                        actions={
                            <ExtensionRedirect
                                href="https://marketplace.visualstudio.com/items?itemName=CopilotLive.copilotLive"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Body_XS_Med>Download Extension</Body_XS_Med>
                            </ExtensionRedirect>
                        }
                    ></List.ComponentHeader>
                </HeaderWrapper>

                <Wrapper>
                    <Label>Change Model used in Vscode for coding</Label>
                    <InputWrapper>
                        <InputLabel>LLM Provider</InputLabel>
                        <CustomDropdown
                            options={oldAllValues.providers}
                            placeholder={"Choose your LLM provider"}
                            showArrow={false}
                            wrapperHeight="56px"
                            value={
                                oldAllValues.providers?.find(
                                    (i) => i.value === allValues.providerId,
                                ) || null
                            }
                            role="providerId"
                            type="providerId"
                            label="providerId"
                            register={register}
                            onChange={(e) => {
                                setValue("model", null);
                                setValue("providerId", e.value);
                                if (
                                    e.value &&
                                    e.value !== allValues.providerId &&
                                    PROVIDERS[e.provider]?.allowModelSelection
                                ) {
                                    fetchModels(e.value);
                                }
                                setValue(
                                    "allowModelSelection",
                                    PROVIDERS?.[e.provider]?.allowModelSelection,
                                );
                            }}
                        />
                    </InputWrapper>
                    {allValues.allowModelSelection ? (
                        <InputWrapper>
                            <InputLabel>Model</InputLabel>
                            <CustomDropdown
                                options={allValues.models}
                                showArrow={false}
                                wrapperHeight="56px"
                                value={
                                    allValues.models?.find((i) => i.value === allValues.model) ||
                                    null
                                }
                                register={register}
                                role="model"
                                type="model"
                                label="model"
                                onChange={(e) => {
                                    setValue("model", e.value);
                                }}
                            />
                        </InputWrapper>
                    ) : null}
                </Wrapper>
            </DialogWrapper>
        </>
    );
};
export default VcCodeSurface;
