import React from "react";

function PdfIcon({ ...restProps }) {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
                fill="#E2E5E7"
            />
            <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
            <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
            <path
                d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
                fill="#F15642"
            />
            <path
                d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z"
                fill="#CAD1D8"
            />
            <path
                d="M9.54649 21.4835C9.71916 21.4835 9.86966 21.4625 9.99799 21.4205C10.1263 21.3762 10.2325 21.3143 10.3165 21.235C10.4028 21.1533 10.467 21.0553 10.509 20.941C10.551 20.8243 10.572 20.6948 10.572 20.5525C10.572 20.4172 10.551 20.2947 10.509 20.185C10.467 20.0753 10.404 19.982 10.32 19.905C10.236 19.828 10.1298 19.7697 10.0015 19.73C9.87316 19.688 9.72149 19.667 9.54649 19.667H8.84299V21.4835H9.54649ZM9.54649 18.939C9.88482 18.939 10.1777 18.9787 10.425 19.058C10.6723 19.1373 10.8765 19.2482 11.0375 19.3905C11.1985 19.5328 11.3175 19.7032 11.3945 19.9015C11.4738 20.0998 11.5135 20.3168 11.5135 20.5525C11.5135 20.7975 11.4727 21.0227 11.391 21.228C11.3093 21.431 11.1868 21.606 11.0235 21.753C10.8602 21.9 10.6548 22.0143 10.4075 22.096C10.1625 22.1777 9.87549 22.2185 9.54649 22.2185H8.84299V24H7.90149V18.939H9.54649ZM17.0795 21.4695C17.0795 21.8405 17.0176 22.1812 16.894 22.4915C16.7703 22.8018 16.5965 23.069 16.3725 23.293C16.1485 23.517 15.879 23.6908 15.564 23.8145C15.249 23.9382 14.899 24 14.514 24H12.5855V18.939H14.514C14.899 18.939 15.249 19.002 15.564 19.128C15.879 19.2517 16.1485 19.4255 16.3725 19.6495C16.5965 19.8712 16.7703 20.1372 16.894 20.4475C17.0176 20.7578 17.0795 21.0985 17.0795 21.4695ZM16.1135 21.4695C16.1135 21.1918 16.0761 20.9433 16.0015 20.724C15.9291 20.5023 15.823 20.3157 15.683 20.164C15.5453 20.01 15.3773 19.8922 15.179 19.8105C14.983 19.7288 14.7613 19.688 14.514 19.688H13.5305V23.251H14.514C14.7613 23.251 14.983 23.2102 15.179 23.1285C15.3773 23.0468 15.5453 22.9302 15.683 22.7785C15.823 22.6245 15.9291 22.4378 16.0015 22.2185C16.0761 21.9968 16.1135 21.7472 16.1135 21.4695ZM19.2057 19.688V21.186H21.1027V21.9385H19.2057V24H18.2607V18.939H21.4527V19.688H19.2057Z"
                fill="white"
            />
        </svg>
    );
}

export default PdfIcon;
