import React from "react";
import styled from "styled-components";

const ListItemWrapper = styled.li`
    background: var(--Color-Background-Default);
    border-radius: var(--Size-CornerRadius-M);
    cursor: pointer;
    &:hover {
        background: var(--Color-Background-Subtle);
    }
`;

const ListItem = ({ children, ...props }) => (
    <ListItemWrapper {...props}>{children}</ListItemWrapper>
);

export default ListItem;
