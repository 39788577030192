export const ScriptInitiated = "SCRIPT_INITIATED";
export const AuthorisedUser = "AUTHORISED_USER";
export const UnAuthorisedUser = "UNAUTHORISED_USER";
export const SetFcRestoredId = "SET_FC_RESTORED_ID";
export const AffiliateConversion = "AFFILIATE_CONVERSION";
export const widgetLoaded = "WIDGET_LOADED";
export const NavItemClick = "NAV_ITEM_CLICK";
export const UserSignUpAttempt = "USER_SIGN_UP_ATTEMPT";
export const UserLoginAttempt = "USER_LOGIN_ATTEMPT";
export const UserSignUpSuccess = "USER_SIGN_UP_SUCCESS";
export const UserLoginSuccess = "USER_LOGIN_SUCCESS";
export const UserSignUpFailed = "USER_SIGN_UP_FAILED";
export const UserLoginFailed = "USER_LOGIN_FAILED";
export const ValidationError = "VALIDATION_ERROR";
export const EmailVerificationSuccess = "EMAIL_VERIFICATION_SUCCESS";
export const EmailVerificationFailed = "EMAIL_VERIFICATION_FAILED";
export const EmailVerificationContinue = "EMAIL_VERIFICATION_CONTINUE";
export const SetupOrganization = "SETUP_ORGANISATION";
export const SettingSidebarItemsClick = "SETTING_SIDEBAR_ITEMS_CLICK";
export const TransformationClick = "TRANSFORMATION_CLICK";
// export const ApplyTransformationClick = "APPLY_TRANSFORMATION_CLICK" // This will be done later in new playground design
export const ChangeImageAttempt = "CHANGE_IMAGE_ATTEMPT";
export const ChangeImageSuccess = "CHANGE_IMAGE_SUCCESS";
// export const ChangeImageFailed = "CHANGE_IMAGE_FAILED"; // to be done later
export const DownloadImageClick = "DOWNLOAD_IMAGE_CLICK";
export const CopyUrlClick = "COPY_URL_CLICK";
export const SaveAsPresetSuccess = "SAVE_AS_PRESET_SUCCESS";
export const SaveAsPresetFailed = "SAVE_AS_PRESET_FAILED";
