import React from "react";
import styled, { css } from "styled-components";

const Hr = styled.hr`
    display: flex;
    flex-direction: row;
    flex-flow: row;
    border: 0;
    background: var(--Color-Border-Subtle);

    ${(props) =>
        props.vertical
            ? css`
                  margin: 0 16px;
                  width: 1px;
                  height: 100%;
              `
            : css`
                  margin: ${props.margin ?? "16px 0"};
                  width: 100%;
                  height: 1px;
              `}
`;

const Divider = (props) => <Hr {...props} />;

export default Divider;
