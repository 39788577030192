import React from "react";

export default function KeyboardArrowDownIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={props.disabled ? "var(--disabled)" : "var( --Color-Text-Default)"}
            {...props}
        >
            <path d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 111.41 1.41l-4.59 4.59a.996.996 0 01-1.41 0L6.7 10.7a.996.996 0 010-1.41c.39-.38 1.03-.39 1.42 0z"></path>
        </svg>
    );
}
