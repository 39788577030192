import React, { useState, useCallback } from "react";
import Modal from "./index";
import styled from "styled-components";
import IconButton from "../IconButton";
import { Breakpoints } from "../../../GlobalStyle";
import Icon from "../Icon";
import { CloseCircle } from "iconsax-react";

const CancelIconWrapper = styled(IconButton)`
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 4;
    @media (max-width: ${Breakpoints.mobile}px) {
        top: 8px;
        right: 8px;
    }
    :hover {
        background: var(--Color-Background-Subtle);
    }
`;

export const useModal = (showCancel = true) => {
    const [isVisible, setIsVisible] = useState(false);
    const show = useCallback(() => setIsVisible(true), []);
    const hide = useCallback(() => setIsVisible(false), []);

    const RenderModal = useCallback(
        ({
            children,
            maxWidth,
            showCancelButton = showCancel,
            CustomModalWrapper,
            CustomModalContent,
        }) => (
            <>
                {isVisible && (
                    <Modal
                        isVisible={isVisible}
                        maxWidth={maxWidth}
                        CustomModalWrapper={CustomModalWrapper}
                        CustomModalContent={CustomModalContent}
                    >
                        {children}
                        {showCancelButton && (
                            <CancelIconWrapper onClick={hide}>
                                {Icon(CloseCircle, { size: 27 })}
                            </CancelIconWrapper>
                        )}
                    </Modal>
                )}
            </>
        ),
        [isVisible, showCancel, hide],
    );

    return {
        show,
        hide,
        RenderModal,
        isVisible,
    };
};
