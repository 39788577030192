import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

import { Body3, Body_XS_Reg } from "../../global-styled-components";
import { Breakpoints } from "../../../GlobalStyle";
import EventEmitter from "../../events";
import { NavItemClick } from "../../events/events";
import Icon from "common/components/Icon";

const NavItem = styled(NavLink)`
    text-decoration: none;
    height: 100%;
    padding: ${(props) =>
        props.mode === "secondary" ? "var(--Size-Padding-S)" : "var(--Size-Padding-L)"};
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
    text-decoration: none;

    border-bottom: ${(props) => props.mode !== "secondary" && "2px solid transparent"};
    white-space: nowrap;
    // :hover {
    //     background-color: var(--dark-800);
    // }
    justify-content: ${(props) => props.mode === "secondary" && "center"};
    width: ${(props) => props.mode === "secondary" && "auto"};
    border-radius: ${(props) => props.mode === "secondary" && "inherit"};

    &.active {
        background: ${(props) =>
            props.mode === "secondary" ? "var(--Color-Background-Default)" : "none"};
        div {
            color: var(--Color-Text-Default);
        }
        border-bottom: ${(props) =>
            props.mode === "secondary"
                ? "none"
                : props?.border || "2px solid var(--Color-Border-Action)"};
        position: relative;
    }
`;
const Text = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
`;
const SecondaryText = styled(Body3)`
    color: var(--Color-Text-Disabled);
    active {
        color: var(--Color-Text-Subtle);
    }
`;

const NavTab = ({ tab, callback, isActive, mode, border, route = true }) => {
    const orgId = useSelector((state) => state?.organizationDetails?.organization?._id);
    const { pathname, search } = useLocation();
    let newRoute = tab?.route?.replace(":orgId", orgId);
    const handleNavItemClick = (tab) => {
        if (callback) {
            callback?.(tab);
            return;
        }
        // EventEmitter.dispatch(NavItemClick, {
        //     name: tab.displayName,
        //     route: tab.route,
        //     from: { pathname, search },
        // });
    };
    return (
        <NavItem
            mode={mode}
            border={border}
            className={isActive ? "active" : ""}
            to={{
                pathname: newRoute,
                state: { from: { pathname, search } },
            }}
            activeClassName="active"
            onClick={(e) => {
                if (mode === "secondary" || !route) e.preventDefault();
                handleNavItemClick(tab);
            }}
            id={tab?.displayName?.toLowerCase() + "_navbar"}
        >
            {tab?.icon ? (
                <div>
                    {Icon(tab?.icon, {
                        size: 21,
                        color:
                            location?.pathname?.indexOf?.(tab?.identifier) !== -1
                                ? "var(--Color-Icon-Subtlest)"
                                : "var(--Color-Icon-Subtlest)",
                    })}
                </div>
            ) : (
                ""
            )}
            {mode !== "secondary" ? (
                <Text className={isActive ? "active" : ""}>{tab.displayName}</Text>
            ) : (
                <SecondaryText className={isActive ? "active" : ""}>
                    {tab.displayName}
                </SecondaryText>
            )}{" "}
            {tab.displayComponent}
        </NavItem>
    );
};

export default NavTab;
