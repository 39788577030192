import React, { useState, useEffect, useRef } from "react";
import IconLogo from "common/components/Logo/IconLogo";
import styled from "styled-components";
import Icon from "common/components/Icon";
import { Copy, ExportSquare, Magicpen } from "iconsax-react";
import {
    Body_XS_Med,
    Body_XXS_Reg,
    Body_S_Med,
    Body_XS_Reg,
    Body_L_Bold,
    Body_M_Bold,
    StatusWrapper as CopilotStatusWrapper,
} from "common/global-styled-components";
import Button from "common/components/Button";
import SlideToggle from "common/components/SlideToggle";
import { useModal } from "common/components/NewModal";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import { Breakpoints } from "GlobalStyle";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { usePageTitle } from "common/utils";
import CopilotService from "services/copilot.service";
import environment from "environment";
import { updateCopilotStatus } from "common/redux/actions/copilotActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { startCase } from "lodash";
import { fromNow } from "common/utils/utils";
import Tooltip from "common/components/Tooltip";
import { timeFormat } from "../Application/imports";
import copy from "copy-to-clipboard";

const DetailsWrapper = styled.div`
    display: flex;
    padding: var(--Size-Padding-XXL);
    justify-content: space-between;
    gap: var(--Size-Gap-XXL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
    min-height: 154px;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const CopilotDetails = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--Size-Gap-M);
`;

const CopilotDetailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-M);
`;

const CopilotName = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
        align-items: normal;
        gap: var(--Size-Gap-L);
    }
`;

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const Notification = styled.div`
    display: flex;
    padding: var(--Size-Padding-L) 16px;
    align-items: center;
    gap: var(--Size-Gap-XL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const NotificationDetail = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Size-Gap-M);
    flex: 1 0 0;

    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const Statswrapper = styled.div`
    background: var(--Color-Background-Default);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-self: stretch;
    gap: 20px;
`;

const StatCards = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-template-columns: 1fr;
    }
`;

const StatCard = styled.div`
    display: flex;
    padding: var(--Size-Padding-L);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-L);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    // flex: 1 0 0;
`;
const StatCardTitle = styled(Body_XS_Reg)`
    display: flex;
    justify-content: space-between;
    color: var(--Color-Text-Default);
    align-items: center;
    align-self: stretch;
    color: var(--Color-Text-Subtle);
`;

const StatUpdatedAt = styled(Body_XS_Reg)`
    display: flex;
    justify-content: end;
    font-size: 12px;
    font-weight: 400;
    color: var(--Color-Text-Subtle);
`;

const StatUpdatedAtSpan = styled.span`
    cursor: pointer;
`;

const StatCardStats = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--Color-Text-Subtle);
`;

const StatsNumber = styled(Body_M_Bold)`
    font-size: 32px;
    font-family: Lato;
    font-weight: 700;
    color: var(--Color-Text-Subtle);
`;

const DomainWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-M);

    align-items: center;
    font-style: ${(props) => (props?.isDomainSet ? "normal" : "italic")};
    ${Body_XS_Reg};
    font-weight: ${(props) => (props?.isDomainSet ? "normal" : "600")};
`;

const DomainName = styled.a`
    color: ${(props) => props?.isDomainSet && "var(--Color-Text-Action)"};
    @media (max-width: ${Breakpoints.mobile}px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100px;
    }
    &:hover {
        text-decoration: ${(props) => props?.isDomainSet && "underline"};
    }
`;
const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Size-Gap-XL);
    align-self: stretch;
`;

const StatsBoard = ({ copilotStats }) => {
    return copilotStats?.map(({ label, value }) => (
        <StatCard key={label}>
            <StatCardStats>
                <Body_M_Bold style={{ color: "var(--Color-Text-Subtle)" }}>
                    {value.toString().padStart(2, "0")}
                </Body_M_Bold>
            </StatCardStats>
            <StatCardTitle>{startCase(label)}</StatCardTitle>
        </StatCard>
    ));
};

const DomainActionIcons = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const NO_DOMAIN_SET_MESSAGE = "No domain set yet";

const Overview = ({ allValues, setValue, setShowCopilotPlaygroundWidget }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { orgId, agentId } = useParams();
    const {
        show: showEditStatusConfirmationDialog,
        hide: hideEditStatusConfirmationDialog,
        RenderModal: RenderEditStatusDialog,
    } = useModal();
    usePageTitle(`Copilot Platform - Copilot Details`);
    const [domain, setDomain] = useState("");
    const [copilotStats, setCopilotState] = useState([]);

    const handleToggleSubmit = async () => {
        try {
            await updateCopilotStatus(orgId, agentId, { active: !allValues.active }, dispatch);
            setValue("active", !allValues.active);
            hideEditStatusConfirmationDialog();
            toast.success("Copilot details updated successfully");
        } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong");
        }
    };

    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
        CopilotService.getCopilotStats(orgId, agentId)
            .then((res) => {
                let stats = res?.data?.stats || [];
                setCopilotState({ stats, updatedAt: res?.data?.updatedAt });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error loading stats", {
                    autoClose: 2000,
                });
            });
    }, []);

    useEffect(() => {
        if (!Array.isArray(allValues?.surfaces) || !allValues?.surfaces?.includes("hosted-web")) {
            setDomain(NO_DOMAIN_SET_MESSAGE);
            return;
        }
        CopilotService.getCopilotSurface(orgId, agentId, "hosted-web")
            .then((val) => {
                let res = val.data;
                if (res.config?.customDomain?.verified) {
                    setDomain(`https://${res.config?.customDomain?.name}`);
                } else {
                    if (res.config)
                        setDomain(
                            `https://${res.config?.predefinedDomain?.slug}.${res.config?.predefinedDomain?.subdomain}.${environment.PRIMARY_DOMAIN}`,
                        );
                }
            })
            .catch((e) => {
                console.error(e);
                setDomain("Error!, Cannot load Domain");
            });
    }, [allValues.surfaces]);

    const isDomainSet = domain !== NO_DOMAIN_SET_MESSAGE;

    return (
        <>
            <Details>
                <DetailsWrapper>
                    <IconLogo
                        height="80px"
                        width="80px"
                        src={
                            // allValues?.credentials?.file
                            allValues?.icon?.length === 1
                                ? URL.createObjectURL(allValues?.icon[0])
                                : typeof allValues?.icon === "string"
                                ? allValues?.icon
                                : ""
                        }
                    />
                    <CopilotDetails>
                        <CopilotDetailWrapper>
                            <CopilotName>
                                <Body_L_Bold>{allValues?.name}</Body_L_Bold>
                                <ToggleWrapper>
                                    <CopilotStatusWrapper active={allValues.active} />
                                    <Body_XS_Med
                                        style={{
                                            fontWeight: "bold",
                                            color: allValues?.active
                                                ? "var(--Color-Text-Success)"
                                                : "var(--Color-Text-Danger)",
                                        }}
                                    >
                                        {allValues?.active ? "Active" : "Inactive"}
                                    </Body_XS_Med>
                                    <SlideToggle
                                        checked={allValues.active}
                                        onChange={showEditStatusConfirmationDialog}
                                    />
                                </ToggleWrapper>
                            </CopilotName>
                            <Body_XS_Reg style={{ color: "var(--Color-Text-Subtlest)" }}>
                                Created on {moment(allValues?.createdAt).format("DD MMMM, YYYY")}
                            </Body_XS_Reg>
                        </CopilotDetailWrapper>
                        <CopilotDetailWrapper>
                            {/* <TargetWrapper>
                                    Target Surfaces: &nbsp;
                                    {allValues?.surfaces?.length > 0 ? (
                                        allValues?.surfaces.map((val) => (
                                            <IconLogo
                                                src={SURFACES_ICON[val]}
                                                width="18px"
                                                height="28px"
                                            />
                                        ))
                                    ) : (
                                        <DomainWrapper>Not set</DomainWrapper>
                                    )}
                                </TargetWrapper> */}
                            <CopilotName>
                                <Body_S_Med style={{ display: "flex" }}>
                                    Domain:&nbsp;&nbsp;
                                    <DomainWrapper isDomainSet={isDomainSet}>
                                        <DomainName
                                            isDomainSet={isDomainSet}
                                            href={isDomainSet ? domain : undefined}
                                            target="_blank"
                                        >
                                            {domain}
                                        </DomainName>
                                        {isDomainSet && (
                                            <DomainActionIcons>
                                                {Icon(Copy, {
                                                    size: 16,
                                                    onClick: () => {
                                                        copy(domain);
                                                        toast.info("Copied to clipboard", {
                                                            autoClose: 2000,
                                                        });
                                                    },
                                                    style: { cursor: "pointer" },
                                                })}
                                            </DomainActionIcons>
                                        )}
                                    </DomainWrapper>
                                </Body_S_Med>
                                {!isDomainSet && (
                                    <ToggleWrapper>
                                        <Button
                                            mode="secondary"
                                            onClick={() =>
                                                history.push(
                                                    `/org/${orgId}/cp/${agentId}/deploy/hosted-web`,
                                                )
                                            }
                                            size={"small"}
                                        >
                                            Connect Your Own Domain
                                        </Button>
                                    </ToggleWrapper>
                                )}
                            </CopilotName>
                        </CopilotDetailWrapper>
                    </CopilotDetails>
                </DetailsWrapper>
                {allValues?.dataSources?.length == 0 && (
                    <Notification>
                        {Icon(Magicpen, { size: 21 })}

                        <NotificationDetail>
                            <Body_XS_Med>Connect Your Data Source</Body_XS_Med>
                            <Body_XXS_Reg>
                                Make sure to add your datasource to your copilots for better
                                personalised answers
                            </Body_XXS_Reg>
                        </NotificationDetail>

                        <Button
                            size="small"
                            onClick={() => history.push(`/org/${orgId}/cp/${agentId}/datasources`)}
                        >
                            Create
                        </Button>
                    </Notification>
                )}

                <Statswrapper>
                    <StatCardTitle>Total Data Sources Connected</StatCardTitle>
                    <StatCardStats>
                        <StatsNumber>
                            {allValues?.dataSources?.length?.toString().padStart(2, "0")}
                        </StatsNumber>
                    </StatCardStats>
                    <StatCards>
                        {copilotStats?.stats?.length > 0 && (
                            <StatsBoard copilotStats={copilotStats?.stats} />
                        )}
                    </StatCards>
                    <StatUpdatedAt>
                        <StatUpdatedAtSpan
                            role="tooltip"
                            data-tooltip-id={"overview_updatedat"}
                            data-tip
                        >
                            Updated {fromNow(new Date(copilotStats?.updatedAt))}
                            <Tooltip id={"overview_updatedat"}>
                                {timeFormat("dd LLL yyyy, hh:mm a")(
                                    new Date(copilotStats?.updatedAt),
                                )}
                            </Tooltip>
                        </StatUpdatedAtSpan>
                    </StatUpdatedAt>
                </Statswrapper>

                <RenderEditStatusDialog maxWidth="500px" showCancelButton={false}>
                    <ConfirmationDialog
                        title={allValues.active ? "De-activate" : "Activate"}
                        content={
                            <>
                                Are you sure you want to change status of Copilot{" "}
                                <strong title={allValues.name}>{allValues.name}</strong> ?
                            </>
                        }
                        okText={"Yes"}
                        cancelText={"No"}
                        type="danger"
                        handleOkClick={handleToggleSubmit}
                        handleCancelClick={hideEditStatusConfirmationDialog}
                        // handleCancelClick={hideEditZoneConfirmationDialog}
                        data-testid="edit-agent-confirmation-dialog"
                    />
                </RenderEditStatusDialog>
            </Details>
        </>
    );
};

export default Overview;
