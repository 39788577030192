import React from "react";
import styled from "styled-components";
import LoaderIcon from "common/components/LoaderIcon";
const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const BaseButton = styled.button`
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "var(--Size-CornerRadius-L)"};
    color: var(--Color-Text-Inverse);
    font-family: var(--body-font);
    font-style: normal;
    overflow: hidden;
    position: relative;
    display: ${(props) => (props.truncateText ? "unset" : "flex")};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    width: ${(props) => props.width};
    max-width: ${(props) => props.maxWidth};
    min-width: fit-content;
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-right: ${({ marginRight }) => marginRight};
    margin-top: ${({ marginTop }) => marginTop};
    white-space: ${({ truncateText }) => truncateText && "nowrap"};
    text-overflow: ${({ truncateText }) => truncateText && "ellipsis"};
    letter-spacing: ${({ size }) =>
        size === "default"
            ? "var(--letter-spacing-1)"
            : size === "small"
            ? "var(--letter-spacing-15)"
            : "var(--letter-spacing-1)"};
    line-height: 120%;
    padding: ${({ size }) =>
        size === "default" ? "0 24px" : size === "small" ? "0 16px" : "0 40px"};
    height: ${({ size }) => (size === "default" ? "44px" : size === "small" ? "40px" : "56px")};
    font-size: ${({ size }) =>
        size === "default"
            ? "var(--button)"
            : size === "small"
            ? "var(--button-small)"
            : "var(--button)"};
    @media (max-width: 767px) {
        font-size: var(--body-3-d);
        // in mobile button size will always be small
        font-size: var(--button-small);
        height: 40px;
        padding: 0 16px;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const PrimaryButton = styled(BaseButton)`
    background: ${(props) =>
        props.background ? props.background : "var(--Color-Background-Gradient-Action)"};
    color: var(--Color-Text-Inverse);
    :hover {
        background: var(--Color-Primary-Hover);
    }
`;

const SecondaryButton = styled(BaseButton)`
    background: ${(props) =>
        props.background ? props.background : "var(--Color-Background-Subtle)"};
    border: ${(props) => (props.border ? props.border : "1px solid var(--Color-Border-Subtle)")};
    color: var(--Color-Text-Default);
    :hover {
        border: 1px solid var(--Color-Border-Default);
    }
`;

const TertiaryButton = styled(BaseButton)`
    background: ${(props) => (props.background ? props.background : "rgba(255, 255, 255, 0)")};
    color: var(--Color-Text-Default);
    border: ${(props) => (props.border ? props.border : "1px solid var(--Color-Border-Subtle)")};
    :hover {
        border: 1px solid var(--Color-Border-Default);
    }
`;

const DangerButton = styled(BaseButton)`
    background-color: var(--Color-Background-Danger);
    color: var(--Color-Text-Inverse);
    :hover {
        background-color: var(--Color-Background-Danger);
    }
`;
const DangerButton2 = styled(BaseButton)`
    background: none;
    color: var(--Colot-Text-Inverse);
    :hover {
        color: var(--Color-Text-Inverse);
        background-color: var(--Color-Background-Danger);
    }
`;
const DangerButton3 = styled(BaseButton)`
    background: none;
    color: var(--Colot-Text-Inverse);
    border: 1px solid var(--Color-Border-Accent-Warning);
`;

const Button = ({
    children,
    loader = false,
    mode, // cta, primary, secondary, tertiary, danger
    size = "default", // default, small, large
    ...props
}) => {
    switch (mode) {
        case "cta":
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </PrimaryButton>
            );
        case "primary":
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </PrimaryButton>
            );
        case "secondary":
            return (
                <SecondaryButton data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </SecondaryButton>
            );
        case "tertiary":
            return (
                <TertiaryButton data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </TertiaryButton>
            );
        case "danger":
            return (
                <DangerButton data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </DangerButton>
            );
        case "danger2":
            return (
                <DangerButton2 data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </DangerButton2>
            );
        case "danger3":
            return (
                <DangerButton3 data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </DangerButton3>
            );
        default:
            return (
                <PrimaryButton data-testid="sample-btn" size={size} {...props}>
                    {loader ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : (
                        children
                    )}
                </PrimaryButton>
            );
    }
};

export default Button;
