import { Breakpoints } from "../../../GlobalStyle";
import GitHubIcon from "../../../common/assets/GithubIcon";
import {
    SubTitle1,
    InputLabel,
    Body3,
    Caption1,
    PageContent,
} from "../../../common/global-styled-components";
import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { Stepper } from "react-form-stepper";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Button from "../../../common/components/Button";
import Icon from "../../../common/components/Icon";
import Tile from "../../../common/components/Tile";
import Tag from "../../../common/components/Tag";
import FormStepper from "../../../common/components/Stepper";
import Credentials from "./Credentials";
import CopilotList from "./CopilotsList";
import CopilotService from "services/copilot.service";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SkillDetails from "./Details";
import FormFields from "../../../common/components/FormFields";

const Header = {};
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;

    @media (max-width: ${Breakpoints.mobileXS}px) {
        font-size: var(--subtitle-2-m);
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
    }
`;
const Wrapper = styled.div`
    flex: 1;
    max-height: 90vh;
    overflow: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
`;

const StyledPageContent = styled(PageContent)`
    background: transparent;
    margin: var(--Size-Padding-XL) 0px 0px 0px;
    padding: 0;
    gap: var(--Size-Gap-XXXL);
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    border-radius: 0px;
`;

/**
 *
 * @param {*} callback must be a callback function
 * @returns
 */
function SkillInstall({ skillDetails, hide, copilotId = "", callback }) {
    const [title, setTitle] = useState(["Install Skill"]);
    const [disableStepperContinue, setDisableStepperContinue] = useState(false);
    const { orgId } = useParams();

    const {
        unregister,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        control,
        watch,
    } = useForm({
        mode: "all",
        defaultValues: { credentials: {}, inactiveSkills: [], type: "" },
    });
    const allValues = watch();

    const [steps, setSteps] = useState([
        {
            title: "Install Skill",
            component: "SkillDetails",
        },
        {
            title: "Select Copilot",
            component: "CopilotList",
        },
        {
            title: "Configure Skill",
            component: "Credentials",
        },
    ]);

    useEffect(() => {
        let _steps = [...steps];
        if (!Object.keys(skillDetails?.configuration?.inputSchema?.properties || {})?.length) {
            _steps = steps.filter((step) => step.title !== "Configure Skill");
        }
        if (copilotId) {
            _steps = _steps.filter((step) => step.title !== "Select Copilot");
        }
        setSteps(_steps);
    }, []);

    const renderComponent = (componentName) => {
        switch (componentName) {
            case "SkillDetails":
                return (
                    <StyledPageContent>
                        <SkillDetails skillDetails={skillDetails} />
                    </StyledPageContent>
                );
            case "CopilotList":
                return (
                    <StyledPageContent>
                        <CopilotList
                            setValue={setValue}
                            allValues={allValues}
                            setDisableStepperContinue={setDisableStepperContinue}
                        />
                    </StyledPageContent>
                );
            case "Credentials":
                return (
                    <StyledPageContent>
                        <Credentials
                            skillDetails={skillDetails}
                            register={register}
                            control={control}
                            allValues={allValues}
                            errors={errors}
                            reset={reset}
                            setValue={setValue}
                        />
                    </StyledPageContent>
                );
            default:
                return null;
        }
    };
    const submit = async () => {
        try {
            if (disableStepperContinue) return;
            setDisableStepperContinue(true);
            const body = {
                configuration: {
                    ...allValues.credentials,
                },
                inactiveSkills: allValues.inactiveSkills,
                copilotAppId: allValues.type || copilotId,
                skillId: skillDetails.id,
            };
            const res = await CopilotService.createCopilotSkills(
                orgId,
                allValues.type || copilotId,
                body,
            );
            toast.success(`Skills added successfully`, {
                autoClose: 2000,
            });
            if (callback) callback(res);
            hide();
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message, { autoClose: 2000 });
                return;
            }
            toast.error(`Error adding skills`, {
                autoClose: 2000,
            });
            // callback?.();
        } finally {
            setDisableStepperContinue(false);
        }
    };
    return (
        <Wrapper>
            <Header.Title>{title}</Header.Title>
            <Body>
                <FormStepper
                    submitText="Save & Install"
                    steps={steps.map((step) => ({
                        title: step.title,
                        component: renderComponent(step.component),
                    }))}
                    onSubmit={handleSubmit(submit)}
                    checkIfNoError={handleSubmit}
                    disableContinue={disableStepperContinue}
                    disableSubmit={disableStepperContinue}
                    hideStepperTitle={true}
                    setTitle={setTitle}
                ></FormStepper>
            </Body>
        </Wrapper>
    );
}

export default SkillInstall;
