const PRIMARY_DOMAIN = "copilotz0.de";
const environment = window._conf || {
    VALUE: "development",
    PORT: 9090,
    RECAPTCHA_KEY: "6LetJmMlAAAAACUVvUJxZIS8_bLcNkgeX0fkXyQ7",
    DISABLE_CAPTCHA: true,
    GOOGLE_APP_ID: "1013376942527-0lsgohmchelpsbaj9qqvjb6i9cgnla5q.apps.googleusercontent.com",
    PRIMARY_DOMAIN,
    COPILOT_BASE_URL: `https://local.${PRIMARY_DOMAIN}:9090/`,
    APOC_MAIN_URL: `https://api.${PRIMARY_DOMAIN}/service/panel/users`,
    APOC_WEBHOOK_URL: `https://api.${PRIMARY_DOMAIN}/service/webhook/users`,
    NEO_SCRIPT_DOMAIN: `https://script.${PRIMARY_DOMAIN}`,
    NIOBE_MAIN_URL: `https://api.${PRIMARY_DOMAIN}/service/panel/organization`,
    NEO_MAIN_URL: `https://api.${PRIMARY_DOMAIN}/service/panel/console`,
    NEO_WEBHOOKS_URL: `https://api.${PRIMARY_DOMAIN}/service/webhooks`,
    TRINITY_MAIN_DOMAIN: `https://local.${PRIMARY_DOMAIN}:9090`,
    API_MAIN_DOMAIN: `https://api.${PRIMARY_DOMAIN}`,
    GTM_CONTAINER_ID: "GTM-WMSHMQH",
};

export default environment;
