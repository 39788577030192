import React from "react";

function TextFileIcon({ ...restProps }) {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
                fill="#E2E5E7"
            />
            <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
            <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
            <path
                d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z"
                fill="#747272"
            />
            <path
                d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z"
                fill="#CAD1D8"
            />
            <path
                d="M11.2031 19.7125H9.67709V24H8.73559V19.7125H7.20959V18.939H11.2031V19.7125ZM16.3279 24H15.3899C15.3245 24 15.272 23.9837 15.2324 23.951C15.195 23.9183 15.1647 23.881 15.1414 23.839L13.9689 21.8965C13.9502 21.9548 13.928 22.005 13.9024 22.047L12.7789 23.839C12.7509 23.8787 12.7182 23.916 12.6809 23.951C12.6459 23.9837 12.5992 24 12.5409 24H11.6624L13.3424 21.396L11.7289 18.939H12.6669C12.7322 18.939 12.7789 18.9483 12.8069 18.967C12.8372 18.9833 12.8652 19.0113 12.8909 19.051L14.0424 20.906C14.0657 20.8477 14.0937 20.7893 14.1264 20.731L15.1834 19.0685C15.209 19.0242 15.237 18.9915 15.2674 18.9705C15.2977 18.9495 15.3362 18.939 15.3829 18.939H16.2824L14.6549 21.3575L16.3279 24ZM20.7966 19.7125H19.2706V24H18.3291V19.7125H16.8031V18.939H20.7966V19.7125Z"
                fill="white"
            />
        </svg>
    );
}

export default TextFileIcon;
