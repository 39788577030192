// import { DataSourceAction } from "../actions/datasourceActions";

// const initialState = {
//     datasourceList: {
//         datasources: [],
//         page: {},
//     },
//     datasource: {},
// };

// const datasourceReducer = (state = initialState, action) => {
//     const { type, payload } = action;
//     const newState = { ...state };
//     let datasource;

//     switch (type) {
//         case DataSourceAction.GetDataSources:
//             // do not overrider zones list, instead concatenate them
//             const prevDataSources = state.datasourceList.datasources;
//             const nextDataSources = payload.datasourceList.datasources;
//             const updatedState = { ...state, ...payload };
//             updatedState.datasourceList.datasources = [...prevDataSources, ...nextDataSources];
//             return updatedState;
//         case DataSourceAction.DataSourceCreateSuccess:
//             datasource = payload.datasource;
//             newState.datasourceList.datasources[datasource.id] = datasource;
//             return {
//                 ...state,
//                 ...newState,
//             };
//         case DataSourceAction.DataSourceCreateFailed:
//             return {
//                 ...state,
//             };
//         case DataSourceAction.UpdateDataSource:
//             if (payload.isDataSourceUpdated) {
//                 datasource = payload.datasource;
//                 newState.datasourceList.datasources[datasource.id] = datasource;
//                 return {
//                     ...state,
//                     ...newState,
//                 };
//             }
//             return state;
//         case DataSourceAction.GetDataSource:
//             if (payload.exists) {
//                 datasource = payload.datasource;
//                 newState.datasourceList.datasources[datasource.id] = datasource;
//                 return {
//                     ...state,
//                     ...newState,
//                 };
//             }
//             return state;
//         case DataSourceAction.DeleteDataSource:
//             datasource = payload.datasource;
//             newState.datasourceList.datasources = newState.datasourceList.datasources.filter(
//                 (datasource) => datasource.dd !== datasource,
//             );
//             return {
//                 ...state,
//                 ...newState,
//             };

//         default:
//             return state;
//     }
// };

// export default datasourceReducer;
