import React from "react";
import ReactDOM from "react-dom";
import store from "./common/redux/store";
import { GlobalStyle } from "./GlobalStyle";
import App from "./App";
import { Provider } from "react-redux";
import "./common/events";

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <GlobalStyle />
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById("root"),
);
