import { useLottie } from "lottie-react";
import copilotIcon from "./copilotIcon.json";

const LottieLoader = () => {
    const style = {
        height: 100,
    };

    const options = {
        animationData: copilotIcon,
        loop: true,
        autoplay: true,
    };

    const { View } = useLottie(options, style);

    return View;
};

export default LottieLoader;
