import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const SyncService = {
    getdatasourceHistory(orgId, copilotId, datasourceId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotDataSourceHistory}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    syncDataSources(orgId, copilotId, datasourceId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            URLS.copilotDataSouceSync
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    syncDataSourceStatus(orgId, copilotId, datasourceId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.copilotDataSouceSync
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    scheduleDataSourcesSync(orgId, copilotId, datasourceId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.put(
            URLS.copilotDataSouceSyncSchedule
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    syncDataSourceFile(orgId, copilotId, datasourceId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            URLS.copilotDataSouceFileSync
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    fetchSignedUrlAssets(orgId, path) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.fetchSignedUrlAssets}${path}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
};

export default SyncService;
