import React from "react";

const ErrorIcon = ({ color, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="none"
            viewBox="0 0 64 64"
            {...props}
        >
            <path
                d="M28.8 41.6h6.4V48h-6.4v-6.4zm0-25.6h6.4v19.2h-6.4V16zm3.184-16C14.304 0 0 14.32 0 32s14.304 32 31.984 32S64 49.68 64 32 49.664 0 31.984 0zM32 57.6C17.856 57.6 6.4 46.144 6.4 32 6.4 17.856 17.856 6.4 32 6.4c14.144 0 25.6 11.456 25.6 25.6 0 14.144-11.456 25.6-25.6 25.6z"
                fill={color}
            ></path>
        </svg>
    );
};

export default ErrorIcon;
