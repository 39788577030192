import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import List from "common/components/List";
import Button from "common/components/Button";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
    Body_S_Bold,
    Body_XS_Reg,
    SubTitle2,
    Body_S_Reg,
    Body_XS_Med,
} from "common/global-styled-components";
import LoaderIcon from "../../../common/components/LoaderIcon";
import AdvancedPagination from "common/components/AdvancedPagination";
import debounce from "lodash.debounce";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import Icon from "common/components/Icon";
import { Add, AddCircle, Data } from "iconsax-react";
import { Breakpoints } from "GlobalStyle";
import { usePageTitle } from "common/utils";
import WorkflowService from "../../../services/workflow.service";
import BolticIcon from "common/assets/BolticIcon";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    flex-grow: 1;
    justify-content: space-between;
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--Size-Gap-L);
`;

const SearchPlaceHolderWrapper = styled(SearchPlaceHolder)`
    height: 12px;
`;
const AddMoreButton = styled.div`
    cursor: pointer;
`;

const CardsWrapper = styled.div`
    display: grid;
    gap: var(--Size-Gap-XL);
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-content: center;

    @media (max-width: ${Breakpoints.mobile}px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
`;

const Card = styled.div`
    display: flex;
    padding: var(--Size-Padding-XXL);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-XL);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const SkillDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-S);
    align-self: stretch;
`;

const CardDescription = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CardTitle = styled(Body_S_Bold)`
    gap: var(--Size-Gap-M);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: -webkit-fill-available;
`;

const DefaultIcon = styled.div`
    rotate: 90deg;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: var(--Size-CornerRadius-L);
    background: var(--Color-Background-Accent-Action);
`;
const AddCopilotInlineIcon = styled.div`
    background: var(--Color-Background-Subtle);
    border-radius: 50%;
    padding: var(--Size-Padding-L);
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HeaderTitle = styled(SubTitle2)`
    @media (max-width: ${Breakpoints.mobileXS}px) {
        margin-top: -8px;
        margin-left: -12px;
    }
`;
const BrandIconWrapper = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-M);
`;
const Workflows = ({ setShowCopilotPlaygroundWidget }) => {
    const { orgId, agentId } = useParams();
    const [searchName, setSearchName] = useState("");

    const [workflows, setWorkflows] = useState([]);
    const [isWorkflowProviderConnected, setIsWorkflowProviderConnected] = useState(false);

    const [pageSize, setPageSize] = useState(10);
    const [isPageLoading, setPageLoading] = useState(true);
    const [isPopUpBlocked, setIsPopUpBlocked] = useState(false);
    const [builderUrl, setBuilderUrl] = useState("");
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [windowMap, setWindowMap] = useState({});

    usePageTitle(`Copilot Platform - Copilot Workflows`);

    const debounceFetch = useMemo(() => debounce(fetchWorkflows, 300), []);

    const messageHandler = (e) => {
        switch (e.data?.event) {
            case "connection_success":
                toast.success(`Workflow Engine Connected`, { autoClose: 1000 });
                setIsWorkflowProviderConnected(true);
                let queryString = `${getQueryParams()}&q=${searchName}`;
                debounceFetch(orgId, agentId, queryString);

                break;
            case "connection_failed":
                toast.error(`Unable to connect to workflow engine`, { autoClose: 1000 });
                setWorkflows([]);
                setPageDetails({ totalItems: 0, page: 1, limit: 10 });
                break;
        }
        switch (e.data?.source) {
            case "boltic":
                toast.info(`Workflow saved`, { autoClose: 2000 });
                debounceFetch(orgId, agentId, `page=1&limit=10`);
                const id = e.data?.payload?.data?.workflow_id;
                const _window = windowMap[id] || windowMap["new"];
                if (e.data?.payload?.event === "blt_wf_close") {
                    _window?.close?.();
                }
                break;
        }
    };

    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
        window.addEventListener("message", messageHandler);
        return () => {
            window.removeEventListener("message", messageHandler);
        };
    }, []);
    useEffect(() => {
        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetch(orgId, agentId, queryString);
        }
    }, [currentPage, searchName]);

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";

        return [pageQuery, pageSizeQuery].join("&");
    };
    // function mockClick() {
    //     const elem = document.getElementById("add-workflow");
    //     elem.click();
    // }

    function fetchWorkflows(orgId, agentId, queryString, openBuilder) {
        WorkflowService.getAll(orgId, agentId, "boltic", queryString)
            .then((res) => {
                setWorkflows(res.data?.items || []);
                setBuilderUrl(res?.data?.builderUrl);
                setPageDetails(res.data.page);
                setIsWorkflowProviderConnected(true);
                if (openBuilder) openWorkflow(res?.data?.builderUrl);
            })
            .catch((err) => {
                // toast.error(`Unable to retrieve to workflow connection details`);
                setIsWorkflowProviderConnected(false);
                console.log("err", err);
            })
            .finally(() => {
                setPageLoading(false);
            });
    }
    function connectToWorkflowProvider(workflowId) {
        let qs = "";
        if (workflowId) {
            qs = `workflowId=${workflowId}`;
        }
        WorkflowService.connect(orgId, "boltic", qs)
            .then((res) => {
                // eslint-disable-next-line no-eval
                window.open(res.data?.windowUrl);
            })
            .catch((err) => {
                console.log("err", err);
            });
    }

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    const openWorkflow = (url, id) => {
        const newWindow = window.open(url || builderUrl, "_blank");
        if (!newWindow) {
            setIsPopUpBlocked(true);
        }
        // todo keep new window object as reference to close it later
        setWindowMap((prev) => ({
            ...prev,
            [id || "new"]: newWindow,
        }));
    };

    return (
        <>
            <List.ComponentHeader
                $padding="0px"
                $overallPadding="0px"
                actions={
                    <ActionWrapper>
                        <SearchPlaceHolderWrapper
                            searchName={searchName}
                            handleSearchChange={handleSearchChange}
                            width="100%"
                        />
                        <AddMoreButton
                            onClick={(e) => {
                                e.stopPropagation();
                                connectToWorkflowProvider();
                            }}
                        >
                            {Icon(AddCircle, { size: 32, variant: "Bold" })}
                        </AddMoreButton>
                    </ActionWrapper>
                }
            >
                <HeaderTitle>Workflows</HeaderTitle>
            </List.ComponentHeader>
            {isPageLoading ? (
                <Loader>
                    <LoaderIcon />
                </Loader>
            ) : (
                <>
                    <Wrapper>
                        <CardsWrapper>
                            <Card>
                                <CardHeader>
                                    <AddCopilotInlineIcon
                                        id={"add-workflow"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            connectToWorkflowProvider();
                                        }}
                                    >
                                        {Icon(Add, {
                                            size: 24,
                                            color: "var(--Color-Icon-Default)",
                                        })}
                                    </AddCopilotInlineIcon>
                                </CardHeader>
                                <SkillDetails>
                                    <Body_S_Reg>Custom Workflow</Body_S_Reg>
                                    <CardDescription>
                                        Dive into our powerful editor and start creating
                                    </CardDescription>
                                </SkillDetails>
                            </Card>
                            {workflows.map((val) => (
                                <Card key={val.id}>
                                    <CardHeader>
                                        <DefaultIcon>
                                            {Icon(Data, {
                                                size: 28,
                                                color: "var(--Color-Icon-Action)",
                                            })}
                                        </DefaultIcon>
                                        <Button
                                            mode="secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                connectToWorkflowProvider(val.id);
                                            }}
                                        >
                                            <Body_XS_Med>Open</Body_XS_Med>
                                        </Button>
                                    </CardHeader>
                                    <SkillDetails>
                                        <CardTitle>{val?.name}</CardTitle>
                                        <CardDescription>
                                            Add some secondary description about above title
                                        </CardDescription>
                                    </SkillDetails>
                                </Card>
                            ))}
                        </CardsWrapper>
                        <AdvancedPagination
                            page={pageDetails}
                            pageSize={pageSize}
                            paginationInfo="Workflow"
                            setPageSize={setPageSize}
                            setCurrentPage={setCurrentPage}
                        >
                            <BrandIconWrapper href="https://www.boltic.io/" target="_blank">
                                <Body_XS_Reg>Powered By</Body_XS_Reg>
                                {Icon(BolticIcon, {
                                    height: 24,
                                    color: "var(--Color-Icon-Default)",
                                })}
                            </BrandIconWrapper>
                        </AdvancedPagination>
                    </Wrapper>
                </>
            )}
        </>
    );
};
export default Workflows;
