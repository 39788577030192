import DataSourceService from "../../../services/datasource.service";

export const DataSourceAction = {
    DataSourceCreateSuccess: "CREATE_DATASOURCE_SUCCESS",
    DataSourceCreateFailed: "CREATE_DATASOURCE_FAILED",
    CreateDataSource: "CREATE_DATASOURCE",
    GetDataSources: "GET_DATASOURCES",
    GetDataSource: "GET_DATASOURCE",
    UpdateDataSource: "UPDATE_DATASOURCE",
    DeleteDataSource: "DELETE_DATASOURCE",
};

export const createCopilotDataSources = (orgId, copilotAppId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        DataSourceService.createCopilotDataSources(orgId, copilotAppId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: DataSourceAction.DataSourceCreateSuccess,
                    payload: { isZoneRegistered: true, datasource: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DataSourceAction.DataSourceCreateFailed,
                    payload: { isZoneRegistered: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const getCopilotDataSources = (orgId, copilotAppId, queryParams, dispatch) => {
    return new Promise((resolve, reject) => {
        DataSourceService.getCopilotDataSources(orgId,copilotAppId,queryParams) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: DataSourceAction.GetDataSources,
                    payload: {
                        datasourceList: { datasources: payload.items, page: payload.page },
                    },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DataSourceAction.GetDataSources,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const updateCopilotDataSources = (orgId, copilotAppId, datasourceId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        DataSourceService.updateCopilotDataSources(orgId, copilotAppId, datasourceId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: DataSourceAction.UpdateDataSource,
                    payload: { isDataSourceUpdated: true, datasource: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DataSourceAction.UpdateDataSource,
                    payload: { isDataSourceUpdated: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const getCopilotDataSource = (orgId, copilotAppId, datasourceId, dispatch) => {
    return new Promise((resolve, reject) => {
        DataSourceService.getCopilotDataSource(orgId, copilotAppId, datasourceId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: DataSourceAction.GetDataSource,
                    payload: { exists: true, datasource: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DataSourceAction.GetDataSource,
                    payload: { exists: false, datasource: null },
                });
                reject(err);
            });
    });
};

export const getDataSourceLogs = (orgId, copilotId, datasourceId, dataSyncHistoryId) => {
    return new Promise((resolve, reject) => {
        DataSourceService.getDataSourceLogs(orgId, copilotId, datasourceId, dataSyncHistoryId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteCopilotDataSources = (orgId, copilotAppId, datasourceId, dispatch) => {
    return new Promise((resolve, reject) => {
        DataSourceService.deleteCopilotDataSources(orgId, copilotAppId, datasourceId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: DataSourceAction.DeleteDataSource,
                    payload: { exists: true, datasource: datasourceId, copilotId: copilotAppId },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DataSourceAction.DeleteDataSource,
                    payload: { exists: false, datasource: null, copilotId: copilotAppId },
                });
                reject(err);
            });
    });
};
