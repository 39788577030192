import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";
import axios from "axios";
import Pixelbin from "@pixelbin/core";
const AuthService = {
    register(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.registerUser, axiosOptions);
    },

    login(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.loginUser, axiosOptions);
    },

    forgotPassword(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.forgotPassword, axiosOptions);
    },

    resetPassword(formData) {
        let axiosOptions = Object.assign(
            {},
            { data: formData },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.resetPassword, axiosOptions);
    },

    verifyEmail(code) {
        let axiosOptions = Object.assign(
            {},
            { data: { code } },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.verifyEmail, axiosOptions);
    },

    loginViaGoogle(data) {
        let axiosOptions = Object.assign(
            {},
            { data },
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.googleLogin, axiosOptions);
    },

    logout() {
        let axiosOptions = Object.assign(
            {},
            {},
            // { params: params },
            // this.getCommonHeaderOptions()
        );
        return ApiService.post(URLS.logoutUser, axiosOptions);
    },

    updateProfile(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.patch(URLS.profileDetails, axiosOptions);
    },

    updateEmail(updateOpts) {
        const axiosOptions = {
            data: updateOpts,
        };
        return ApiService.post(URLS.updateEmailInit, axiosOptions);
    },

    verifyEmailUpdate(code) {
        return ApiService.post(URLS.updateEmailComplete, {
            data: { code },
        });
    },

    getSignedURL(orgId = "", data) {
        const axiosOptions = {
            data,
        };
        return orgId
            ? ApiService.post(URLS.uploadAssets.replace(":orgId", orgId), axiosOptions)
            : ApiService.post(URLS.uploadAssetsForPublicNameSpace, axiosOptions);
    },

    uploadAssets({ method, url, file, fields }) {
        switch (method) {
            case "PUT":
                return axios.put(url, file, {
                    withCredentials: false,
                    headers: {
                        "Content-Type": file.type,
                        ...fields,
                    },
                });
            case "POST":
                return Pixelbin.upload(file, { url, fields });
            default:
                throw new Error("Invalid method");
        }
    },

    updatePassword(newPassword) {
        return ApiService.post(URLS.updatePassword, { data: { password: newPassword } });
    },

    updateMeta(updateOpts) {
        return ApiService.patch(URLS.updateMeta, { data: updateOpts });
    },
};

export default AuthService;
