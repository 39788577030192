import styled, { css } from "styled-components";

export const H1 = styled.h1`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--bold);
    font-size: var(--h1-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-15N);
    line-height: var(--line-height-120);

    @media (max-width: 1024px) {
        font-size: 40px;
        line-height: 42px;
    }

    @media (max-width: 768px) {
        font-size: var(--h1-m);
    }
`;

export const H2 = styled.h2`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--bold);
    font-size: var(--h2-d);
    letter-spacing: var(--letter-spacing-1N);
    line-height: var(--line-height-120);

    @media (max-width: 768px) {
        font-size: var(--h2-m);
    }
`;

export const H3 = styled.h3`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--bold);
    font-size: var(--h3-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-05N);
    line-height: var(--line-height-120);

    @media (max-width: 768px) {
        font-size: var(--h3-m);
    }
`;

export const LeadBody = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--lead-body-d);
    line-height: var(--line-height-140);

    @media (max-width: 767px) {
        font-size: var(--lead-body-m);
    }
`;

export const SubTitle1 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--subtitle-1-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--subtitle-1-m);
    }
`;

export const SubTitle2 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--subtitle-2-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--subtitle-2-m);
    }
`;

export const Body1 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-1-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-1-m);
    }
`;

export const Body2 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-2-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-2-m);
    }
`;

export const Body3 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-3-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-140);

    @media (max-width: 768px) {
        font-size: var(--body-3-m);
        letter-spacing: var(--letter-spacing-1);
    }
`;

export const SubHeading1 = styled.div`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: var(--regular);
    font-size: var(--body-2-d);
    color: var(--Color-Text-Default);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-21);

    @media (max-width: 768px) {
        font-size: var(--body-2-m);
        letter-spacing: var(--letter-spacing-1);
    }
`;

export const Caption1 = styled.p`
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: normal;
    font-size: var(--caption-1-d);
    color: var(--Color-Text-Default);
    /* letter-spacing: var(--caption-1-letter-spacing-d); */
    line-height: var(--line-height-22);

    @media (max-width: 768px) {
        font-size: var(--caption-1-m);
        /* letter-spacing: var(--caption-1-letter-spacing-m); */
        line-height: var(--line-height-16);
    }
`;

export const ContentDescr = styled.p`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: var(--body-1-d);
    color: var(--white-90);
    /* letter-spacing: var(--letter-spacing); */
    line-height: var(--line-height-28);
    color: var(--white-80);

    @media (max-width: 1024px) {
        font-size: var(--body-1-m);
        line-height: var(--line-height-28);
    }
`;

export const Container = styled.div`
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
    color: var(--Color-Text-Default);
`;

export const Button = styled.h2`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--button-default-d);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-120);
    color: var(--Color-Text-Default);
`;

export const ButtonSmall = styled.h2`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: var(--semi-bold);
    font-size: var(--button-default-m);
    letter-spacing: var(--letter-spacing-0);
    line-height: var(--line-height-120);
    color: var(--Color-Text-Default);
`;

export const InputLabel = styled(Body2)`
    margin: 2px -2px;
    position: relative;
    left: 0px;
    width: fit-content;
    padding: 0 6px 0px 4px;
    z-index: 1;
    background: ${(props) => (props?.background ? props.background : "transparent;")};
    border: ${(props) => (props?.border ? props.border : "var(--Color-Border-Subtle)")};
    color: var(--Color-Text-Subtle);
`;
export const InputLabelSmall = styled(Body3)`
    margin: 2px -2px;
    position: relative;
    left: 0px;
    width: fit-content;
    padding: 0 6px 0px 4px;
    z-index: 1;
    background: ${(props) => (props?.background ? props.background : "transparent;")};
    border: ${(props) => (props?.border ? props.border : "var(--Color-Border-Subtle)")};
    color: var(--Color-Text-Subtle);
`;

export const GradientText = css`
    background: var(--Color-Background-Action);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

export const Body_XXS_Med = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-4-d);
    font-weight: 500;
    line-height: var(--body-1-d);
    letter-spacing: 0em;
    text-align: center;
    color: var(--Color-Text-Default);
`;

export const Body_XXS_Reg = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-4-d);
    font-weight: 400;
    line-height: var(--body-1-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;
export const Body_XXXS_Reg = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-4-m);
    font-weight: 400;
    line-height: var(--body-1-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_XS_Reg = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-2-d);
    font-weight: 400;
    line-height: var(--subtitle-2-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;
export const Body_2XS_Reg = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-3-d);
    font-weight: 400;
    line-height: var(--subtitle-2-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_2XS_Med = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-3-d);
    font-weight: 500;
    line-height: var(--subtitle-2-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_XS_Med = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-2-d);
    font-weight: 500;
    line-height: var(--subtitle-2-d);
    letter-spacing: 0em;
    text-align: center;
    color: var(--Color-Text-Default);
`;

export const Body_XS_Bold = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-2-d);
    font-weight: 700;
    line-height: var(--subtitle-2-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_L_Bold = styled.div`
    font-family: var(--body-font);
    font-size: var(--subtitle-2-d);
    font-weight: 700;
    line-height: var(--body-5-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_M_Bold = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-lead-d);
    font-weight: 700;
    line-height: var(--h3-m);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_M_Med = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-lead-d);
    font-weight: 500;
    line-height: var(--h3-m);
    letter-spacing: 0em;
    text-align: center;
    color: var(--Color-Text-Default);
`;

export const Body_M_Reg = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-lead-d);
    font-weight: 400;
    line-height: var(--h3-m);
    letter-spacing: 0em;
    text-align: center;
    color: var(--Color-Text-Default);
`;

export const Body_S_Bold = styled.div`
    font-family: var(--body-font);
    font-size: var(--body-1-d);
    font-weight: 700;
    line-height: var(--h3-m);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_S_Med = styled.div`
    font-family: var(--body-font);
    font-size: var(--lead-body-m);
    font-weight: 600;
    line-height: var(--h3-m);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_S_Reg = styled.div`
    font-family: var(--body-font);
    font-size: var(--lead-body-m);
    font-weight: 400;
    line-height: var(--h3-m);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;

export const Body_Pane = styled.section`
    // width: calc(100% - 20px);
    display: flex;
    flex-grow: 1;
    border-radius: var(--Size-CornerRadius-XXXL);
    background: var(--Color-Background-Subtle);
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: var(--Size-Padding-XXL);
    gap: var(--Size-Gap-XL);
    margin-bottom: 20px;
`;
export const PageContent = styled.section`
    display: flex;
    flex-grow: 1;
    border-radius: var(--Size-CornerRadius-XXL);
    padding: var(--Size-Padding-XL);
    gap: var(--Size-Gap-L);
    background: var(--Color-Background-Subtle);
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    margin: 20px 20px 20px 0px;
    ::-webkit-scrollbar {
        display: none !important;
    }
    @media (max-width: 480px) {
        margin: 0px;
    }

    & > * {
        width: 100%;
        margin: 0 auto;
    }
`;
export const StickyPageHeader = styled.section`
    position: sticky;
    top: 0;
    z-index: 3;
    background: var(--Color-Background-Default);
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
`;
export const StickyPageFooter = styled.section`
    position: sticky;
    bottom: 0;
    z-index: 3;
    background: var(--Color-Background-Default);
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: var(--Size-Gap-XXL);
    width: -webkit-fill-available;
    & > * {
        flex: 0.5;
    }
`;

export const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

export const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Default);
`;

export const StatusWrapper = styled.div`
    background: ${(props) =>
        props.default
            ? "var(--Color-Icon-Subtle)"
            : props.active
            ? "var(--Color-Icon-Success)"
            : "var(--Color-Icon-Danger)"};
    width: 7px;
    height: 7px;
    border-radius: 50%;
`;
export const PageTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XS);
`;
export const PageTitleHeader = styled(SubTitle1)`
    display: flex;
    gap: var(--Size-Gap-M);
`;
export const PageTitleHeader2 = styled(SubTitle2)``;

export const PageTitleSubtitleWrapper = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
`;

export const VerticalLine = styled.div`
    border: 1px solid var(--Color-Border-Subtle);
`;
