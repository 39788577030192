import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Breakpoints } from "../../../GlobalStyle";

const StyledLogo = styled.img.attrs({
    alt: "copilot logo",
})`
    height: ${({ height }) => (height ? height : "40px")};
    width: auto;
    margin-top: 7px;
    cursor: pointer;
    @media (max-width: ${Breakpoints.mobile}px) {
        height: 36px;
        margin-top: 4px;
    }
`;

const FullLogo = ({ isRedirect, route, ...props }) => {
    const isDarkMode = localStorage.getItem("_theme") === "dark";
    const src = isDarkMode
        ? "https://cdn.copilot.live/v2/original/web/icons/light_theme/white_bg_full_logo.png"
        : "https://cdn.copilot.live/v2/original/web/icons/dark_theme/dark_bg_full_logo.png";
    return (
        <>
            {isRedirect ? (
                <Link to={route}>
                    <StyledLogo src={src} {...props} />
                </Link>
            ) : (
                <StyledLogo src={src} {...props} />
            )}
        </>
    );
};

export default FullLogo;
