import React, { useState, Fragment, useEffect } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Body1 } from "common/global-styled-components";
import FullLogo from "common/components/Logo/FullLogo";
import IconLogo from "common/components/Logo/IconLogo";
import EventEmitter from "../../events";
import ProfileDropdown from "../ProfileDropdown";
import { NavItemClick } from "../../events/events";
import { PlatformThemeAction } from "common/redux/actions/platformthemeAction";
import useMediaQuery from "../useMediaQuery";
import { useModal } from "../NewModal";
import CopilotFormPage from "pages/CopilotApp/Create";
import { Body_S_Reg } from "common/global-styled-components";
import {
    Home,
    Layer,
    MessageQuestion,
    Setting2,
    Flash,
    OceanProtocol,
    Sun1,
    Moon,
    Add,
    HambergerMenu,
    CloseCircle,
    CalendarAdd,
} from "iconsax-react";
import Icon from "common/components/Icon.js";
import CopilotIcon from "common/assets/CopilotIcon";
import { Breakpoints } from "GlobalStyle";
import Tooltip from "../Tooltip";
import Button from "../Button";

const SidebarWrapper = styled.div`
    height: 100%;
    flex-direction: column;
    width: 80px;
    display: flex;
    padding: var(--Size-Padding-XXL) 15px;
    gap: var(--Size-Gap-XXL);
    -webkit-transition: 0.3s left ease 0s;
    transition: left 0.3s ease 0s;

    @media (max-width: ${Breakpoints.tablet}px) {
        justify-content: space-between;

        // background-color: var(--dark-900);
        // padding: var(--Size-Padding-M) 16px;
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        left: ${(props) => (props.collapse ? "0px" : "-760px")};
        position: fixed;
        z-index: 13;
        gap: 0px;
        width: 100%;
        background: var(--Color-Background-Default);
    }
    @media (max-width: ${Breakpoints.mobileXS}px) {
        left: ${(props) => (props.collapse ? "0px" : "-480px")};
        position: fixed;
        z-index: 13;
        gap: 0px;
        width: 100%;
        background: var(--Color-Background-Default);
    }
`;

const MobileSidebarWrapper = styled.div`
    display: none;
    @media (max-width: ${Breakpoints.mobile}px) {
        position: absolute;
        z-index: 12;
        flex-direction: column;
        width: 80px;
        display: flex;
        padding: var(--Size-Padding-XXL) 15px;
        gap: var(--Size-Gap-XXL);
        -webkit-transition: 1.3s left ease;
        transition: 1.3s left ease;
    }
`;

const SidebarHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    @media (max-width: ${Breakpoints.mobile}px) {
        height: unset;
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--Size-Padding-XL);
        border-bottom: 1px solid var(--Color-Border-Subtle);
    }
`;

const SidebarContainer = styled(Body1)`
    overflow-y: scroll;
    padding: 16px 0px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    align-items: center;
    scroll-behavior: smooth;
    border-top: ${({ hasItems }) => (hasItems ? "1px solid var(--Color-Border-Subtle)" : "none")};
    border-bottom: ${({ hasItems }) =>
        hasItems ? "1px solid var(--Color-Border-Subtle)" : "none"};
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        -webkit-border-radius: 1px;
        width: 0px;
        // border-radius: 1px;
    }
    ::-webkit-scrollbar {
        display: none !important;
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        border: 0px;
        align-items: normal;
    }
`;

const hoverStyle = css`
    background: var(--Color-Background-Accent-Action);
    border-radius: var(--Size-CornerRadius-XXXL);
    border: 1px solid var(--Color-Border-Action);
    padding: 24px;
    box-sizing: border-box;
    @media (max-width: ${Breakpoints.mobile}px) {
        background: transparent;
        border-radius: 0;
        border: none;
        padding: 0px;
        box-sizing: border-box;
        justify-content: flex-start;
    }
`;

const NavItem = styled(NavLink)`
    text-decoration: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: none;
    color: var(--Color-Text-Default);
    box-sizing: border-box;
    padding: 25px;
    :hover {
        ${hoverStyle};
        border: 1px solid transparent;
    }

    &.active {
        ${hoverStyle};
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0px 8px 16px !important;
        height: unset;
        gap: 12px;
        width: unset;
        justify-content: flex-start;
        border-bottom: 1px solid var(--Color-Border-Subtle) !important;
    }
`;

const ActionMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: ${Breakpoints.mobile}px) {
        justify-content: flex-start;
        border-bottom: 1px solid var(--Color-Border-Subtle) !important;
    }
`;
const InlineIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--Size-Padding-XL);
    border-radius: var(--Size-CornerRadius-XL);
    :hover {
        background: var(--Color-Background-Subtle);
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 16px 0px;
        gap: 12px;
    }
`;

const CreateCopilotButton = styled(Button)`
    width: 100%;
    display: flex;
    gap: 12px;
`;
const AddCopilotInlineIcon = styled(InlineIcon)`
    background: var(--Color-Background-Subtle);
    border-radius: 50%;
    padding: var(--Size-Padding-L);
`;

const SidebarContainerWrapper = styled.div`
    height: 60%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
`;

const ModeText = styled(Body_S_Reg)`
    display: none;
    @media (max-width: ${Breakpoints.mobile}px) {
        display: flex;
    }
`;

const IconsMap = {
    Home,
    Layer,
    Setting2,
    MessageQuestion,
    CopilotIcon,
    Flash,
    OceanProtocol,
};

const SidePanel = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const organizationId = useSelector(
        (state) => state.organizationDetails.organization?._id || -1,
    );
    const { pathname, search } = useLocation();
    const { copilots } = useSelector((state) => state.copilotDetails.copilotList || {});
    const [collapse, setCollapse] = useState(false);
    const [isDark, setIsDark] = useState(localStorage.getItem("_theme") === "dark");
    const isTablet = useMediaQuery(`(max-width: ${Breakpoints.tablet}px)`);
    const isMobile = useMediaQuery(`(max-width: ${Breakpoints.mobile}px)`);

    const showTopNavbar = useSelector((state) => state.menuDetails.showTopNavbar);
    const isUserLoggedIn = useSelector((state) => state.authenticationDetails.isUserLoggedIn);
    const {
        show: showCopilotModal,
        hide: hideCopilotModal,
        RenderModal: CreateCopilotModal,
    } = useModal();

    useEffect(() => {
        if (pathname.includes("/cp/")) {
            const parts = pathname.split("/");
            if (parts.length > 3) {
                let element = document.getElementById(`${parts[4]}__Sidebar`);
                element?.scrollIntoView();
            }
        }
    }, [location, copilots.length]);

    const handleNavItemClick = (tab) => {
        EventEmitter.dispatch(NavItemClick, {
            name: tab.name,
            route: `/org/${organizationId}/cp/${tab.id}`,
            from: { pathname, search },
        });
    };

    const handleToggleChange = () => {
        if (isDark) {
            document.body.classList.add("theme-light");
            document.body.classList.remove("theme-dark");
            localStorage.setItem("_theme", "light");
            dispatch({
                type: PlatformThemeAction.AddMode,
                payload: "light",
            });
        } else {
            document.body.classList.add("theme-dark");
            document.body.classList.remove("theme-light");
            localStorage.setItem("_theme", "dark");
            dispatch({
                type: PlatformThemeAction.AddMode,
                payload: "dark",
            });
        }
        setIsDark(!isDark);
    };

    const menuItems = copilots.map((tab, index) => {
        const DynamicIcon = IconsMap[tab.icon];
        // if (tab .users.includes(user?.type))
        return (
            <Fragment key={tab.id}>
                <NavItem
                    id={tab.id + "__Sidebar"}
                    role="tooltip"
                    data-tip
                    data-tooltip-id={tab.id + "__SidebarToolTip"}
                    to={{
                        pathname: `/org/${organizationId}/cp/${tab.id}`,
                        state: { from: { pathname, search } },
                    }}
                    onClick={(e) => {
                        handleNavItemClick(tab);
                        setCollapse(false);
                    }}
                >
                    <IconLogo height="28px" width="28px" src={tab.icon} /> {isMobile && tab.name}
                </NavItem>
                {!isMobile && (
                    <Tooltip
                        place="right"
                        type="faint"
                        effect="solid"
                        noArrow={true}
                        id={tab.id + "__SidebarToolTip"}
                    >
                        {tab.name}
                    </Tooltip>
                )}
            </Fragment>
        );
    });

    return isUserLoggedIn && showTopNavbar ? (
        <>
            <MobileSidebarWrapper>
                {Icon(HambergerMenu, {
                    size: 24,
                    color: "var(--Color-Icon-Default)",
                    cursor: "pointer",
                    onClick: () => {
                        setCollapse(!collapse);
                    },
                })}
            </MobileSidebarWrapper>

            <SidebarWrapper collapse={collapse}>
                <SidebarHeader
                    collapse={collapse}
                    onClick={() => {
                        // e.preventDefault();
                        // setCollapse(!collapse);
                    }}
                >
                    {/* Remove this once collapsible state comes in */}
                    {isMobile && collapse ? (
                        <>
                            <FullLogo
                                onClick={() => {
                                    history.push(`/org/${organizationId}/dashboard`);
                                }}
                            />
                            {Icon(CloseCircle, {
                                size: 24,
                                color: "var(--Color-Icon-Default)",
                                cursor: "pointer",
                                onClick: () => {
                                    setCollapse(!collapse);
                                },
                            })}
                        </>
                    ) : (
                        <IconLogo onClick={() => history.push(`/org/${organizationId}`)} />
                    )}
                    {/* <IconLogo onClick={() => history.push(`/org/${organizationId}`)} /> */}
                </SidebarHeader>
                {/* <div> */}
                <SidebarContainerWrapper>
                    <SidebarContainer hasItems={menuItems.length > 0} collapse={collapse}>
                        {menuItems}
                    </SidebarContainer>
                    {/* <UpgradePlanComponent /> */}
                    <ActionMenuWrapper
                        onClick={(e) => {
                            e.preventDefault();
                            showCopilotModal();
                        }}
                        id={"new-copilot"}
                        role="tooltip"
                        data-tip
                        data-tooltip-id={"new-copilot"}
                    >
                        {!isMobile ? (
                            <>
                                <AddCopilotInlineIcon>
                                    {Icon(Add, {
                                        size: 24,
                                        color: "var(--Color-Icon-Default)",
                                    })}
                                </AddCopilotInlineIcon>
                                <Tooltip
                                    place="right"
                                    type="faint"
                                    effect="solid"
                                    noArrow={true}
                                    id={"new-copilot"}
                                >
                                    Create New Copilot
                                </Tooltip>
                            </>
                        ) : (
                            <CreateCopilotButton>
                                {" "}
                                {Icon(Add, {
                                    size: 24,
                                    color: "var(--Color-Text-Inverse)",
                                })}
                                Create New Copilot
                            </CreateCopilotButton>
                        )}
                    </ActionMenuWrapper>
                </SidebarContainerWrapper>

                <ActionMenuWrapper
                    onClick={(e) => {
                        window?.customerBot?.("event", "open");
                    }}
                    id={"lets-chat"}
                    role="tooltip"
                    data-tip
                    data-tooltip-id={"lets-chat"}
                    data-gtm-track="lets-chat"
                >
                    {!isMobile ? (
                        <>
                            <AddCopilotInlineIcon data-gtm-track="lets-chat">
                                {Icon(MessageQuestion, {
                                    "data-gtm-track": "lets-chat",
                                    size: 24,
                                    color: "var(--Color-Icon-Action)",
                                })}
                            </AddCopilotInlineIcon>
                            <Tooltip
                                place="right"
                                type="faint"
                                effect="solid"
                                noArrow={true}
                                id={"lets-chat"}
                            >
                                Let's Chat
                            </Tooltip>
                        </>
                    ) : (
                        <CreateCopilotButton mode="tertiary" data-gtm-track="lets-chat">
                            {Icon(MessageQuestion, {
                                "data-gtm-track": "lets-chat",
                                size: 24,
                                color: "var(--Color-Text-Action)",
                            })}
                            Let's Chat!
                        </CreateCopilotButton>
                    )}
                </ActionMenuWrapper>
                <ActionMenuWrapper
                    onClick={(e) => {
                        e.preventDefault();
                        handleToggleChange();
                    }}
                >
                    {isDark ? (
                        <InlineIcon title="Switch to Light Mode">
                            {Icon(Sun1, {
                                size: isMobile ? 30 : 20,
                                color: "var(--Color-Icon-Default)",
                            })}
                            <ModeText>Light Mode</ModeText>
                        </InlineIcon>
                    ) : (
                        <InlineIcon title="Switch to Dark Mode">
                            {Icon(Moon, {
                                size: isMobile ? 30 : 20,
                                color: "var(--Color-Icon-Default)",
                            })}
                            <ModeText>Dark Mode</ModeText>
                        </InlineIcon>
                    )}
                </ActionMenuWrapper>
                <ProfileDropdown collapse={collapse} setCollapse={setCollapse} />
                {/* </div> */}
                <CreateCopilotModal maxWidth="600px">
                    <CopilotFormPage
                        mode="create"
                        hideCopilotCreateDialog={hideCopilotModal}
                        propOrgId={organizationId}
                    />
                </CreateCopilotModal>
            </SidebarWrapper>
        </>
    ) : // )
    null;
};

export default SidePanel;
