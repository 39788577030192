import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const KeyService = {
    getKeys(orgId, queryString) {
        if (!queryString) queryString = "";
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.keys}?${queryString.trim()}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getKey(orgId, keyId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.key}`.replace(":orgId", orgId).replace(":keyId", keyId),
            axiosOptions,
        );
    },
    createKey(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(`${URLS.keys}`.replace(":orgId", orgId), axiosOptions);
    },
    validateKey(orgId, data, type = "completion") {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.keyValidation}`.replace(":orgId", orgId).replace(":type", type),
            axiosOptions,
        );
    },
    updateKey(orgId, keyId, data) {
        const axiosOptions = { data };
        return ApiService.put(
            `${URLS.key}`.replace(":orgId", orgId).replace(":keyId", keyId),
            axiosOptions,
        );
    },
    updateDefaults(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(`${URLS.defaults}`.replace(":orgId", orgId), axiosOptions);
    },
    deleteKey(orgId, keyId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.key}`.replace(":orgId", orgId).replace(":keyId", keyId),
            axiosOptions,
        );
    },
    getModels(orgId, keyId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.models}`.replace(":orgId", orgId).replace(":keyId", keyId),
            axiosOptions,
        );
    },
};

export default KeyService;
