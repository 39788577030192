import React, { lazy } from "react";
import executor from "common/utils/executor";
import AuthGuard from "guards/auth.guard";
import OrganizationGuard from "guards/organization.guard";

const Auth = lazy(() => import("./pages/Auth"));
const Organization = lazy(() => import("./pages/Organization"));
const SetupOrg = lazy(() => import("./pages/SetupOrg"));
const ChooseOrg = lazy(() => import("./pages/ChooseOrg"));
const Profile = lazy(() => import("./pages/Profile/new-index"));
const EmailUpdateConfirmation = lazy(() => import("./pages/Profile/Profile/EmailUpdated"));
const Settings = lazy(() => import("./pages/Settings"));
const ChooseCopilot = lazy(() => import("./pages/CopilotApp/ChooseCopilot"));
const TryForFree = lazy(() => import("./pages/TryForFree"));
const GenerateCopilotFromTIYUsers = lazy(() => import("./pages/GenerateCopilotFromTIYUsers"));

const routes = [
    {
        path: "/auth",
        component: Auth,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/choose-company",
        component: ChooseOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/setup-company",
        component: SetupOrg,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        // TODO: create separate page for user level profile
        path: "/org/:orgId/profile",
        component: Profile,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/org/:orgId/settings",
        component: Settings,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/org/:orgId/generateCopilotFromFreeSession/:uuid",
        component: GenerateCopilotFromTIYUsers,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/org/:orgId",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
    {
        path: "/email-update",
        component: EmailUpdateConfirmation,
        guard: () => {
            return executor([]);
        },
    },
    {
        path: "/choose-app",
        component: ChooseCopilot,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "/try-for-free",
        component: TryForFree,
        guard: () => {
            return executor([AuthGuard]);
        },
    },
    {
        path: "*",
        component: Organization,
        guard: () => {
            return executor([AuthGuard, OrganizationGuard]);
        },
    },
];

export default routes;
