import SubscriptionService from "../../../services/subscription.service";

export const SubscriptionAction = {
    CurrentSubscription: "CURRENT_SUBSCRIPTION",
};

export const createSubscription = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        SubscriptionService.createSubscription(orgId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SubscriptionAction.CurrentSubscription,
                    payload: { currentSubscription: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getSubscriptionDetail = (orgId, dispatch) => {
    return new Promise((resolve, reject) => {
        SubscriptionService.getSubscriptionDetail(orgId) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SubscriptionAction.CurrentSubscription,
                    payload: { currentSubscription: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const cancelSubscriptionDetail = (orgId) => {
    return new Promise((resolve, reject) => {
        SubscriptionService.cancelSubscriptionDetail(orgId) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getSubscriptionUsageDetail = (orgId) => {
    return new Promise((resolve, reject) => {
        SubscriptionService.getSubscriptionUsageDetail(orgId) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getPlans = (orgId) => {
    return new Promise((resolve, reject) => {
        SubscriptionService.getPlans(orgId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getStripePortal = (orgId) => {
    return new Promise((resolve, reject) => {
        SubscriptionService.getStripePortal(orgId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};
