import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TextInput from "common/components/TextInput";
import { Body3, InputLabel, PageTitleWrapper } from "common/global-styled-components";
import { usePageTitle, max_suggestions } from "common/utils";
import Divider from "common/components/Divider";
import { Label, BodyWrapper, InputWrapper, StyledErrorSkeleton, StyledError } from "./index.js";
import ChipInput from "common/components/ChipInput.js";

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const TextCounterStyle = styled(InputLabel)`
    font-size: var(--body-3-d);
    color: ${(props) => (props.overlimit ? "var(--Color-Text-Danger)" : "inherit")};
`;

const WelcomeContent = ({ register, errors, allValues, setValue }) => {
    usePageTitle(`Copilot Platform - Copilot Welcome Content`);
    const [suggestionsCount, setSuggestionsCount] = useState(allValues?.suggestions?.length || 0);

    useEffect(() => {
        setSuggestionsCount(allValues?.suggestions?.length || 0);
    }, [allValues?.suggestions?.length]);

    const handleSuggestionsChange = (event) => {
        if (event.length <= max_suggestions) {
            setValue("suggestions", event);
            setSuggestionsCount(event.length);
        }
    };

    return (
        <>
            <BodyWrapper id="welcome-content-tab">
                <PageTitleWrapper>
                    <Label>Welcome Content</Label>
                    <Body3>
                        Personalize widget behavior with tailored settings for a seamless experience
                        for your users.
                    </Body3>
                </PageTitleWrapper>
                <Divider style={{ margin: "0px" }}></Divider>
                <InputWrapper>
                    <FlexContainer>
                        <InputLabel>Title</InputLabel>
                        <TextCounterStyle overlimit={allValues?.titleGreeting?.length > 50}>
                            {allValues?.titleGreeting?.length || 0}/50
                        </TextCounterStyle>
                    </FlexContainer>

                    <TextInput
                        data-testid="titleGreeting"
                        role="titleGreeting"
                        type="text"
                        label="titleGreeting"
                        name="titleGreeting"
                        register={register}
                        autoComplete="off"
                        placeholder={`Hi, How can I help?`}
                        validation={{
                            maxLength: {
                                value: 50,
                                message: "Title cannot be more than 50 characters",
                            },
                        }}
                    />
                    {errors?.titleGreeting?.message && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">{errors?.titleGreeting?.message}</StyledError>
                        </StyledErrorSkeleton>
                    )}
                </InputWrapper>
                <InputWrapper>
                    <FlexContainer>
                        <InputLabel>Subtitle</InputLabel>
                        <TextCounterStyle overlimit={allValues?.titleText?.length > 100}>
                            {allValues?.titleText?.length || 0}/100
                        </TextCounterStyle>
                    </FlexContainer>
                    <TextInput
                        data-testid="titleText"
                        role="titleText"
                        type="text"
                        label="titleText"
                        name="titleText"
                        register={register}
                        autoComplete="off"
                        placeholder={`You can ask me anything...`}
                        validation={{
                            maxLength: {
                                value: 100,
                                message: "Subtitle cannot be more than 100 characters",
                            },
                        }}
                    />
                    {errors?.titleText?.message && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">{errors?.titleText?.message}</StyledError>
                        </StyledErrorSkeleton>
                    )}
                </InputWrapper>
                <InputWrapper>
                    <FlexContainer>
                        <InputLabel>Suggestions </InputLabel>
                        <TextCounterStyle>
                            {suggestionsCount}/{max_suggestions}
                        </TextCounterStyle>
                    </FlexContainer>
                    <ChipInput
                        cb={handleSuggestionsChange}
                        chipValues={allValues?.suggestions || []}
                        maxChip={max_suggestions}
                    />
                    <Body3>
                        Tip : Press "Enter" on your keyboard to input the next suggestion.
                    </Body3>
                </InputWrapper>
            </BodyWrapper>
        </>
    );
};

export default WelcomeContent;
