export const UserAgentAction = {
    AddAgent: "ADD_AGENT",
    SetFcWidgetLoaded: "SET_FC_WIDGET_LOADED",
};

export const addUserAgent = (data, dispatch) => {
    dispatch({
        type: UserAgentAction.AddAgent,
        payload: data,
    });
};

export const setWidgetLoaded = (data, dispatch) => {
    dispatch({
        type: UserAgentAction.SetFcWidgetLoaded,
        payload: data,
    });
};
