import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "common/components/Button";
import {
    PageContent,
    StickyPageHeader,
    Body_XS_Med,
    Body_XS_Bold,
    Body_XS_Reg,
    Body_S_Reg,
    Loader,
    Body_M_Reg,
    Body_XXS_Med,
    Body_XXXS_Reg,
    StatusWrapper,
} from "common/global-styled-components";
import Icon from "common/components/Icon";
import { Play, LogoutCurve } from "iconsax-react";
import { usePageTitle } from "common/utils";
import { getThreads, patchThread } from "common/redux/actions/threadActions";
import LoaderIcon from "common/components/LoaderIcon";
import ChatIcon from "common/assets/ChatIcon";
import startCase from "lodash/startCase";
import NoDataFound from "common/components/NoDataFound";
import CustomDropdown from "common/components/CustomDropdown";
import environment from "../../../environment";
import { SURFACES_ICON } from "../Icons";
import { getState } from "./index";
import { useModal } from "common/components/NewModal";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import { getSocket } from "common/websocket";
import URLS from "common/utils/apiUrls";
import { fromNow } from "common/utils/utils";
import Tooltip from "common/components/Tooltip";
import { timeFormat } from "pages/Application/imports";

const ExportButton = styled(Button)`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-S);
    padding: var(--Size-Padding-M) var(--Size-Padding-L);
    border-color: ${(props) => !props.isAssistant && "var(--Color-Background-Accent-Danger)"};
`;

const ExportButtonText = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const InnerPageContent = styled(PageContent)`
    flex-grow: 1;
    overflow-y: scroll;
    border-radius: 0px;
    background: var(--Color-Background-Default);
    gap: var(--Size-Gap-XL);
`;

const UserImage = styled.div`
    display: flex;
    gap: var(--Size-Gap-S);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--Color-Background-Accent-Action);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const UserImageSrc = styled.img`
    width: 32px;
    height: 32px;
    object-fit: contain;
    border-radius: 50%;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-S);
    width: -webkit-fill-available;
    overflow: hidden;
`;

const IconText = styled.div`
    display: flex;
    gap: var(--Size-Gap-S);
    align-items: center;
    max-width: -webkit-fill-available;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SectionLeft = styled.div`
    width: 30%;
    height: 100%;
    overflow: scroll;
    border-right: 1px solid var(--Color-Border-Subtle);
    ::-webkit-scrollbar {
        width: 0;
    }
`;
const SectionRight = styled.div`
    width: 70%;
`;
const Tile = styled.div`
    padding: var(--Size-Padding-L);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid var(--Color-Border-Subtle);
    width: 100%;
`;

const ConversationPage = styled.div``;
const TileUpperRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CurrentUser = styled.div`
    display: flex;
    border: 0.5px dotted var(--Color-Border-Success);
    gap: var(--Size-Gap-S);
    padding: var(--Size-Padding-S) var(--Size-Padding-M);
    border-radius: var(--Size-CornerRadius-XXL);
    background: var(--Color-Background-Accent-Success);
    align-items: center;
`;

const WidgetLoader = styled(Loader)`
    height: 100%;
    width: 100%;
    position: absolute;
    background: var(--Color-Background-Default);
`;

const IconWrapper = styled.div`
    border-radius: var(--Size-CornerRadius-XXXL);
    background: ${(props) =>
        props.currentThreadStatus === "assistant"
            ? "var(--Color-Background-Accent-Action)"
            : " var(--Color-Background-Accent-Danger)"};
    padding: 12px;
    height: 48px;
    width: 48px;
`;
const StatusIndicator = styled.div`
    width: 8px;
    height: 8px;
    position: absolute;
    right: 0px;
    top: 24px;
    background: ${(props) =>
        props.$state === "success"
            ? "var(--Color-Icon-Success)"
            : props.$state === "danger"
            ? "var(--Color-Icon-Danger)"
            : "var(--Color-Icon-Warning)"};
    border-radius: 50%;
    border: 1px solid var(--Color-Icon-Inverse);
`;
const ActvityChip = styled(Body_XXXS_Reg)`
    white-space: nowrap;
    border-radius: var(--Size-CornerRadius-XXXL);
    padding: var(--Size-Padding-XS) var(--Size-Padding-S);
    gap: var(--Size-Gap-M);
    background: ${(props) =>
        props.active
            ? "var(--Color-Background-Accent-Success)"
            : "var(--Color-Background-Accent-Danger)"};
    color: ${(props) => (props.active ? "var(--Color-Text-Success)" : "var(--Color-Text-Danger)")};
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    grid-gap: var(--Size-Gap-M);
`;
const ConversationFilters = [
    { label: "All Conversations", value: "all" },
    { label: "My Conversations", value: "self" },
];

const UserConversations = ({ allValues, setUserList, setUser, chatUser }) => {
    const { orgId, agentId } = useParams();
    const dispatch = useDispatch();
    const isMounted = useRef(null);
    const [searchName, setSearchName] = useState("");
    const [copilotConversation, setCopilotConversation] = useState([]);
    const [userMap, setUserMap] = useState({});
    const currentConversationId = useRef(null);
    const [currentConversationUser, setCurrentConversationUser] = useState({});
    const [currentThreadStatus, setCurrentThreadStatus] = useState("assistant");
    const [limit, setLimit] = useState(30);
    const [next, setNext] = useState(undefined);
    const [loadNext, setLoadNext] = useState(true);
    const type = useRef("all");
    const [isPageLoading, setPageLoading] = useState(true);
    const [widgetLoading, setWidgetLoading] = useState(true);
    const [connected, setConnected] = useState(false);
    const [_socket, setSocket] = useState(null);
    const {
        show: showEditTakeOverConversationDialog,
        hide: hideEditTakeOverConversationDialog,
        RenderModal: RenderTakeOverConversationDialog,
    } = useModal();
    const user = useSelector((state) => state.authenticationDetails.user);
    const containerRef = useRef(null);

    const debouncefetchThreads = useMemo(() => debounce(fetchThreads, 300), [next]);

    const getQueryParams = () => {
        const _next = typeof next !== "undefined" ? `next=${next}` : "";
        const _limit = limit ? `limit=${limit}` : "";
        const __self = `self=${type.current === "self"}`;
        return [_next, _limit, __self].join("&");
    };
    const getRTQueryParams = (userId) => {
        const pageQuery = "page=1";
        const pageSizeQuery = "limit=10";
        userId = userId ? `userId=${encodeURIComponent(userId)}` : "limit=";
        return [pageQuery, pageSizeQuery, userId].join("&");
    };

    usePageTitle("Copilot Inbox - User Conversations");

    useEffect(() => {
        connectSocket();
        return () => {
            _socket?.close();
        };
    }, []);
    useEffect(() => {
        if (!allValues?.token || isPageLoading) return;
        let js;
        const widgetElement = document.getElementById("conversation-page");
        const widgetUrl = `${environment.NEO_SCRIPT_DOMAIN}/v1/copilot.min.js?tkn=${
            allValues?.token ?? ""
        }`;

        if (widgetElement) {
            widgetElement.style.height = "fit-content";
            widgetElement.style.border = "none";
            widgetElement.style.width = "100%";
            widgetElement.style.borderRadius = "16px";
            widgetElement.style.display = "block";
        }
        if (!window.agent) {
            (function (w, d, s, o, f, fjs) {
                w[o] =
                    w[o] ||
                    function (...args) {
                        (w[o].q = w[o].q || []).push(args);
                    };
                js = d.createElement(s);
                fjs = d.getElementsByTagName(s)[0];
                js.id = o;
                js.src = f;
                js.async = 1;
                js.onload = function () {};
                fjs.parentNode.insertBefore(js, fjs);
            })(window, document, "script", "agent", widgetUrl);
            window.agent("init", {
                minimized: false,
                element: widgetElement,
                disableMinimized: true,
                showTitleBar: false,
                disableSuggestionBox: true,
                instanceType: "platform",
                disableDraggable: true,
                disableButtons: ["aiWebCall"],
                styles: {
                    mainDivContainer: {
                        position: "unset",
                        height: "calc(100vh - 242px)",
                        boxShadow: "none",
                    },
                },
                isReadOnly: true,
            });

            setTimeout(() => {
                const colorFields = [
                    { name: "primaryColor", color: "var(--Color-Background-Default)" },
                    {
                        name: "secondaryColor",
                        color: "var(--Color-Background-Subtle)",
                    },
                    { name: "primaryFontColor", color: "var(--Color-Text-Default)" },
                    { name: "secondaryFontColor", color: "var(--Color-Text-Default)" },
                    {
                        name: "activeButtonColor",
                        color: "linear-gradient(90deg, #7F00FF 0%, #3D05DD 100%)",
                    },
                    { name: "borderColor", color: "var(--Color-Border-Subtle)" },
                ];
                colorFields.forEach((colorValue) => {
                    window?.agent?.("event", "setThemeConfig", {
                        [colorValue.name]: colorValue.color,
                    });
                });

                const threadStatus = Boolean(
                    currentConversationUser?.state == "support_in_progress" &&
                        currentConversationUser?.userId?.substring(2) == user?._id &&
                        allValues?.customerSupport?.active,
                );
                window?.agent?.("event", "hideMessageBar", {
                    value: !threadStatus,
                });
                setCurrentThreadStatus(threadStatus ? "support" : "assistant");

                window?.agent?.("event", "setMessages", {
                    threadId: currentConversationId.current,
                });
                setWidgetLoading(false);
            }, 2200);
        }
        // Return a cleanup function
        return () => {
            // Remove the copilot script
            if (js && js.parentNode) {
                js.parentNode.removeChild(js);
            }
            if (window?.agent) window?.agent("destroy");
        };
    }, [allValues?.token, isPageLoading, type.current]);

    useEffect(() => {
        isMounted.current = true;

        if (loadNext) {
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debouncefetchThreads(queryString);
        }

        return () => {
            // executed when unmount
            isMounted.current = false;
        };
    }, [loadNext]);

    const handleToggleTakeOverConversation = () => {
        patchThread(
            orgId,
            agentId,
            currentConversationId.current,
            currentThreadStatus == "support" ? "leave" : "join",
        )
            .then((res) => {
                if (res?.meta?.customerSupport?.state == "support_in_progress") {
                    setCurrentThreadStatus("support");
                    window?.agent?.("event", "hideMessageBar", {
                        value: false,
                    });
                } else {
                    if (type.current === "self") {
                        const cc = copilotConversation;
                        const index = cc.findIndex((i) => i.id === currentConversationId.current);
                        if (cc !== -1) {
                            cc.splice(index, 1);
                        }
                        setCopilotConversation((prev) => [...cc]);
                    }
                    setCurrentThreadStatus("assistant");
                    window?.agent?.("event", "hideMessageBar", {
                        value: true,
                    });
                }
                hideEditTakeOverConversationDialog();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong");
            });
    };

    const handleMessageClick = (i) => {
        setCurrentConversationUser(i?.meta?.customerSupport);
        setCopilotConversation((prevConversationList) => {
            const conversationList = [...prevConversationList];
            const threadId = i?.id;
            const idx = conversationList.findIndex((j) => j.id === threadId);
            conversationList[idx] = {
                ...conversationList[idx],
                messages: conversationList[idx]?.messages?.slice?.(-1),
            }; // Update the specific index
            currentConversationId.current = i?.id;
            return conversationList;
        });
        let queryString = `${getRTQueryParams(i?.userId)}`;
        getThreads(orgId, agentId, queryString, dispatch)
            .then((res) => {
                if (res?.user)
                    setUser?.({
                        ...res?.user,
                    });
                else setUser({});
                let currentThread = res?.items?.find((val) => val.id == i.id);
                const threadType = currentThread?.meta?.customerSupport;
                if (
                    threadType?.state == "support_in_progress" &&
                    threadType?.userId?.substring(2) == user?._id &&
                    allValues?.customerSupport?.active
                ) {
                    setCurrentThreadStatus("support");
                    window?.agent?.("event", "hideMessageBar", {
                        value: false,
                    });
                } else {
                    setCurrentThreadStatus("assistant");
                    window?.agent?.("event", "hideMessageBar", {
                        value: true,
                    });
                }
                window?.agent?.("event", "setMessages", {
                    threadId: i?.id,
                });
                setUserList({ ...i, threads: res.items });
            })
            .catch((err) => {
                setCurrentThreadStatus("assistant");
                window?.agent?.("event", "hideMessageBar", {
                    value: true,
                });
                setUserList([]);
            });
    };

    async function fetchThreads(queryString, opts = {}) {
        getThreads(orgId, agentId, queryString, dispatch)
            .then((res) => {
                setLoadNext(false);
                if (next !== res.page?.next) {
                    if (!opts?.clear) {
                        setCopilotConversation((prevDataArray) => [...prevDataArray, ...res.items]);
                    } else {
                        handleMessageClick(res.items?.[0]);
                        setCopilotConversation((prevDataArray) => [...res.items]);
                    }

                    if (res?.items?.length > 0 && typeof next === "undefined") {
                        handleMessageClick(res.items?.[0]);
                    }
                }
                setNext(res.page.next || null);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong");
            })
            .finally(() => {
                if (isMounted.current) {
                    setPageLoading(false);
                }
            });
    }

    const handleFilters = (e) => {
        _socket?.off?.(`platform:${orgId}:${agentId}:data`);
        setConnected(false);
        setNext(null);
        type.current = e.value;
        let queryString = `self=${e.value === "self"}&limit=${limit}`;
        debouncefetchThreads(queryString, { clear: true });
    };
    useEffect(() => {
        let subscribed = false;
        const id = setInterval(() => {
            let users = copilotConversation?.map((l) => l?.userId);
            if (!users.length) return;
            users = Array.from(new Set(users));
            _socket?.emit("platform:userActivity", { users }, function (emitRes) {
                if (!subscribed) {
                    subscribed = true;
                }
                _socket.on(`platform:userActivity:data`, function (onRes) {
                    const { users: userMap = {} } = onRes?.data || {};
                    if (!Object.keys(userMap).length) return;
                    setUserMap(userMap);
                });
            });
        }, [10000]);

        if (!connected && _socket) {
            _socket?.on?.(`platform:${orgId}:${agentId}:data`, function (res) {
                switch (res.eventName) {
                    case "NEW_MESSAGE":
                        if (
                            type.current === "self" &&
                            res?.data?.meta?.customerSupport?.userId !== `u-${user?._id}`
                        ) {
                            return;
                        }
                        setCopilotConversation((prevConversationList) => {
                            const conversationList = [...prevConversationList];
                            const threadId = res.data.id;
                            const idx = conversationList.findIndex((i) => i.id === threadId);
                            if (idx < 0) conversationList.unshift(res.data);
                            else {
                                conversationList[idx] = {
                                    ...res.data,
                                    meta: { ...res.data.meta },
                                    user: conversationList[idx].user,
                                    ...(currentConversationId.current !== threadId
                                        ? {
                                              messages: [
                                                  ...conversationList[idx].messages,
                                                  ...res.data.messages,
                                              ],
                                          }
                                        : { messages: [...res.data.messages] }),
                                }; // Update the specific index
                            }
                            return conversationList;
                        });
                        if (`u-${user?._id}` !== res.data?.messages?.[0]?.userId) {
                            const _usersMap = { ...userMap };
                            _usersMap[res.data.userId] = new Date().getTime();
                            setUserMap(_usersMap);
                        }
                        break;
                    // case "SUPPORT_REQUESTED":
                    //     if (res?.data?.id === currentConversationId.current) {
                    //         setCurrentConversationUser((prevMessage) => ({
                    //             ...prevMessage,
                    //             state: "support_requested",
                    //         }));
                    //     }
                    //     break; //TODO will remove this handlers if requested
                }
            });
            setConnected(true);
        }
        return () => {
            clearInterval(id);
            _socket?.off?.("platform:userActivity:data");
        };
    }, [copilotConversation, _socket]);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollHeight - scrollTop < clientHeight + 300) {
                setLoadNext(true);
            }
        };
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
        }
    }, [containerRef.current]);

    const connectSocket = async () => {
        const socket = await getSocket(
            {
                url: environment.NEO_MAIN_URL,
                nsp: URLS.copilotEventsNamespace.replace(":copilotId", agentId),
                query: { orgId },
            },
            {},
        );
        setSocket(socket);
    };

    return (
        <PageContent
            style={{
                padding: "0px",
                flexGrow: 1,
                overflowY: "scroll",
                background: "var(--Color-Background-Default)",
                gap: "0px",
                margin: "0px",
            }}
        >
            {isPageLoading ? (
                <Loader>
                    <LoaderIcon />
                </Loader>
            ) : (
                <InnerPageContent
                    style={{
                        padding: "0px",
                        gap: "0px",
                        overflow: "hidden",
                        flexDirection: "row",
                        alignItems: "start",
                    }}
                    key="user-convo-header"
                >
                    <SectionLeft>
                        <StickyPageHeader
                            style={{ display: "flex", flexDirection: "row", gap: "0px" }}
                        >
                            <Tile>
                                <CustomDropdown
                                    role="tooltip"
                                    // data-tip
                                    // data-tooltip-id={isFreePlan ? "model-tooltip" : "dropdown"}
                                    options={ConversationFilters}
                                    placeholder={"sdsds"}
                                    value={ConversationFilters.find(
                                        (i) => i.value === type.current,
                                    )}
                                    onChange={(e) => handleFilters(e)}
                                    type="conversationType"
                                    label="conversationType"
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            border: "0 !important",
                                            boxShadow: "0 !important",
                                            backgroundColor: "transparent !important",
                                            fontSize: "16px !important",
                                            fontWeight: "700 !important",
                                            padding: "0px !important",
                                            "&:hover": {
                                                border: "0 !important",
                                                boxShadow: "0 !important",
                                                backgroundColor: "transparent !important",
                                            },
                                            "@media only screen and (max-width: 768px)": {
                                                ...base[
                                                    "@media only screen and (max-width: 768px)"
                                                ],
                                                fontSize: "14px !important",
                                                fontWeight: "700 !important",
                                            },
                                        }),
                                        menu: (base) => ({
                                            ...base,

                                            width: "max-content",
                                            minWidth: "100%",
                                            height: "max-content",
                                        }),
                                    }}
                                />
                            </Tile>
                        </StickyPageHeader>
                        {copilotConversation.length > 0 && (
                            // TODO: attach scroll listener on this div and fetch new page
                            <InnerPageContent
                                style={{
                                    flexDirection: "column",
                                    padding: "0px",
                                    margin: "0px",
                                    gap: "0px",
                                    height: "100%",
                                }}
                                ref={containerRef}
                            >
                                {copilotConversation.map((i, idx) => {
                                    const updatedAtDate = new Date(
                                        i?.messages[i.messages.length - 1]?.updatedAt,
                                    );

                                    return (
                                        <Tile
                                            style={{
                                                gap: "var(--Size-Gap-L)",
                                                cursor: "pointer",
                                                background:
                                                    i.id == currentConversationId.current
                                                        ? "var(--Color-Background-Subtle)"
                                                        : "",
                                            }}
                                            key={i.id}
                                            onClick={(e) => {
                                                if (i.id === currentConversationId.current) return;
                                                e.preventDefault();
                                                handleMessageClick(i);
                                            }}
                                        >
                                            <UserImage
                                                style={{
                                                    width: "32px",
                                                    height: "32px",
                                                    position: "relative",
                                                }}
                                            >
                                                <StatusIndicator
                                                    $state={getState(
                                                        // eslint-disable-next-line no-prototype-builtins
                                                        userMap?.hasOwnProperty?.(i.userId)
                                                            ? userMap[i.userId]
                                                            : i?.user?.lastOnline,
                                                    )}
                                                ></StatusIndicator>
                                                {i?.user?.profilePicUrl ? (
                                                    <UserImageSrc
                                                        src={i?.user?.profilePicUrl}
                                                    ></UserImageSrc>
                                                ) : (
                                                    <Body_S_Reg
                                                        style={{
                                                            color: "var(--Color-Text-Action)",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: "32px",
                                                            height: "32px",
                                                        }}
                                                    >
                                                        {
                                                            (i?.user?.name ||
                                                                i?.user?.email ||
                                                                i?.user?.phone ||
                                                                "A")?.[0]?.toUpperCase()
                                                            // idx
                                                        }
                                                    </Body_S_Reg>
                                                )}
                                            </UserImage>
                                            <UserInfo>
                                                <TileUpperRow>
                                                    <Row>
                                                        <Body_XS_Bold
                                                            style={{
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                textWrap: "nowrap",
                                                            }}
                                                        >
                                                            {startCase(i?.user?.name) ||
                                                                i?.user?.email ||
                                                                i?.user?.phone ||
                                                                "Anonymous User"}
                                                        </Body_XS_Bold>
                                                        {Icon(
                                                            SURFACES_ICON[
                                                                i?.meta?.surface || "web"
                                                            ],
                                                            {
                                                                size: 12,
                                                                color: "var(--Color-Icon-Subtle)",
                                                            },
                                                        )}
                                                    </Row>
                                                    <Body_XXXS_Reg
                                                        style={{
                                                            color: "var(--Color-Text-Disabled)",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            marginLeft: "var(--Size-Gap-XS)",
                                                            gap: "var(--Size-Gap-S)",
                                                        }}
                                                    >
                                                        <span
                                                            role="tooltip"
                                                            data-tip
                                                            data-tooltip-id={i?.id}
                                                        >
                                                            {fromNow(updatedAtDate)}
                                                        </span>
                                                        <Tooltip id={i.id}>
                                                            {timeFormat("dd LLL yyyy, hh:mm a")(
                                                                updatedAtDate,
                                                            )}
                                                        </Tooltip>
                                                    </Body_XXXS_Reg>
                                                </TileUpperRow>
                                                <TileUpperRow>
                                                    <IconText>
                                                        <Body_XS_Reg
                                                            style={{
                                                                textOverflow: "ellipsis",
                                                                overflow: "hidden",
                                                                color: "var(--Color-Text-Subtlest)",
                                                            }}
                                                        >
                                                            {
                                                                i?.messages?.[
                                                                    i.messages?.length - 1
                                                                ]?.content
                                                            }
                                                        </Body_XS_Reg>
                                                    </IconText>
                                                    {currentConversationId.current !== i.id &&
                                                        i?.messages?.length > 1 && (
                                                            <ActvityChip active={true}>
                                                                +{i?.messages?.length - 1} New
                                                                Message
                                                            </ActvityChip>
                                                        )}
                                                    {/* {Icon(ChatIcon, {
                                                    size: 16,
                                                    color: "var(--Color-Icon-Default)",
                                                })} */}
                                                </TileUpperRow>
                                            </UserInfo>
                                        </Tile>
                                    );
                                })}
                            </InnerPageContent>
                        )}
                    </SectionLeft>
                    <SectionRight>
                        <StickyPageHeader style={{ display: "flex", flexDirection: "column" }}>
                            <Tile style={{ justifyContent: "space-between" }}>
                                <ExportButtonText>
                                    {Icon(ChatIcon, {
                                        size: 16,
                                        color: "var(--Color-Icon-Default)",
                                    })}
                                    <Body_XS_Med
                                        style={{
                                            whiteSpace: "nowrap",
                                            color: "var(--Color-Text-Default)",
                                        }}
                                    >
                                        {`Conversation ${
                                            chatUser?.name ? "with " + chatUser.name : ""
                                        }`}
                                    </Body_XS_Med>
                                </ExportButtonText>
                                {allValues?.customerSupport?.active &&
                                    !widgetLoading &&
                                    // currentConversationUser?.state &&
                                    (currentConversationUser?.state == "support_in_progress" &&
                                    currentConversationUser?.userId?.substring(2) !== user?._id ? (
                                        <CurrentUser>
                                            <StatusWrapper active={true} />
                                            <Body_XXS_Med
                                                style={{
                                                    fontWeight: 400,
                                                    whiteSpace: "nowrap",
                                                    color: "var(--Color-Text-Success)",
                                                }}
                                            >
                                                {currentConversationUser?.firstName}
                                            </Body_XXS_Med>
                                        </CurrentUser>
                                    ) : (
                                        <ExportButton
                                            mode="secondary"
                                            size="small"
                                            background="var(--Color-Background-Default)"
                                            isAssistant={currentThreadStatus === "assistant"}
                                            onClick={showEditTakeOverConversationDialog}
                                        >
                                            <ExportButtonText>
                                                {Icon(
                                                    currentThreadStatus === "assistant"
                                                        ? Play
                                                        : LogoutCurve,
                                                    {
                                                        size: 16,
                                                        color:
                                                            currentThreadStatus === "assistant"
                                                                ? "var(--Color-Icon-Default)"
                                                                : "var(--Color-Text-Danger)",
                                                    },
                                                )}
                                                <Body_XS_Med
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        color:
                                                            currentThreadStatus === "assistant"
                                                                ? "var(--Color-Text-Default)"
                                                                : "var(--Color-Text-Danger)",
                                                    }}
                                                >
                                                    {currentThreadStatus === "assistant"
                                                        ? "Take Over Conversation"
                                                        : "Leave Conversation"}
                                                </Body_XS_Med>
                                            </ExportButtonText>
                                        </ExportButton>
                                    ))}
                            </Tile>
                            <div
                                style={{
                                    display: copilotConversation.length > 0 ? "flex" : "none",
                                }}
                            >
                                <ConversationPage id="conversation-page" />
                                {widgetLoading && (
                                    <WidgetLoader>
                                        <LoaderIcon />
                                    </WidgetLoader>
                                )}
                            </div>
                        </StickyPageHeader>
                    </SectionRight>
                </InnerPageContent>
            )}
            {!isPageLoading && copilotConversation.length == 0 ? (
                <NoDataFound>
                    <Body_M_Reg>No conversation found</Body_M_Reg>
                </NoDataFound>
            ) : null}

            <RenderTakeOverConversationDialog maxWidth="500px" showCancelButton={false}>
                <ConfirmationDialog
                    title={
                        <ExportButtonText>
                            <IconWrapper currentThreadStatus={"assistant"}>
                                {Icon(currentThreadStatus === "assistant" ? Play : LogoutCurve, {
                                    size: 24,
                                    color:
                                        currentThreadStatus === "assistant"
                                            ? "var(--Color-Icon-Action)"
                                            : "var(--Color-Icon-Danger)",
                                })}
                            </IconWrapper>
                            {currentThreadStatus === "assistant"
                                ? "Take Over Conversation"
                                : "Leave Conversation"}
                        </ExportButtonText>
                    }
                    content={
                        currentThreadStatus == "support" ? (
                            `You're about to hand this conversation back to Copilot. After confirming, Copilot's automated assistance will be reactivated. You will be unable to send further message to this user and will be disconnected from this chat.`
                        ) : (
                            <>
                                You're about to switch from Copilot to a live support agent. Once
                                confirmed, Copilot will enter standby mode, and the user will no
                                longer receive automated responses.
                                <br />
                                <br />
                                To resume assistance from Copilot for the user, you will need to
                                exit the chat.
                            </>
                        )
                    }
                    okText={"Confirm"}
                    cancelText={"Cancel"}
                    type={currentThreadStatus == "support" ? "danger" : "primary"}
                    handleOkClick={handleToggleTakeOverConversation}
                    handleCancelClick={hideEditTakeOverConversationDialog}
                    data-testid="edit-takeover-confirmation-dialog"
                />
            </RenderTakeOverConversationDialog>
        </PageContent>
    );
};

export default UserConversations;
