import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import styled, { css } from "styled-components";
import { Breakpoints } from "GlobalStyle";

import { Controller } from "react-hook-form";
import { isPhoneValid } from "../utils/validations";

const PhoneInputCss = css`
    --react-international-phone-border-radius: var(--Size-CornerRadius-L);
    --react-international-phone-height: 48px;
    --react-international-phone-country-selector-border-color: var(--Color-Border-Subtle);
    --react-international-phone-border-color: var(--Color-Border-Subtle);
    --react-international-phone-background-color: var(--Color-Background-Default);
    --react-international-phone-text-color: var(--Color-Text-Default);
    --react-international-phone-country-selector-background-color-hover: var(
        --Color-Background-Default
    );
    --react-international-phone-dropdown-item-height: 40px;

    .react-international-phone-input-container {
        width: 100%;
        cursor: ${(props) => props.type === "checkbox" && "pointer"};
        background: var(--Color-Background-Default);

        border-radius: var(--Size-CornerRadius-L);
        font-size: var(--body-2-d);
        font-weight: var(--regular);
        line-height: var(--line-height-21);
        color: ${(props) =>
            props.disabled ? "var(--Color-Text-Subtlest)" : "var(--Color-Text-Default)"};
        padding-right: ${(props) => props.paddingRight};

        ::placeholder {
            color: var(--Color-Text-Disabled);
        }

        :-webkit-autofill {
            -webkit-text-fill-color: var(--Color-Text-Default);
            transition: background-color 5000s ease-in-out 0s;
        }

        @media (max-width: ${Breakpoints.mobile}px) {
            font-size: var(--body-2-m);
            height: 48px;
        }

        & > input {
            width: 100%;
            margin-left: 61px;
        }
    }
    .react-international-phone-country-selector {
        position: absolute;
        transform: translateZ(0);
        z-index: 10;
    }
    .react-international-phone-country-selector-button {
        height: var(--react-international-phone-height);
        padding: var(--Size-Padding-M);
    }
    .react-international-phone-country-selector-dropdown {
        border-radius: var(--Size-CornerRadius-L);
        width: 250px;
        outline: none;
        position: fixed;
    }

    .react-international-phone-country-selector-dropdown__list-item:hover {
        background-color: var(--Color-Background-Subtle-2);
    }
    .react-international-phone-country-selector-dropdown__list-item--selected,
    .react-international-phone-country-selector-dropdown__list-item--selected:hover {
        background-color: var(--Color-Background-Action);
        color: var(--Color-Text-Inverse);
    }
    .react-international-phone-country-selector-dropdown__list-item-dial-code {
        color: inherit;
    }
`;

const StyledPhoneInput = styled.div`
    ${PhoneInputCss};
`;

const PhoneNumberInput = ({ label, control, validation, onChange: handleChange }) => {
    return (
        <StyledPhoneInput>
            <Controller
                name={label}
                control={control}
                rules={{
                    required: true,
                    validate: (value) => isPhoneValid(value) || "Phone number is invalid",
                    ...(validation ?? {}),
                }}
                render={({ field: { onChange, value, onBlur }, fieldState: { invalid } }) => (
                    <PhoneInput
                        name={label}
                        value={value}
                        onChange={(val) => {
                            onChange(val);
                            handleChange?.(val, invalid);
                        }}
                        onBlur={onBlur}
                        forceDialCode={true}
                        defaultCountry="in"
                        preferredCountries={["in", "us", "jp"]}
                    />
                )}
            />
        </StyledPhoneInput>
    );
};

export default PhoneNumberInput;
