import React, { useState } from "react";
import environment from "../../../environment";
import styled from "styled-components";
import LoaderIcon from "common/components/LoaderIcon";
import GoogleIcon from "common/assets/google.png";
import URLS from "../../utils/apiUrls";
import { Body1 } from "../../global-styled-components";
import EventEmitter from "../../events";
import { UserSignUpSuccess } from "../../events/events";
import { UserLoginSuccess } from "../../events/events";
import { useQuery, getStoredUTMParameters } from "common/utils";
import { browserName, osName, mobileModel, isMobileOnly, isTablet } from "react-device-detect";

const GoogleLoginButton = styled.button`
    color: var(--Color-Text-Default);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--Color-Background-Default);
    border: none;
    height: 48px;
    position: relative;
    border: 1px solid var(--Color-Border-Subtle);
    padding: var(--Size-Padding-L) 24px;
    box-sizing: border-box;
    border-radius: var(--Size-CornerRadius-XXXL);
    span {
        flex-grow: 1;
        font-weight: var(--semi-bold);
        height: 48px;
    }
`;

const GoogleImage = styled.img.attrs({
    src: GoogleIcon,
})`
    height: 25px;
    width: 25px;
`;

const GoogleImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    // background: var(--Color-Text-Default);
    height: 100%;
    // border-radius: var(--Size-CornerRadius-M);
`;

const GoogleLoginText = styled(Body1)`
    color: var(--Color-Text-Default);
    font-weight: 500;
`;

const GoogleLoginNew = ({ actionType, children, onClick }) => {
    const [loading, setLoading] = useState(false);
    const redirectUrl = useQuery().get("redirectUrl");
    const signIn = () => {
        if (actionType === "signup") {
            EventEmitter.dispatch(UserSignUpSuccess, {
                signup_type: "SSO signup",
                platform: "google",
            });
        } else {
            EventEmitter.dispatch(UserLoginSuccess, {
                login_type: "SSO login",
                platform: "google",
            });
        }
        setLoading(true);
        const handleFocus = () => {
            setLoading(false);
            // Remove the event listener once it's been triggered
            window.removeEventListener("focus", handleFocus);
        };
        let params = {
            ...getStoredUTMParameters(),
            "device_type": isMobileOnly ? "Mobile" : isTablet ? "Tablet" : "Desktop",
            "device_browser": browserName,
            "device_os": osName,
            "device_model": mobileModel,
        };

        if (history?.location?.pathname === "/try-for-free") {
            params.user_source = "try-for-free";
        }

        // Add an event listener for the focus event
        window.addEventListener("focus", handleFocus);
        window.open(
            `${URLS.googleOAuthLogin}?redirect_uri=${environment.TRINITY_MAIN_DOMAIN}/${
                redirectUrl || ""
            }&state=${encodeURIComponent(JSON.stringify(params))}`,
            "_self",
        );
    };
    return (
        <>
            <GoogleLoginButton data-testid="google_btn" onClick={onClick ?? signIn}>
                {loading ? (
                    <LoaderIcon height="42px" />
                ) : (
                    <>
                        <GoogleImageWrapper>
                            <GoogleImage />
                        </GoogleImageWrapper>
                        <GoogleLoginText className="btn-text">{children}</GoogleLoginText>
                    </>
                )}
            </GoogleLoginButton>
        </>
    );
};

export default GoogleLoginNew;
