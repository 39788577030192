import AndroidIcon from "common/assets/AndroidIcon";
import WhatsappIcon from "common/assets/WhatsappIcon";
import FigmaIcon from "common/assets/FigmaIcon";
import VSCode from "common/assets/Vscode";
import Slack from "common/assets/SlackIcon";
import HostedWeb from "common/assets/HostedWebIcon";
import { GlobalSearch, Call } from "iconsax-react";

export const SURFACES_ICON = {
    "web": GlobalSearch,
    "hosted-web": HostedWeb,
    "mobile": AndroidIcon,
    "whatsapp": WhatsappIcon,
    "figma-plugin": FigmaIcon,
    "vscode-extension": VSCode,
    "slack": Slack,
    "call": Call,
};
