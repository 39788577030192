import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import CopilotEditPage from "../Edit";
import { SubTitle1, Body_M_Med, Body_L_Bold } from "common/global-styled-components";
import LottieLoader from "common/components/Loader/LottieLoader";
import BasicCopilotInfo from "../BasicCopilotInfo";
import Button from "../../../common/components/Button";
import ComponentHeader from "../../../common/components/ComponentHeader";
import { createCopilot, updateCopilot, getCopilot } from "common/redux/actions/copilotActions";

import { uploadStorageAssets } from "common/utils";
import { Breakpoints } from "../../../GlobalStyle";
import ActionBar from "../../Settings/ActionBar";
import NavBar from "common/components/HorizontalNavBar";
import CopilotPlayground from "../CopilotPlayground";
import LoaderIcon from "common/components/LoaderIcon";
import { useQuery } from "common/utils";
import { StickyPageHeader } from "common/global-styled-components";
import IconLogo from "common/components/Logo/IconLogo";
import Tag from "common/components/Tag";

import { persona } from "../../../pages/Application/constants";
const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
`;

const CreateWrapper = styled.div`
    border-radius: var(--Size-CornerRadius-XXXL);
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 100%;
    // max-width: 850px;
    gap: var(--Size-Gap-XL);
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--Size-Gap-XXL);
    align-items: center;
    justify-content: flex-end;
    & > button {
        flex: 1;
    }
`;

const EditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
`;

const Header = styled.header``;
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    border-radius: var(--Size-CornerRadius-M);
    align-items: center;
    margin-right: auto;
    @media (max-width: ${Breakpoints.mobile}px) {
        gap: 4px;
        padding-left: 36px;
        padding-top: 8px;
    }
`;

Header.Wrapper = styled.div`
    display: flex;
    margin-bottom: 32px;
`;
Header.Actions = styled.div`
    display: flex;
    flex-wrap: wrap;

    button {
        margin-left: 16px;
        font-weight: 600;
    }
`;
Header.Options = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
        align-items: center;
        justify-content: center;
    }
`;

const AbsoluteLoader = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: brightness(0.4);
    top: 0;
    right: 0;
    border-radius: var(--Size-CornerRadius-M);
`;

const FirstAbsoluteLoader = styled.div`
    position: absolute;
    height: 100%;
    width: calc(100% - 73px);
    z-index: 14;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(9px);
    top: 0;
    right: 0;
    border-radius: var(--Size-CornerRadius-M);
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
    }
`;

const OuterWrapper = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
`;

const LoaderWrapper = styled.div`
    display: inline-flex;
    padding: var(--Size-Padding-XXL) 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-M);
    border-radius: var(--Size-CornerRadius-XL);
    box-shadow: 0px 1.9300594329833984px 6.7552080154418945px 0px rgba(0, 0, 0, 0.25);
    background: var(--Color-Background-Subtle);
`;

const ActionButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-XXL);
`;

const AgentLogo = styled(IconLogo)`
    display: none;
    @media (max-width: ${Breakpoints.mobile}px) {
        display: flex;
    }
`;

const H1Styled = styled.h1`
    font-family: var(--body-font);
    font-size: var(--subtitle-2-d);
    font-weight: 700;
    line-height: var(--body-5-d);
    letter-spacing: 0em;
    color: var(--Color-Text-Default);
`;
const SAVE_ALLOWED_ON_ROUTES = [
    // "deploy/hosted-web",
    // "deploy/web",
    // "/deploy/vscode-extension",
    // "/deploy/figma-plugin",
    // "/users",
    // "/customise",
    // "/settings",
    // "/overview?edit=true",
];
export default function CopilotFormPage({
    mode,
    firstCopilot = false,
    hideCopilotCreateDialog,
    tryForFreeCallback = null,
    propOrgId,
}) {
    const [agent, setAgent] = useState({});
    const copilotList = useSelector((state) => state?.copilotDetails?.copilotList);
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [tabs, setTab] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const query = useQuery();
    const [showCopilotPlaygroundWidget, setShowCopilotPlaygroundWidget] = useState(true);
    const [isChanged, setIsChanged] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({
        name: `Copilot ${(copilotList?.page?.totalItems || 0) + 1}`,
        icon: "",
        dataSources: [],
        persona: persona,
        public: true,
        allowedEmailDomains: undefined,
        providerId: "gpt-4o-mini",
    });
    const { orgId, agentId } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        control,
        setError,
        reset,
        clearErrors,
        trigger,
    } = useForm({
        mode: "all",
        defaultValues: initialFormValues,
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const playgroundRef = useRef();
    const allValues = watch();

    const handleResponseError = (err) => {
        setLoading(false);
        let errorText = `Error while ${mode === "create" ? "creating" : "editing"} copilot.`;

        if (err?.response?.data?.meta?.errors) {
            const { name, credentials } = err.response.data.meta.errors;

            if (name) {
                setError("name", { type: "server", message: name });
            }

            if (credentials) {
                errorText += " " + credentials;
            }

            toast.error(<div>{errorText}</div>, { autoClose: 5000 });
            return;
        }

        if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message, { autoClose: 2000 });
            return;
        }

        toast.error(errorText, { autoClose: 2000 });
    };

    // onSave
    const saveChanges = async (cb) => {
        if (tryForFreeCallback) {
            tryForFreeCallback(allValues);
            return;
        }
        let isPlaygroundRestart = false;
        try {
            setLoading(true);
            let data, iconData;
            if (allValues?.icon?.length === 1) {
                data = {
                    namespace: "app_icon",
                    options: {
                        file_name: allValues?.icon?.[0]?.name,
                        file_type: allValues?.icon?.[0]?.type,
                        file_size: allValues?.icon?.[0]?.size,
                    },
                };
                iconData = await uploadStorageAssets(
                    propOrgId ?? orgId,
                    data,
                    allValues?.icon?.[0],
                );
            }
            let {
                icon,
                dataSources = [],
                primaryColor,
                secondaryColor,
                activeButtonColor,
                primaryFontColor,
                secondaryFontColor,
                borderColor,
                themeId,
                titleGreeting,
                titleText,
                suggestions,
                providerId,
                dataCollection,
                voiceAgent,
                customerSupport,
                ...allValueCopy
            } = allValues;

            let access;
            if (allValues?.public == false) {
                access = {
                    type: "private",
                    allowedEmailDomains: allValues?.allowedEmailDomains?.split("\n"),
                };
            }
            data = {
                ...allValueCopy,
                icon: iconData || icon,
                datasources: Array.isArray(dataSources)
                    ? dataSources.map((val) => JSON.parse(val)?.value)
                    : [JSON.parse(dataSources)?.value],
                access,
                initialContent: undefined, // TODO: fix this
            };
            // sanitize extra data

            if (mode !== "create") {
                data.configuration = {
                    dataCollection,
                    voiceAgent,
                    customerSupport,
                    appearance: {
                        colors: {
                            primaryColor,
                            secondaryColor,
                            activeButtonColor,
                            primaryFontColor,
                            secondaryFontColor,
                            borderColor,
                            themeId,
                        },
                    },
                    provider: {
                        chatModelName: providerId,
                    },
                    initialContent: {
                        titleGreeting,
                        titleText,
                        suggestions: suggestions?.filter((s) => s),
                    },
                };
            }

            isPlaygroundRestart = !_.isEqual(agent?.configuration, data.configuration);
            const resData =
                mode === "create"
                    ? await createCopilot(propOrgId ?? orgId, data, dispatch)
                    : await updateCopilot(orgId, agentId, data, dispatch);
            toast.success(`Copilot ${mode === "create" ? "created" : "updated"} successfully`, {
                autoClose: 2000,
            });
            setValue("icon", resData.icon);
            setIsChanged(false);
            setInitialFormValues(JSON.parse(JSON.stringify({ ...allValues, icon: resData.icon })));
            if (mode === "create" || query.get("edit")) {
                hideCopilotCreateDialog();
                history.push(
                    `/org/${propOrgId ?? orgId}/cp/${
                        resData.id
                    }/datasources?primaryDatasource=true`,
                );
            }
        } catch (error) {
            console.error(error);
            handleResponseError(error);
        } finally {
            setLoading(false);
            if (isPlaygroundRestart) {
                playgroundRef?.current?.restart();
            }
        }
    };

    // onSubmit
    const onSubmit = async () => {
        if (allValues?.isOtherSubmit) {
            allValues?.isOtherSubmit?.();
            return;
        }
        saveChanges();
    };

    useEffect(() => {
        if (mode !== "edit") {
            return;
        }
        getCopilot(orgId, agentId, dispatch)
            .then(async (res) => {
                setAgent({ ...res });
                playgroundRef?.current?.restart();
            })
            .catch((error) => {
                console.error(error);
                toast.error("Error Loading Copilot");
                history.push(`/org/${orgId}/dashboard`);
            });
        // window.addEventListener("message", notifyProductResult, false);
        // return () => window.removeEventListener("message", notifyProductResult);
    }, [agentId]);

    // set data for 'edit' mode
    useEffect(async () => {
        if (!_.isEmpty(agent) && mode === "edit") {
            const appearanceColors = agent?.configuration?.appearance?.colors || {};
            const {
                primaryColor = "#0A0E18",
                secondaryColor = "#141822",
                activeButtonColor = "linear-gradient(90deg, #7F00FF 0%, #3D05DD 100%)",
                primaryFontColor = "#ffffff",
                secondaryFontColor = "#ffffff",
                borderColor = "#21263A",
                themeId = "default", // added to support backward compatiblity
            } = appearanceColors;
            try {
                // Setting default lead config values
                const dataCollection = agent?.configuration?.dataCollection || {
                    captureMode: "chat",
                    active: false,
                    skippable: false,
                    sequential: false,
                    fields: [
                        {
                            id: "name",
                            name: "Name",
                            required: true,
                            description: "user's name",
                            type: "string",
                        },
                        {
                            id: "email",
                            name: "Email",
                            required: true,
                            format: "email",
                            description: "user's email",
                            type: "string",
                        },
                        {
                            id: "phone",
                            name: "Phone",
                            required: true,
                            description: "user's phone number",
                            type: "string",
                        },
                    ],
                };
                const data = {
                    name: agent.name,
                    icon: agent.icon,
                    domain:
                        agent?.config?.domains?.length > 0
                            ? agent?.config?.domains?.join("\n")
                            : "",
                    visibilityPathPatterns: agent?.config?.visibilityPathPatterns?.join("\n") || "",
                    dataSources:
                        (agent?.datasources?.length > 0 &&
                            agent.datasources.map((val) => {
                                return JSON.stringify({ name: val.name, value: val.id });
                            })) ||
                        [],
                    dataCollection,
                    platform: "web",
                    persona: agent.persona,
                    active: agent.active,
                    primaryColor,
                    secondaryColor,
                    activeButtonColor,
                    primaryFontColor,
                    secondaryFontColor,
                    borderColor,
                    themeId,
                    providerId: agent?.configuration?.provider?.chatModelName || "gpt-4o-mini",
                    initialContent: agent?.configuration?.initialContent,
                    titleGreeting: agent?.configuration?.initialContent?.titleGreeting || "",
                    titleText: agent?.configuration?.initialContent?.titleText || "",
                    suggestions: agent?.configuration?.initialContent?.suggestions || [],
                    public: agent?.access?.type === "private" ? false : true,
                    allowedEmailDomains: agent?.access?.allowedEmailDomains?.join("\n") || "",
                    createdAt: agent?.createdAt,
                    surfaces: agent?.surfaces || [],
                    customerSupport: agent?.configuration?.customerSupport,
                    voiceAgent: agent?.configuration?.voiceAgent || {},
                    items: agent?.configuration?.dataCollection?.fields,
                };

                setInitialFormValues(data);
                reset(data, { keepDirty: true });
            } catch (error) {
                toast.error("Error Loading Copilot");
                console.error(error);
                history.push(`/org/${orgId}/dashboard`);
            }
        }
    }, [agent, mode, agentId]);
    // hiding loader once data is available
    useEffect(() => {
        if (mode === "edit" && allValues.name === agent.name) {
            setFirstLoading(false);
        } else if (mode === "create") {
            setFirstLoading(false);
        }
    }, [agent, agentId, allValues.name, mode]);

    // enabling/disabling 'save' button

    useEffect(() => {
        setTab([
            {
                displayName: "Overview",
                identifier: "edit",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/overview`,
            },
            {
                displayName: "Inbox",
                identifier: "inbox",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/inbox/conversations`,
            },
            {
                displayName: "Data Sources",
                identifier: "datasources",
                dropDown: false,
                // icon: Data2,
                route: `/org/${orgId}/cp/${agentId}/datasources`,
            },
            {
                displayName: "Skills",
                identifier: "linkedSkills",
                dropDown: false,
                // icon: Setting,
                route: `/org/${orgId}/cp/${agentId}/linkedSkills`,
            },

            {
                displayName: "Deploy",
                identifier: "platform",
                dropDown: false,
                // icon: Setting,
                route: `/org/${orgId}/cp/${agentId}/deploy`,
            },
            // {
            //     displayName: `Workflows`,
            //     displayComponent: <Tag mode="primary" title="New" />,
            //     identifier: "workflows",
            //     dropDown: false,
            //     // icon: Setting,
            //     route: `/org/${orgId}/cp/${agentId}/workflows`,
            // },
            // {
            //     displayName: "Integrations",
            //     displayComponent: <Tag mode="primary" title="New" />,
            //     identifier: "integrations",
            //     dropDown: false,
            //     // icon: Setting,
            //     route: `/org/${orgId}/cp/${agentId}/integrations`,
            // },
            {
                displayName: "Settings",
                identifier: "settings",
                dropDown: false,
                // icon: Setting,
                route: `/org/${orgId}/cp/${agentId}/settings/info`,
            },
        ]);
    }, [agentId]);
    const location = useLocation();
    const getTextToShow = () => {
        return location.pathname === "/try-for-free" ? (
            <H1Styled>Try For Free</H1Styled>
        ) : (
            <Body_L_Bold>Create Copilot</Body_L_Bold>
        );
    };
    return (
        <OuterWrapper>
            {mode === "create" ? (
                getTextToShow()
            ) : (
                <StickyPageHeader>
                    <ComponentHeader showUpgradeButton={true}>
                        <Header.Title>
                            <AgentLogo src={agent?.icon} />
                            {allValues?.name?.trim() || agent.name || ""}
                        </Header.Title>
                        <Header.Options>
                            {mode === "edit" && (
                                <ActionButtonWrapper>
                                    {/* <Button
                                form="copilot-form"
                                title={`${_.startCase(mode)} agent`}
                                type="submit"
                                mode={showCopilotPlaygroundWidget ? "primary" : "secondary"}
                                size="small"
                                onClick={() =>
                                    window.innerWidth > Breakpoints.tablet
                                        ? setShowCopilotPlaygroundWidget(
                                              !showCopilotPlaygroundWidget,
                                          )
                                        : toast.info("Widget can only be tested in large screens")
                                }
                            >
                                {Icon(Magicpen, { size: 21 })} &nbsp; Test Copilot
                            </Button> */}
                                    {!isSubmitDisabled && (
                                        <Button
                                            form="copilot-form"
                                            title={`${_.startCase(mode)} copilot`}
                                            type="submit"
                                            mode="primary"
                                            size="small"
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </ActionButtonWrapper>
                            )}
                        </Header.Options>
                    </ComponentHeader>

                    {mode === "edit" && <NavBar TABS={tabs} />}
                </StickyPageHeader>
            )}
            <Wrapper>
                {loading && (
                    <AbsoluteLoader>
                        <LoaderWrapper>
                            <LottieLoader />
                            <Body_M_Med>
                                {mode === "edit" ? "Updating" : "Creating"} your widget...
                            </Body_M_Med>
                        </LoaderWrapper>
                    </AbsoluteLoader>
                )}
                {firstLoading && (
                    <FirstAbsoluteLoader>
                        <LoaderIcon />
                    </FirstAbsoluteLoader>
                )}
                {mode === "edit" ? (
                    <EditWrapper>
                        <CopilotEditPage
                            trigger={trigger}
                            reset={reset}
                            token={agent?.token || ""}
                            register={register}
                            control={control}
                            allValues={allValues}
                            agentId={agentId}
                            errors={errors}
                            setValue={setValue}
                            playgroundRef={playgroundRef}
                            setError={setError}
                            initialFormValues={initialFormValues}
                            clearErrors={clearErrors}
                            onSubmit={handleSubmit(onSubmit)}
                            setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                            showCopilotPlaygroundWidget={showCopilotPlaygroundWidget}
                            setIsChanged={setIsChanged}
                            isChanged={isChanged}
                        />
                        {agentId && agent?.token && showCopilotPlaygroundWidget && (
                            <CopilotPlayground
                                ref={playgroundRef}
                                token={agent?.token ?? ""}
                                showWidget={true}
                            />
                        )}
                    </EditWrapper>
                ) : (
                    <CreateWrapper>
                        <BasicCopilotInfo
                            register={register}
                            control={control}
                            allValues={allValues}
                            errors={errors}
                            setValue={setValue}
                            setError={setError}
                        />
                        <ButtonsWrapper>
                            {!firstCopilot && (
                                <Button onClick={() => hideCopilotCreateDialog()} mode="secondary">
                                    Cancel
                                </Button>
                            )}
                            <Button
                                onClick={handleSubmit(onSubmit)}
                                disabled={Object.keys(errors).length > 0}
                            >
                                Create
                            </Button>
                        </ButtonsWrapper>
                    </CreateWrapper>
                )}
            </Wrapper>

            {!isSubmitDisabled && (
                <ActionBar
                    showSaveModal={handleSubmit(onSubmit)}
                    disabled={isSubmitDisabled}
                    buttonText={mode === "edit" ? "Save Changes" : "New Copilot"}
                />
            )}
        </OuterWrapper>
    );
}
