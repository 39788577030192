import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const SkillService = {
    getSkills(orgId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.skills}?${queryString}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    getSystemSkills(orgId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.systemSkills}?${queryString}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    getSkill(orgId, skillId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.skill.replace(":orgId", orgId).replace(":skillId", skillId),
            axiosOptions,
        );
    },

    getSystemSkillDetails(orgId, systemSkillId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.systemSkill.replace(":orgId", orgId).replace(":systemSkillId", systemSkillId),
            axiosOptions,
        );
    },
    getSkillDataSources(orgId, skillId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.skillDatasources}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":skillId", skillId),
            axiosOptions,
        );
    },
    createSkill(orgId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(URLS.skills.replace(":orgId", orgId), axiosOptions);
    },
    updateSkill(orgId, skillId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.put(
            URLS.skill.replace(":orgId", orgId).replace(":skillId", skillId),
            axiosOptions,
        );
    },
    deleteSkill(orgId, skillId) {
        const axiosOptions = {};
        return ApiService.delete(
            URLS.skill.replace(":orgId", orgId).replace(":skillId", skillId),
            axiosOptions,
        );
    },
};

export default SkillService;
