import React, { useState } from "react";
import { css } from "styled-components";
import VisibilityIcon from "../assets/VisibilityIcon";
import VisibilityOffIcon from "../assets/VisibilityOffIcon";
import TextInput from "./TextInput";
import { Eye, EyeSlash } from "iconsax-react";
import Icon from "./Icon";

const PasswordIconStyle = css`
    cursor: pointer;
    padding-left: 0;
    fill: var(--light-100);
`;

export default function PasswordInput({ children, ...rest }) {
    const [visibility, setVisiblility] = useState(false);

    return (
        <TextInput
            {...rest}
            $autoComplete={false}
            type={visibility ? "text" : "password"}
            paddingRight="35px"
        >
            {visibility ? (
                <div onClick={() => setVisiblility(false)}>
                    {Icon(EyeSlash, { size: 24, color: "var(--Color-Icon-Subtle)" })}
                </div>
            ) : (
                <div onClick={() => setVisiblility(true)}>
                    {Icon(Eye, { size: 24, color: "var(--Color-Icon-Subtle)" })}
                </div>
            )}
            {children}
        </TextInput>
    );
}
