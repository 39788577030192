import React from "react";
import styled from "styled-components";
import Icon from "common/components/Icon";
import { DocumentCopy } from "iconsax-react";
import { Body_XS_Reg, Body_S_Bold } from "common/global-styled-components";
import copy from "copy-to-clipboard";
import Button from "common/components/Button";
import { widgetScriptText } from "common/utils";
import { toast } from "react-toastify";
import TextArea from "common/components/TextArea";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--Size-Gap-XXL);
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: var(--Size-Gap-S);
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-XL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    // background: var(--Color-Background-Subtle);
`;

const ScriptTag = styled(Body_XS_Reg)`
    overflow-wrap: anywhere;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: var(--Color-Text-Default);
    background: var(--Color-Background-Subtle);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-XL);
`;

const CopyWidget = styled(Button)`
    display: flex;
    gap: var(--Size-Gap-M);
    margin: 12px;
    width: fit-content;
    background: var(--Color-Background-Default);
    padding: 10px var(--Size-Padding-L);
`;

const Footer = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: var(--Color-Text-Subtlest);
`;

const ScriptCopyModal = ({ name, instruction, scriptText, title, textAreaOptions = {} }) => {
    const _textAreaOptions = { rows: 7, disabled: true, ...textAreaOptions };

    const copyToClipboard = () => {
        copy(scriptText);
        toast.info("Copied to clipboard", { autoClose: 2000 });
    };

    return (
        <Wrapper>
            <Header>
                <Body_S_Bold>{title}</Body_S_Bold>
                <Body_XS_Reg>{instruction}</Body_XS_Reg>
            </Header>
            <Container>
                <ScriptTag>
                    <TextArea
                        value={scriptText}
                        name={name}
                        style={{
                            resize: "vertical",
                            background: "transparent",
                            color: "var(--Color-Text-Subtle)",
                            border: "0",
                        }}
                        rows={_textAreaOptions.rows}
                        disabled={_textAreaOptions.disabled}
                    ></TextArea>
                    <CopyWidget mode="secondary" onClick={copyToClipboard}>
                        {Icon(DocumentCopy, { size: 21, color: "var(--Color-Icon-Default)" })}
                        Copy Script
                    </CopyWidget>
                </ScriptTag>
            </Container>
        </Wrapper>
    );
};

export default ScriptCopyModal;
