import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SubTitle1, Body_XS_Reg } from "../global-styled-components";
import SearchIcon from "common/assets/SearchIcon";
import { ReactComponent as CancelIcon } from "common/assets/svgs/Cross.svg";
import { Breakpoints } from "../../GlobalStyle";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from "./Button";
import { useDispatch } from "react-redux";
import { getSubscriptionDetail } from "common/redux/actions/subscriptionActions";
import { FREE_PLANS } from "common/constants";
import Icon from "./Icon";
import { CalendarEdit, Danger } from "iconsax-react";
import Tooltip from "./Tooltip";

// import { useHistory } from "react-router-dom";

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    // height: 96px;
    position: relative;
    width: 100%;
    padding: ${(props) => (props?.$overallPadding ? props.$overallPadding : "0px 0px 0px 12px")};
    @media (max-width: ${Breakpoints.mobile}px) {
        background-color: transparent;
        padding: 0;
        height: unset;
    }
`;

const Heading = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--Size-Gap-XL);
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "space-between")};
    width: 100%;
    // max-width: ${({ fullWidth }) => !fullWidth && "1200px"};
    // min-height: 50px;
    padding: ${(props) => (props?.$padding ? props.$padding : "12px 24px 0px 0px")};
    @media (max-width: ${Breakpoints.mobile}px) {
        padding: ${(props) => (props?.$padding ? props.$padding : "0px")};
        align-items: flex-start;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: var(--Size-Gap-XL);
    position: relative;
    @media (max-width: ${Breakpoints.mobileXS}px) {
        width: 100%;
        justify-content: space-between;
        padding: var(--Size-Padding-M) 16px;
    }
`;

const InputWrapper = styled.div`
    max-width: 500px;
    width: 100%;
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const Warning = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: var(--Size-Gap-XS);
    width: 100%;
    cursor: pointer;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    @media (max-width: ${Breakpoints.mobileXS}px) {
        justify-content: flex-end;
        width: 100%;
        padding: 0 16px;
    }
`;

const IconWrapper = styled.div`
    min-width: 40px;
    width: 40px;
    // min-height: 40px;
    height: 40px;
    background: var(--dark-700);
    border-radius: var(--Size-CornerRadius-M);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background: var(--dark-600);
    }
    @media (min-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const OverlaySearch = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark-900);
    z-index: 1;
    gap: 20px;
    // min-height: 40px;
    @media (max-width: ${Breakpoints.mobile}px) {
        padding: var(--Size-Padding-XL);
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 20px;
    // min-height: 40px;
`;

const PageTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 4px;
    height: 52px;
    @media (max-width: ${Breakpoints.mobile}px) {
        padding-left: 36px;
    }
`;

const SubtitleWrapper = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
`;

const AIKeyRequiredModal = styled.div`
    display: flex;
    gap: var(--Size-Gap-M);
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Size-CornerRadius-L);
    background: var(--Color-Background-Danger);
    color: var(--Color-Text-Inverse);
    cursor: pointer;
`;

const BookDemoButtonWrapper = styled(Button)`
    gap: var(--Size-Gap-L);
`;

const ComponentHeader = ({
    children,
    justifyContent,
    fullWidth = false,
    title,
    subtitle,
    back,
    search,
    learnMore,
    actions,
    $padding,
    $overallPadding,
    showUpgradeButton,
    ...props
}) => {
    const [showMobSearch, setShowMobSearch] = useState(false);
    const org = useSelector((state) => state?.organizationDetails);
    const dispatch = useDispatch();
    const planId = org?.currentSubscription?.planId;
    const usageLimit = org?.currentSubscription?.usageLimitExceeded || {};
    const orgId = org?.organization?._id;
    const history = useHistory();
    useEffect(() => {
        const fetchSubscriptionDetail = () =>
            getSubscriptionDetail(orgId, dispatch)
                .then((response) => {})
                .catch((error) => {})
                .finally(() => {});
        if (!planId) fetchSubscriptionDetail();
    }, []);

    const isPlanUpgradable = FREE_PLANS.includes(planId) && showUpgradeButton;

    return (
        <HeadingWrapper $overallPadding={$overallPadding}>
            <Heading fullWidth={fullWidth} justifyContent={justifyContent} $padding={$padding}>
                <LeftWrapper>
                    <Wrapper>
                        {back && <div>{back}</div>}
                        {title && (
                            <PageTitle>
                                <SubTitle1>{title || ""}</SubTitle1>
                                {subtitle && <SubtitleWrapper>{subtitle}</SubtitleWrapper>}
                            </PageTitle>
                        )}
                        {search && <InputWrapper>{search}</InputWrapper>}
                        {children}
                    </Wrapper>
                    {search && (
                        <>
                            {!showMobSearch && (
                                <IconWrapper onClick={() => setShowMobSearch(true)}>
                                    <SearchIcon width="18" height="18" />
                                </IconWrapper>
                            )}
                            {showMobSearch && (
                                <OverlaySearch>
                                    {search}
                                    <IconWrapper onClick={() => setShowMobSearch(false)}>
                                        <CancelIcon />
                                    </IconWrapper>
                                </OverlaySearch>
                            )}
                        </>
                    )}
                </LeftWrapper>

                {actions && <ActionWrapper>{actions}</ActionWrapper>}
                {Object.values(usageLimit).includes(true) && showUpgradeButton && (
                    <ActionWrapper>
                        <Warning
                            onClick={() => {
                                history.push(`/org/${orgId}/settings/pricing`);
                            }}
                        >
                            {Icon(Danger, { size: 16, color: "var(--Color-Icon-Warning)" })}
                            <Body_XS_Reg style={{ color: "var(--Color-Text-Warning)" }}>
                                Message Queries exceeded
                            </Body_XS_Reg>
                        </Warning>
                    </ActionWrapper>
                )}
                {showUpgradeButton && (
                    <ActionWrapper>
                        <BookDemoButtonWrapper
                            mode={isPlanUpgradable ? "secondary" : "primary"}
                            size="small"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                    "https://calendly.com/copilot-live/30min?utm_source=copilot+platform&utm_medium=button&utm_campaign=copilot+demo&utm_content=platform+book+a+demo+button+click",
                                    "_blank",
                                );
                            }}
                            id={"talk-to-an-expert"}
                            role="tooltip"
                            data-tip
                            data-tooltip-id={"talk-to-an-expert"}
                            data-gtm-track="talk-to-an-expert"
                        >
                            {Icon(CalendarEdit, {
                                size: 20,
                                color: isPlanUpgradable
                                    ? "var(--Color-Text-Default)"
                                    : "var(--Color-Icon-Inverse)",
                            })}
                            Book a Demo
                        </BookDemoButtonWrapper>
                        <Tooltip
                            place="right"
                            type="faint"
                            effect="solid"
                            noArrow={true}
                            id={"talk-to-an-expert"}
                        >
                            Talk to an expert!
                        </Tooltip>
                    </ActionWrapper>
                )}
                {isPlanUpgradable && (
                    <ActionWrapper>
                        <Button
                            mode="primary"
                            size="small"
                            onClick={() => {
                                history.push(`/org/${orgId}/settings/pricing`);
                            }}
                        >
                            Upgrade Now
                        </Button>
                    </ActionWrapper>
                )}
                {/* )} */}
            </Heading>
        </HeadingWrapper>
    );
};

export default ComponentHeader;
