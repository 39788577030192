import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const WorkflowService = {
    connect(orgId, provider, queryString) {
        const axiosOptions = {};
        return ApiService.post(
            `${URLS.connectIntegration}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":provider", provider),
            axiosOptions,
        );
    },
    getAll(orgId, copilotId, provider, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotWorkflows}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":provider", provider),
            axiosOptions,
        );
    },
};

export default WorkflowService;
