import React, { forwardRef } from "react";
import styled from "styled-components";

const TrWrapper = styled.tr`
    // :hover {
    //     background: var(--Color-Background-Subtle);
    // }
`;

const TableRow = forwardRef(({ children }, ref) => {
    return <TrWrapper ref={ref}>{children}</TrWrapper>;
});

export default TableRow;
