import SkillService from "../../../services/skill.service";

export const SkillAction = {
    SkillCreateSuccess: "CREATE_SKILL_SUCCESS",
    SkillCreateFailed: "CREATE_SKILL_FAILED",
    CreateSkill: "CREATE_SKILL",
    GetSkills: "GET_SKILLS",
    GetSkill: "GET_SKILL",
    UpdateSkill: "UPDATE_SKILL",
    DeleteSkill: "DELETE_SKILL",
};

export const createSkill = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.createSkill(orgId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.SkillCreateSuccess,
                    payload: { isZoneRegistered: true, skill: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.SkillCreateFailed,
                    payload: { isZoneRegistered: false, skill: null },
                });
                reject(err);
            });
    });
};

export const getSkills = (orgId, queryParams, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.getSkills(orgId, queryParams) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.GetSkills,
                    payload: {
                        skillList: { skills: payload.items, page: payload.page },
                    },
                    // page: payload.data.page
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.GetSkills,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const getSystemSkills = (orgId, queryParams, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.getSystemSkills(orgId, queryParams) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.GetSkills,
                    payload: {
                        skillList: { skills: payload.items, page: payload.page },
                    },
                    // page: payload.data.page
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.GetSkills,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const updateSkill = (orgId, skillId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.updateSkill(orgId, skillId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.UpdateSkill,
                    payload: { isSkillUpdated: true, skill: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.UpdateSkill,
                    payload: { isSkillUpdated: false, skill: null },
                });
                reject(err);
            });
    });
};

export const getSkill = (orgId, skillId, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.getSkill(orgId, skillId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.GetSkill,
                    payload: { exists: true, skill: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.GetSkill,
                    payload: { exists: false, skill: null },
                });
                reject(err);
            });
    });
};

export const getSystemSkillDetails = (orgId, systemSkillId, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.getSystemSkillDetails(orgId, systemSkillId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.GetSkill,
                    payload: { exists: true, skill: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.GetSkill,
                    payload: { exists: false, skill: null },
                });
                reject(err);
            });
    });
};

export const deleteSkill = (orgId, skillId, dispatch) => {
    return new Promise((resolve, reject) => {
        SkillService.deleteSkill(orgId, skillId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: SkillAction.DeleteSkill,
                    payload: { exists: true, skill: skillId },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: SkillAction.DeleteSkill,
                    payload: { exists: false, skill: null },
                });
                reject(err);
            });
    });
};
