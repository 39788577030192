import React, { useEffect, useState } from "react";
import styled from "styled-components";
import List from "common/components/List";
import { useParams } from "react-router-dom";
import CopilotService from "services/copilot.service";
import { useForm } from "react-hook-form";
import psl from "psl";
import TextArea from "common/components/TextArea";
import TextInput from "common/components/TextInput";
import VoiceInfo from "./VoiceInfo";
import {
    Body3,
    InputLabel,
    Body_XS_Reg,
    Body_XS_Med,
    Body_2XS_Reg,
    StickyPageHeader,
    StickyPageFooter,
    PageContent,
    PageTitleWrapper,
    PageTitleHeader,
    PageTitleSubtitleWrapper,
    VerticalLine,
} from "common/global-styled-components";
import { Breakpoints } from "GlobalStyle";
import ScriptCopyModal from "../ScriptCopyModal";
import { ReactComponent as SuccessIcon } from "common/assets/svgs/alerts/Success.svg";
import { toast } from "react-toastify";
import SlideToggle from "common/components/SlideToggle";
import { usePageTitle } from "common/utils";
import Button from "common/components/Button";
import NavBar from "common/components/HorizontalNavBar";
import DividerHr from "common/components/DividerHr";
import { isEqualWith } from "lodash";
import { widgetScriptText } from "common/utils";

const DialogWrapper = styled.div`
    margin: auto;
    max-height: 80vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
    ::-webkit-scrollbar {
        display: none !important;
    }
`;
const StyledPageContent = styled(PageContent)`
    background: transparent;
    margin: 0px;
    height: 430px;
    overflow-y: scroll;
    padding: 0;
    gap: var(--Size-Gap-L);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    justify-content: space-between;
    gap: var(--Size-Gap-XXL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;
const InputWrapper = styled.div`
    width: 100%;
`;
const Label = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtle);
`;

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--Size-Gap-XL);
    flex-wrap: wrap;
`;

const DraggableWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const LayoutInputWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-XXL);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const SuccessIconWrapper = styled.div`
    position: absolute;
    top: 55%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 1;
`;
const OuterDiv = styled.div`
    width: 64px;
    height: 64px;
    cursor: pointer;
    background: var(--Color-Background-Subtle);
    border-radius: var(--Size-CornerRadius-XL);
    position: relative;
`;

const Unit = styled(Body_XS_Reg)`
    border-left: 1px solid var(--Color-Border-Subtlest);
    color: var(--Color-Text-Subtlest);
    padding-left: 10px;
    position: absolute;
    right: 13px;
    @media (max-width: ${Breakpoints.mobile}px) {
        top: 10px;
    }
`;

const InnerRect = styled.div`
    width: 20px;
    height: 20px;
    background-color: #535a7a;
    border-radius: 6px;
    position: absolute;
    top: ${(props) => props.y}px;
    left: ${(props) => props.x}px;
`;

const SlideToggleWrapper = styled(SlideToggle)`
    margin-left: 12px;
`;

const SideWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--Size-Gap-XXL);
    justify-content: space-between;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const Layout = [
    {
        position: "bottom-right",
        x: 36,
        y: 36,
    },
    {
        position: "top-right",
        x: 36,
        y: 8,
    },
    {
        position: "top-left",
        x: 8,
        y: 8,
    },
    {
        position: "bottom-left",
        x: 8,
        y: 36,
    },
];
const tabs = [
    {
        displayName: "General",
        identifier: "general",
    },
    {
        displayName: "Embed Code",
        identifier: "embedCode",
    },
];
const Website = ({
    token,
    type,
    oldAllValues,
    oldSetValue,
    modalCloseButtonCallback,
    handleSurfaceUpdate,
    playgroundRef,
}) => {
    const [editOption, setEditOption] = useState("general");
    const [isWebWidgetActive, setWebWidgetActive] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [initialValue, setInitialValue] = useState({
        aiWebCall: false,
        allowedDomains: "",
        allowedPaths: "",
        draggable: true,
        nativeAudio: true,
        position: "",
        spacingX: 20,
        spacingY: 20,
    });
    const { orgId, agentId } = useParams();

    const {
        register,
        formState: { errors },
        watch,
        setValue,
        setError,
        reset,
    } = useForm({
        mode: "all",
        defaultValues: {},
    });

    const allValues = watch();

    usePageTitle(`${oldAllValues?.name} widget`);
    useEffect(() => {
        CopilotService.getCopilotSurface(orgId, agentId, type)
            .then((val) => {
                let res = val.data;
                setWebWidgetActive(!!res.active);
                let data = {
                    active: res?.active,
                    allowedPaths: res?.config?.allowedPaths?.join("\n") || "",
                    spacingX: res?.config?.layout?.spacingX || 20,
                    spacingY: res?.config?.layout?.spacingY || 20,
                    aiWebCall: res?.config?.aiWebCall ?? false,
                    nativeAudio: res?.config?.nativeAudio ?? true,
                    draggable: res?.config?.layout?.draggable ?? true,
                    loggedInUsers: res?.config?.layout?.loggedInUsers ?? false,
                    position: res?.config?.layout?.position || "bottom-right",
                    allowedDomains: res?.config?.allowedDomains?.join("\n") || "",
                };
                setInitialValue(data);
                reset(data, { keepDirty: true });
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Unable to fetch widget data", {
                    autoClose: 2000,
                });
            });

        return () => {
            oldSetValue("isOtherSubmit", null);
        };
    }, []);
    const fields = [
        "aiWebCall",
        "allowedDomains",
        "allowedPaths",
        "draggable",
        "nativeAudio",
        "position",
        "spacingX",
        "spacingY",
        "active",
        "loggedInUsers",
    ];
    useEffect(() => {
        const compareValues = (val1, val2) => {
            return val1 == val2;
        };
        const allFalse = fields.every((key) =>
            isEqualWith(initialValue?.[key], allValues?.[key], compareValues),
        );
        setIsChanged(!allFalse);
    }, [allValues]);

    const updateWebsiteDeployment = () => {
        if (handleDomains() || handlePaths()) return;
        let allowedDomains = new Set(
            allValues?.allowedDomains
                ?.trim()
                ?.split("\n")
                .filter((i) => i),
        );
        let allowedPaths = new Set(
            allValues?.allowedPaths
                ?.trim()
                ?.split("\n")
                .filter((i) => i),
        );
        if (allowedDomains.size === 0) {
            allowedDomains.add("*");
        }
        if (allowedPaths.size === 0) {
            allowedPaths.add("*");
        }
        CopilotService.updateCopilotSurface(orgId, agentId, type, {
            active: allValues?.active || true,
            config: {
                allowedDomains: Array.from(allowedDomains),
                allowedPaths: Array.from(allowedPaths),

                layout: {
                    spacingX: +allValues?.spacingX,
                    spacingY: +allValues?.spacingY,
                    draggable: allValues?.draggable,
                    loggedInUsers: allValues.loggedInUsers,
                    position: allValues?.position,
                },
                aiWebCall: allValues?.aiWebCall ?? false,
                nativeAudio: allValues?.nativeAudio ?? true,
            },
        })
            .then((val) => {
                playgroundRef?.current?.restart(); // added to restart the playground so it fetches latest config
                let res = val?.data;
                let data = {
                    active: res?.active,
                    allowedPaths: res?.config?.allowedPaths?.join("\n") || "",
                    spacingX: res?.config?.layout?.spacingX,
                    spacingY: res?.config?.layout?.spacingY,
                    aiWebCall: res?.config?.aiWebCall,
                    nativeAudio: res?.config?.nativeAudio,
                    draggable: res?.config?.layout?.draggable,
                    loggedInUsers: res?.config?.layout?.loggedInUsers,
                    position: res?.config?.layout?.position,
                    allowedDomains: res?.config?.allowedDomains?.join("\n") || "",
                };
                setInitialValue(data);
                reset(data, { keepDirty: true });
                setWebWidgetActive(true);
                handleSurfaceUpdate(val?.data || {});
                toast.success("Web surface updated successfully");
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Web surface not updated successfully", {
                    autoClose: 2000,
                });
            });
    };
    /**
     * path must not contain `\`, ` `
     * path must not contain multiple `*`
     * @param {*} path
     * @returns
     */
    const isValidWildcard = (path) => {
        const regex = /[\s\\]|(\*{2,})/;
        return !regex.test(path);
    };

    const handleDomains = () => {
        const domains = allValues.allowedDomains
            ?.trim()
            ?.split("\n")
            .filter((val) => val);
        let error = [];
        domains.forEach((val) => {
            if (!psl.isValid(val) && val != "*") {
                error.push(val);
            }
        });
        if (error.length === 0) return false;
        setError("allowedDomains", {
            type: "custom",
            message: `Invalid domain(s): ${error?.flat()}`,
        });
        return true;
    };

    const handlePaths = () => {
        const paths = allValues.allowedPaths
            ?.trim()
            ?.split("\n")
            .filter((val) => val);
        let error = [];
        paths.forEach((val) => {
            if (!isValidWildcard(val)) {
                error.push(val);
            }
        });
        if (error.length === 0) return false;
        setError("allowedPaths", {
            type: "custom",
            message: `Invalid wildcard(s): ${error.flat()}`,
        });
        return true;
    };

    const scriptWithToken = widgetScriptText.replace(":tokenId", token || "");

    return (
        <DialogWrapper data-testid="edit-skills">
            <StickyPageHeader>
                <List.ComponentHeader $padding="0px">
                    <PageTitleWrapper>
                        <PageTitleHeader>Web Widget</PageTitleHeader>
                        <PageTitleSubtitleWrapper>
                            Note: It might take upto 1 min to reflect any changes on the live
                            copilot
                        </PageTitleSubtitleWrapper>
                    </PageTitleWrapper>
                </List.ComponentHeader>
                <NavBar
                    route={false}
                    TABS={tabs}
                    activeTabIdentifier={editOption}
                    callback={(tab) => {
                        setEditOption(tab?.identifier);
                    }}
                ></NavBar>
            </StickyPageHeader>

            <StyledPageContent>
                {editOption === "general" && (
                    <>
                        <VoiceInfo
                            aiWebCall={allValues.aiWebCall}
                            nativeAudio={allValues.nativeAudio}
                            setValue={setValue}
                            allValues={allValues}
                        />

                        <Wrapper>
                            <Label>Copilot Position on Website</Label>
                            <SideWrapper>
                                <InputWrapper style={{ width: "unset" }}>
                                    <LayoutWrapper>
                                        {Layout.map((val) => (
                                            <OuterDiv
                                                key={`${val.x}-${val.y}`}
                                                onClick={() => setValue("position", val.position)}
                                            >
                                                <InnerRect x={val.x} y={val.y} />
                                                {allValues.position === val.position && (
                                                    <SuccessIconWrapper>
                                                        <SuccessIcon />
                                                    </SuccessIconWrapper>
                                                )}
                                            </OuterDiv>
                                        ))}
                                    </LayoutWrapper>
                                </InputWrapper>
                                <VerticalLine />
                                <LayoutInputWrapper>
                                    <InputWrapper>
                                        <InputLabel>
                                            {allValues?.position?.includes("bottom")
                                                ? "Bottom"
                                                : "Top"}{" "}
                                            Spacing
                                        </InputLabel>
                                        <TextInput
                                            data-testid="spacingX"
                                            role="spacingX"
                                            type="number"
                                            label="spacingX"
                                            register={register}
                                            validation={{ required: true }}
                                            placeholder="Enter number"
                                        >
                                            <Unit>px</Unit>
                                        </TextInput>
                                        <StyledErrorSkeleton>
                                            {errors?.spacingX?.type === "required" && (
                                                <StyledError role="error">
                                                    Value is required. Default is 20
                                                </StyledError>
                                            )}
                                        </StyledErrorSkeleton>
                                    </InputWrapper>
                                    <InputWrapper>
                                        <InputLabel>
                                            {allValues?.position?.includes("left")
                                                ? "Left"
                                                : "Right"}{" "}
                                            Spacing
                                        </InputLabel>
                                        <TextInput
                                            data-testid="spacingY"
                                            role="spacingY"
                                            type="number"
                                            label="spacingY"
                                            register={register}
                                            validation={{ required: true }}
                                            placeholder="Enter number"
                                        >
                                            <Unit>px</Unit>
                                        </TextInput>
                                        <StyledErrorSkeleton>
                                            {errors?.spacingY?.type === "required" && (
                                                <StyledError role="error">
                                                    Value is required. Default is 20
                                                </StyledError>
                                            )}
                                        </StyledErrorSkeleton>
                                    </InputWrapper>
                                </LayoutInputWrapper>
                            </SideWrapper>
                            <DividerHr margin={"0px"} />
                            <Wrapper style={{ padding: "0px", gap: "4px", border: "0" }}>
                                <DraggableWrapper>
                                    <Body_XS_Med>Draggable</Body_XS_Med>
                                    <SlideToggleWrapper
                                        checked={allValues?.draggable}
                                        onChange={(e) => setValue("draggable", e)}
                                    />
                                </DraggableWrapper>
                                <Body_2XS_Reg>
                                    When enabled, users on your website will be able to drag &
                                    position copilot anywhere
                                </Body_2XS_Reg>
                            </Wrapper>
                            <Wrapper style={{ padding: "0px", gap: "4px", border: "0" }}>
                                <DraggableWrapper>
                                    <Body_XS_Med>Logged In Users Only</Body_XS_Med>
                                    <SlideToggleWrapper
                                        checked={allValues?.loggedInUsers}
                                        onChange={(e) => setValue("loggedInUsers", e)}
                                    />
                                </DraggableWrapper>
                                <Body_2XS_Reg>
                                    When enabled, only logged in (authenticated) users can view the
                                    Widget. Use setUser event to pass user details to widget
                                </Body_2XS_Reg>
                            </Wrapper>
                        </Wrapper>

                        <Wrapper style={{ marginBottom: "50px" }}>
                            <InputWrapper>
                                <InputLabel>Allowed Domains</InputLabel>
                                <TextArea
                                    data-testid="allowedDomains"
                                    role="allowedDomains"
                                    type="text"
                                    label="allowedDomains"
                                    rows="3"
                                    onBlur={handleDomains}
                                    register={register}
                                    style={{ resize: "vertical" }}
                                    placeholder="example.com&#10;another.example.com"
                                />
                                <StyledErrorSkeleton>
                                    {errors.allowedDomains &&
                                        errors.allowedDomains?.type === "custom" && (
                                            <StyledError role="error">
                                                {errors.allowedDomains.message}
                                            </StyledError>
                                        )}
                                </StyledErrorSkeleton>
                            </InputWrapper>
                            <InputWrapper>
                                <InputLabel>Allowed Paths</InputLabel>
                                <TextArea
                                    data-testid="allowedPaths"
                                    role="allowedPaths"
                                    type="text"
                                    label="allowedPaths"
                                    rows="3"
                                    onBlur={handlePaths}
                                    register={register}
                                    style={{ resize: "vertical" }}
                                    placeholder="*&#10;/allowed-path/"
                                />
                                <StyledErrorSkeleton>
                                    {errors?.allowedPaths?.type === "custom" && (
                                        <StyledError role="error">
                                            {errors.allowedPaths.message}
                                        </StyledError>
                                    )}
                                </StyledErrorSkeleton>
                            </InputWrapper>
                        </Wrapper>
                    </>
                )}
                {editOption === "embedCode" && (
                    <Wrapper>
                        {isWebWidgetActive ? (
                            <ScriptCopyModal
                                token={token}
                                name={oldAllValues.name}
                                instruction={
                                    "Instruction: Copy and paste the below code in the head section of your website between <head> and </head>"
                                }
                                title={"Embed Code"}
                                scriptText={scriptWithToken}
                            />
                        ) : (
                            <Body_XS_Reg style={{ fontStyle: "italic" }}>
                                Please save the widget setting to get the code snippet
                            </Body_XS_Reg>
                        )}
                    </Wrapper>
                )}
            </StyledPageContent>
            <StickyPageFooter>
                {modalCloseButtonCallback && (
                    <Button
                        mode="secondary"
                        onClick={() => {
                            modalCloseButtonCallback?.();
                        }}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    onClick={updateWebsiteDeployment}
                    disabled={
                        allValues?.active && (!isChanged || !(Object.keys(errors).length === 0))
                    }
                >
                    Save
                </Button>
            </StickyPageFooter>
        </DialogWrapper>
    );
};
export default Website;
