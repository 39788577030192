import React, { useRef, useState } from "react";
import IconLogo from "common/components/Logo/IconLogo";
import styled from "styled-components";
import Icon from "common/components/Icon";
import TextInput from "common/components/TextInput";
import TextArea from "common/components/TextArea";
import { Export } from "iconsax-react";
import { Body3, InputLabel, Body_XS_Med } from "common/global-styled-components";
import { Breakpoints } from "GlobalStyle";
import { usePageTitle } from "common/utils";
import { useSelector } from "react-redux";
import CustomDropdown from "common/components/CustomDropdown";
import Tooltip from "common/components/Tooltip";
import { FREE_PLANS } from "common/constants";
import { toast } from "react-toastify";

const ProviderWrapper = styled.div`
    gap: var(--Size-Gap-XXL);
    width: 100%;
    display: flex;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;

const HiddenInput = styled.input`
    display: none;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Size-Gap-XL);
    align-self: stretch;
`;

const ImageWrapper = styled.div`
    display: flex;
    padding: var(--Size-Padding-L);
    margin: 2px 20px;
    width: 120px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: var(--Size-Gap-L);
    align-self: center;
    border-radius: var(--Size-CornerRadius-L);
    border: 2px dashed var(--Color-Border-Default);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const ImageContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    background: var(--Color-Background-Subtle);
    border-radius: var(--Size-CornerRadius-L);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--Color-Border-Default);
    &.img {
        width: 100px;
        height: 100px;
    }
`;

const ImageLabel = styled(Body_XS_Med)`
    margin-top: -12px;
`;

const IconLogoWithRadius = styled(IconLogo)`
    border-radius: var(--Size-CornerRadius-L);
`;

const ButtonTextWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-L);
    cursor: pointer;
    padding: var(--Size-Padding-XXL);
`;

const CopilotNameWrapper = styled.div`
    width: 100%;
    width: ${(props) => (props.width ? props.width : "100%")};
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
    }
`;

const TextCounter = styled(InputLabel)`
    font-size: var(--body-3-d);
    color: ${(props) => (props.overlimit ? "var(--Color-Text-Danger)" : "inherit")};
`;

const PersonaHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

// Persona characters limit
const MAX_PERSONA_LIMIT = 5000;
const LOWER_EDGE_PERSONA_LIMIT = 2500;

const BasicCopilotInfo = ({
    mode = "create",
    register,
    allValues,
    errors,
    setValue,
    setError,
    clearErrors,
}) => {
    const currentSubscription = useSelector(
        (state) => state?.organizationDetails?.currentSubscription,
    );

    usePageTitle(`Copilot Platform - Copilot Details`);
    const hiddenFileInput = useRef(null);

    const handleClick = () => {
        register("icon", "");
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files;
        if (fileUploaded?.[0]?.size > 1048576) {
            toast.error(`The image size should be 1MB or less. Upload reduced image size < 1MB`);
            return;
        }
        setValue("icon", fileUploaded);
    };

    const getModelSelectedData = (providerId = allValues?.providerId) =>
        LLM_MODELS.find((_model) => _model.value === providerId);

    const getModelName = (modelLabel) => {
        return modelLabel.length ? modelLabel?.split("(")[0]?.trim() : "";
    };

    const getPersonaErrorMessage = (modelLabel, modelLimit) =>
        `The ${getModelName(
            modelLabel,
        )} model does not support personas with more than ${modelLimit} characters. Please upgrade.`;

    const getPersonaErrors = (existingProviderId, personaLength) => {
        if (personaLength > MAX_PERSONA_LIMIT) {
            return `Personas cannot be more than ${MAX_PERSONA_LIMIT} characters`;
        }
        const existingModel = getModelSelectedData(existingProviderId);

        if (!existingModel) return;

        const { limit: modelLimit, label: modelName } = existingModel;
        if (personaLength > modelLimit) {
            return getPersonaErrorMessage(modelName, modelLimit);
        }
    };
    const handlePersonaLimitValidation = (value) => {
        if (!value) return;
        const personaLength = value?.trim()?.length;

        return getPersonaErrors(allValues?.providerId, personaLength);
    };

    const handleModelChange = (e) => {
        setValue("providerId", e.value);

        const personaValidationError = getPersonaErrors(
            e.value,
            allValues?.persona?.trim()?.length,
        );

        if (personaValidationError) {
            setError("persona", { type: "custom", message: personaValidationError });
        } else {
            clearErrors("persona", "");
        }
    };

    const isFreePlan = FREE_PLANS.includes(currentSubscription?.planId);

    const LLM_MODELS = [
        {
            "label": "OpenAI GPT-3.5 Turbo (1 credit/message)",
            "value": "gpt-3.5-turbo",
            "isDisabled": false,
            "limit": LOWER_EDGE_PERSONA_LIMIT,
        },
        {
            "label": `OpenAI GPT-4 (10 credits/message)${
                isFreePlan ? " [Unvailable in Free Plan]" : ""
            }`,
            "value": "gpt-4",
            "isDisabled": isFreePlan,
            "limit": MAX_PERSONA_LIMIT,
        },
        {
            "label": "OpenAI GPT-4o mini (1 credit/message)",
            "value": "gpt-4o-mini",
            "isDisabled": false,
            "limit": LOWER_EDGE_PERSONA_LIMIT,
        },
        {
            "label": `OpenAI GPT-4o (5 credits/message)${
                isFreePlan ? " [Unvailable in Free Plan]" : ""
            }`,
            "value": "gpt-4o",
            "isDisabled": isFreePlan,
            "limit": MAX_PERSONA_LIMIT,
        },
        {
            "label": "Google Gemini-1.5 Flash (1 credits/message)",
            "value": "gemini-1.5-flash",
            "isDisabled": false,
            "limit": LOWER_EDGE_PERSONA_LIMIT,
        },
        {
            "label": `Google Gemini-1.5 Pro (5 credits/message)${
                isFreePlan ? " [Unvailable in Free Plan]" : ""
            }`,
            "value": "gemini-1.5-pro",
            "isDisabled": isFreePlan,
            "limit": MAX_PERSONA_LIMIT,
        },
        {
            "label": "Anthropic Claude-3 Haiku (1 credit/message)",
            "value": "claude-3-haiku",
            "isDisabled": false,
            "limit": LOWER_EDGE_PERSONA_LIMIT,
        },
        {
            "label": `Anthropic Claude-3 Opus (10 credits/message)${
                isFreePlan ? " [Unvailable in Free Plan]" : ""
            }`,
            "value": "claude-3-opus",
            "isDisabled": isFreePlan,
            "limit": MAX_PERSONA_LIMIT,
        },
        {
            "label": `Anthropic Claude-3.5 Sonnet (5 credits/message)${
                isFreePlan ? " [Unvailable in Free Plan]" : ""
            }`,
            "value": "claude-3-5-sonnet",
            "isDisabled": isFreePlan,
            "limit": MAX_PERSONA_LIMIT,
        },
        {
            "label": `Meta Llama-3.1 (Coming soon)`,
            "value": "meta-llama-3.1",
            "isDisabled": true,
            "limit": 0,
        },
    ];

    return (
        <>
            <HiddenInput
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                accept="image/*"
                id="inputFile"
                label={`icon`}
            />
            <Details>
                <ImageWrapper>
                    <ImageContainer>
                        {allValues?.icon ? (
                            <>
                                <IconLogoWithRadius
                                    height="96px"
                                    width="96px"
                                    src={
                                        allValues?.icon?.length === 1
                                            ? URL.createObjectURL(allValues?.icon[0])
                                            : typeof allValues?.icon === "string"
                                            ? allValues?.icon
                                            : ""
                                    }
                                />
                                {Icon(Export, {
                                    size: 40,
                                    onClick: handleClick,
                                    tille: "Upload Logo",
                                    style: {
                                        position: "absolute",
                                        cursor: "pointer",
                                        padding: "5px",
                                        border: "1px solid var(--Color-Border-Default)",
                                        borderRadius: "50%",
                                        background: "var(--Color-Background-Default)",
                                    },
                                })}
                            </>
                        ) : (
                            <ButtonTextWrapper onClick={handleClick} title="Upload Logo">
                                {Icon(Export, { size: 46 })}
                            </ButtonTextWrapper>
                        )}
                    </ImageContainer>
                </ImageWrapper>
                <ImageLabel>Logo</ImageLabel>
                <CopilotNameWrapper>
                    <InputLabel>Name *</InputLabel>
                    <TextInput
                        data-testid="name"
                        role="name"
                        type="text"
                        label="name"
                        register={register}
                        name="name"
                        validation={{
                            required: "Name is required",
                            maxLength: {
                                value: 30,
                                message: "Name cannot be more than 30 characters",
                            },
                            validate: (value) =>
                                value?.trim() !== "" || "Copilot name cannot be empty",
                        }}
                        placeholder="Enter copilot name"
                        maxLength={31}
                    />
                    {errors.name && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">{errors.name.message}</StyledError>
                        </StyledErrorSkeleton>
                    )}
                </CopilotNameWrapper>
                <CopilotNameWrapper>
                    <PersonaHeader>
                        <InputLabel>Persona </InputLabel>
                        <TextCounter
                            overlimit={
                                (allValues?.persona?.trim()?.length || 0) >
                                getModelSelectedData()?.limit
                            }
                        >
                            {allValues?.persona?.trim()?.length || 0}/
                            {getModelSelectedData()?.limit}
                        </TextCounter>
                    </PersonaHeader>
                    <TextArea
                        data-testid="persona"
                        role="persona"
                        type="text"
                        label="persona"
                        name="persona"
                        rows="5"
                        register={register}
                        style={{ resize: "vertical" }}
                        placeholder="You are a kind and friendly AI assistant named Copilot"
                        validation={{
                            validate: handlePersonaLimitValidation,
                        }}
                    />
                    {errors.persona && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">{errors?.persona?.message}</StyledError>
                        </StyledErrorSkeleton>
                    )}
                </CopilotNameWrapper>

                {mode === "edit" && (
                    <ProviderWrapper>
                        <CopilotNameWrapper>
                            <InputLabel>Chat Model</InputLabel>
                            <CustomDropdown
                                id={isFreePlan ? "model-tooltip" : "dropdown"}
                                role="tooltip"
                                data-tip
                                data-tooltip-id={isFreePlan ? "model-tooltip" : "dropdown"}
                                options={LLM_MODELS}
                                placeholder={"Choose your Model"}
                                showArrow={false}
                                wrapperHeight="56px"
                                value={getModelSelectedData()}
                                onChange={handleModelChange}
                                register={register}
                                type="providerId"
                                label="providerId"
                            />
                        </CopilotNameWrapper>
                        <Tooltip
                            place="top"
                            type="faint"
                            effect="solid"
                            noArrow={true}
                            id={"model-tooltip"}
                        >
                            Available to edit on premium plans
                        </Tooltip>
                    </ProviderWrapper>
                )}
            </Details>
        </>
    );
};

export default BasicCopilotInfo;
