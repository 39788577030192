import React from "react";
import styled, { css } from "styled-components";
import { Breakpoints } from "GlobalStyle";
import Icon from "common/components/Icon";
import { ArrowUp2, ArrowDown2 } from "iconsax-react";

const Wrapper = styled.div`
    overflow-x: auto;
    width: 100%;
    margin: 0 0 16px 0;
    flex-grow: 1;
    @media (max-width: ${Breakpoints.mobile}px) {
        // margin-top: 16px;
    }
`;

const TableWrapper = styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    // border-spacing: 0 1em;
    white-space: nowrap;
`;

const Cell = css`
    height: 48px;
    margin: 12px;
    padding: var(--Size-Padding-L) 20px;
    font-weight: 400;
`;

const Head = styled.th`
    font-size: var(--body-3-d);
    color: var(--Color-Text-Subtle);
    line-height: var(--line-height-16);
    font-weight: var(--bold);
    text-align: start;
`;

const Data = styled.td`
    font-size: var(--body-2-d);
    line-height: var(--line-height-20);
    padding: var(--Size-Padding-XL) var(--Size-Padding-XL) var(--Size-Padding-XL) 0px;
    border-bottom: ${(props) => !props.$noBorder && "1px solid var(--Color-Border-Subtle)"};
`;

const Row = styled.tr`
    ${(props) => props.clickable && "cursor: pointer;"}
    // border-bottom: 1px solid var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-M);
    :hover {
        background: var(--Color-Background-Subtle);
    }
    :last-child {
        border: none;
    }
`;

/**
 * getTableProps --> additional props
 * getTableBodyProps --> [k1, k2]
 *
 */

export default function Table({
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    onRowClick = false,
    noBorder = false,
    alignment = "center",
}) {
    return (
        <Wrapper>
            <TableWrapper alignment={alignment} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Head
                                    {...column.getHeaderProps(column.getSortByToggleProps(), {
                                        style: {
                                            minWidth: column.minWidth,
                                            width: column.width,
                                            textWrap: "pretty",
                                        },
                                    })}
                                >
                                    <>
                                        <span>{column.render("Header")}</span>
                                        {/* make sure to pass css styled components from parent */}
                                        <span css={column.sortIconStyle}>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? Icon(ArrowUp2, { size: 10 })
                                                    : Icon(ArrowDown2, { size: 10 })
                                                : ""}
                                        </span>
                                    </>
                                </Head>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, idx) => {
                        prepareRow(row);
                        return (
                            <Row
                                clickable={!!onRowClick}
                                {...row.getRowProps({
                                    onClick: () => onRowClick && onRowClick(row), // Add the onClick event here
                                })}
                            >
                                {row.cells.map((cell, jdx) => {
                                    return (
                                        <Data
                                            $noBorder={rows.length - 1 === idx}
                                            {...cell.getCellProps({
                                                style: {
                                                    // minWidth: cell.column.minWidth,
                                                    // width: cell.column.width,
                                                    padding:
                                                        row?.cells?.length - 1 === jdx
                                                            ? "0px"
                                                            : cell.column.padding,
                                                    border: cell.column.border,
                                                    textWrap: "nowrap",
                                                },
                                            })}
                                        >
                                            {cell.render("Cell")}
                                        </Data>
                                    );
                                })}
                            </Row>
                        );
                    })}
                </tbody>
            </TableWrapper>
        </Wrapper>
    );
}
