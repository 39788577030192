import React, {
    forwardRef,
    useEffect,
    useState,
    useRef,
    useCallback,
    useImperativeHandle,
} from "react";
import Editor, { useMonaco } from "@monaco-editor/react";
import styled from "styled-components";
import {
    Body3,
    InputLabel,
    Body_XS_Reg,
    Body_XXS_Reg,
    Body_L_Bold,
    Body_XS_Bold,
} from "../../../common//global-styled-components";
import TextInput from "../../../common/components/TextInput";
import Checkbox from "common/components/CheckBox";
import FileCollector from "./FileCollector";
import Icon from "../../../common/components/Icon";
import { GoogleDrive, TickCircle } from "iconsax-react";
import QuipIcon from "common/assets/Quip";
import PasswordInput from "common/components/PasswordInput";
import TextArea from "common/components/TextArea";
import CustomDropdown from "common/components/CustomDropdown";
import AddKeyValuePair from "../../../common/components/AddKeyValuePair";
import { startCase } from "lodash";
import SlideToggle from "../../../common/components/SlideToggle";
import { CustomRadioButtonGroup } from "../../../common/components/CustomRadioButton";
import { usePageTitle } from "common/utils";
import NotionIcon from "common/assets/NotionIcon";
import Tooltip from "common/components/Tooltip";
import InfoIcon from "common/assets/InfoIcon";

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    display: ${(props) => props.display};
`;

const TitleWrapper = styled(Body_L_Bold)`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;
const TitleBar = styled(Body_L_Bold)`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;

const PaginationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: var(--Size-Gap-XXL);
`;
const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;
const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--Color-Text-Default);
`;
const TypeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--Size-Gap-M);
    font-size: var(--font-size-lg);
    font-weight: 500;
    margin-bottom: 12px;
`;
const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;
const KeyValueWrapper = styled.div`
    gap: var(--Size-Gap-XXL);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;
const NavBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--Size-CornerRadius-XXL);
    overflow: hidden;
    width: inherit;
    cursor: pointer;
`;
const NavItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    flex-grow: 1;
    background: var(--Color-Background-Subtle);
    border-bottom: ${(props) => (props.active ? "4px solid var(--Color-Border-Subtle)" : "0px")};
`;

const GetQuipToken = styled.span`
    text-decoration: underline;
    cursor: pointer;
    margin-left: 11px;
`;

const ConnectedWithGoogleWrapper = styled.div`
    display: flex;
    align-items: center;
    display: flex;
    gap: var(--Size-Gap-L);
`;

const TextToBeCrawled = styled(Body_XS_Bold)`
    display: flex;
    color: ${(props) => (props.overlimit ? "var(--Color-Text-Danger)" : "inherit")};
    justify-content: flex-end;
`;
const RadioGroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    justify-content: center;
    max-width: 100%;
    overflow-x: scroll;
`;

const InputRowWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 14px;
`;

const TooltipData = styled.div`
    font-size: 14px;
    max-width: 300px;
`;
const Description = styled(InfoIcon)`
    margin-left: 4px;
    margin-bottom: -2px;
    width: var(--body-1-d);
    fill: var(--light-300);
    cursor: pointer;
    height: auto;
`;
const Label = styled(Body_XXS_Reg)`
    padding: 0px 35px;
    color: var(--Color-Text-Disabled);
`;

const _InputLabel = styled(InputLabel)`
    margin: auto;
    left: 8px;
`;
const restMethods = [
    { label: "GET", value: "get" },
    { label: "POST", value: "post" },
    { label: "PUT", value: "put" },
];
const dataTabs = [
    { label: "Headers", value: "headers" },
    { label: "Query Params", value: "params" },
    { label: "Body", value: "body" },
];
const paginationTabs = [
    { label: "Page", value: "page", child: <_InputLabel>Page</_InputLabel> },

    { label: "Offset", value: "offset-limit", child: <_InputLabel>Offset</_InputLabel> },
    { label: "Cursor", value: "cursor", child: <_InputLabel>Cursor</_InputLabel> },
];
const authTabs = [
    { label: "Basic Auth", value: "basic-auth", child: <_InputLabel>Basic Auth</_InputLabel> },
];

const Sources = forwardRef(
    (
        {
            data: dataSource,
            setDataSource,
            uploadedFiles,
            setUploadedFiles,
            allValues,
            register,
            setValue,
            errors,
            dsId,
        },
        ref,
    ) => {
        usePageTitle(`Copilot Platform - Edit Data Source`);
        let monaco = useMonaco();
        const uploadRef = useRef();
        const headers = useRef({
            all: [],
            id: "headers",
        });
        const params = useRef({
            all: [],
            id: "params",
        });
        const [selectedTab, setSelectedTab] = useState("headers");
        const [selectedPaginationTab, setSelectedPaginationTab] = useState("");
        const [selectedAuth, setSelectedAuth] = useState("basic-auth");
        const [enableAuth, setEnableAuth] = useState(false);
        const [enablePagination, setEnablePagination] = useState(false);

        useEffect(() => {
            if (!allValues?.id) return;
            const paginationKeys = {};
            for (const key of dataSource?.config?.pagination || []) {
                paginationKeys[`${key.type}_key`] = key.key;
                paginationKeys[`${key.type}_value`] = key.value;
            }
            setValue("config", {
                ...allValues.config,
                ...paginationKeys,
                url: allValues?.config?.url?.split("https://")?.pop(),
            });
            headers.current = {
                all: allValues?.config?.headers ? allValues.config?.headers : [],
                id: "headers",
            };
            params.current = {
                all: allValues?.config?.params ? allValues?.config?.params : [],
                id: "params",
            };
            setSelectedPaginationTab(allValues?.config?.paginationType);
            setSelectedAuth(allValues?.auth?.type || "basic-auth");
            setEnablePagination(!!allValues?.config?.pagination?.length);
            setEnableAuth(!!Object.keys(allValues?.config?.auth || {}).length);
        }, [allValues?.id]);
        const syncData = useCallback((index, key, value, type, ref) => {
            const { all } = ref?.current;
            switch (type) {
                case "delete":
                    // Remove item at the specified index
                    if (index >= 0 && index < all.length) {
                        all.splice(index, 1);
                    }
                    break;

                default:
                    // If the index is out of bounds, push a new object
                    if (index >= all.length) {
                        const newObj = {};
                        newObj[key] = value?.trim();
                        all.push(newObj);
                    } else {
                        // Ensure the object at the index exists and set its key-value
                        if (!all[index]) {
                            all[index] = {};
                        }
                        all[index][key] = value?.trim();
                    }
                    break;
            }
            ref.current.all = all;
        });
        const editorRef = useRef(null);

        useEffect(() => {
            if (!monaco || !editorRef?.current) return;
            editorRef?.current?.setValue(JSON.stringify(dataSource?.config?.body || {}));
            editorRef?.current?.getAction("editor.action.formatDocument").run();
            monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                noSemanticValidation: true,
                noSyntaxValidation: true,
            });

            return () => {
                editorRef?.current?.dispose();
            };
        }, [editorRef, monaco]);
        useEffect(() => {
            if (!monaco || !editorRef?.current) return;
            editorRef?.current?.setValue(JSON.stringify(dataSource?.config?.body || {}));
        }, [dataSource]);

        useImperativeHandle(ref, () => ({
            enableAuth: enableAuth,
            enablePagination: enablePagination,
            selectedAuth: selectedAuth,
            editorRef: editorRef.current,
            params: params.current,
            headers: headers.current,
        }));

        const paginationInput = ({ type }, register) => (
            <PaginationWrapper>
                <InputWrapper>
                    <InputLabel>{startCase(type)} Key Name*</InputLabel>
                    <TextInput
                        data-testid={`${type}_key`}
                        role={`config.${type}_key`}
                        type={`config.${type}_key`}
                        label={`config.${type}_key`}
                        register={register}
                        validation={{ required: "Field is required" }}
                        placeholder={`Key name`}
                    />
                    {errors?.[`config.${type}_key`] && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">
                                {errors[`config.${type}_key`].message}
                            </StyledError>
                        </StyledErrorSkeleton>
                    )}
                </InputWrapper>
                <InputWrapper>
                    <InputLabel>{startCase(type)} Initial Value</InputLabel>
                    <TextInput
                        data-testid={"password"}
                        role={`config.${type}_value`}
                        type={`config.${type}_value`}
                        label={`config.${type}_value`}
                        register={register}
                        validation={{ required: "Field is required" }}
                        placeholder={`Initial value`}
                    />
                    {errors?.[`config.${type}_value`] && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">
                                {errors[`config.${type}_value`].message}
                            </StyledError>
                        </StyledErrorSkeleton>
                    )}
                </InputWrapper>
            </PaginationWrapper>
        );
        switch (dataSource.type) {
            case "files":
                return (
                    <FileCollector
                        ref={uploadRef}
                        dataSource={dataSource}
                        dsId={dsId}
                        namespaceforFileUpload={"files_datasource"}
                        mode="edit"
                        setDataSource={setDataSource}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                    ></FileCollector>
                );
            case "web":
                return (
                    <>
                        {/* <HeaderWrapper>
                            <TypeWrapper>{Icon(Global, { size: 24 })} Website</TypeWrapper>
                        </HeaderWrapper> */}
                        <InputWrapper>
                            <InputLabel style={{ background: "var(--Color-Background-Default)" }}>
                                Website Link *
                            </InputLabel>
                            <TextInput
                                name="config.domain"
                                label="config.domain"
                                placeholder="e.g.: https://www.example.com"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Website Link is required",
                                    pattern: {
                                        value: /^(http|https):\/\/(([a-zA-Z0-9-_]{1,63}\.)+(?:[a-zA-Z]{2,})|localhost|((\d{1,3}\.){3}\d{1,3}))(?::\d+)?(?:\/[^\s]*)?$/,
                                        message: "Invalid Website Link",
                                    },
                                }}
                                data-testid="datasource-domain"
                            ></TextInput>
                            <StyledErrorSkeleton>
                                {errors?.config?.domain && (
                                    <StyledError role="error">
                                        {errors?.config?.domain?.message}
                                    </StyledError>
                                )}
                            </StyledErrorSkeleton>
                        </InputWrapper>
                        <InputWrapper>
                            <Checkbox
                                checked={allValues?.config?.renderType === "csr"}
                                onChange={(e) => {
                                    if (e.target.checked) setValue("config.renderType", "csr");
                                    else setValue("config.renderType", "ssr");
                                }}
                            >
                                <InputLabel>
                                    Enable dynamic content collection{" "}
                                    <Description
                                        role="tooltip"
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={true}
                                        data-tooltip-id="csr"
                                        data-tip
                                    />
                                    <Tooltip id="csr">
                                        Data is gathered directly on your device after the webpage
                                        is loaded. This method provides more flexibility but may
                                        require more resources and time.
                                    </Tooltip>
                                </InputLabel>
                            </Checkbox>
                            <Label>
                                Note: Check this box to capture content that appears after page
                                load.
                            </Label>
                        </InputWrapper>
                    </>
                );
            case "quip":
                return (
                    <>
                        <HeaderWrapper>
                            <TypeWrapper>{Icon(QuipIcon, { size: 21 })}Quip</TypeWrapper>
                        </HeaderWrapper>
                        <InputWrapper>
                            <InputLabel>
                                Quip Token *
                                <GetQuipToken
                                    onClick={() => {
                                        window.open("https://quip.com/dev/token", "_blank");
                                    }}
                                >
                                    Get Token Here
                                </GetQuipToken>
                            </InputLabel>
                            <PasswordInput
                                name="config.quipToken"
                                label="config.quipToken"
                                placeholder="Add token from the quip"
                                height="156px"
                                margin="0"
                                register={register}
                                onChange={(e) => {
                                    setValue("quipToken", e.target.value);
                                }}
                                validation={{
                                    required: "Quip Token is required",
                                }}
                                data-testid="quipToken-link"
                            >
                                {" "}
                            </PasswordInput>

                            {errors?.config?.quipToken && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.quipToken?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <InputLabel>Quip Link *</InputLabel>
                            <TextInput
                                name="config.quipLink"
                                label="config.quipLink"
                                placeholder="Add Quip Folder/FIles Path"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Quip folders/files path is required",
                                }}
                                data-testid="quipLink-link"
                            />

                            {errors?.config?.quipLink && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.quipLink?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                    </>
                );
            case "notion":
                return (
                    <>
                        <HeaderWrapper>
                            <TypeWrapper>{Icon(NotionIcon, { size: 21 })}Notion Page</TypeWrapper>
                        </HeaderWrapper>
                        <InputWrapper>
                            <InputLabel>
                                Notion Internal Integration Secret *
                                <Description
                                    role="tooltip"
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={true}
                                    data-tooltip-id="notionLink"
                                    data-tip
                                />
                                <Tooltip
                                    id="notionLink"
                                    type="faint"
                                    effect="solid"
                                    width="10px"
                                    enableToolTip={true}
                                >
                                    <TooltipData>
                                        Only notion workspace owners can create integration. Please
                                        add Internal Integration Secret here
                                    </TooltipData>
                                </Tooltip>
                                <GetQuipToken
                                    onClick={() => {
                                        window.open(
                                            "https://www.notion.so/my-integrations",
                                            "_blank",
                                        );
                                    }}
                                >
                                    Get Token Here
                                </GetQuipToken>
                            </InputLabel>
                            <PasswordInput
                                name="config.notionToken"
                                label="config.notionToken"
                                placeholder="Add token from the quip"
                                height="156px"
                                margin="0"
                                register={register}
                                onChange={(e) => {
                                    setValue("notionToken", e.target.value);
                                }}
                                validation={{
                                    required: "Notion Token is required",
                                }}
                                data-testid="notionToken-link"
                            />

                            {errors?.config?.notionToken && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.notionToken?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <InputLabel>
                                {" "}
                                Notion Page Link *
                                <GetQuipToken
                                    onClick={() =>
                                        window.open(
                                            "https://developers.notion.com/docs/create-a-notion-integration#give-your-integration-page-permissions",
                                            "_blank",
                                        )
                                    }
                                >
                                    ( Make sure the internal integration secret is added to the
                                    notion page link added below.)
                                </GetQuipToken>
                            </InputLabel>
                            <TextInput
                                name="config.notionLink"
                                label="config.notionLink"
                                placeholder="Add Notion FIles Path"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Notion files path is required",
                                }}
                                data-testid="notionLink-link"
                            />

                            {errors?.config?.notionLink && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.notionLink?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                    </>
                );
            case "git":
                return (
                    <>
                        <HeaderWrapper>
                            <TypeWrapper>{Icon(QuipIcon, { size: 21 })}Git</TypeWrapper>
                        </HeaderWrapper>
                        <InputWrapper>
                            <InputLabel>Git Token *</InputLabel>
                            <PasswordInput
                                name="config.gitToken"
                                label="config.gitToken"
                                key="config.gitToken"
                                placeholder="Add token from the git"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Git Token is required",
                                    maxLength: {
                                        value: 120,
                                        message:
                                            "Tokens should be between 20 and 128 characters long.",
                                    },
                                    minLength: {
                                        value: 2,
                                        message:
                                            "Tokens should be between 20 and 128 characters long.",
                                    },
                                }}
                                data-testid="quipToken-link"
                            />

                            {errors?.config?.gitToken && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.gitToken?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <InputLabel>Git Repository Link *</InputLabel>
                            <TextInput
                                name="config.gitRepoLink"
                                label="config.gitRepoLink"
                                key="config.gitRepoLink"
                                placeholder="Add repo from the git source"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Git folders/files path is required",
                                    pattern: {
                                        value: /^(ftp|http|https):\/\/[^ "]+$/,
                                        message: "Invalid URL",
                                    },
                                }}
                                data-testid="gitLink-link"
                            />
                            {errors?.config?.gitRepoLink && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.gitRepoLink?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                    </>
                );

            case "text":
                return (
                    <InputWrapper>
                        <InputLabel>Text* </InputLabel>
                        <TextArea
                            name="config.textToBeCrawled"
                            label="config.textToBeCrawled"
                            key="config.textToBeCrawled"
                            placeholder="Add Text"
                            height="156px"
                            margin="0"
                            register={register}
                            validation={{
                                required: "Text is required",
                                maxLength: {
                                    value: 10000,
                                    message: "Text should less then 10000 characters.",
                                },
                            }}
                            data-testid="textsource-link"
                        />
                        <TextToBeCrawled
                            overlimit={allValues?.config?.textToBeCrawled?.length > 10000}
                        >
                            {allValues?.config?.textToBeCrawled?.length}/10000
                        </TextToBeCrawled>
                        {errors?.config?.textToBeCrawled && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.config?.textToBeCrawled?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                );
            case "googleDrive":
                return (
                    <>
                        <HeaderWrapper>
                            <TypeWrapper>{Icon(GoogleDrive, { size: 24 })}Google Drive</TypeWrapper>
                        </HeaderWrapper>
                        <ConnectedWithGoogleWrapper>
                            {Icon(TickCircle, { size: 27, color: "var(--success-dark)" })} Connected
                            with Google
                        </ConnectedWithGoogleWrapper>
                        <InputWrapper>
                            <InputLabel>Google Drive Link *</InputLabel>
                            <TextInput
                                name="config.googleDriveLink"
                                label="config.googleDriveLink"
                                key="config.googleDriveLink"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Google Drive folders/files path is required",
                                }}
                                data-testid="googleDriveLink-link"
                            />

                            {errors?.config?.googleDriveLink && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.googleDriveLink?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                    </>
                );
            case "api":
                return (
                    <>
                        <InputWrapper>
                            <TitleWrapper>URL*</TitleWrapper>
                            <br></br>
                            <div
                                style={{
                                    display: "flex",
                                    border: "1px solid var(--Color-Border-Disabled)",
                                    borderRadius: "12px",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    className="unit"
                                    style={{
                                        height: "56px",
                                        position: "relative",
                                        padding: "0px 16px 0%",
                                        "alignItems": "center",
                                        "display": "flex",
                                        "background": "var(--Color-Border-Subtle)",
                                        "borderRadius": "12px 0px 0px 11px",
                                        "color": "var(--Color-Text-Default)",
                                    }}
                                >
                                    https://
                                </span>
                                <TextInput
                                    data-testid="url"
                                    role="config.url"
                                    type="config.url"
                                    label="config.url"
                                    register={register}
                                    validation={{ required: "Resource URL is required" }}
                                    placeholder="Enter url"
                                    style={{
                                        paddingLeft: "9px",
                                        border: "none",
                                        outline: "none",
                                        margin: 0,
                                    }}
                                />
                                <CustomDropdown
                                    showArrow={false}
                                    value={restMethods.find(
                                        (i) => i.value === allValues?.config?.method,
                                    )}
                                    defaultValue={restMethods[0]}
                                    onChange={(e) => setValue("config.method", e.value)}
                                    validation={{ required: "Resource Method is required" }}
                                    options={restMethods}
                                    width="max-content"
                                    height="56px"
                                    placeholder="Method"
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            border: "0px !important",
                                            boxShadow: "0 !important",
                                            background: "var(--Color-Border-Subtle) !important",
                                            width: "100%",
                                            "&:hover": {
                                                border: "0px !important",
                                                boxShadow: "0 !important",
                                                background: "var(--Color-Border-Subtle) !important",
                                            },
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            width: "max-content",
                                            minWidth: "100%",
                                            height: "max-content",
                                        }),
                                    }}
                                />
                            </div>
                            {errors?.config?.url && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.url?.message ||
                                            errors?.config?.url?.label?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                            {errors?.config?.method && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.config?.method?.message ||
                                            errors?.config?.method?.label?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>

                        <br></br>
                        <DataWrapper>
                            <TitleWrapper>Data</TitleWrapper>
                            <NavBar>
                                {dataTabs.map((i) => (
                                    <NavItem
                                        onClick={() => {
                                            setSelectedTab(i.value);
                                            editorRef?.current?.setValue(
                                                JSON.stringify(allValues?.config?.body),
                                            );
                                        }}
                                        label={i.label}
                                        value={i.value}
                                        active={selectedTab === i.value}
                                    >
                                        <Body_XS_Reg>{i.label}</Body_XS_Reg>
                                    </NavItem>
                                ))}
                            </NavBar>
                            <InputWrapper display={selectedTab !== "headers" ? "none" : "block"}>
                                <AddKeyValuePair
                                    displayErrors={false}
                                    allValues={allValues.config}
                                    keyValueRef={headers}
                                    syncData={syncData}
                                ></AddKeyValuePair>
                            </InputWrapper>
                            <InputWrapper display={selectedTab !== "params" ? "none" : "block"}>
                                <AddKeyValuePair
                                    allValues={allValues.config}
                                    keyValueRef={params}
                                    syncData={syncData}
                                ></AddKeyValuePair>
                            </InputWrapper>
                            <InputWrapper display={selectedTab !== "body" ? "none" : "block"}>
                                <Editor
                                    height="200px"
                                    width="100%"
                                    theme={"vs-dark"}
                                    defaultLanguage="json"
                                    onMount={(editor, monaco) => {
                                        editorRef.current = editor;
                                    }}
                                />
                            </InputWrapper>
                        </DataWrapper>
                        <br></br>
                        <InputWrapper>
                            <InputLabel>Response Data Root</InputLabel>
                            <TextInput
                                data-testid="config.dataRoot"
                                role="config.dataRoot"
                                type="config.dataRoot"
                                label="config.dataRoot"
                                register={register}
                                validation={{ required: false }}
                                placeholder="Enter data root"
                            />
                        </InputWrapper>
                        <br></br>
                        <DataWrapper>
                            <TitleBar>
                                <TitleWrapper>Auth</TitleWrapper>
                                <SlideToggle
                                    onChange={(e) => {
                                        setEnableAuth(e);
                                        setSelectedAuth("basic-auth");
                                    }}
                                    checked={enableAuth}
                                ></SlideToggle>
                            </TitleBar>

                            {enableAuth && (
                                <>
                                    <NavBar>
                                        <RadioGroupWrapper>
                                            <InputRowWrapper>
                                                <CustomRadioButtonGroup
                                                    register={register}
                                                    name="authType"
                                                    label="authType"
                                                    elements={authTabs}
                                                    disabled={!enableAuth}
                                                    selectedValue={selectedAuth}
                                                    callback={(e) => {
                                                        setSelectedAuth(e.value);
                                                    }}
                                                ></CustomRadioButtonGroup>
                                            </InputRowWrapper>
                                        </RadioGroupWrapper>
                                    </NavBar>
                                    <PaginationWrapper>
                                        <InputWrapper>
                                            <InputLabel>Username*</InputLabel>
                                            <TextInput
                                                data-testid={`username`}
                                                role={`config.username`}
                                                type={`config.username`}
                                                label={`config.username`}
                                                register={register}
                                                validation={{ required: "Username is requied" }}
                                                placeholder={`Key name`}
                                            />
                                            {errors?.config?.username && (
                                                <StyledErrorSkeleton>
                                                    <StyledError role="error">
                                                        {errors?.config?.username?.message}
                                                    </StyledError>
                                                </StyledErrorSkeleton>
                                            )}
                                        </InputWrapper>
                                        <InputWrapper>
                                            <InputLabel>Password*</InputLabel>
                                            <PasswordInput
                                                data-testid={"password"}
                                                role={"config.password"}
                                                type={"config.password"}
                                                label={"config.password"}
                                                register={register}
                                                validation={{ required: "Password is requied" }}
                                                placeholder={`...`}
                                            />
                                            {errors?.config?.password && (
                                                <StyledErrorSkeleton>
                                                    <StyledError role="error">
                                                        {errors?.config?.password?.message}
                                                    </StyledError>
                                                </StyledErrorSkeleton>
                                            )}
                                        </InputWrapper>
                                    </PaginationWrapper>
                                </>
                            )}
                        </DataWrapper>
                        <br></br>
                        <DataWrapper>
                            <TitleBar>
                                <TitleWrapper>Pagination</TitleWrapper>
                                <SlideToggle
                                    onChange={(e) => {
                                        setEnablePagination(e);
                                    }}
                                    checked={enablePagination}
                                ></SlideToggle>
                            </TitleBar>
                            {enablePagination && (
                                <>
                                    <RadioGroupWrapper>
                                        <InputRowWrapper>
                                            <CustomRadioButtonGroup
                                                register={register}
                                                name="config.paginationType"
                                                label="config.paginationType"
                                                elements={paginationTabs}
                                                disabled={!enablePagination}
                                                callback={(e) => {
                                                    setSelectedPaginationTab(e.value);
                                                }}
                                            ></CustomRadioButtonGroup>
                                        </InputRowWrapper>
                                    </RadioGroupWrapper>
                                    <DataWrapper>
                                        {["page"].includes(selectedPaginationTab) &&
                                            paginationInput({ type: "page" }, register)}
                                        {["offset-limit"].includes(selectedPaginationTab) &&
                                            paginationInput({ type: "offset" }, register)}
                                        {["page", "offset-limit"].includes(selectedPaginationTab) &&
                                            paginationInput({ type: "limit" }, register)}
                                        {["cursor"].includes(selectedPaginationTab) &&
                                            paginationInput({ type: "cursor" }, register)}
                                    </DataWrapper>
                                    <InputWrapper>
                                        <InputLabel>Pagination Data Root</InputLabel>
                                        <TextInput
                                            data-testid="paginationRoot"
                                            role="config.paginationRoot"
                                            type="config.paginationRoot"
                                            label="config.paginationRoot"
                                            register={register}
                                            validation={{ required: false }}
                                            placeholder="Enter pagination root"
                                        />
                                    </InputWrapper>
                                </>
                            )}
                        </DataWrapper>
                        <br></br>
                    </>
                );
            default:
                return <></>;
        }
    },
);

export default Sources;
