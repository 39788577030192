import React, {
    useState,
    useRef,
    useMemo,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import SearchPlaceHolder from "../../../common/components/SearchPlaceHolder";
import { toast } from "react-toastify";
import SyncService from "services/sync.service";
import { uploadStorageAssets, getMimeTypeOfFile } from "common/utils";
import Icon from "../../../common/components/Icon";
import List from "../../../common/components/List";
import Table from "../../../common/components/Table";
import {
    DocumentText,
    DocumentUpload,
    TableDocument,
    Html5,
    Bookmark,
    Trash,
    ArrowDown,
} from "iconsax-react";
import { Body_XXS_Reg, Body_XS_Reg } from "../../../common/global-styled-components";
import { useSortBy, useTable } from "react-table";
import PdfIcon from "../../../common/assets/PdfIcon";
import PptIcon from "../../../common/assets/PptIcon";
import XlsxIcon from "../../../common/assets/XlsxIcon";
import WordIcon from "../../../common/assets/WordIcon";
import CsvIcon from "common/assets/CsvIcon";
import TextFileIcon from "common/assets/TextFileIcon";

const UploadWrapper = styled.div`
    width: 100%;
    border: 2px dashed var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-XL);
    padding: var(--Size-Padding-XXL);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: var(--Size-Gap-L);
    background: var(--Color-Background-Subtle);
`;

const _Body_XXS_Reg = styled(Body_XXS_Reg)`
    color: var(--Color-Text-Subtlest);
`;
const _Body_XS_Reg = styled(Body_XS_Reg)`
    color: var(--Color-Text-Default);
`;

const TypeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--Size-Gap-M);
    font-size: var(--font-size-lg);
    font-weight: 500;
    color: var(--Color-Text-Default);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--Size-Gap-XXL);
    font-size: var(--font-size-lg);
    font-weight: 500;
`;

const StyledError = styled.p`
    color: var(--Color-Text-Danger);
    font-size: var(--font-size-lg);
    font-weight: 500;
    margin-top: 2px;
    text-align: center;
`;
const ActionIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: var(--Size-Padding-M);
    border-radius: var(--Size-CornerRadius-XXXL);
    background-color: ${(props) => props.backgroundColor || `var(--Color-Border-Subtle)`};
`;
const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: var(--Color-Text-Subtle);
`;
const NameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--Size-Gap-XL);
    align-items: center;
    color: var(--Color-Text-Default);
`;

const InputFileWrapper = styled.div`
    max-height: 430px;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
    overflow-y: auto;
    overflow-x: hidden;
`;

const ACCEPTED_MIME_TYPES = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // i.e. docx file
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // i.e. xlsx file
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // i.e. pptx file
    "text/markdown",
    "text/csv",
    "text/html",
    "text/plain",
    "application/json",
];
const ACCEPTED_MIME_TYPES_ICONS = {
    "application/pdf": Icon(PdfIcon, { size: 24 }),
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": Icon(WordIcon, {
        size: 24,
    }), // i.e. docx file
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": Icon(XlsxIcon, {
        size: 24,
    }), // i.e. xlsx file
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": Icon(PptIcon, {
        size: 24,
    }), // i.e. pptx file
    "text/markdown": Icon(Bookmark, { size: 24 }),
    "text/csv": Icon(CsvIcon, { size: 24 }),
    "text/html": Icon(Html5, { size: 24 }),
    "text/plain": Icon(TextFileIcon, { size: 24 }),
    "application/json": Icon(TableDocument, { size: 24 }),
};

const FileCollector = forwardRef(
    (
        {
            dataSource,
            setDataSource,
            uploadedFiles,
            setUploadedFiles,
            dsId,
            mode = "create",
            fileQuantityRestriction = 100,
            allowedFileTypes = ACCEPTED_MIME_TYPES, // no allowing files for upload, defaults to allow all file types[...Object.keys(ACCEPTED_MIME_TYPES_ICONS)
            namespaceforFileUpload = "files_datasource",
        },
        ref,
    ) => {
        const { orgId, agentId, datasourceId } = useParams();
        const [filteredList, setFileredList] = useState(uploadedFiles || []);
        const [searchText, setSearchText] = useState("");
        const [isSearching, setIsSearching] = useState(false);

        const hiddenFileInput = useRef(null);

        const handleClick = () => {
            hiddenFileInput.current.click();
        };
        const handleChange = async (event, type = "click") => {
            try {
                let filesUploaded =
                    type === "click" ? event.target.files : event?.dataTransfer.files;
                let newFiles;
                if (
                    filesUploaded.length > fileQuantityRestriction ||
                    uploadedFiles.length >= fileQuantityRestriction
                ) {
                    toast.error(
                        `Can only upload up to ${fileQuantityRestriction} files for the current subscription. Please adjust and try again.`,
                    );
                    return;
                }
                for (const fileUploaded of filesUploaded) {
                    if (!allowedFileTypes.includes(fileUploaded.type)) {
                        toast.error("Unsupported file type");
                        return;
                    }
                    let path = await uploadFiles(fileUploaded);
                    fileUploaded["path"] = path;
                    const _uploadedFile = {
                        path: fileUploaded.path,
                        size: fileUploaded.size,
                        name: fileUploaded.name,
                        type: fileUploaded.type,
                    };
                    newFiles = [_uploadedFile, ...uploadedFiles];
                    setUploadedFiles((arr) => [_uploadedFile, ...arr]);
                }
                mode != "create" && (await submit(newFiles));
            } catch (error) {
                toast.error(error?.response?.data?.message);
                console.error(error);
            }
        };

        const submit = async (newFiles) => {
            try {
                let data = {
                    new_files: getDifferenceBetweenPathArrays(newFiles, dataSource?.meta?.files),
                    deleted_files: getDifferenceBetweenPathArrays(
                        dataSource?.meta?.files,
                        newFiles,
                    ),
                };
                if (!data.new_files.length && !data.deleted_files.length) {
                    toast.info("No file changes detected", { autoClose: 2000 });
                    return;
                }
                await SyncService.syncDataSourceFile(orgId, agentId, datasourceId ?? dsId, data);
                const DS = { ...dataSource };
                DS.meta.files = newFiles;
                setDataSource(DS);
                toast.success("File updated successfully", { autoClose: 2000 });
            } catch (error) {
                toast.error(error?.response?.data?.message);
                console.error(error);
            }
        };

        const getDifferenceBetweenPathArrays = (primaryArray = [], secondaryArray = []) => {
            return primaryArray.filter((x) => !secondaryArray.includes(x)).map((val) => val?.path);
        };

        const uploadFiles = async (file) => {
            let data = {
                "namespace": namespaceforFileUpload,
                "options": {
                    "file_name": file?.name,
                    "file_type": file?.type,
                    "file_size": file?.size,
                    "copilot_id": agentId,
                },
            };
            return await uploadStorageAssets(orgId, data, file);
        };
        const onDelete = async (index) => {
            let uploadedFilesCopy = [...uploadedFiles];
            uploadedFilesCopy.splice(index, 1);
            setUploadedFiles(uploadedFilesCopy);
            mode !== "create" && (await submit(uploadedFilesCopy));
        };

        const handleDownload = async (val) => {
            try {
                let {
                    data: { signed_url },
                } = await SyncService.fetchSignedUrlAssets(orgId, val?.path);
                const response = await fetch(signed_url);

                if (!response.ok) {
                    throw new Error("Network response was not ok " + response.statusText);
                }
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.download = val?.name;
                link.href = blobUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {}
        };
        const defaultColumn = React.useMemo(
            () => ({
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            }),
            [],
        );

        const columns = useMemo(
            () => [
                {
                    Header: "Name",
                    accessor: "name",
                    minWidth: 140,
                    width: 100,
                    Cell: (props) => (
                        <NameWrapper>
                            {ACCEPTED_MIME_TYPES_ICONS[props.row.values.type]}
                            {props.row.values.name}
                        </NameWrapper>
                    ),
                },
                {
                    Header: "Size",
                    accessor: "size",
                    // disableSortBy: true,
                    Cell: (props) => (
                        <_Body_XS_Reg>{(props.row.values.size / 1000).toFixed(1)} KB</_Body_XS_Reg>
                    ),
                },
                {
                    Header: "Type",
                    accessor: "type",
                    // disableSortBy: true,
                    Cell: (props) => (
                        <_Body_XS_Reg>{props.row.values.type?.split(".")?.pop()}</_Body_XS_Reg>
                    ),
                },
                {
                    accessor: "action",
                    Header: "Actions",
                    disableSortBy: true,
                    Cell: (props) => (
                        <ActionWrapper>
                            <ActionIconWrapper onClick={(e) => handleDownload(props.row?.original)}>
                                {Icon(ArrowDown, { size: 18, variant: "Broken" })}
                            </ActionIconWrapper>
                            <ActionIconWrapper
                                backgroundColor="#FF4D4D40"
                                onClick={(e) => onDelete(props.row.index)}
                            >
                                {Icon(Trash, { size: 18, color: "#FF4D4D" })}
                            </ActionIconWrapper>
                        </ActionWrapper>
                    ),
                },
            ],
            [uploadedFiles],
        );

        const tableInstance = useTable(
            {
                columns,
                data: searchText === "" ? uploadedFiles : filteredList,
                defaultColumn,
                autoResetPage: true,
                manualSortBy: true,
                autoResetSortBy: true,
                initialState: {},
            },
            useSortBy,
        );
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            rows,

            state: { sortBy },
        } = tableInstance;
        return (
            <>
                <HeaderWrapper>
                    <TypeWrapper>{Icon(DocumentText, { size: 24 })}Files</TypeWrapper>
                    <SearchPlaceHolder
                        maxWidth={"300px"}
                        handleSearchChange={(e) => {
                            const value = e.target.value;
                            setSearchText(value);
                            setIsSearching(value.length > 0);

                            const filteredResults = [];
                            const regex = new RegExp(value, "i");

                            for (const item of uploadedFiles) {
                                if (regex.test(item.name) || regex.test(item.type)) {
                                    filteredResults.push(item);
                                }
                            }

                            setFileredList(filteredResults);
                        }}
                    ></SearchPlaceHolder>
                </HeaderWrapper>

                <InputFileWrapper>
                    {fileQuantityRestriction > uploadedFiles.length && (
                        <>
                            <UploadWrapper
                                onClick={handleClick}
                                onDragEnter={(e) => e.preventDefault()}
                                onDragLeave={(e) => e.preventDefault()}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    handleChange(e, "drop");
                                }}
                            >
                                {Icon(DocumentUpload, { size: 32 })}
                                <_Body_XS_Reg>Drag and drop or Browse Files</_Body_XS_Reg>
                                <_Body_XXS_Reg>
                                    Supports{" "}
                                    {Object.keys(getMimeTypeOfFile)
                                        .filter((key) =>
                                            allowedFileTypes.includes(getMimeTypeOfFile[key]),
                                        )
                                        .map((key) => key.replace("Files", ""))
                                        .join(", ")}{" "}
                                    files only
                                </_Body_XXS_Reg>
                            </UploadWrapper>
                            <input
                                type="file"
                                accept={allowedFileTypes}
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: "none" }}
                                multiple
                            />
                        </>
                    )}

                    <List.ListLayout>
                        {rows.length > 0 ? (
                            <Table
                                getTableProps={getTableProps}
                                getTableBodyProps={getTableBodyProps}
                                headerGroups={headerGroups}
                                prepareRow={prepareRow}
                                rows={rows}
                            />
                        ) : (
                            isSearching && <StyledError>No Result found</StyledError>
                        )}
                    </List.ListLayout>
                </InputFileWrapper>
            </>
        );
    },
);
export default FileCollector;
