import { Breakpoints } from "../../../GlobalStyle";
import TextInput from "../../../common/components/TextInput";
import TextArea from "../../../common/components/TextArea";
import GitHubIcon from "../../../common/assets/GithubIcon";
import QuipIcon from "common/assets/Quip";
import RestAPI from "../../../common/assets/RestAPI";
import {
    SubTitle1,
    InputLabel,
    Body3,
    Caption1,
    GradientText,
    Body_XS_Bold,
    Body_XS_Reg,
} from "../../../common/global-styled-components";
import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { Stepper } from "react-form-stepper";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Button from "../../../common/components/Button";
import Icon from "../../../common/components/Icon";
import Tile from "../../../common/components/Tile";
import Tag from "../../../common/components/Tag";
import FormStepper from "../../../common/components/Stepper";
import CustomDropdown from "../../../common/components/CustomDropdown";
import GoogleLoginNew from "common/components/GoogleAuthButton/GoogleLoginNew";
import {
    Magicpen,
    Smallcaps,
    DocumentUpload,
    Global,
    DocumentCloud,
    Verify,
    GoogleDrive,
    TickCircle,
} from "iconsax-react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DataSourceService from "../../../services/datasource.service";
import { createCopilotDataSources } from "../../../common/redux/actions/datasourceActions";
import { toast } from "react-toastify";
import URLS from "common/utils/apiUrls";
import { getMimeTypeOfFile, fileDatasources } from "common/utils";
import PasswordInput from "common/components/PasswordInput";
import NotionIcon from "common/assets/NotionIcon";
import { CustomRadioButtonGroup } from "common/components/CustomRadioButton";
import Checkbox from "common/components/CheckBox";
import Tooltip from "common/components/Tooltip";
import InfoIcon from "common/assets/InfoIcon";
import FileCollector from "../Edit/FileCollector";
import PdfIcon from "../../../common/assets/PdfIcon";
import XlsxIcon from "../../../common/assets/XlsxIcon";
import CsvIcon from "../../../common/assets/CsvIcon";
import WordIcon from "../../../common/assets/WordIcon";
import TextFileIcon from "../../../common/assets/TextFileIcon";
import { validateRepoUrl } from "common/utils/validations";

const Header = {};
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;

    @media (max-width: ${Breakpoints.mobileXS}px) {
        font-size: var(--subtitle-2-m);
        width: 130px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline;
    }
`;
const Wrapper = styled.div`
    flex: 1;
    max-height: 90vh;
    overflow: auto;
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Default);
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXXL);
`;
const ValidationMessage = styled.p`
    color: var(--Color-Text-Danger);
    font-size: 0.8em;
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: var(--Size-Padding-XXL);
    gap: var(--Size-Gap-XXL);
`;
const StyledError = styled.div`
    color: var(--Color-Text-Danger);
`;
const StyledErrorSkeleton = styled(Body3)`
    margin-left: 12px;
    height: var(--body-2-d);
`;
const OuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
`;
const _Caption1 = styled(Caption1)`
    text-align: center;
    font-weight: 400;
`;

const GetQuipToken = styled.span`
    text-decoration: underline;
    cursor: pointer;
    margin-left: 11px;
    color: var(--Color-Text-Action);
`;

const VerifyToken = styled(Button)`
    ${GradientText}
    position: absolute;
    right: 20px;
    text-decoration: underline;
    border: 0;
    &:hover {
        border: 0;
    }
    @media (max-width: ${Breakpoints.mobileXS}px) {
        margin-right: 5px;
        top: auto;
    }
`;

const ConnectedWithGoogleWrapper = styled.div`
    display: flex;
    align-items: center;
    display: flex;
    gap: var(--Size-Gap-L);
`;

const TilesWrapper = styled.div`
    display: grid;
    grid-gap: var(--Size-Gap-XL);
    grid-template-columns: repeat(2, 1fr);
    overflow: auto;
    max-height: 500px;
    @media (max-width: ${Breakpoints.mobileXS}px) {
        grid-template-columns: repeat(1, 1fr);
        max-height: 380px;
    }
`;

const TextToBeCrawled = styled(Body_XS_Bold)`
    display: flex;
    color: ${(props) => (props.overlimit ? "var(--Color-Text-Danger)" : "inherit")};
    justify-content: flex-end;
`;

const _InputLabel = styled(InputLabel)`
    left: 8px;
    color: var(--Color-Text-Disabled);
`;
const TooltipData = styled.div`
    font-size: 14px;
    max-width: 300px;
`;
const Description = styled(InfoIcon)`
    margin-left: 4px;
    margin-bottom: -2px;
    width: var(--body-1-d);
    fill: var(--light-300);
    cursor: pointer;
    height: auto;
`;
const Label = styled(Body_XS_Reg)`
    padding: 0px 35px;
    color: var(--Color-Text-Disabled);
`;
const APILogoTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const tiles = [
    {
        type: "text",
        enabled: true,
        icon: Icon(Smallcaps, { size: 32 }),
        title: "Text",
        description: "Add or copy/paste some text here",
        tags: [],
    },
    {
        type: "web",
        enabled: true,
        icon: Icon(Global, { size: 32 }),
        title: "Website",
        description: "Crawl all pages from a website",
        tags: [],
    },
    {
        type: "files",
        enabled: true,
        icon: Icon(DocumentUpload, { size: 32 }),
        title: "Files",
        description: "PDF, CSV, JSON, TXT, EXCEL, Word, etc",
        tags: [],
    },
    {
        type: "textFiles",
        enabled: true,
        icon: Icon(TextFileIcon, { size: 32 }),
        title: "Text Files",
        description: "Upload .txt files",
        tags: [],
    },
    {
        type: "pdfFiles",
        enabled: true,
        icon: Icon(PdfIcon, { size: 32 }),
        title: "PDF Files",
        description: "Upload .pdf files",
        tags: [],
    },
    {
        type: "csvFiles",
        enabled: true,
        icon: Icon(CsvIcon, { size: 32 }),
        title: "CSV Files",
        description: "Upload .csv files",
        tags: [],
    },
    {
        type: "docFiles",
        enabled: true,
        icon: Icon(WordIcon, { size: 32 }),
        title: "DOC Files",
        description: "Upload .doc files",
        tags: [],
    },
    {
        type: "excelFiles",
        enabled: true,
        icon: Icon(XlsxIcon, { size: 32 }),
        title: "Excel Files",
        description: "Upload .xls files",
        tags: [],
    },
    {
        type: "quip",
        enabled: true,
        icon: Icon(QuipIcon, { size: 32 }),
        title: "Quip",
        description: "Add your Quip files/folders",
        tags: [],
    },
    {
        type: "api",
        enabled: true,
        icon: Icon(RestAPI, { size: 32 }),
        title: "Rest API",
        description: "Pull data from API's",
        tags: [],
    },
    {
        type: "googleDrive",
        enabled: true,
        icon: Icon(GoogleDrive, { size: 32 }),
        title: "Google Drive",
        description: "Get data from your Google Drive",
        tags: [],
    },
    {
        type: "git",
        enabled: true,
        icon: Icon(GitHubIcon, { size: 32 }),
        title: "Git",
        description: "Interact with your Git repositories",
        tags: [],
    },
    {
        type: "notion",
        enabled: true,
        icon: Icon(NotionIcon, { size: 32 }),
        title: "Notion Page",
        description: "Connect Notion Pages",
        tags: [],
    },
];

/**
 *
 * @param {*} callback must be a callback function
 * @param {*} cancelButtonCallback must be a cancelButtonCallback function
 * @param {*} tryForFreeCallback must be a tryForFreeCallback function
 * @param {*} neededDatasources must be an array, if empty everything is required
 * @param {*} fileQuantityRestriction must be an integer, it represents no of files that can be uploaded for files datasource
 * @param {*} namespaceforFileUpload must be an string, namespace where files sould be uploaded
 * @returns
 */
function DataSourceCreate({
    callback,
    cancelButtonCallback,
    neededDatasources = [],
    tryForFreeCallback = null,
    defaultType = "web",
    fileQuantityRestriction = 100,
    namespaceforFileUpload = "files_datasource",
}) {
    /**
     *
     * Form Submission
     */

    const [type, setType] = useState(
        tiles.find((tile) => tile.type === defaultType) !== undefined ? defaultType : "web",
    );
    const { orgId, agentId } = useParams();
    const uploadRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const platformPathRef = useRef("Add Quip Folder/FIles Path");
    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [stepPosition, setStepPosition] = useState(null);
    const [initialValue, setInitialValue] = useState({
        googleTokens: {},
        isVerifyToken: false,
        renderType: "",
        webLink: "",
    });
    const {
        unregister,
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
        watch,
        control,
        reset,
    } = useForm({
        // default values
        defaultValues: { googleTokens: {}, isVerifyToken: false, renderType: "", webLink: "" },
        mode: "all",
    });
    const allValues = watch();

    useEffect(() => {
        setDisableCreateButton(["quip", "notion"].includes(type));
    }, [type]);

    const verifyToken = (type) => {
        try {
            let request;
            switch (type) {
                case "quip":
                    request = {
                        type: type,
                        token: allValues?.quipToken,
                    };
                    break;
                case "notion":
                    request = {
                        type: type,
                        token: allValues?.notionToken,
                    };
                    break;

                default:
                    throw new Error("Invalid Type");
            }
            DataSourceService.verifyDatasourceToken(orgId, agentId, { ...request })
                .then((res) => {
                    const { data } = res;
                    toast.success("Token validated successfully");
                    platformPathRef.current = data?.url;
                    setValue("isVerifyToken", true);
                    setDisableCreateButton(false);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message || "Something went wrong");
                    setValue("isVerifyToken", false);
                    setDisableCreateButton(true);
                });
        } catch (error) {
            toast.error(error?.response?.data?.message || "Invalid type for token verification");
        }
    };

    const createGoogleToken = () => {
        window.addEventListener(
            "message",
            function (event) {
                console.log("Received message from:", event.origin);
                console.log("Current window origin:", window.location.origin);

                const tokens = event.data;

                if (Object.keys(tokens).length > 0) {
                    setValue("googleTokens", tokens);
                    console.log("Closing window...");
                }
            },
            false,
        );
        console.log("Opening window...");

        window.open(
            `${URLS.dataSourceGoogleTokenCreate}?clientUrl=${window.location.origin}`,
            "google-auth-popup",
            "width=600,height=600",
        );
    };

    const generateToken = (link) => {
        window.open(link, "_blank");
    };

    const noConfigDatasources = {
        api: {
            message: "API can be configured once your data source is created",
            icon: RestAPI,
        },
    };

    const typeMap = (type, register) => {
        let returnComponent;
        switch (type) {
            case "web":
                returnComponent = (
                    <>
                        <InputWrapper>
                            <InputLabel>Website Link *</InputLabel>
                            <TextInput
                                name="webLink"
                                label="webLink"
                                key="webLink"
                                placeholder="e.g.: https://www.example.com"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Website Link is required",
                                    pattern: {
                                        value: /^(http|https):\/\/(([a-zA-Z0-9-_]{1,63}\.)+(?:[a-zA-Z]{2,})|localhost|((\d{1,3}\.){3}\d{1,3}))(?::\d+)?(?:\/[^\s]*)?$/,
                                        message: "Invalid Website Link",
                                    },
                                }}
                                data-testid="datasource-link"
                            ></TextInput>
                            <_InputLabel>
                                Note: By continuing, you confirm that you are authorized to scrape
                                the website content.
                            </_InputLabel>
                            {errors?.webLink && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.webLink?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) setValue("renderType", "csr");
                                    else setValue("renderType", "");
                                }}
                            >
                                <InputLabel>
                                    Enable dynamic content collection{" "}
                                    <Description
                                        role="tooltip"
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={true}
                                        data-tooltip-id="csr"
                                        data-tip
                                    />
                                    <Tooltip id="csr">
                                        Data is gathered directly on your device after the webpage
                                        is loaded. This method provides more flexibility but may
                                        require more resources and time.
                                    </Tooltip>
                                </InputLabel>
                            </Checkbox>
                            <Label>
                                Note: Check this box to capture content that appears after page
                                load.
                            </Label>
                        </InputWrapper>
                    </>
                );
                break;
            case "quip":
                returnComponent = (
                    <>
                        <InputWrapper>
                            <InputLabel>
                                Quip Token *
                                <GetQuipToken
                                    onClick={() => generateToken("https://quip.com/dev/token")}
                                >
                                    (Get Token Here)
                                </GetQuipToken>
                            </InputLabel>
                            <PasswordInput
                                name="quipToken"
                                label="quipToken"
                                key="quipToken"
                                placeholder="Add token from the quip"
                                height="156px"
                                margin="0"
                                register={register}
                                onChange={(e) => {
                                    setValue("quipToken", e.target.value);
                                    setValue("isVerifyToken", false);
                                    setDisableCreateButton(true);
                                }}
                                validation={{
                                    required: "Quip Token is required",
                                }}
                                data-testid="quipToken-link"
                            >
                                {" "}
                                <VerifyToken
                                    mode="secondary"
                                    onClick={handleSubmit(() => verifyToken("quip"))}
                                    disabled={allValues?.isVerifyToken}
                                >
                                    {allValues?.isVerifyToken ? "Verified" : "Verify"}
                                </VerifyToken>
                            </PasswordInput>

                            {errors.quipToken && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors.quipToken.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        {allValues?.isVerifyToken ? (
                            <InputWrapper>
                                <InputLabel>Quip Link *</InputLabel>
                                <TextInput
                                    name="quipLink"
                                    label="quipLink"
                                    key="quipLink"
                                    placeholder={platformPathRef.current}
                                    height="156px"
                                    margin="0"
                                    register={register}
                                    validation={{
                                        required: "Quip folders/files path is required",
                                        validate: (value) => {
                                            let cleanPath =
                                                platformPathRef.current.split(
                                                    "/yourfolderOrfileId",
                                                )[0];

                                            const regex = new RegExp(
                                                `^${cleanPath.replace(
                                                    /[-\/\\^$*+?.()|[\]{}]/g,
                                                    "\\$&",
                                                )}\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$`,
                                            );
                                            if (regex.test(value)) {
                                                return true;
                                            } else {
                                                return "Invalid Quip folders/files path";
                                            }
                                        },
                                    }}
                                    data-testid="quipLink-link"
                                />

                                {errors?.quipLink && (
                                    <StyledErrorSkeleton>
                                        <StyledError role="error">
                                            {errors?.quipLink?.message}
                                        </StyledError>
                                    </StyledErrorSkeleton>
                                )}
                            </InputWrapper>
                        ) : (
                            ""
                        )}
                    </>
                );
                break;
            case "notion":
                returnComponent = (
                    <>
                        <InputWrapper>
                            <InputLabel>
                                Notion Internal Integration Secret *
                                <Description
                                    role="tooltip"
                                    onClick={(e) => e.stopPropagation()}
                                    disabled={true}
                                    data-tooltip-id="notionLink"
                                    data-tip
                                />
                                <Tooltip
                                    id="notionLink"
                                    type="faint"
                                    effect="solid"
                                    width="10px"
                                    enableToolTip={true}
                                >
                                    <TooltipData>
                                        Only notion workspace owners can create integration. Please
                                        add Internal Integration Secret here
                                    </TooltipData>
                                </Tooltip>
                                <GetQuipToken
                                    onClick={() =>
                                        generateToken("https://www.notion.so/my-integrations")
                                    }
                                >
                                    (Get it Here)
                                </GetQuipToken>
                            </InputLabel>
                            <PasswordInput
                                name="notionToken"
                                label="notionToken"
                                key="notionToken"
                                placeholder="Add token from the notion"
                                height="156px"
                                margin="0"
                                register={register}
                                onChange={(e) => {
                                    setValue("notionToken", e.target.value);
                                    setValue("isVerifyToken", false);
                                    setDisableCreateButton(true);
                                }}
                                validation={{
                                    required: "Notion Token is required",
                                }}
                                data-testid="notionToken-link"
                            >
                                {" "}
                                <VerifyToken
                                    mode="secondary"
                                    onClick={handleSubmit(() => verifyToken("notion"))}
                                    disabled={allValues?.isVerifyToken}
                                >
                                    {allValues?.isVerifyToken ? "Verified" : "Verify"}
                                </VerifyToken>
                            </PasswordInput>

                            {errors.notionToken && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors.notionToken.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        {allValues?.isVerifyToken ? (
                            <InputWrapper>
                                <InputLabel>
                                    Notion Page Link *
                                    <GetQuipToken
                                        onClick={() =>
                                            generateToken(
                                                "https://developers.notion.com/docs/create-a-notion-integration#give-your-integration-page-permissions",
                                            )
                                        }
                                    >
                                        ( Make sure the internal integration secret is added to the
                                        notion page link added below.)
                                    </GetQuipToken>
                                </InputLabel>
                                <TextInput
                                    name="notionLink"
                                    label="notionLink"
                                    key="notionLink"
                                    placeholder={"https://www.notion.so/testsample-id"}
                                    height="156px"
                                    margin="0"
                                    register={register}
                                    validation={{
                                        required: "Notion files path is required",
                                    }}
                                    data-testid="notionLink-link"
                                />

                                {errors?.notionLink && (
                                    <StyledErrorSkeleton>
                                        <StyledError role="error">
                                            {errors?.notionLink?.message}
                                        </StyledError>
                                    </StyledErrorSkeleton>
                                )}
                            </InputWrapper>
                        ) : (
                            ""
                        )}
                    </>
                );
                break;
            case "git":
                returnComponent = (
                    <>
                        <InputWrapper>
                            <InputLabel>Git Token * </InputLabel>
                            <PasswordInput
                                name="gitToken"
                                label="gitToken"
                                key="gitToken"
                                placeholder="Add token from the git"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Git Token is required",
                                    maxLength: {
                                        value: 120,
                                        message:
                                            "Tokens should be between 20 and 128 characters long.",
                                    },
                                    minLength: {
                                        value: 2,
                                        message:
                                            "Tokens should be between 20 and 128 characters long.",
                                    },
                                    validate: (val) =>
                                        (val && val.trim().length > 0) || "Token cannot be empty",
                                }}
                                data-testid="gitToken-link"
                            />
                            {errors?.gitToken && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.gitToken?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <InputLabel>Git Repository Link *</InputLabel>
                            <TextInput
                                name="gitRepoLink"
                                label="gitRepoLink"
                                key="gitRepoLink"
                                placeholder="Add repo from the git source"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Git folders/files path is required",
                                    validate: validateRepoUrl,
                                }}
                                data-testid="gitLink-link"
                            />

                            {errors?.gitRepoLink && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.gitRepoLink?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                    </>
                );
                break;
            case "text":
                returnComponent = (
                    <>
                        <InputWrapper>
                            <InputLabel>Text * </InputLabel>
                            <TextArea
                                name="textToBeCrawled"
                                label="textToBeCrawled"
                                key="textToBeCrawled"
                                placeholder="Add Text"
                                height="156px"
                                margin="0"
                                register={register}
                                validation={{
                                    required: "Text is required",
                                    maxLength: {
                                        value: 10000,
                                        message: "Text should less then 10000 characters.",
                                    },
                                }}
                                data-testid="textsource-link"
                            />
                            <TextToBeCrawled overlimit={allValues?.textToBeCrawled?.length > 10000}>
                                {allValues?.textToBeCrawled?.length}/10000
                            </TextToBeCrawled>
                            {errors?.textToBeCrawled && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.textToBeCrawled?.message}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InputWrapper>
                    </>
                );
                break;
            case "googleDrive":
                returnComponent =
                    Object.keys(allValues?.googleTokens).length === 0 ? (
                        <InputWrapper>
                            <GoogleLoginNew onClick={createGoogleToken}>
                                Connect with Google Drive
                            </GoogleLoginNew>
                        </InputWrapper>
                    ) : (
                        <>
                            <ConnectedWithGoogleWrapper>
                                {Icon(TickCircle, { size: 27, color: "var(--success-dark)" })}{" "}
                                Connected with Google
                            </ConnectedWithGoogleWrapper>
                            <InputWrapper>
                                <InputLabel>Google Drive Link *</InputLabel>
                                <TextInput
                                    name="googleDriveLink"
                                    label="googleDriveLink"
                                    key="googleDriveLink"
                                    height="156px"
                                    margin="0"
                                    register={register}
                                    validation={{
                                        required: "Google Drive folders/files path is required",
                                    }}
                                    data-testid="googleDriveLink-link"
                                />

                                {errors?.googleDriveLink && (
                                    <StyledErrorSkeleton>
                                        <StyledError role="error">
                                            {errors?.googleDriveLink?.message}
                                        </StyledError>
                                    </StyledErrorSkeleton>
                                )}
                            </InputWrapper>
                        </>
                    );

                break;
            case "files":
                returnComponent = (
                    <FileCollector
                        ref={uploadRef}
                        fileQuantityRestriction={fileQuantityRestriction}
                        namespaceforFileUpload={namespaceforFileUpload}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                    />
                );
                break;
            case "pdfFiles":
            case "textFiles":
            case "csvFiles":
            case "docFiles":
            case "excelFiles":
                returnComponent = (
                    <FileCollector
                        ref={uploadRef}
                        allowedFileTypes={[getMimeTypeOfFile[type]]}
                        fileQuantityRestriction={fileQuantityRestriction}
                        namespaceforFileUpload={namespaceforFileUpload}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                    />
                );
                break;
            case "api":
                returnComponent = (
                    <TextWrapper>
                        <_Caption1>
                            Great! Your data source looks good. <br />
                            <APILogoTextWrapper>
                                {Icon(noConfigDatasources[type]?.icon, {
                                    size: 48,
                                    variant: "Bulk",
                                })}
                                {noConfigDatasources[type]?.message} <br />
                            </APILogoTextWrapper>
                        </_Caption1>
                    </TextWrapper>
                );
                break;
            default:
                break;
        }
        return returnComponent;
    };

    const steps = [
        {
            label: "Choose type",
            component: (
                <TilesWrapper>
                    {tiles.map((i) =>
                        neededDatasources.length > 0 &&
                        !neededDatasources.includes(i.type) ? null : (
                            <Tile
                                key={i.title}
                                selected={i.enabled && i.type === type}
                                icon={i.icon}
                                enabled={i.enabled}
                                title={i.title}
                                description={i.description}
                                tags={i.tags}
                                onClick={() => i.enabled && setType(i.type)}
                                onDoubleClick={() => i.enabled && setStepPosition(1)}
                            />
                        ),
                    )}
                </TilesWrapper>
            ),
        },
        {
            label: "Details",
            component: <OuterWrapper>{typeMap(type, register)}</OuterWrapper>,
            onClickBack: () => setStepPosition(0),
        },
    ];
    const submit = async () => {
        if (type != "googleDrive") {
            delete allValues["googleTokens"];
        }
        if (type != "web") {
            delete allValues["renderType"];
        }
        // try for free flow
        if (tryForFreeCallback) {
            tryForFreeCallback({ ...allValues, type, uploadedFiles });
            return;
        }

        try {
            let body = {
                // name: allValues.datasourceName,
                type: type,
                config: {
                    domain: allValues.webLink,
                    quipToken: allValues.quipToken,
                    quipLink: allValues.quipLink,
                    notionToken: allValues.notionToken,
                    notionLink: allValues.notionLink,
                    googleTokens: allValues.googleTokens,
                    googleDriveLink: allValues.googleDriveLink,
                    gitToken: allValues.gitToken,
                    gitRepoLink: allValues.gitRepoLink,
                    textToBeCrawled: allValues.textToBeCrawled,
                    renderType: allValues.renderType,
                },
                configuration: {
                    provider: {
                        embeddingModelName: allValues.embeddingModelName,
                    },
                },
                copilotAppId: agentId,
            };
            if (fileDatasources.includes(type)) {
                body = {
                    ...body,
                    type: "files", // added to override single particular file type (csv, pdf etc) into one type
                    files: uploadedFiles?.length > 0 ? uploadedFiles.map((val) => val.path) : [],
                };
            }
            const res = await createCopilotDataSources(orgId, agentId, body, dispatch);
            toast.success(`Data Sources created successfully`, {
                autoClose: 2000,
            });
            if (!callback) {
                history.push(`/org/${orgId}/datasources/${res?.id}/sources`);
            }

            callback?.(res);
        } catch (error) {
            toast.error(error?.response?.data?.message || `Unable to create Data Source`, {
                autoClose: 2000,
            });
        }
    };
    return (
        <Wrapper>
            <Header.Title>Connect Data Source</Header.Title>
            <Body>
                <FormStepper
                    submitText="Connect"
                    steps={steps}
                    hideStepperTitle={true}
                    onSubmit={handleSubmit(submit)}
                    disableSubmit={disableCreateButton || !(Object.keys(errors).length === 0)}
                    checkIfNoError={handleSubmit}
                    cancelButtonCallback={cancelButtonCallback}
                    changeStepperPosition={stepPosition}
                ></FormStepper>
            </Body>
        </Wrapper>
    );
}

export default DataSourceCreate;
