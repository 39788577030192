import React from "react";
import styled from "styled-components";

const Label = styled.label`
    height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--body-font);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 767px) {
        font-size: 14px;
    }
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1024px) {
        left: 10%;
    }
`;
const GroupWrapper = styled.div`
    display: flex;
    gap: 50px;
    flex-direction: row;
    width: 100%;
    @media (max-width: 480px) {
        gap: var(--Size-Gap-XS);
        flex-direction: column;
    }
`;

const InputRadio = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    :checked ~ .checkmark:after {
        display: block;
    }
`;

const RadioButtonDot = styled.span`
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid var(--Color-Border-Default);

    :after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: ${(props) =>
            props.disabled
                ? "var(--Color-Background-Disabled);"
                : "var(--Color-Background-Action);"}
        width: ${(props) => (props.width ? props.width : "")};
        display: none;
    }

    @media (max-width: 767px) {
        width: 20px;
        height: 20px;

        :after {
            width: 10px;
            height: 10px;
        }
    }
`;

const CustomRadioButton = ({
    htmlFor,
    children,
    id,
    label,
    name,
    value,
    checked,
    register,
    disabled,
    callback,
}) => {
    const handleLabelClick = (e) => {
        if (e.target.checked) {
            e.stopPropagation();
            callback?.({ label: e.label, value: e.value });
        }
    };
    return (
        <Label htmlFor={htmlFor} className="container">
            <Wrapper>
                <InputRadio
                    type="radio"
                    id={id}
                    label={label}
                    name={name}
                    value={value}
                    onClick={(e) => {
                        e.label = label;
                        e.value = value;
                        handleLabelClick(e);
                    }}
                    defaultChecked={checked}
                    {...(register && register(label))}
                    disabled={disabled}
                />
                <RadioButtonDot className="checkmark" disabled={disabled}></RadioButtonDot>
                {children}
            </Wrapper>
        </Label>
    );
};
const CustomRadioButtonGroup = ({
    elements = [],
    register,
    label,
    selectedValue,
    disabled,
    callback,
}) => {
    return (
        <GroupWrapper>
            {elements.map((e, index) => {
                return (
                    <CustomRadioButton
                        key={index}
                        register={register}
                        label={label}
                        id={label}
                        value={e.value}
                        checked={e.value === selectedValue}
                        disabled={e.disabled || disabled}
                        callback={callback}
                    >
                        {e.child}
                    </CustomRadioButton>
                );
            })}
        </GroupWrapper>
    );
};

export { CustomRadioButton, CustomRadioButtonGroup };
