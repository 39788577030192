import { Breakpoints } from "../../../GlobalStyle";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { SubTitle1, Body_M_Reg } from "../../../common/global-styled-components";
import debounce from "lodash.debounce";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AdvancedPagination from "common/components/AdvancedPagination";
import { getCopilots } from "common/redux/actions/copilotActions";
import Tile from "../../../common/components/Tile";
import IconLogo from "common/components/Logo/IconLogo";
import NoDataFound from "common/components/NoDataFound";
import LoaderIcon from "common/components/LoaderIcon";

const Header = {};
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;

    @media (max-width: ${Breakpoints.mobileXS}px) {
        font-size: var(--subtitle-2-m);
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
    }
`;

const RoundedBorderGeneralDiv = styled.div`
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    display: flex;
    padding: var(--Size-Padding-XL) 24px;
    flex-direction: column;
    gap: var(--Size-Gap-M);
    align-self: stretch;
    overflow-y: scroll;
    max-height: 500px;
`;

const Form = {};
Form.Desc = styled.div`
    color: var(--Color-Text-Subtlest);
    font-weight: 400;
    font-size: var(--body-3-d);
`;
Form.Title = styled.div`
    color: var(--Color-Text-Subtl);
    font-weight: 400;
    font-size: var(--caption-1-d);
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
    align-self: center;
`;

const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Default);
`;

function CopilotList({ setValue, allValues, setDisableStepperContinue }) {
    const { orgId } = useParams();
    const dispatch = useDispatch();
    const isMounted = useRef(null);
    const [copilots, setCopilots] = useState([]);
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [searchName, setSearchName] = useState("");
    const [isPageLoading, setPageLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const debounceFetch = useMemo(() => debounce(getAgents, 300), []);
    useEffect(() => {
        if (allValues?.type) {
            return;
        }
        setDisableStepperContinue(true);
        return () => {
            setDisableStepperContinue(false);
        };
    }, []);
    useEffect(() => {
        isMounted.current = true;

        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetch(orgId, queryString);
        }
        return () => {
            // executed when unmount
            isMounted.current = false;
        };
    }, [currentPage, searchName]);

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";
        return [pageQuery, pageSizeQuery].join("&");
    };

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    function getAgents(orgId, queryString) {
        getCopilots(orgId, queryString, dispatch)
            .then((res) => {
                setCopilots(res.items);
                setPageDetails(res.page);
            })
            .catch((err) => {
                setCopilots([]);
                setPageDetails({});
            })
            .finally(() => {
                if (isMounted.current) {
                    setPageLoading(false);
                }
            });
    }

    const selectCopilot = (id) => {
        setValue("type", id);
        setDisableStepperContinue(false);
    };

    return (
        <RoundedBorderGeneralDiv>
            {isPageLoading ? (
                <Loader>
                    <LoaderIcon />
                </Loader>
            ) : copilots.length > 0 ? (
                <>
                    {copilots.map((i) => (
                        <Tile
                            key={i.id}
                            selected={i.id === allValues.type}
                            icon={<IconLogo src={i.icon}></IconLogo>}
                            title={i.name}
                            tags={i.tags}
                            onClick={() => selectCopilot(i?.id)}
                        />
                    ))}

                    <AdvancedPagination
                        page={pageDetails}
                        pageSize={pageSize}
                        paginationInfo="Copilot"
                        setPageSize={setPageSize}
                        setCurrentPage={setCurrentPage}
                    />
                </>
            ) : (
                <NoDataFound>
                    {!searchName?.length && (
                        <>
                            <DataNotFoundMessage>
                                Looks like you are new here. Please create a widget before adding
                                skills.
                            </DataNotFoundMessage>
                        </>
                    )}
                </NoDataFound>
            )}
        </RoundedBorderGeneralDiv>
    );
}

export default CopilotList;
