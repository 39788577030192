import React, { memo, useEffect } from "react";
import styled from "styled-components";
import TextInput from "../components/TextInput";
import { useFieldArray, useForm } from "react-hook-form";
import Button from "./Button";
import Icon from "./Icon";
import { Add, CloseCircle } from "iconsax-react";
import { InputLabel } from "../global-styled-components";

const Label = styled.label`
    margin-bottom: 8px;
    font-size: var(--body-3-d);
    font-weight: 400;
    color: var(--light-200);
    display: flex;
`;

const KeyValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: var(--Size-Gap-L);
`;

const DeleteButtonWrapper = styled.div`
    cursor: pointer;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
`;

const StyledError = styled.div`
    margin-top: 4px;
    font-size: var(--body-4-m);
    color: var(--Color-Text-Danger);
`;
const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-L);
`;
const _TextInput = styled(TextInput)``;

// const DEFAULT_OBJECT = [{ "key": "", "value": "" }];

const AddKeyValuePair = memo(
    ({
        pair = {},
        label = ["key", "value"],
        validation = [{ required: true }, {}],
        syncData,
        hasLabelMapped = false,
        keyValueRef,
        renderOptions,
        allValues,
        displayErrors = true,
    }) => {
        // useEffect(() => {
        //     if (!Object.keys(pair).length == 0) {
        //         if (!hasLabelMapped) {
        //             let objectPair = Object.entries(pair).map(([key, value]) => {
        //                 return { [label[0]]: key, [label[1]]: value.toString() };
        //             });
        //             replace(objectPair);
        //         } else {
        //             replace(pair);
        //         }
        //     }
        // }, [pair]);

        useEffect(() => {
            if (!Object.keys(pair).length == 0) {
                if (!hasLabelMapped) {
                    let objectPair = Object.entries(pair).map(([key, value]) => {
                        return { [label[0]]: key, [label[1]]: value.toString() };
                    });
                    replace(objectPair);
                } else {
                    replace(pair);
                }
            }
        }, []);

        useEffect(() => {
            if (allValues?.[keyValueRef?.current?.id]) {
                remove();
                replace(allValues[keyValueRef?.current.id]);
            }
        }, [allValues?.[keyValueRef?.current?.id]]);

        const {
            register,
            handleSubmit,
            control,
            formState: { errors },
        } = useForm({
            mode: "all",
        });

        const { fields, append, prepend, remove, swap, move, insert, replace, update } =
            useFieldArray({
                control,
                name: keyValueRef?.current.id,
            });

        const changeHandler = (event) => {
            let {
                target: { name, value },
            } = event;
            const [_, index, key] = name.split(".");
            syncData?.(index, key, value, "insert", keyValueRef);
        };

        const onSubmit = (data, event) => {
            event.preventDefault();
            append([{ [label[0]]: "", [label[1]]: "" }]);
        };

        const onDelete = (event, index) => {
            event.preventDefault();
            remove(index);
            syncData?.(index, null, null, "delete", keyValueRef);
        };

        const getError = (index, labelValue) => {
            const errorObject = errors?.[keyValueRef?.current.id]?.[index];
            if (
                errorObject &&
                labelValue in errorObject &&
                errorObject?.[labelValue]?.type == "validate"
            ) {
                if (labelValue == "name") {
                    return "duplicate name";
                } else if (labelValue == "cidr") {
                    return "overlapping cidr or subnet is not subset of cluster CIDR";
                } else {
                    throw new Error(`invalid labelValue ${labelValue} in validate function`);
                }
            }
            if (errorObject && labelValue in errorObject) {
                return errorObject?.[labelValue].message || `invalid ${labelValue} value`;
            }
        };

        return (
            <FormWrapper id={keyValueRef?.current?.id}>
                {fields.map((item, index) => {
                    const fieldName = `${keyValueRef?.current.id}.${index}`;
                    return (
                        <KeyValueWrapper key={`${keyValueRef?.current.id}.${index}`}>
                            <InputWrapper>
                                {/* <InputLabel>{label[0]} </InputLabel> */}
                                <TextInput
                                    label={`${fieldName}.${label[0]}`}
                                    register={register}
                                    type="text"
                                    onChange={changeHandler}
                                    name={`${fieldName}.${label[0]}`}
                                    defaultValue={item[label[0]]}
                                    validation={{
                                        ...validation[0],
                                        pattern: {
                                            value: validation[0]?.pattern
                                                ? new RegExp(validation[0].pattern.value)
                                                : null,
                                            message: validation[0]?.pattern
                                                ? validation[0].pattern.message
                                                : null,
                                        },
                                        validate: () => {
                                            if (validation[0]?.validate?.hasCustomValidation) {
                                                return renderOptions[
                                                    validation[0]?.validate.callBackId
                                                ](`${label[0]}`, item[label[0]]);
                                            }
                                            return true;
                                        },
                                    }}
                                    placeholder={`Enter ${label[0]}`}
                                />
                                <StyledError>
                                    {displayErrors && getError(index, label[0])}
                                </StyledError>
                            </InputWrapper>
                            <InputWrapper>
                                {/* <InputLabel>{label[1]}</InputLabel> */}
                                <TextInput
                                    label={`${fieldName}.${label[1]}`}
                                    register={register}
                                    type="text"
                                    onChange={changeHandler}
                                    name={`${fieldName}.${label[1]}`}
                                    defaultValue={item[label[1]]}
                                    validation={{
                                        ...validation[1],
                                        pattern: {
                                            value: validation[1]?.pattern
                                                ? new RegExp(validation[1].pattern.value)
                                                : null,
                                            message: validation[1]?.pattern
                                                ? validation[1].pattern.message
                                                : null,
                                        },
                                        validate: () => {
                                            if (validation[1]?.validate?.hasCustomValidation) {
                                                return renderOptions[
                                                    validation[1]?.validate.callBackId
                                                ](`${label[1]}`, item[label[1]]);
                                            }
                                            return true;
                                        },
                                    }}
                                    placeholder={`Enter ${label[1]}`}
                                />
                                <StyledError>
                                    {displayErrors && getError(index, label[1])}
                                </StyledError>
                            </InputWrapper>
                            <DeleteButtonWrapper onClick={(e) => onDelete(e, index)}>
                                {Icon(CloseCircle, { size: 21 })}
                            </DeleteButtonWrapper>
                        </KeyValueWrapper>
                    );
                })}
                <Button
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    mode="primary"
                    size="small"
                    width="fit-content"
                    borderRadius="24px"
                >
                    {Icon(Add, { size: 21, color: "var(--Color-Text-Inverse)" })}
                </Button>
            </FormWrapper>
        );
    },
);

export default AddKeyValuePair;
