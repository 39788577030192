function addScript(document, id, src, innerHTML) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.id = id;
    if (innerHTML) {
        script.innerHTML = innerHTML;
    }
    document.getElementsByTagName("head")[0].appendChild(script);
    return script;
}

function formatBytes(bytes, decimals = 2) {
    if (isNaN(bytes)) {
        return NaN;
    }
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

function abbreviateNumber(number) {
    if (!number) return number;

    let SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

    // what tier? (determines SI symbol)
    let tier = Math.floor(Math.log10(Math.abs(number)) / 3);

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix
    return parseFloat(scaled.toFixed(1)) + suffix;
}

function formatToBytes(size, unit) {
    size = parseFloat(size);
    const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    if (!units.includes(unit)) return NaN;
    if (size === 0) return 0;
    const k = 1024;
    return size * Math.pow(k, units.indexOf(unit));
}
function isZoneSlug(text) {
    return text.match(/^[a-zA-Z0-9_-]{6}$/);
}

function getImageUrlWithOptions(url, pattern) {
    let urlArr = url.replace("https://", "").split("/");
    const isVersionedUrl = ["v1", "v2"].includes(urlArr[1]);
    if (isZoneSlug(urlArr[2 + isVersionedUrl])) {
        // URL is a zone URL: [cdn_url,[ version,] cloudName, zone-slug, pattern, relative_path...]
        // 3 + true/false
        urlArr[3 + isVersionedUrl] = pattern;
    } else {
        // URL is a zone less (default zone) URL: [cdn_url,[ version,] cloudName, pattern, relative_path...]
        // 2 + true/false
        urlArr[2 + isVersionedUrl] = pattern;
    }
    return `https://${urlArr.join("/")}`;
}

const isValidDate = function (date) {
    if (date) {
        // First check for the pattern
        if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date)) return false;

        var parts = date.split("-");
        var day = parseInt(parts[2], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }
    return false;
};

const formFieldPayloadTransformer = function (schema = {}) {
    const transformedArray = [];
    let { properties, required } = schema;
    if (properties) {
        // eslint-disable-next-line guard-for-in
        for (const p in properties) {
            const prop = properties[p];
            const obj = {};
            obj.id = p;
            const validations = {};
            if (required?.includes(p)) {
                validations.required = {
                    value: true,
                    message: "Field is required",
                };
            }
            if (typeof prop?.minLength === "number") {
                validations.minLength = {
                    value: prop?.minLength,
                    message: "Invalid data",
                };
            }
            if (prop?.inputType) {
                obj.type = prop?.inputType;
            }
            if (prop?.description) {
                obj.description = prop?.description;
            }
            if (prop?.inputEnum) {
                obj.data = prop?.inputEnum;
            }
            if (prop?.label) {
                obj.displayName = prop?.label;
            }
            if (prop?.apiCache) {
                obj.apiCache = prop?.apiCache;
            }
            obj.validations = validations;
            transformedArray.push(obj);
        }
    }
    return transformedArray;
};
const flattenJSON = (obj = {}, res = {}, extraKey = "") => {
    for (const key in obj) {
        if (typeof obj[key] !== "object") {
            res[extraKey + key] = obj[key];
        } else {
            flattenJSON(obj[key], res, `${extraKey}${key}.`);
        }
    }
    return res;
};
const jsonToCsv = (jsonArray) => {
    if (jsonArray.length === 0) return "";

    // Flatten each object in the array
    const flattenedArray = jsonArray.map((obj) => flattenJSON(obj));

    // Gather all unique keys from all objects
    const allKeys = [...new Set(flattenedArray.flatMap(Object.keys))];

    const csv = flattenedArray.map((row) =>
        allKeys.map((key) => JSON.stringify(row[key] || "")).join(","),
    );
    csv.unshift(allKeys.join(","));

    return csv.join("\r\n");
};

const getMimeTypeOfFile = {
    "pdfFiles": "application/pdf",
    "docFiles": "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // i.e. docx file
    "excelFiles": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // i.e. xlsx file
    "csvFiles": "text/csv",
    "textFiles": "text/plain",
    "pptFiles": "application/vnd.openxmlformats-officedocument.presentationml.presentation", // i.e. pptx file
    "markdownFiles": "text/markdown",
    "htmlFiles": "text/html",
    "jsonFiles": "application/json",
};

/**
 * Human readable elapsed or remaining time (example: 3 minutes ago)
 * @param  {Date|Number|String} date A Date object, timestamp or string parsable with Date.parse()
 * @param  {Date|Number|String} [nowDate] A Date object, timestamp or string parsable with Date.parse()
 * @param  {Intl.RelativeTimeFormat} [trf] A Intl formater
 * @return {string} Human readable elapsed or remaining time
 */
function fromNow(
    date,
    nowDate = Date.now(),
    rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" }),
) {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const YEAR = 365 * DAY;
    const MONTH = YEAR / 12;
    const intervals = [
        { ge: YEAR, divisor: YEAR, unit: "year" },
        { ge: MONTH, divisor: MONTH, unit: "month" },
        { ge: WEEK, divisor: WEEK, unit: "week" },
        { ge: DAY, divisor: DAY, unit: "day" },
        { ge: HOUR, divisor: HOUR, unit: "hour" },
        { ge: MINUTE, divisor: MINUTE, unit: "minute" },
        { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
        { ge: 0, divisor: 1, text: "just now" },
    ];
    const now = typeof nowDate === "object" ? nowDate.getTime() : new Date(nowDate).getTime();
    const diff = now - (typeof date === "object" ? date : new Date(date)).getTime();
    const diffAbs = Math.abs(diff);
    for (const interval of intervals) {
        if (diffAbs >= interval.ge) {
            const x = Math.round(Math.abs(diff) / interval.divisor);
            const isFuture = diff < 0;
            return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text;
        }
    }
}

const fileDatasources = ["files", "csvFiles", "textFiles", "docFiles", "pdfFiles", "excelFiles"];
const MAX_SUGGESTIONS = 10;

exports.addScript = addScript;
exports.formatBytes = formatBytes;
exports.formatToBytes = formatToBytes;
exports.getImageUrlWithOptions = getImageUrlWithOptions;
exports.isValidDate = isValidDate;
exports.isZoneSlug = isZoneSlug;
exports.abbreviateNumber = abbreviateNumber;
exports.formFieldPayloadTransformer = formFieldPayloadTransformer;
exports.flattenJSON = flattenJSON;
exports.jsonToCsv = jsonToCsv;
exports.getMimeTypeOfFile = getMimeTypeOfFile;
exports.fileDatasources = fileDatasources;
exports.max_suggestions = MAX_SUGGESTIONS;
exports.fromNow = fromNow;
