import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import List from "common/components/List";
import Button from "common/components/Button";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Body_M_Reg, SubTitle1, SubTitle2, Body_XS_Reg } from "common/global-styled-components";
import { Breakpoints } from "GlobalStyle";
import LoaderIcon from "common/components/LoaderIcon";
import AdvancedPagination from "common/components/AdvancedPagination";
import NoDataFound from "common/components/NoDataFound";
import debounce from "lodash.debounce";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import Icon from "common/components/Icon";
import { AddCircle, Trash, Refresh } from "iconsax-react";
import { SOURCE_TYPES } from "pages/DataSources/types";
import { useModal } from "../../../common/components/NewModal";
import DataSourceCreate from "pages/DataSources/Create";
import DataSourceService from "services/datasource.service";
import { toast } from "react-toastify";
import DatasourceSelectionList from "./SelectCopilot";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import Tooltip from "common/components/Tooltip";
import { useQuery, usePageTitle } from "common/utils";
import { deleteCopilotDataSources } from "common/redux/actions/datasourceActions";
import DatasourceViewPage from "pages/DataSources/PopupEdit";
import SyncService from "services/sync.service";
import { useLottie } from "lottie-react";
import checkmarkgif from "common/assets/checkmarkgif.json";
import crossmarkgif from "common/assets/crossmarkgif.json";
import BuildStatus from "common/components/BuildStatus";
import ProgressBar from "common/components/ProgressBar";
import JoyRide from "common/components/JoyRide";
import useMediaQuery from "common/components/useMediaQuery";

const DataSourceListWrapper = styled.label`
    display: flex;
    padding: 14px 0px;
    align-items: center;
    cursor: pointer;
    border-bottom: ${(props) => !props.$isLastItem && "1px solid var(--Color-Border-Subtle)"};

    background-color: transparent;
`;

const ServiceName = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-L);
    font-size: var(--body-lead-d);
    font-weight: 400;
    flex: 2;
    line-height: var(--line-height-25);
    color: var(--Color-Text-Default);
`;

const NoDataFoundWrapper = styled(NoDataFound)`
    // height: unset;
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Default);
`;

const NoDatasourceCreateButton = styled(Button)`
    color: var(--Color-Text-Inverse);
    border-radius: var(--Size-CornerRadius-L);
    padding: var(--Size-Padding-L) 24px 12px 24px;
    display: flex;
    gap: var(--Size-Gap-M);
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-L);
    justify-content: space-between;
`;

const DataSourceMiscelleneous = styled.div`
    cursor: pointer;
`;

const DatasourceSyncSchedule = styled.div`
    display: flex;
    flex-direction: column;
`;

const CustomModalContent = css`
    width: 1096px;
    height: 100%;
    max-height: unset;
    max-width: unset;
`;
const Stats = styled.div`
    background: var(--Color-Background-Default);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XL);
    align-self: stretch;
    gap: 20px;
    flex-grow: 1;
`;

const ActionIcons = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
    gap: var(--Size-Gap-L);
`;

const LoaderBox = styled.div`
    display: flex;
    padding: var(--Size-Padding-XXL) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-XXXL);
`;

const DatasourceName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100px;
    }
`;

const HeaderTitle = styled(SubTitle2)`
    @media (max-width: ${Breakpoints.mobile}px) {
    }
`;

const TestCopilotDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-M);
`;

const _Body_XS_Reg = styled(Body_XS_Reg)`
    font-style: italic;
`;
const CheckMarkLoader = () => {
    const style = {
        height: 100,
    };

    const options = {
        animationData: checkmarkgif,
        loop: false,
        autoplay: true,
    };

    const { View } = useLottie(options, style);

    return View;
};

const CrossMarkLoader = () => {
    const style = {
        height: 100,
    };

    const options = {
        animationData: crossmarkgif,
        loop: false,
        autoplay: true,
    };

    const { View } = useLottie(options, style);

    return View;
};

const steps = [
    {
        content: (
            <TestCopilotDiv>
                Test and chat with your Copilot here. Any changes made to the theme, icon , or name
                will reflect here live.
                <_Body_XS_Reg>
                    Pro tip: A detailed persona on do's and don'ts enhances your Copilot.
                </_Body_XS_Reg>
            </TestCopilotDiv>
        ),
        placement: "left",
        target: "#playground-copilot",
        title: "Copilot Preview",
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightPadding: 0,
    },
    {
        content: "Use this tab to access where all you can publish or embed your Copilot.",
        placement: "bottom",
        target: "#deploy_navbar",
        title: "Publishing Your Copilot",
        disableBeacon: true,
        disableOverlayClose: true,
        spotlightClicks: true,
        hideFooter: false,
        spotlightPadding: 0,
        hideBackButton: true,
        locale: { last: "Done" },
    },
];

const DatasourceListPage = ({ allValues, setValue, setShowCopilotPlaygroundWidget }) => {
    const { orgId, agentId } = useParams();
    const copilotList = useSelector((state) => state?.copilotDetails?.copilotList);
    const [searchName, setSearchName] = useState("");
    const [datasourceList, setDatasourcesList] = useState([]);
    const [unlinkDatasource, setUnlinkDatasource] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const query = useQuery();
    const history = useHistory();
    const [isPageLoading, setPageLoading] = useState(true);
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [datasourceId, selectedDatasourceId] = useState("");
    const [syncPollingpercentage, setSyncPollingpercentage] = useState("0%");
    const [syncStatus, setSyncStatus] = useState("");
    const [runTourGuide, setRunTourGuide] = useState(false);
    const isTablet = useMediaQuery(`(max-width: ${Breakpoints.tablet}px)`);

    const [timeoutId, setTimeoutId] = useState(null); // State variable to store timeout ID
    const primaryDatasource = query.get("primaryDatasource");
    const redirectedFromTryForFree = query.get("redirectedFromTryForFree");
    const dispatch = useDispatch();
    usePageTitle(`Copilot Platform - Manage Datasource`);
    const debounceFetch = useMemo(() => debounce(fetchDatasourcesServices, 300), []);
    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
    }, []);
    useEffect(() => {
        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetch(orgId, queryString, searchName);
        }
    }, [currentPage, searchName]);
    const {
        show: showDatasourceCreateDialog,
        hide: hideDatasourceCreateDialog,
        RenderModal: RenderDatasourceCreate,
    } = useModal();

    const {
        show: showUnlinkDatasourceDialog,
        hide: hideUnlinkDatasourceDialog,
        RenderModal: RenderUnlinkDatasourceDialog,
    } = useModal();

    const { show: showDatasourceDetailsView, RenderModal: RenderDatasourceDetailsView } =
        useModal();

    const {
        show: showDataSourceSyncStatus,
        hide: hideDataSourceSyncStatus,
        RenderModal: RenderDataSourceSyncStatus,
    } = useModal();

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";

        return [pageQuery, pageSizeQuery].join("&");
    };

    const setDatasourcesListData = (items, pageNum, searchValue) => {
        setDatasourcesList(items);
        if (primaryDatasource) {
            // added to open create datasource dialog when a first copilot is created
            showDatasourceCreateDialog();
            return;
        }
        if (copilotList?.copilots?.length == 1 && redirectedFromTryForFree) {
            // added to run the joyride tour when a user is redirected from try for free flow
            setRunTourGuide(true);
        }
    };

    function fetchDatasourcesServices(orgId, queryString, searchValue) {
        // const query = `${queryString}&active=${true}`;
        DataSourceService.getCopilotDataSources(orgId, agentId, queryString)
            .then((res) => {
                setDatasourcesListData(res.data.items, res.data.page.page, searchValue);
                setPageDetails(res.data.page);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong", {
                    autoClose: 2000,
                });
                setDatasourcesList([]);
                setPageDetails({});
            })
            .finally(() => {
                setPageLoading(false);
            });
    }

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    const datasourceSyncPolling = (id) => {
        const pollingInterval = 2000; // 2 seconds
        const poll = () => {
            SyncService.syncDataSourceStatus(orgId, agentId, id)
                .then((res) => {
                    const { data: payload } = res;
                    if (Object.keys(payload).length == 0) {
                        hideDataSourceSyncStatus();
                        stopPolling();
                        return;
                    }
                    const completedPercentage = payload?.result?.completion;
                    // Check if sync is complete
                    if (completedPercentage === "100%" || payload?.status === "FAILED") {
                        setSyncPollingpercentage("100%");
                        setSyncStatus(payload.status);
                        payload?.status === "FAILED"
                            ? toast.error("Sync Failed")
                            : toast.success("Datasource connected successfully");
                    } else {
                        setSyncPollingpercentage(completedPercentage || "10%");
                        setTimeoutId(setTimeout(poll, pollingInterval));
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        };

        poll();
    };

    const stopPolling = () => {
        clearTimeout(timeoutId);
    };

    const handleDataSourceCreate = (datasource) => {
        if (datasource) {
            setDatasourcesList((prevDatasources) => {
                const newDatasources = [datasource, ...prevDatasources];
                const newValues = JSON.stringify({
                    name: datasource.name,
                    value: datasource.id,
                });
                setValue("dataSources", [newValues, ...allValues.dataSources]);

                return newDatasources;
            });
        }
        hideDatasourceCreateDialog();
        if (["files", "api"].includes(datasource?.type)) {
            return;
        }
        showDataSourceSyncStatus();
        datasourceSyncPolling(datasource?.id);
    };

    const handleUnlinkDatasource = () => {
        deleteCopilotDataSources(orgId, agentId, unlinkDatasource?.id, dispatch)
            .then(() => {
                setDatasourcesList((prevDatasources) => {
                    const newDatasources = prevDatasources.filter(
                        (val) => val.id !== unlinkDatasource?.id,
                    );
                    setValue("dataSources", newDatasources);

                    return newDatasources;
                });
                const queryString = `${getQueryParams()}&q=${searchName}`;
                fetchDatasourcesServices(orgId, queryString, searchName);
                toast.success("Data source deleted successfully", {
                    autoClose: 2000,
                });
                hideUnlinkDatasourceDialog();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong", {
                    autoClose: 2000,
                });
            });
    };

    /**
     * Redirects to the edit page of a specific data source.
     * @param {string} id - The ID of the data source.
     * @returns {void}
     */
    const routeToDataSource = (id) => {
        selectedDatasourceId(id);
        showDatasourceDetailsView();
    };

    // update name of the datasource
    const handleLocalDataSourceUpdate = useCallback((datasourceId, updateData) => {
        setDatasourcesList((prevData) =>
            prevData.map((_dsData) =>
                _dsData.id === datasourceId ? { ..._dsData, ...updateData } : _dsData,
            ),
        );
    }, []);

    function refreshDataSourceStatus(orgId, copilotId, datasourceId) {
        DataSourceService.getCopilotDataSource(orgId, copilotId, datasourceId)
            .then((res) => {
                handleLocalDataSourceUpdate(datasourceId, {
                    latestSync: res.data?.syncHistory,
                });

                toast.success("Data source refreshed successfully", {
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Unable to refresh datasource", {
                    autoClose: 2000,
                });
            });
    }

    return (
        <>
            <Stats>
                <List.ComponentHeader
                    $padding="0px"
                    $overallPadding="0px"
                    justifyContent="end"
                    actions={
                        <ActionWrapper>
                            <SearchPlaceHolder
                                searchName={searchName}
                                handleSearchChange={handleSearchChange}
                                width="100%"
                            />

                            <DataSourceMiscelleneous
                                mode="secondary"
                                size="small"
                                data-tooltip-id="create-new-datasource"
                                onClick={showDatasourceCreateDialog}
                            >
                                {Icon(AddCircle, { size: 32, variant: "Bold" })}
                            </DataSourceMiscelleneous>
                            <Tooltip id="create-new-datasource"> Connect new data source</Tooltip>
                        </ActionWrapper>
                    }
                >
                    <HeaderTitle>Connected data sources: {datasourceList?.length || 0}</HeaderTitle>
                </List.ComponentHeader>
                <List.ListLayout $padding="0px">
                    {isPageLoading ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : datasourceList.length ? (
                        <>
                            {datasourceList.map((ds, i) => {
                                return (
                                    <List.ListItem
                                        key={ds.id}
                                        onClick={() => routeToDataSource(ds.id)}
                                    >
                                        <DataSourceListWrapper
                                            $isLastItem={datasourceList.length == i + 1}
                                        >
                                            <ServiceName>
                                                {
                                                    SOURCE_TYPES.find(
                                                        (val) => val.value === ds.type,
                                                    )?.icon
                                                }
                                                <DatasourceName>{ds.name}</DatasourceName>
                                            </ServiceName>
                                            <BuildStatus
                                                status={
                                                    ds?.latestSync?.status?.toLowerCase() ||
                                                    "created"
                                                }
                                            />

                                            <ActionIcons>
                                                <div
                                                    role="tooltip"
                                                    data-tooltip-id="sync"
                                                    data-tip
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        refreshDataSourceStatus(
                                                            orgId,
                                                            agentId,
                                                            ds?.id,
                                                        );
                                                    }}
                                                >
                                                    {Icon(Refresh, {
                                                        size: 20,
                                                    })}

                                                    <Tooltip id="sync">Refresh Datasource</Tooltip>
                                                </div>
                                                <div
                                                    role="tooltip"
                                                    data-tooltip-id="delete"
                                                    data-tip
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setUnlinkDatasource(ds);
                                                        showUnlinkDatasourceDialog();
                                                    }}
                                                >
                                                    {Icon(Trash, {
                                                        color: "var(--Color-Icon-Danger)",
                                                        size: 20,
                                                        variant: "Bold",
                                                    })}
                                                    <Tooltip id="delete">Delete Datasource</Tooltip>
                                                </div>
                                            </ActionIcons>
                                        </DataSourceListWrapper>
                                    </List.ListItem>
                                );
                            })}
                        </>
                    ) : (
                        <NoDataFoundWrapper>
                            {!searchName.length && (
                                <>
                                    <DataNotFoundMessage>
                                        Your Copilot is currently set up for new connections, ready
                                        to connect data sources.
                                    </DataNotFoundMessage>
                                    <NoDatasourceCreateButton
                                        mode="primary"
                                        size="small"
                                        onClick={showDatasourceCreateDialog}
                                    >
                                        {Icon(AddCircle, {
                                            color: "var(--Color-Text-Inverse)",
                                            size: 20,
                                            variant: "Bold",
                                        })}
                                        Connect
                                    </NoDatasourceCreateButton>
                                </>
                            )}
                        </NoDataFoundWrapper>
                    )}
                </List.ListLayout>
            </Stats>
            {datasourceList.length > 0 && (
                <AdvancedPagination
                    page={pageDetails}
                    pageSize={pageSize}
                    paginationInfo="datasource"
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                />
            )}
            {!isTablet && (
                <JoyRide
                    runTourGuide={runTourGuide}
                    steps={steps}
                    styles={{ buttonClose: { display: "none" } }}
                />
            )}
            <RenderDatasourceCreate showCancelButton={!primaryDatasource}>
                <DataSourceCreate
                    neededDatasources={[
                        "text",
                        "web",
                        "files",
                        "quip",
                        "git",
                        "googleDrive",
                        "notion",
                        "api",
                    ]}
                    callback={handleDataSourceCreate}
                    cancelButtonCallback={hideDatasourceCreateDialog}
                />
            </RenderDatasourceCreate>

            <RenderDatasourceDetailsView CustomModalContent={CustomModalContent}>
                <DatasourceViewPage
                    datasourceId={datasourceId}
                    updateDataSource={handleLocalDataSourceUpdate}
                />
            </RenderDatasourceDetailsView>

            <RenderDataSourceSyncStatus showCancelButton={false}>
                <ConfirmationDialog
                    title={
                        syncPollingpercentage == "100%"
                            ? `Training ${syncStatus !== "FAILED" ? "Completed" : "Failed"}`
                            : "Training your data"
                    }
                    content={
                        <DatasourceSyncSchedule>
                            {syncPollingpercentage == "100%" ? (
                                syncStatus !== "FAILED" ? (
                                    <CheckMarkLoader />
                                ) : (
                                    <>
                                        <CrossMarkLoader />
                                        Try again after some time
                                    </>
                                )
                            ) : (
                                <LoaderBox>
                                    <Body_M_Reg>
                                        Datasource is currently undergoing training. Please be with
                                        us while this process completes.
                                    </Body_M_Reg>
                                    <ProgressBar percentage={syncPollingpercentage} />
                                </LoaderBox>
                            )}
                        </DatasourceSyncSchedule>
                    }
                    okText={
                        syncPollingpercentage == "100%" &&
                        syncStatus !== "FAILED" &&
                        primaryDatasource
                            ? `Test and deploy`
                            : null
                    }
                    handleOkClick={() => {
                        copilotList?.copilots?.length == 1
                            ? (hideDataSourceSyncStatus(), setRunTourGuide(true))
                            : history.push(`/org/${orgId}/cp/${agentId}/deploy`);
                    }}
                    cancelText={
                        syncPollingpercentage == "100%"
                            ? syncStatus !== "FAILED" && primaryDatasource
                                ? "Skip for now"
                                : "OK"
                            : "Hide and Explore"
                    }
                    handleCancelClick={() => {
                        stopPolling();
                        if (syncStatus !== "FAILED") {
                            const { pathname } = history.location;
                            history.push(pathname);
                        }
                        hideDataSourceSyncStatus();
                        primaryDatasource &&
                            copilotList?.copilots?.length === 1 &&
                            setRunTourGuide(true);
                    }}
                    data-testid="edit-agent-confirmation-dialog"
                />
            </RenderDataSourceSyncStatus>

            <RenderUnlinkDatasourceDialog maxWidth="500px" showCancelButton={false}>
                <ConfirmationDialog
                    title="Delete data source?"
                    content={
                        <>
                            Are you sure you want to delete"
                            <strong title={unlinkDatasource.name}>{unlinkDatasource.name}</strong>"
                            data source? This action cannot be undone and will permanently remove
                            all associated training data and configurations.
                        </>
                    }
                    okText={"Delete"}
                    cancelText={"Cancel"}
                    handleOkClick={handleUnlinkDatasource}
                    handleCancelClick={hideUnlinkDatasourceDialog}
                    type="danger"
                    data-testid="edit-agent-confirmation-dialog"
                />
            </RenderUnlinkDatasourceDialog>
        </>
    );
};

export default DatasourceListPage;
