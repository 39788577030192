import React, { Suspense, useEffect, useState } from "react";
import { hot } from "react-hot-loader";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import SidePanel from "./common/components/SidePanel";
import PrivateRoute from "./common/components/PrivateRoute";
import ScrollToTop from "./common/components/ScrollToTop";
import ToastNotification from "./common/components/ToastNotification";
import EventEmitter from "./common/events";
import {
    AuthorisedUser,
    SetFcRestoredId,
    ScriptInitiated,
    widgetLoaded,
} from "./common/events/events";
import { UserAgentAction } from "./common/redux/actions/useragentAction";
import { PlatformThemeAction } from "./common/redux/actions/platformthemeAction";
import environment from "./environment";
import routes from "./routes";
import AuthService from "./services/auth.service";
import useRedirectUrl from "./common/hooks/useRedirectUrl";

const SuspenseDiv = styled.div`
    height: 100vh;
    background: var(--Color-Background-Default);
`;
const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
`;
const ComponentWrapper = styled.div`
    overflow: auto;
    scrollbar-gutter: stable;
    width: 100%;
    height: 100%;
`;

const App = () => {
    useRedirectUrl();
    const scrollTopVisible = useSelector((state) => state.menuDetails.scrollTopVisible);
    const showTopNavbar = useSelector((state) => state.menuDetails.showTopNavbar);
    const { isUserLoggedIn, user } = useSelector((state) => state.authenticationDetails);
    const org = useSelector((state) => state?.organizationDetails);
    const planId = org?.currentSubscription?.planId;
    const orgId = org?.organization?._id;
    const dispatch = useDispatch();

    useEffect(() => {
        // const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

        let theme = localStorage.getItem("_theme") || "light";

        document.body.classList.add(`theme-${theme}`);

        // Dispatch actions
        dispatch({
            type: PlatformThemeAction.AddMode,
            payload: theme,
        });

        dispatch({
            type: UserAgentAction.AddAgent,
            payload: window.navigator.userAgent,
        });

        // added to get to know Freshchat widget is loaded in website
        // used in UploadStatusToast component to position the toast
        // also in storage page to place mobile upload icon
        EventEmitter.on(widgetLoaded, function () {
            dispatch({
                type: UserAgentAction.SetFcWidgetLoaded,
                payload: true,
            });
        });
    }, []);

    const updateMeta = (updateOpts) => {
        AuthService.updateMeta(updateOpts)
            .then(() => {})
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (environment.VALUE !== "development" && environment.GTM_CONTAINER_ID) {
            window["dataLayer"] = window["dataLayer"] || [];
            window["dataLayer"].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js",
            });
            const f = document.getElementsByTagName("script")[0],
                j = document.createElement("script"),
                dl = "&l=dataLayer";
            j.async = true;
            j.defer = true;
            const i = environment.GTM_CONTAINER_ID;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f?.parentNode?.insertBefore(j, f);
        }
    }, []);

    useEffect(() => {
        if (isUserLoggedIn && orgId && planId) {
            EventEmitter.dispatch(AuthorisedUser, user);
            window.onUsersnapLoad = function (api) {
                api.init({
                    user: {
                        userId: user?._id,
                        email: user?.email,
                        plan: planId,
                    },
                    custom: {
                        orgId,
                    },
                });
                window.Usersnap = api;
            };
            setTimeout(() => {
                window?.customerBot?.("event", "setUser", {
                    hostId: user?._id,
                    email: user?.email,
                    name: `${user?.firstName} ${user?.lastName}`,
                    profilePicUrl: user?.profilePicUrl,
                    additionalFields: {
                        orgId,
                        planId,
                    },
                });
            }, 1500);
        }
    }, [isUserLoggedIn, orgId, planId]);

    return (
        <Wrapper data-testid="app">
            <BrowserRouter>
                <ThemeProvider theme={{ scrollTopVisible }}>
                    <ScrollToTop />
                    <SidePanel />
                    <ComponentWrapper>
                        <Suspense fallback={<SuspenseDiv></SuspenseDiv>}>
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route path={route.path} key={index}>
                                        <PrivateRoute
                                            component={route.component}
                                            guard={route.guard}
                                            path={route.path}
                                        />
                                    </Route>
                                ))}
                            </Switch>
                        </Suspense>
                    </ComponentWrapper>
                </ThemeProvider>
            </BrowserRouter>
            <ToastNotification />
            <div
                id="modal-root"
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    zIndex: 1000,
                }}
            ></div>
            <div id="customerWidgetScript" />
        </Wrapper>
    );
};

export default hot(module)(App);
