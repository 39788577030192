import { PhoneNumberUtil } from "google-libphonenumber";

export const loginValidation = {
    emailValidation: {
        required: true,
        pattern:
            /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    },
    passwordValidation: {
        required: true,
    },
};
export const signupValidation = {
    emailValidation: {
        required: true,
        pattern:
            /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    },
    passwordValidation: {
        required: true,
        pattern:
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/,
    },
    firstNameValidation: {
        minLength: { value: 3, message: "Minimum 3 characters required" },
        required: { value: true, message: "First Name is required" },
        validate: (value) => value?.trim() !== "" || "first name cannot be empty.",
    },
    lastNameValidation: {
        minLength: { value: 3, message: "Minimum 3 characters required" },
        required: { value: true, message: "Last Name is required" },
        validate: (value) => value?.trim() !== "" || "last name cannot be empty.",
    },
};

export const organizationValidation = {
    nameValidation: {
        maxLength: { value: 50, message: "Organization Name cannot be more than 50 characters" },
        minLength: { value: 3, message: "Minimum 3 characters required" },
        required: { value: true, message: "Organization Name is required" },
        validate: (value) => value?.trim() !== "" || "Organization name cannot be empty.",
    },
    cloudNameValidation: {
        required: true,
        pattern: /^([A-Za-z\d])([\w\d\-]+$)/,
    },
    otherIndustryValidation: {
        required: true,
    },
};

export const IngressPathValidation = {
    required: { value: true, message: "Path is required" },
    pattern: {
        value: /^\/[A-Za-z0-9\/]+$/,
        message: "Invalid Path",
    },
};

export const CommitIdValidation = {
    pattern: {
        value: /[a-zA-Z0-9]+$/,
        message: "Invalid Commit Id",
    },
};
export const userSearchValidation = {
    emailValidation: {
        required: true,
        pattern:
            /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    },
};
export const cidrValidation = {
    cidrValidation: {
        required: true,
        pattern: /^([0-9]{1,3}.){3}[0-9]{1,3}(\/([4-9]|[1][0-9]|[2][0-3]))?$/,
    },
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
    try {
        const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);
        return phoneUtil.isValidNumber(parsedPhone);
    } catch (error) {
        return false; // Return the error message when parsing fails
    }
};

const validateGitHubUrl = (value) => {
    const regex =
        /^(https:\/\/github\.com\/|git@github\.com:)([\w.-]+)\/([\w.-]+)(\/(tree|blob)\/[\w.-]+(\/[\w.-]+)*)?(\.git)?$/;
    return regex.test(value);
};

const validateGitLabUrl = (value) => {
    const regex =
        /^(https:\/\/gitlab\.com\/|git@gitlab\.com:)([\w.-]+)\/([\w.-]+)(\/(tree|blob)\/[\w.-]+(\/[\w.-]+)*)?(\.git)?$/;
    return regex.test(value);
};

const validateBitbucketUrl = (value) => {
    const regex =
        /^(https:\/\/bitbucket\.org\/|git@bitbucket\.org:)([\w.-]+)\/([\w.-]+)(\/(src|tree|blob)\/[\w.-]+(\/[\w.-]+)*)?(\.git)?$/;
    return regex.test(value);
};

const validateAzureUrl = (value) => {
    const regex =
        /^(git@ssh\.dev\.azure\.com:v3\/[\w.-]+\/[\w.-]+\/[\w.-]+|https:\/\/[\w.-]+@dev\.azure\.com\/[\w.-]+\/[\w.-]+\/_git\/[\w.-]+(\/(tree|blob)\/[\w.-]+(\/[\w.-]+)*)?)$/;
    return regex.test(value);
};

export const validateRepoUrl = (value) => {
    if (
        validateGitHubUrl(value) ||
        validateBitbucketUrl(value) ||
        validateGitLabUrl(value) ||
        validateAzureUrl(value)
    ) {
        return true;
    }
    return "Invalid repository URL. Please enter a valid git repository URL.";
};
