import { combineReducers } from "redux";
import authReducer from "./reducers/auth.reducer";
import orgReducer from "./reducers/organization.reducer";
import teamReducer from "./reducers/team.reducer";
import copilotReducer from "./reducers/copilot.reducer";
import menuReducer from "./reducers/menu.reducer";
import userAgentReducer from "./reducers/useragent.reducer";
import datasourceReducer from "./reducers/datasource.reducer";
import keyReducer from "./reducers/key.reducer";
import skillReducer from "./reducers/skill.reducer";
import platformThemeReducer from "./reducers/platformtheme.reducer";

const rootReducer = combineReducers({
    authenticationDetails: authReducer,
    organizationDetails: orgReducer,
    teamDetails: teamReducer,
    menuDetails: menuReducer,
    copilotDetails: copilotReducer,
    userAgentDetails: userAgentReducer,
    datasourceDetails: datasourceReducer,
    skillReducer: skillReducer,
    keyReducer: keyReducer,
    platformThemeDetails: platformThemeReducer,
});

export default rootReducer;
