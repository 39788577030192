import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import { useModal } from "common/components/NewModal";
import styled, { css } from "styled-components";
import TextInput from "common/components/TextInput";
import { Body3, InputLabel, Body_XS_Reg, PageTitleWrapper } from "common/global-styled-components";
import { Breakpoints } from "GlobalStyle";
import { usePageTitle } from "common/utils";
import Divider from "common/components/Divider";
import SlideToggle from "common/components/SlideToggle";
import Icon from "common/components/Icon";
import { Clock } from "iconsax-react";
import startCase from "lodash/startCase";
import debounce from "lodash/debounce";
import CustomDropdown from "common/components/CustomDropdown";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import { components } from "react-select";
import moment from "moment-timezone";
import { getTeam } from "common/redux/actions/teamActions";
import {
    Label,
    BodyWrapper,
    InputWrapper,
    StyledErrorSkeleton,
    StyledError,
    Title,
    Column,
    Row,
    RadioGroup,
    StyledCheckbox,
    ResizableIunput,
} from "./index.js";

const TimeWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-XL);
`;

const TimeSelectionOptionsWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-S);
    justify-content: start;
    align-items: center;
`;

const TimeSelectionDiffInfo = styled.span`
    font-size: var(--body-3-d);
    font-weight: bold;
    opacity: 0.5;
`;

const DayHeader = styled(Body_XS_Reg)`
    color: ${(props) => props?.disabled && "var(--Color-Text-Disabled)"};
    width: 75px;
    flex-shrink: 0;
`;

const daysOfWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

// Get all timezones
const timeZoneList = moment.tz.names();

const formattedTimezones = timeZoneList.map((timezone) => {
    // Get timezone offset
    const offset = moment.tz(timezone).format("Z");

    // Get timezone label
    const timeZoneLabel = `${timezone} ${offset}`;

    return {
        label: timeZoneLabel,
        value: timezone,
    };
});

const _24hrIntervals = [];

for (let hour = 0; hour < 24; hour++) {
    const formattedHour = String(hour).padStart(2, "0");
    _24hrIntervals.push(
        {
            label: `${formattedHour}:00`,
            value: `${formattedHour}:00`,
        },
        {
            label: `${formattedHour}:30`,
            value: `${formattedHour}:30`,
        },
    );

    if (hour === 23) {
        _24hrIntervals.push({
            label: "23:59",
            value: "23:59",
        });
    }
}

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            {Icon(Clock, {
                size: 20,
                color: "var(--Color-Icon-Default)",
            })}
        </components.DropdownIndicator>
    );
};

const initializeToTimes = () => {
    const resp = {};
    daysOfWeek.forEach((day) => {
        resp[day] = [];
    });
    return resp;
};

const CustomerSupport = ({ register, allValues, errors, setValue, control }) => {
    const [teamMembers, setTeamMembers] = useState([]);
    const { orgId } = useParams();
    const dispatch = useDispatch();
    const {
        show: showHumanHandoffConfirmation,
        hide: hideHumanHandoffConfirmation,
        RenderModal: RenderHumanHandoffConfirmation,
    } = useModal();
    const [daysToTimeSelectionOptions, setDaysToTimeSelectionOptions] = useState(initializeToTimes);

    useEffect(() => {
        const users = teamMembers?.reduce?.((accumulator, currentValue) => {
            if (
                allValues?.customerSupport?.emailNotifications?.users?.includes?.(
                    currentValue.value,
                )
            ) {
                accumulator.push({
                    value: currentValue.value,
                    label: currentValue.label,
                });
            }
            return accumulator;
        }, []);
        setValue("__customerSupport.emailNotifications.users", users);
    }, [teamMembers, allValues?.customerSupport?.emailNotifications?.users]);

    useEffect(() => {
        const { days } = allValues?.customerSupport || {};
        if (days && Object.keys(days)?.length) {
            const res = {};
            for (const key in days) {
                if (Object.prototype.hasOwnProperty.call(days, key)) {
                    const { from } = days[key];
                    res[key] = getToSelectionOptions(from);
                }
            }

            if (Object.keys(res).length) {
                setDaysToTimeSelectionOptions(res);
            }
        }
    }, [allValues?.customerSupport]);

    usePageTitle(`Copilot Platform - Copilot Human Handoff`);

    const getTeams = (page = 1, pageSize = 1000) => {
        getTeam(orgId, null, page, pageSize, dispatch)
            .then((res) => {
                const members = res.data?.team?.map((i) => {
                    return {
                        value: i.userId,
                        label: `${i.firstName?.trim?.() || ""} ${i.lastName?.trim?.() || ""}`,
                    };
                });
                setTeamMembers(members);
            })
            .catch(() => {
                setTeamMembers([]);
            })
            .finally(() => {});
    };

    const debouncedGetTeams = useMemo(() => debounce(getTeams, 700, { trailing: true }), []);

    const handleHandoffSettingState = (e) => {
        if (e) {
            setValue("customerSupport.active", e);
        } else {
            showHumanHandoffConfirmation();
        }
    };

    const handleConfirmation = (confirm) => {
        if (confirm) {
            setValue("customerSupport.active", false);
        }
        hideHumanHandoffConfirmation();
    };

    const getCustomerSupportDay = (i) => allValues?.customerSupport?.days?.[i];

    const getDatetimeFromLabel = (value) => new Date(`1970-01-01T${value}:00`);

    const getDiffBetweenFromAndToTime = (from, to) => {
        const fromTime = getDatetimeFromLabel(from);
        const toTime = getDatetimeFromLabel(to);

        let diffMinutes = (toTime - fromTime) / (1000 * 60);
        if (diffMinutes < 0) {
            diffMinutes += 24 * 60; // Handle wrap-around for next day
        }

        const diffHours = Math.floor(diffMinutes / 60);
        diffMinutes = Math.floor(diffMinutes % 60);

        let result = "";
        if (diffHours > 0) {
            result += `${diffHours} hr${diffHours > 1 ? "s" : ""} `;
        }
        if (diffMinutes > 0) {
            result += `${diffMinutes} min${diffMinutes > 1 ? "s" : ""}`;
        }

        return result.trim() || "0 mins";
    };

    function getToSelectionOptions(selectedFromTime) {
        return selectedFromTime
            ? _24hrIntervals.reduce((acc, _i) => {
                  if (getDatetimeFromLabel(_i.value) > getDatetimeFromLabel(selectedFromTime)) {
                      acc.push({
                          ..._i,
                      });
                  }
                  return acc;
              }, [])
            : _24hrIntervals;
    }

    const handleFromTimeChange = (i) => (data) => {
        setValue(`customerSupport.days.${i}.from`, data.value);
        setValue(`customerSupport.days.${i}.to`, null);

        const selectedFromTime = getCustomerSupportDay(i)?.from;
        const toTimeOptions = getToSelectionOptions(selectedFromTime);
        setDaysToTimeSelectionOptions((prevState) => ({ ...prevState, [i]: toTimeOptions }));
    };

    const selectedTimezone =
        formattedTimezones?.find((i) => i.value === allValues?.customerSupport?.timezone) || null;

    const formatOptionLabel =
        (i) =>
        ({ label, value }, { context }) => {
            if (context === "menu") {
                const fromTime = getCustomerSupportDay(i)?.from;
                if (fromTime) {
                    return (
                        <TimeSelectionOptionsWrapper>
                            {label}
                            <TimeSelectionDiffInfo>
                                ({getDiffBetweenFromAndToTime(fromTime, value)})
                            </TimeSelectionDiffInfo>
                        </TimeSelectionOptionsWrapper>
                    );
                }
            }
            return label;
        };

    return (
        <>
            <BodyWrapper id="human-handoff-settings-tab">
                <Title>
                    <PageTitleWrapper>
                        <Label>Human Handoff Settings</Label>
                        <Body3>
                            Manage & Empower your support by smoothly taking over conversations with
                            Human to Handoff.
                        </Body3>
                    </PageTitleWrapper>
                    <SlideToggle
                        checked={allValues?.customerSupport?.active}
                        onChange={(e) => {
                            handleHandoffSettingState(e);
                        }}
                    />
                </Title>
                <Divider style={{ margin: "0px" }}></Divider>
                <InputWrapper>
                    <InputLabel>Timezone</InputLabel>
                    <ResizableIunput>
                        <Controller
                            defaultValue={
                                formattedTimezones?.find((t) => t.value === moment.tz.guess())
                                    ?.value
                            }
                            name={`customerSupport.timezone`}
                            control={control}
                            rules={{
                                required: allValues?.customerSupport?.active
                                    ? "Timezone selection is required"
                                    : false,
                            }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select Timezone"}
                                    options={formattedTimezones}
                                    showArrow={false}
                                    onChange={(_data) => {
                                        setValue("customerSupport.timezone", _data.value);
                                    }}
                                    value={selectedTimezone}
                                    register={register}
                                    role="customerSupport.timezone"
                                    type="customerSupport.timezone"
                                    label="customerSupport.timezone"
                                    name="customerSupport.timezone"
                                    isDisabled={!allValues?.customerSupport?.active}
                                />
                            )}
                        />
                    </ResizableIunput>
                    {errors?.customerSupport?.timezone && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">
                                {errors?.customerSupport.timezone?.message}
                            </StyledError>
                        </StyledErrorSkeleton>
                    )}
                </InputWrapper>
                <InputWrapper
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "var(--Size-Gap-L)",
                    }}
                >
                    <InputLabel>Select Working Days</InputLabel>
                    <RadioGroup>
                        {daysOfWeek?.map((i) => {
                            return (
                                <StyledCheckbox key={i}>
                                    <TextInput
                                        key={`customerSupport.days.${i}.active`}
                                        width="auto"
                                        register={register}
                                        type="checkbox"
                                        name={`customerSupport.days.${i}.active`}
                                        label={`customerSupport.days.${i}.active`}
                                        disabled={!allValues?.customerSupport?.active}
                                    ></TextInput>
                                    <Body3>{startCase(i)}</Body3>
                                </StyledCheckbox>
                            );
                        })}
                    </RadioGroup>
                </InputWrapper>
                <Column style={{ gap: "var(--Size-Gap-XL)", overflowX: "scroll" }}>
                    {daysOfWeek.map((i) => {
                        const supportDayData = getCustomerSupportDay(i);
                        const isDayDisabled =
                            !supportDayData?.active || !allValues?.customerSupport?.active;

                        const fromSelectedValue = _24hrIntervals?.find(
                            (i) => i.value === supportDayData?.from,
                        );
                        const toSelectedValue = _24hrIntervals?.find(
                            (i) => i.value === supportDayData?.to,
                        );

                        const timeDifference = getDiffBetweenFromAndToTime(
                            supportDayData?.from,
                            supportDayData?.to,
                        );

                        const showTimeDifference =
                            allValues?.customerSupport?.active && supportDayData?.active;

                        return (
                            <TimeWrapper key={i}>
                                <DayHeader disabled={isDayDisabled}>{startCase(i)}</DayHeader>

                                <Row>
                                    {/* from time */}
                                    <Controller
                                        name={`customerSupport.days.${i}.from`}
                                        control={control}
                                        rules={{
                                            required: allValues?.customerSupport?.active
                                                ? "From selection is required"
                                                : false,
                                        }}
                                        defaultValue={_24hrIntervals[18].value}
                                        render={({ field }) => (
                                            <CustomDropdown
                                                {...field}
                                                wrapperWidth={"155px"}
                                                components={{ DropdownIndicator }}
                                                ref={null}
                                                placeholder={
                                                    allValues?.customerSupport?.days?.[i]?.from ||
                                                    "Select"
                                                }
                                                options={_24hrIntervals}
                                                showArrow={false}
                                                onChange={handleFromTimeChange(i)}
                                                value={fromSelectedValue}
                                                register={register}
                                                role={`customerSupport.days.${i}.from`}
                                                type={`customerSupport.days.${i}.from`}
                                                label={`customerSupport.days.${i}.from`}
                                                name={`customerSupport.days.${i}.from`}
                                                validation={{ required: true }}
                                                isDisabled={isDayDisabled}
                                            />
                                        )}
                                    />
                                    <Body_XS_Reg>to</Body_XS_Reg>
                                    {/* to time */}
                                    <Controller
                                        name={`customerSupport.days.${i}.to`}
                                        control={control}
                                        rules={{
                                            required: allValues?.customerSupport?.active
                                                ? "To selection is required"
                                                : false,
                                        }}
                                        defaultValue={_24hrIntervals[36].value}
                                        render={({ field }) => {
                                            return (
                                                <CustomDropdown
                                                    {...field}
                                                    wrapperWidth={"155px"}
                                                    components={{ DropdownIndicator }}
                                                    ref={null}
                                                    placeholder={
                                                        getCustomerSupportDay(i)?.to || "Select"
                                                    }
                                                    options={daysToTimeSelectionOptions?.[i]}
                                                    showArrow={false}
                                                    onChange={(_data) => {
                                                        setValue(
                                                            `customerSupport.days.${i}.to`,
                                                            _data.value,
                                                        );
                                                    }}
                                                    value={
                                                        getCustomerSupportDay(i)?.to
                                                            ? toSelectedValue
                                                            : null
                                                    }
                                                    register={register}
                                                    role={`customerSupport.days.${i}.to`}
                                                    type={`customerSupport.days.${i}.to`}
                                                    label={`customerSupport.days.${i}.to`}
                                                    name={`customerSupport.days.${i}.to`}
                                                    validation={{ required: true }}
                                                    isDisabled={isDayDisabled}
                                                    formatOptionLabel={formatOptionLabel(i)}
                                                />
                                            );
                                        }}
                                    />
                                    {showTimeDifference && timeDifference && (
                                        <Body_XS_Reg
                                            style={{
                                                color: "var(--Color-Text-Subtlest)",
                                                textWrap: "nowrap",
                                            }}
                                        >
                                            {timeDifference}
                                        </Body_XS_Reg>
                                    )}
                                </Row>
                            </TimeWrapper>
                        );
                    })}
                </Column>
                <RenderHumanHandoffConfirmation maxWidth="500px" showCancelButton={false}>
                    <ConfirmationDialog
                        title="Disable Human Handoff"
                        content={
                            "If you turn off Human Handoff, your active conversations with your users will also turn off. To proceed, click on OK."
                        }
                        okText={"OK"}
                        cancelText={"Cancel"}
                        type={"danger"}
                        handleOkClick={() => handleConfirmation(true)}
                        handleCancelClick={() => handleConfirmation(false)}
                        data-testid="edit-human-handoff-confirmation-dialog"
                    />
                </RenderHumanHandoffConfirmation>
                <InputWrapper style={{ width: "50%" }}>
                    <InputLabel>Human Agent Alias</InputLabel>
                    <TextInput
                        data-testid="customerSupport.agentAlias"
                        role="customerSupport.agentAlias"
                        autoComplete="off"
                        placeholder="e.g Agent/Supervisor"
                        key={`customerSupport.agentAlias`}
                        width="auto"
                        register={register}
                        {...register("customerSupport.agentAlias", {
                            setValueAs: (value) => value.trim(),
                        })}
                        type="text"
                        name={`customerSupport.agentAlias`}
                        label={`customerSupport.agentAlias`}
                        disabled={!allValues?.customerSupport?.active}
                    />
                    <Body3>
                        Set the name that Copilot will display to users when notifying them that a
                        human agent is taking over the conversation.
                    </Body3>
                    {errors?.customerSupport?.agentAlias && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">
                                {errors.customerSupport.agentAlias.message}
                            </StyledError>
                        </StyledErrorSkeleton>
                    )}
                </InputWrapper>
            </BodyWrapper>
        </>
    );
};

export default CustomerSupport;
