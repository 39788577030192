import React from "react";

export default function WhatsappIcon({ size, color, ...rest }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.78906 0.833496C12.1832 0.833496 14.4332 1.76433 16.1232 3.45516C19.6166 6.9485 19.6166 12.6318 16.1232 16.1252C14.4107 17.8385 12.1049 18.7452 9.76906 18.7452C8.49573 18.7452 7.21406 18.476 6.0149 17.921C5.66156 17.7793 5.33073 17.646 5.09323 17.646C4.8199 17.6477 4.4524 17.7743 4.0974 17.8968C3.36906 18.1468 2.4624 18.4585 1.79156 17.7902C1.12323 17.121 1.43156 16.2168 1.6799 15.4893C1.8024 15.131 1.92823 14.761 1.92823 14.481C1.92823 14.251 1.8174 13.9577 1.6474 13.5352C0.0865622 10.1643 0.808229 6.10183 3.45573 3.456C5.14573 1.76516 7.3949 0.833496 9.78906 0.833496ZM9.7899 2.0835C7.72906 2.0835 5.79323 2.88516 4.33906 4.34016C2.06073 6.61683 1.44073 10.1127 2.7949 13.0402C2.9899 13.5227 3.17823 13.9927 3.17823 14.481C3.17823 14.9685 3.01073 15.4593 2.86323 15.8927C2.74156 16.2493 2.5574 16.7877 2.67573 16.906C2.79156 17.026 3.33323 16.8368 3.69073 16.7143C4.1199 16.5677 4.60656 16.3993 5.08906 16.396C5.5724 16.396 6.02823 16.5793 6.51073 16.7735C9.46656 18.1402 12.9624 17.5185 15.2399 15.2418C18.2449 12.2352 18.2449 7.34433 15.2399 4.33933C13.7849 2.88433 11.8499 2.0835 9.7899 2.0835ZM13.079 9.30241C13.539 9.30241 13.9123 9.67491 13.9123 10.1357C13.9123 10.5966 13.539 10.9691 13.079 10.9691C12.619 10.9691 12.2423 10.5966 12.2423 10.1357C12.2423 9.67491 12.6115 9.30241 13.0715 9.30241H13.079ZM9.73831 9.30241C10.1983 9.30241 10.5716 9.67491 10.5716 10.1357C10.5716 10.5966 10.1983 10.9691 9.73831 10.9691C9.27831 10.9691 8.90165 10.5966 8.90165 10.1357C8.90165 9.67491 9.26998 9.30241 9.73081 9.30241H9.73831ZM6.39731 9.30241C6.85731 9.30241 7.23065 9.67491 7.23065 10.1357C7.23065 10.5966 6.85731 10.9691 6.39731 10.9691C5.93731 10.9691 5.56065 10.5966 5.56065 10.1357C5.56065 9.67491 5.92981 9.30241 6.38981 9.30241H6.39731Z"
                fill={color}
            />
        </svg>
    );
}
