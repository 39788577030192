import React from "react";
const EmailIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size}
            height={props.size}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M11.332 14.1666H4.66536C2.23203 14.1666 0.832031 12.7666 0.832031 10.3333V5.66659C0.832031 3.23325 2.23203 1.83325 4.66536 1.83325H11.332C13.7654 1.83325 15.1654 3.23325 15.1654 5.66659V10.3333C15.1654 12.7666 13.7654 14.1666 11.332 14.1666ZM4.66536 2.83325C2.7587 2.83325 1.83203 3.75992 1.83203 5.66659V10.3333C1.83203 12.2399 2.7587 13.1666 4.66536 13.1666H11.332C13.2387 13.1666 14.1654 12.2399 14.1654 10.3333V5.66659C14.1654 3.75992 13.2387 2.83325 11.332 2.83325H4.66536Z"
                fill={props.color}
            />
            <path
                d="M7.9992 8.57998C7.4392 8.57998 6.87254 8.40665 6.4392 8.05331L4.35253 6.38665C4.1392 6.21331 4.0992 5.89998 4.27254 5.68665C4.44587 5.47331 4.75921 5.43332 4.97254 5.60665L7.0592 7.27332C7.56587 7.67998 8.42586 7.67998 8.93253 7.27332L11.0192 5.60665C11.2325 5.43332 11.5525 5.46665 11.7192 5.68665C11.8925 5.89998 11.8592 6.21998 11.6392 6.38665L9.55254 8.05331C9.12587 8.40665 8.5592 8.57998 7.9992 8.57998Z"
                fill={props.color}
            />
        </svg>
    );
};

export default EmailIcon;
