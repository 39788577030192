import React from "react";
import styled from "styled-components";

const StyledDivider = styled.div`
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    flex-flow: row;
    color: var(--Color-Text-Subtlest);
    width: 100%;
    > label {
        align-self: baseline;
        flex-grow: 2;
        padding 0px 12px;
        white-space: nowrap;
    }
    > hr {
        margin-top: 10px;
        width: 100%;
        border: 0;
        height: 1px;
        background: var(--Color-Border-Default);
    }
    > :first-child {
        margin-right: 5px;
    }
    > :last-child {
        margin-left: 5px;
    }
`;

function DividerHr({ label, children, ...props }) {
    return (
        <StyledDivider id="divider" {...props}>
            {label ? (
                <>
                    <hr />
                    <label>{label}</label>
                    <hr />
                </>
            ) : (
                <hr />
            )}
        </StyledDivider>
    );
}

export default DividerHr;
