import React from "react";
import styled from "styled-components";
import ShowMorePagination from "./ShowMorePagination";

const Pagination = ({ page, loadPage, isLoading, children }) => {
    return (
        <>
            {children}
            <ShowMorePagination page={page} loadPage={loadPage} isLoading={isLoading} />
        </>
    );
};

export default Pagination;
