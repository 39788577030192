import { useLocation, useHistory } from "react-router";
import { useEffect } from "react";
import urlJoin from "url-join";
import moment from "moment";
import environment from "../../environment";
import { sign } from "services/interceptors/signature";
import AuthService from "services/auth.service";
import Pixelbin from "@pixelbin/core";

export function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const getDurations = (endTime, startTime) => {
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    const hours = duration.asHours().toString().split(".")[0].padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");
    return `${hours}h:${minutes}m:${seconds}s`;
};

export function convertEpoch(epoch) {
    epoch *= 1000;
    const date = new Date(epoch);
    const year = date.getFullYear();
    if (year === 1970) {
        return "NA";
    }
    return date.getDate() + ", " + MONTHS[date.getMonth()] + ", " + year;
}

export function toUpperCase(s) {
    return s.toUpperCase()[0] + s.slice(1);
}

function base64Encode(text) {
    return Buffer.from(text).toString("base64");
}

export function generateSignature(url, payload) {
    const { method, body, headers } = payload;
    const { host, pathname, search } = new URL(url);
    const signingOptions = {
        method: method && method.toUpperCase(),
        host: host,
        path: pathname + search,
        body: body,
        headers,
    };
    sign(signingOptions);
    payload = {
        ...payload,
        headers: {
            "x-cplt-signature": signingOptions.headers["x-cplt-signature"],
            "x-cplt-param": base64Encode(signingOptions.headers["x-cplt-param"]),
        },
    };
    return payload;
}

export function handleScroll(container, dir = "left") {
    if (dir.toLowerCase() === "left") {
        container.scrollLeft += container.clientWidth;
    } else {
        container.scrollLeft -= container.clientWidth;
    }
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const useRemoveQueryParam = () => {
    const history = useHistory();
    const location = useLocation();

    const removeQueryParam = (param) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete(param);
        history.replace({ search: queryParams.toString() });
    };

    return removeQueryParam;
};

export function getDocLink(path) {
    return urlJoin(environment.SAMUI_MAIN_DOMAIN, path);
}

export const selKeys = (keys, obj) =>
    obj &&
    Object.entries(obj)
        .filter(([key]) => keys.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const uploadStorageAssets = async (orgId, data, file) => {
    try {
        let {
            data: { method, url, fields, cdn_path, storage_type },
        } = await AuthService.getSignedURL(orgId, data);
        if (storage_type === "pixelbin") {
            await Pixelbin.upload(file, { url, fields });
        } else {
            await AuthService.uploadAssets({ method, url, fields, file });
        }
        return cdn_path;
    } catch (error) {
        throw error;
    }
};

export const widgetScriptText = `<script type="application/javascript">
(function(w,d,s,o,f,js,fjs){w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments);};(js=d.createElement(s)),(fjs=d.getElementsByTagName(s)[0]);js.id=o;js.src=f;js.async=1;js.referrerPolicy = "origin";fjs.parentNode.insertBefore(js,fjs);})(window,document,"script","copilot","${environment.NEO_SCRIPT_DOMAIN}/v1/copilot.min.js?tkn=:tokenId");
copilot("init",{});
</script>`;

export const webIframeEmbedCode = `<iframe
        title="{TITLE}"
        src="{URL}"
        style="border:none;"
        loading="lazy"
        allow="microphone;camera;speaker;clipboard-read;clipboard-write;geolocation;"
        width="700px"  // set your own width
        height="360px"  // set your own height
      ></iframe>`;

export const removeDuplicatedById = (jsonArray) => {
    const uniqueIds = new Set();
    const result = [];

    for (const item of jsonArray) {
        const id = item.id;

        if (!uniqueIds.has(id)) {
            uniqueIds.add(id);
            result.push(item);
        }
    }

    return result;
};

export function usePageTitle(title) {
    useEffect(() => {
        document.title = title ?? "Copilot";
        // This is the cleanup function
        return () => {
            document.title = "Copilot"; // Reset to default title
        };
    }, [title]);
}

export function useCanonicalLink(link) {
    useEffect(() => {
        let canonicalLink = document.querySelector('link[rel="canonical"]');
        const defaultCanonicalLink = canonicalLink ? canonicalLink.href : "";

        if (!canonicalLink && link) {
            canonicalLink = document.createElement("link");
            canonicalLink.rel = "canonical";
            document.head.appendChild(canonicalLink);
        }

        if (canonicalLink) {
            canonicalLink.href = link || defaultCanonicalLink;
        }

        return () => {
            if (canonicalLink) {
                document.head.removeChild(canonicalLink); // Remove the link tag
            }
        };
    }, [link]);
}

export function normalizeQueryParam(value) {
    if (value === "null" || value === "undefined") {
        return null;
    }
    return value;
}

export function storeRedirectUrl(redirectUrl) {
    if (redirectUrl && typeof window !== "undefined")
        sessionStorage.setItem("redirectUrl", redirectUrl);
}

export function clearRedirectUrl() {
    sessionStorage.removeItem("redirectUrl");
}
export function getRedirectUrl() {
    let redirectUrl = sessionStorage.getItem("redirectUrl");
    return redirectUrl ? redirectUrl : undefined;
}

export const getStoredUTMParameters = () => {
    const utmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
        "entry_page",
        "ref",
    ];
    const utmData = {};

    utmParams.forEach((param) => {
        const value = sessionStorage.getItem(param);
        if (value) {
            utmData[param] = value;
        }
    });
    return utmData;
};
