import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import environment from "../../environment";
import { Breakpoints } from "GlobalStyle";
import { useLocation } from "react-router-dom";
import { Body_XS_Med } from "common/global-styled-components";

const Wrapper = styled.div`
    border: 12px solid var(--Color-Background-Action);
    border-top: 36px solid var(--Color-Border-Action);
    border-radius: 16px;
    width: 365px;
    height: calc(100vh - 125px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-XS);
    position: fixed;
    right: 12px;
    transition: all 2.5s ease;

    @media (max-width: ${Breakpoints.tablet}px) {
        display: none !important;
    }
`;
const PlaygroundWrapper = styled.div`
    transition: all 2.5s ease;
`;
const Styled_Body_XS_Med = styled(Body_XS_Med)`
    color: var(--Color-Text-Inverse);
    margin-top: -20px;
`;

function CopilotPlayground({ token, showWidget }, ref) {
    const path = useLocation();
    const [restartCounter, setRestartCounter] = useState(0);
    const [isAssistantMode, setIsAssistantMode] = useState(false);

    const widgetUrl = `${environment.NEO_SCRIPT_DOMAIN}/v1/copilot.min.js?tkn=${token ?? ""}`;
    // const widgetUrl = `http://localhost:9000/widget.js?tkn=${token ?? ""}`;
    useEffect(() => {
        const isAssistant = /^\/org\/\d+\/cp\/[a-z0-9]+\/threads$/.test(path.pathname);
        setIsAssistantMode(isAssistant);
        if (isAssistant !== isAssistantMode) {
            restart();
        }

        let js;
        const widgetElement = document.getElementById("playground-widget");
        if (showWidget) {
            if (widgetElement) {
                widgetElement.style.border = "none";
                widgetElement.style.borderRadius = "16px";
                widgetElement.style.display = "block";
            }
            if (!window.agentId) {
                (function (w, d, s, o, f, fjs) {
                    w[o] =
                        w[o] ||
                        function (...args) {
                            (w[o].q = w[o].q || []).push(args);
                        };
                    js = d.createElement(s);
                    fjs = d.getElementsByTagName(s)[0];
                    js.id = o;
                    js.src = f;
                    js.async = 1;
                    js.onload = function () {};
                    fjs.parentNode.insertBefore(js, fjs);
                })(window, document, "script", "agentId", widgetUrl);
                window.agentId("init", {
                    minimized: false,
                    element: widgetElement,
                    disableMinimized: true,
                    disableDraggable: true,
                    styles: {
                        mainDivContainer: { position: "unset" },
                        widgetHeight: "calc(100vh - 155px)",
                    },
                    isReadOnly: isAssistant,
                });
            }
        } else {
            if (widgetElement) {
                widgetElement.style.display = "none";
            }
        }

        // Return a cleanup function
        return () => {
            // Remove the copilot script
            if (js && js.parentNode) {
                js.parentNode.removeChild(js);
            }
        };
    }, [widgetUrl, showWidget, restartCounter, path]);

    useEffect(() => {
        return () => {
            if (window?.agentId) window?.agentId("destroy");
        };
    }, []);

    const restart = () => {
        if (window?.agentId) {
            window?.agentId("destroy");
            setRestartCounter(restartCounter + 1);
        }
    };

    useImperativeHandle(ref, () => ({
        restart,
    }));

    return (
        <Wrapper id="playground-copilot">
            <Styled_Body_XS_Med>Test Your Copilot here</Styled_Body_XS_Med>
            <PlaygroundWrapper id="playground-widget"></PlaygroundWrapper>
        </Wrapper>
    );
}

export default forwardRef(CopilotPlayground);
