// src/components/CheckboxGroup.js
import React, { useState } from "react";
import styled from "styled-components";

const GroupTitle = styled.div`
    font-weight: bold;
    margin-top: 10px;
`;

const GroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-L);
`;

const GroupItems = styled.div`
    padding-left: var(--Size-Padding-XXXL);
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
`;
const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
    margin-right: 10px;
    cursor: pointer;
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    margin: 5px 0;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    max-height: 520px;
    overflow: scroll;
`;

export const Checkbox = ({ label, checked, onChange }) => (
    <Label>
        <StyledCheckbox checked={checked} onChange={onChange} />
        {label}
    </Label>
);
const CheckboxGroupItem = ({ group, groupChecked, onGroupChange, onItemChange }) => (
    <GroupWrapper>
        <GroupTitle>
            <Checkbox label={group.title} checked={groupChecked} onChange={onGroupChange} />
        </GroupTitle>
        <GroupItems>
            {group.items.map((item) => (
                <Checkbox
                    key={item.id}
                    label={item.label}
                    checked={item.checked}
                    onChange={() => onItemChange(group.id, item.id)}
                />
            ))}
        </GroupItems>
    </GroupWrapper>
);

const CheckboxGroup = ({ state, setState }) => {
    const handleGroupChange = (groupId) => {
        const newState = state.map((group) => {
            if (group.id === groupId) {
                const newChecked = !group.items.every((item) => item.checked);
                return {
                    ...group,
                    items: group.items.map((item) => ({ ...item, checked: newChecked })),
                };
            }
            return group;
        });
        setState(newState);
    };

    const handleItemChange = (groupId, itemId) => {
        const newState = state.map((group) => {
            if (group.id === groupId) {
                return {
                    ...group,
                    items: group.items.map((item) =>
                        item.id === itemId ? { ...item, checked: !item.checked } : item,
                    ),
                };
            }
            return group;
        });
        setState(newState);
    };

    return (
        <Wrapper>
            {state.map((group) => (
                <CheckboxGroupItem
                    key={group.id}
                    group={group}
                    groupChecked={group.items.every((item) => item.checked)}
                    onGroupChange={() => handleGroupChange(group.id)}
                    onItemChange={handleItemChange}
                />
            ))}
        </Wrapper>
    );
};

export default CheckboxGroup;
