import TextInput from "common/components/TextInput";
import Icon from "common/components/Icon";
import styled from "styled-components";
import { SearchNormal1, ArrowDown2, CloseCircle } from "iconsax-react";
import { Breakpoints } from "GlobalStyle";

const SearchInput = styled(TextInput)`
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: 50px;
    padding-left: 50px;
    padding-right: 50px;
    height: 40px;
`;

const SearchInputWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: ${(props) => (props.width ? props.width : "50%")};
    max-width: ${(props) => props.maxWidth};
    @media (max-width: ${Breakpoints.mobile}px) {
        width: unset;
    }
`;

const IconWrapper = styled.div`
    position: absolute;
    margin-top: 4px;
    left: 1rem;
    z-index: 1;
`;

export default function SearchPlaceHolder({
    searchName,
    handleSearchChange,
    placeholder = "Search...",
    width,
    maxWidth,
}) {
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearchChange(e);
        }
    };

    const handleCancelClick = (e) => {
        e.target.value = "";
        handleSearchChange(e);
    };

    return (
        <SearchInputWrapper width={width} maxWidth={maxWidth}>
            <IconWrapper>
                {Icon(SearchNormal1, { size: 20, color: "var(--Color-Icon-Disabled)" })}
            </IconWrapper>

            <SearchInput
                name="searchName"
                label="searchName"
                value={searchName}
                placeholder={placeholder}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
            />

            {!!searchName && (
                <IconWrapper
                    style={{ right: "1rem", left: "unset", cursor: "pointer" }}
                    onClick={handleCancelClick}
                >
                    {Icon(CloseCircle, { size: 20, color: "var(--Color-Icon-Disabled)" })}
                </IconWrapper>
            )}
        </SearchInputWrapper>
    );
}
