import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import TextInput from "common/components/TextInput";
import Tooltip from "common/components/Tooltip";
import InfoIcon from "common/assets/InfoIcon";
import { ReactComponent as SuccessIcon } from "common/assets/svgs/Tick Alt.svg";
import { Body3, InputLabel, PageTitleWrapper, Body_XS_Reg } from "common/global-styled-components";
import { SketchPicker } from "react-color";
import { Breakpoints } from "GlobalStyle";
import ColorPicker from "common/assets/ColorPicker";
import { usePageTitle } from "common/utils";
import Divider from "common/components/Divider";
import startCase from "lodash/startCase";
import { Label, BodyWrapper, InputWrapper, StyledErrorSkeleton, StyledError } from "./index.js";

const ColorsPallete = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-XL);
    flex-wrap: wrap;
    > * {
        cursor: pointer;
    }
`;

const Circle = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #2c324e;
    position: relative; // Add this line
    overflow: hidden; // Add this line if you want the SVG to be cut off at the circle's edge
    background: ${(props) =>
        `conic-gradient(
        from 180deg,
        ${props.primaryColor ? props.primaryColor : `yellow`} 0% 50%,
        ${props.activeButtonColor ? props.activeButtonColor : `#0a0e18`} 50% 75%,
        ${props.secondaryColor ? props.secondaryColor : `#141822`} 75% 100%
    );
   `};
    transition: all 0.3s ease;
`;

const ColorsWrapper = styled.div`
    display: grid;
    gap: var(--Size-Gap-XXL);
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-content: center;
    position: relative;

    @media (max-width: ${Breakpoints.mobile}px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
`;

const Description = styled(InfoIcon)`
    margin-left: 4px;
    margin-bottom: -2px;
    width: var(--body-1-d);
    fill: var(--light-300);
    cursor: pointer;
    height: auto;
`;

const SuccessIconWrapper = styled.div`
    position: absolute;
    top: 55%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 1; // make sure the icon is above the background
`;
const ColorPickerWrapper = styled.div`
    position: absolute;
    z-index: 2;
    right: auto;
`;

const CircleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-M);
    align-items: center;
`;

const Theme = styled(Body_XS_Reg)`
    color: var(--Color-Text-Default);
`;

const CircleDefault = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #2c324e;
    background: ${(props) => props.color1};
    position: relative;
    overflow: hidden;
    &:after,
    &:before {
        content: "";
        position: absolute;
        width: 50%;
        height: 50%;
        // border-radius: 50%;
    }
    &:before {
        bottom: 0;
        right: 0;
        background: ${(props) => props.color2};
    }
    &:after {
        top: 0;
        right: 0;
        background: ${(props) =>
            `linear-gradient(90deg, ${props?.color3Start} 0%, ${props?.color3End} 100%)`};
    }
`;

const StyledInput = styled(TextInput)`
    width: 80%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const THEMES = [
    {
        id: "Default",
        primaryColor: "#0A0E18",
        secondaryColor: "#141822",
        primaryFontColor: "#ffffff",
        secondaryFontColor: "#ffffff",
        activeButtonColor: "linear-gradient(90deg, #7F00FF 0%, #3D05DD 100%)",
        borderColor: "#21263A",
        isLinearGradient: true,
    },
    {
        id: "Cosmic Chill",
        primaryColor: "#f4f6f8",
        secondaryColor: "#ebebeb",
        primaryFontColor: "#000000",
        secondaryFontColor: "#1F1F1F",
        activeButtonColor: "linear-gradient(90deg, #7F00FF 0%, #3D05DD 100%)",
        borderColor: "#D3D3D3",
        isLinearGradient: true,
    },
    {
        id: "Sunset Bliss",
        primaryColor: "#FFDFC8",
        secondaryColor: "#FFF2E999",
        primaryFontColor: "#000000",
        secondaryFontColor: "#1F1F1F",
        activeButtonColor: "linear-gradient(90deg, #F2D048 12.95%, #F56C62 90.11%)",
        borderColor: "#FFB986",
        isLinearGradient: true,
    },
    {
        id: "Starry Night",
        primaryColor: "#FFFFFF",
        secondaryColor: "#F5F5F5",
        primaryFontColor: "#141414",
        secondaryFontColor: "#141414",
        activeButtonColor: "#3535F3",
        borderColor: "#E0E0E0",
        isLinearGradient: false,
    },
    {
        id: "Magenta Mirage",
        primaryColor: "#EFFDFA",
        secondaryColor: "#003A4326",
        primaryFontColor: "#000000",
        secondaryFontColor: "#000000",
        activeButtonColor: "#E60787",
        borderColor: "#003A4326",
        isLinearGradient: false,
    },
];

const colorFields = [
    { name: "primaryColor", tooltip: "Used for entire chat window." },
    { name: "secondaryColor", tooltip: "Used for responses and chat boxes background" },
    { name: "primaryFontColor", tooltip: "Used for primary fonts color" },
    { name: "secondaryFontColor", tooltip: "Used for secondary fonts color" },
    { name: "activeButtonColor", tooltip: "Used for active buttons color" },
    { name: "borderColor", tooltip: "Used for border color" },
];

const Appearance = ({ register, allValues, errors, setValue }) => {
    const [themeId, setThemeId] = useState("default");
    const [openColorModal, setColorModal] = useState(false);
    const colorPickerRef = useRef(null);
    useEffect(() => {
        setThemeId(allValues?.themeId);
    }, [allValues?.themeId]);
    usePageTitle(`Copilot Platform - Copilot Appearance Settings`);
    useEffect(() => {
        function handleClickOutside(event) {
            if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
                setColorModal("");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [colorPickerRef]);

    const handleClick = (event, fieldName) => {
        if (colorPickerRef.current && colorPickerRef.current.contains(event.target)) {
            setColorModal(null);
        } else {
            setColorModal(fieldName);
        }
    };

    const getColors = (val) => {
        let presentTheme = THEMES.find((value) => value.id == val.id);
        colorFields.forEach((colorValue) => {
            setValue(colorValue.name, presentTheme[colorValue.name]);
            window?.agentId?.("event", "setThemeConfig", {
                [colorValue.name]: presentTheme[colorValue.name],
            });
        });
        setValue("themeId", val.id);
    };

    return (
        <>
            <BodyWrapper id="appearance-settings-tab">
                <PageTitleWrapper>
                    <Label>Copilot Appearance</Label>
                    <Body3>Personalize your copilot look and theme.</Body3>
                </PageTitleWrapper>
                <Divider style={{ margin: "0px" }}></Divider>
                <ColorsPallete>
                    {THEMES.map((val) => (
                        <CircleWrapper key={val.id}>
                            <Theme>{val.id}</Theme>
                            {val.isLinearGradient ? (
                                <CircleDefault
                                    color1={val.primaryColor}
                                    color2={val.secondaryColor}
                                    color3Start={val.activeButtonColor.match(/#[A-Fa-f0-9]{6}/g)[0]}
                                    color3End={val.activeButtonColor.match(/#[A-Fa-f0-9]{6}/g)[1]}
                                    onClick={() => {
                                        getColors(val);
                                    }}
                                >
                                    {themeId?.toLowerCase() === val.id?.toLowerCase() && (
                                        <SuccessIconWrapper>
                                            <SuccessIcon />
                                        </SuccessIconWrapper>
                                    )}
                                </CircleDefault>
                            ) : (
                                <Circle
                                    key={val.id}
                                    selected={themeId?.toLowerCase() === val.id?.toLowerCase()}
                                    onClick={() => getColors(val)}
                                    {...val}
                                >
                                    {themeId?.toLowerCase() === val.id?.toLowerCase() && (
                                        <SuccessIconWrapper>
                                            <SuccessIcon />
                                        </SuccessIconWrapper>
                                    )}
                                </Circle>
                            )}
                        </CircleWrapper>
                    ))}
                    <CircleWrapper>
                        <Theme>Custom</Theme>
                        <ColorPicker
                            width={"50px"}
                            height={"50px"}
                            onClick={() => {
                                setValue("themeId", "");
                            }}
                        />
                    </CircleWrapper>
                </ColorsPallete>
                {!themeId ? (
                    <ColorsWrapper>
                        {colorFields.map((field) => (
                            <InputWrapper key={field.name}>
                                <InputLabel>
                                    {startCase(field.name)}*
                                    <Description
                                        role="tooltip"
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={true}
                                        data-tooltip-id={field.name}
                                        data-tip
                                    />
                                    <Tooltip id={field.name}>{field.tooltip}</Tooltip>
                                </InputLabel>
                                <StyledInput
                                    data-testid={field.name}
                                    role={field.name}
                                    type={field.name}
                                    label={field.name}
                                    register={register}
                                    name={field.name}
                                    onChange={(val) => {
                                        setValue(field.name, val.currentTarget.value);
                                        window?.agentId?.("event", "setThemeConfig", {
                                            [field.name]: val.currentTarget.value,
                                        });
                                    }}
                                    placeholder={`Enter ${startCase(field.name)}`}
                                >
                                    <ColorPicker onClick={(e) => handleClick(e, field.name)} />
                                </StyledInput>
                                {errors[field.name] && (
                                    <StyledErrorSkeleton>
                                        <StyledError role="error">
                                            {errors[field.name].message}
                                        </StyledError>
                                    </StyledErrorSkeleton>
                                )}
                                {openColorModal === field.name && (
                                    <ColorPickerWrapper ref={colorPickerRef}>
                                        <SketchPicker
                                            color={allValues[field.name]}
                                            onChange={(newColor) => {
                                                setValue(field.name, newColor.hex);
                                                window?.agentId?.("event", "setThemeConfig", {
                                                    [field.name]: newColor.hex,
                                                });
                                            }}
                                        />
                                    </ColorPickerWrapper>
                                )}
                            </InputWrapper>
                        ))}
                    </ColorsWrapper>
                ) : (
                    ""
                )}
            </BodyWrapper>
        </>
    );
};

export default Appearance;
