import React from "react";

export default function DragHandle({ size, color = "var(--Color-Icon-Default)", ...rest }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox="0 0 10 18"
            {...rest}
        >
            <ellipse cx="1.66667" cy="2.33317" rx="1.66667" ry="1.66667" fill={color} />
            <ellipse cx="1.66667" cy="8.99967" rx="1.66667" ry="1.66667" fill={color} />
            <ellipse cx="1.66667" cy="15.6667" rx="1.66667" ry="1.66667" fill={color} />
            <ellipse cx="8.33463" cy="2.33317" rx="1.66667" ry="1.66667" fill={color} />
            <ellipse cx="8.33463" cy="8.99967" rx="1.66667" ry="1.66667" fill={color} />
            <ellipse cx="8.33463" cy="15.6667" rx="1.66667" ry="1.66667" fill={color} />
        </svg>
    );
}
