import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "common/components/Button";
import { useParams } from "react-router-dom";
import { DocumentCopy, ExportSquare } from "iconsax-react";
import { ReactComponent as SuccessIcon } from "common/assets/svgs/Tick Alt.svg";
import { ReactComponent as ErrorIcon } from "common/assets/svgs/Close Alt.svg";
import {
    Body_XXS_Reg,
    Body_XS_Bold,
    Body3,
    InputLabel,
    Body_XS_Reg,
    Body_L_Bold,
    StickyPageHeader,
    PageContent,
    StickyPageFooter,
    PageTitleWrapper,
    PageTitleHeader,
    PageTitleSubtitleWrapper,
} from "common/global-styled-components";
import TextInput from "common/components/TextInput";
import Icon from "common/components/Icon";
import CopilotService from "../../../services/copilot.service";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CustomDropdown from "common/components/CustomDropdown";
import copy from "copy-to-clipboard";
import environment from "environment";
import { usePageTitle } from "common/utils";
import { useSelector } from "react-redux";
import VoiceInfo from "./VoiceInfo";
import { FREE_PLANS } from "common/constants";
import Tooltip from "common/components/Tooltip";
import NavBar from "common/components/HorizontalNavBar";
import { isEqual } from "lodash";
import ScriptCopyModal from "../ScriptCopyModal";
import { webIframeEmbedCode } from "common/utils";

const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Size-Gap-XXL);
    ::-webkit-scrollbar {
        display: none !important;
    }
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const DomainInputWrapper = styled(TextInput)`
    // background: var(--Color-Background-Subtle);
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    justify-content: space-between;
    gap: var(--Size-Gap-M);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;
const InputWrapper = styled.div`
    width: 100%;
`;
const Label = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
`;

const Title = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
    text-align: left;
`;

const ToggleWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: var(--Size-Padding-S);
    align-items: center;
    border-radius: var(--Size-CornerRadius-L);
    width: fit-content;
    background: var(--Color-Background-Subtle);
`;

const Toggle = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-M) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-M);
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: var(--Size-CornerRadius-M);
    background: ${(props) => props.isSelected && "var(--Color-Background-Default)"};
    color: ${(props) => !props.isSelected && "var(--Color-Text-Subtlest)"};
`;

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--primary-grey-90);
    gap: var(--Size-Gap-XL);
`;

const SubDescription = styled(Body_XS_Reg)`
    padding-left: 24px;
`;

const Copy = styled.div`
    cursor: pointer;
`;

const Steps = styled(Body_XS_Reg)`
    display: flex;
    gap: var(--Size-Gap-L);
    align-items: center;
    flex-wrap: wrap;
    margin-left: 36px;
`;

const StepsValue = styled.div`
    display: flex;
    padding: var(--Size-Padding-S) 12px;
    align-items: center;
    gap: var(--Size-Gap-M);
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Subtle);
`;

const TitleWrapper = styled(Body_L_Bold)`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--Size-Gap-L);
`;

const HeaderSelector = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DOMAIN_VALIDATION_REGEX = /^(?:[a-z0-9-]+\.)+[a-z]{2,}$|^$/;
const HOSTING_SUBDOMAINS = ["ai", "talk", "chat", "bot"];

const TABS_IDENTIFIER = {
    GENERAL: "general",
    MANAGE_DOMAIN: "manageDomain",
    EMBED_IFRAME: "embediframe",
};
const TABS = [
    {
        displayName: "General",
        identifier: TABS_IDENTIFIER.GENERAL,
    },
    {
        displayName: "Manage Domain",
        identifier: TABS_IDENTIFIER.MANAGE_DOMAIN,
    },
    {
        displayName: "Embed as iframe",
        identifier: TABS_IDENTIFIER.EMBED_IFRAME,
    },
];

const HostedWebsite = ({
    type,
    oldAllValues,
    oldSetValue,
    modalCloseButtonCallback,
    handleSurfaceUpdate,
}) => {
    const { orgId, agentId } = useParams();
    const [currentButton, setCurrentButton] = useState("primary");
    const [editOption, setEditOption] = useState("general");
    const [domainsteps, setDomainSteps] = useState([]);
    const [isSaveChanged, setIsSaveChanged] = useState(false);
    const [isWesbiteChanged, setIsWebsiteChanged] = useState(false);
    const [iFrameScriptText, setiFrameScriptText] = useState(null);
    const [initialValue, setInitialValue] = useState({
        aiWebCall: false,
        customDomain: "",
        nativeAudio: true,
        predefinedDomain: "",
        subdomain: {
            label: "",
            value: "",
        },
        active: false,
    });
    const currentSubscription = useSelector(
        (state) => state?.organizationDetails?.currentSubscription,
    );
    const isFreePlan = FREE_PLANS.includes(currentSubscription?.planId);

    usePageTitle(`Copilot Platform - Manage Webpage Deployment`);
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        setValue,
        setError,
        reset,
    } = useForm({
        mode: "all",
        defaultValues: {},
    });

    const allValues = watch();
    useEffect(() => {
        CopilotService.getCopilotSurface(orgId, agentId, type)
            .then((val) => {
                let res = val.data;
                let path = HOSTING_SUBDOMAINS.find((val) =>
                    val.includes(res?.config?.predefinedDomain?.subdomain),
                );
                let data = {
                    ...res,
                    customDomain: res?.config?.customDomain?.name || "",
                    predefinedDomain: res?.config?.predefinedDomain?.slug || agentId,
                    subdomain: {
                        label: `.${path ? path : "ai"}.${environment.PRIMARY_DOMAIN}`,
                        value: `.${path ? path : "ai"}.${environment.PRIMARY_DOMAIN}`,
                    },
                    aiWebCall: res?.config?.aiWebCall ?? false,
                    nativeAudio: res?.config?.nativeAudio ?? true,
                };

                setInitialValue(data);
                reset(data, { keepDirty: true });
            })
            .catch((e) => {
                console.error(e);
                toast.error(
                    e?.response?.data?.message ||
                        "We encountered an issue while mapping your domain. Please try again later.",
                    {
                        autoClose: 2000,
                    },
                );
            });

        return () => {
            oldSetValue("isOtherSubmit", null);
        };
    }, []);

    const fields = [
        "aiWebCall",
        "customDomain",
        "nativeAudio",
        "predefinedDomain",
        "subdomain",
        "active",
    ];
    const primaryFields = ["aiWebCall", "nativeAudio"];
    const secondaryFields = ["customDomain", "predefinedDomain", "subdomain", "active"];
    useEffect(() => {
        let noChange;
        if (editOption == "manageDomain") {
            noChange = secondaryFields.every((key) =>
                isEqual(initialValue?.[key], allValues?.[key]),
            );
        } else {
            noChange = primaryFields.every((key) => isEqual(initialValue?.[key], allValues?.[key]));
        }
        setIsSaveChanged(!noChange);
    }, [allValues]);

    const updateHostedWebDeployment = () => {
        CopilotService.updateCopilotSurface(orgId, agentId, type, {
            active: allValues?.active || true,
            config: {
                predefinedDomain: {
                    slug: allValues?.predefinedDomain?.toLowerCase() || "",
                    subdomain: allValues?.subdomain?.value?.split(".")[1],
                },
                customDomain: {
                    name: allValues?.customDomain || "",
                },
                aiWebCall: allValues?.aiWebCall ?? false,
                nativeAudio: allValues?.nativeAudio ?? true,
            },
        })

            .then((val) => {
                setInitialValue(allValues);
                // check if hosted-web is there or not in the surfaces list
                const isHostedWebExists = oldAllValues?.surfaces?.includes(type);
                if (!isHostedWebExists) {
                    oldSetValue("surfaces", [...(oldAllValues?.surfaces ?? []), type]);
                }
                setValue("active", true);
                toast.success("Hosted Webpage Config added");
                handleSurfaceUpdate(val?.data || {});
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error updating hosted page details", {
                    autoClose: 2000,
                });
            });
    };
    const checkDomainStatus = () => {
        if (allValues.customDomain && !DOMAIN_VALIDATION_REGEX.test(allValues.customDomain)) {
            setError("customDomain", {
                type: "custom",
                message: "Please enter a valid domain",
            });
            return;
        }
        let data = {
            domain: allValues.customDomain || " ",
        };
        CopilotService.validateDomain(orgId, agentId, data)
            .then((res) => {
                setDomainSteps(res.data);
                setIsWebsiteChanged(false);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error loading steps", {
                    autoClose: 2000,
                });
                setIsWebsiteChanged(true);
            })
            .finally(() => {});
    };
    const checkDomainAvailability = () => {
        CopilotService.checkAvailabilityOfDomain(orgId, agentId, {
            slug: allValues.predefinedDomain,
            subdomain: allValues?.subdomain?.value?.split(".")[1],
        })
            .then((res) => {
                if (res?.data?.is_valid) {
                    toast.success("Domain Available", {
                        autoClose: 2000,
                    });
                    setIsWebsiteChanged(false);
                } else {
                    toast.error("Invalid Domain", {
                        autoClose: 2000,
                    });
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error validating domain", {
                    autoClose: 2000,
                });
            })
            .finally(() => {
                // setPageLoading(false);
            });
    };

    const copyToClipboard = (data) => {
        copy(data);
        toast.info("Copied to clipboard", { autoClose: 2000 });
    };

    const handleIFrameSrcChange = () => {
        let deployedWebUrl = `https://${initialValue?.predefinedDomain}${initialValue?.subdomain?.value}`;

        if (initialValue?.config?.customDomain) {
            const { name, verified } = initialValue?.config?.customDomain || {};

            if (verified && name?.trim()) {
                deployedWebUrl = `https://${name}`;
            }
        }

        const scriptText = webIframeEmbedCode
            .replace("{TITLE}", oldAllValues?.name || "")
            .replace("{URL}", deployedWebUrl);

        setiFrameScriptText(scriptText);
    };

    return (
        <DialogWrapper data-testid="edit-skills">
            <StickyPageHeader
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    /* flex-grow: 1, */
                    alignSelf: "flex-start",
                }}
            >
                <PageTitleWrapper>
                    <PageTitleHeader>Webpage</PageTitleHeader>
                    <PageTitleSubtitleWrapper>
                        Deploy your chatbot as a dedicated webpage
                    </PageTitleSubtitleWrapper>
                </PageTitleWrapper>
                <NavBar
                    route={false}
                    TABS={TABS}
                    activeTabIdentifier={editOption}
                    callback={(tab) => {
                        if (tab.identifier === TABS_IDENTIFIER.EMBED_IFRAME)
                            handleIFrameSrcChange();

                        setEditOption(tab?.identifier);
                    }}
                ></NavBar>
            </StickyPageHeader>

            <PageContent
                style={{
                    background: "transparent",
                    margin: "0px",
                    height: "465px",
                    overflowY: "scroll",
                    padding: "0px",
                    gap: "var(--Size-Gap-XXL)",
                    width: "100%",
                }}
            >
                {editOption == TABS_IDENTIFIER.GENERAL && (
                    <VoiceInfo
                        aiWebCall={allValues.aiWebCall}
                        nativeAudio={allValues.nativeAudio}
                        setValue={setValue}
                        allValues={allValues}
                    />
                )}
                {editOption == TABS_IDENTIFIER.MANAGE_DOMAIN && (
                    <Wrapper>
                        <HeaderSelector>
                            <Title>
                                {currentButton == "primary" ? (
                                    <>
                                        <TitleWrapper>
                                            Pre-defined Domain{" "}
                                            {allValues?.predefinedDomain &&
                                                Icon(ExportSquare, {
                                                    size: 16,
                                                    onClick: () => {
                                                        window.open(
                                                            `https://${allValues?.predefinedDomain}${allValues?.subdomain?.value}`,
                                                            "_blank",
                                                        );
                                                    },
                                                    style: { cursor: "pointer" },
                                                })}
                                        </TitleWrapper>
                                        <Label>
                                            Boost your online presence and site's visibility with a
                                            custom domain.
                                        </Label>
                                    </>
                                ) : (
                                    <>
                                        <Body_L_Bold>Connect Your Domain</Body_L_Bold>
                                        <Label>
                                            Boost your online presence and site's visibility with a
                                            custom domain
                                        </Label>
                                    </>
                                )}
                            </Title>

                            <ToggleWrapper>
                                <Toggle
                                    isSelected={currentButton === "primary"}
                                    onClick={() => {
                                        setCurrentButton("primary");
                                    }}
                                >
                                    Pre-defined Domain
                                </Toggle>
                                <Toggle
                                    isSelected={currentButton === "secondary"}
                                    onClick={() => {
                                        if (!isFreePlan) {
                                            setCurrentButton("secondary");
                                            checkDomainStatus();
                                        }
                                    }}
                                    disabled={isFreePlan}
                                    id={isFreePlan ? "model-tooltip" : "cdomain"}
                                    role="tooltip"
                                    data-tip
                                    data-tooltip-id={isFreePlan ? "model-tooltip" : "cdomain"}
                                >
                                    Custom Domain
                                </Toggle>
                                <Tooltip
                                    place="top"
                                    type="faint"
                                    effect="solid"
                                    noArrow={true}
                                    id={"model-tooltip"}
                                >
                                    Available to add on premium plans
                                </Tooltip>
                            </ToggleWrapper>
                        </HeaderSelector>
                        {currentButton == "primary" && (
                            <>
                                <InputWrapper>
                                    <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                        Enter Domain
                                    </InputLabel>
                                    <div
                                        style={{
                                            display: "flex",
                                            border: "1px solid var(--Color-Border-Subtle)",
                                            color: "var(--Color-Text-Subtle)",
                                            borderRadius: "12px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span
                                            className="unit"
                                            style={{
                                                position: "relative",
                                                left: "13px",
                                                height: "100%",
                                                color: "var(--Color-Text-Disabled)",
                                            }}
                                        >
                                            https://
                                        </span>
                                        <TextInput
                                            data-testid="predefinedDomain"
                                            role="predefinedDomain"
                                            type="predefinedDomain"
                                            label="predefinedDomain"
                                            register={register}
                                            validation={{
                                                required: true,
                                                maxLength: {
                                                    value: 21,
                                                    "message":
                                                        "Domain cannot be more than 21 characters.",
                                                },
                                                validate: (val) =>
                                                    (val && val.trim().length) ||
                                                    "Domain name cannot be empty",
                                            }}
                                            onChange={(e) => {
                                                setValue("predefinedDomain", e.target.value);
                                                setIsWebsiteChanged(true);
                                            }}
                                            placeholder="Enter Subdomain"
                                            style={{
                                                flex: 1,
                                                border: "none",
                                                outline: "none",
                                                marginLeft: "5%",
                                            }}
                                        />

                                        <CustomDropdown
                                            showArrow={false}
                                            // maxMenuHeight={120}
                                            value={allValues["subdomain"]}
                                            onChange={(e) => setValue("subdomain", e)}
                                            options={HOSTING_SUBDOMAINS.map((val) => ({
                                                label: `.${val}.${environment.PRIMARY_DOMAIN}`,
                                                value: `.${val}.${environment.PRIMARY_DOMAIN}`,
                                            }))}
                                            width="max-content"
                                            wrapperWidth="105px"
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    background:
                                                        "var(--Color-Background-Subtle) !important",
                                                    border: "0px !important",
                                                    boxShadow: "0 !important",
                                                    // background:
                                                    //     "var(--Color-Background-Subtle) !important",
                                                    // color: "var(--Color-Text-Subtle) !important",
                                                    width: "100%",
                                                    "&:hover": {
                                                        border: "0px !important",
                                                        boxShadow: "0 !important",
                                                        // background:
                                                        //     "var(--Color-Border-Subtle) !important",
                                                    },
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    width: "max-content",
                                                    minWidth: "100%",
                                                    height: "max-content",
                                                }),
                                            }}
                                        />
                                    </div>

                                    <StyledErrorSkeleton>
                                        {errors.domain && errors.domain?.type === "required" && (
                                            <StyledError role="error">
                                                Domain Name is required
                                            </StyledError>
                                        )}
                                        {errors.domain && errors.domain?.type === "custom" && (
                                            <StyledError role="error">
                                                {errors.domain.message}
                                            </StyledError>
                                        )}
                                        {errors?.predefinedDomain && (
                                            <StyledError role="error">
                                                {errors?.predefinedDomain?.message}
                                            </StyledError>
                                        )}
                                    </StyledErrorSkeleton>
                                </InputWrapper>
                                <Button mode="secondary" onClick={checkDomainAvailability}>
                                    {" "}
                                    Check Availability
                                </Button>
                            </>
                        )}
                        {currentButton == "secondary" && (
                            <>
                                <InputWrapper>
                                    <InputLabel style={{ color: "var(--Color-Text-Subtlest)" }}>
                                        Current Domain
                                    </InputLabel>
                                    <DomainInputWrapper
                                        data-testid="customDomain"
                                        role="customDomain"
                                        type="customDomain"
                                        label="customDomain"
                                        register={register}
                                        style={{ resize: "vertical" }}
                                        onChange={(e) => {
                                            setValue("customDomain", e.target.value);
                                            setIsWebsiteChanged(true);
                                        }}
                                        placeholder="Enter domain Eg: yourdomain.com"
                                        validation={{
                                            // required: "Domain Name is required",
                                            pattern: {
                                                value: DOMAIN_VALIDATION_REGEX,
                                                message: "Invalid Domain Name",
                                            },
                                        }}
                                    >
                                        {allValues?.customDomain && (
                                            <div
                                                onClick={() => {
                                                    window.open(
                                                        `//${allValues?.customDomain}`,
                                                        "_blank",
                                                    );
                                                }}
                                            >
                                                {Icon(ExportSquare, { size: 21 })}
                                            </div>
                                        )}
                                    </DomainInputWrapper>
                                    <StyledErrorSkeleton>
                                        {errors.customDomain && (
                                            <StyledError role="error">
                                                {errors.customDomain.message}
                                            </StyledError>
                                        )}
                                    </StyledErrorSkeleton>
                                </InputWrapper>
                                {domainsteps?.length > 0 && (
                                    <StepWrapper>
                                        <Body_XS_Bold>
                                            Follow this steps to link your domain:
                                        </Body_XS_Bold>
                                        <SubDescription>Open domain settings</SubDescription>
                                        {domainsteps?.map((val) => (
                                            <Steps>
                                                {val?.success ? <SuccessIcon /> : <ErrorIcon />}
                                                {val.label}
                                                {val.value && (
                                                    <StepsValue>
                                                        {val.value}
                                                        <Copy
                                                            onClick={() =>
                                                                copyToClipboard(val.value)
                                                            }
                                                        >
                                                            {Icon(DocumentCopy, { size: 21 })}
                                                        </Copy>
                                                    </StepsValue>
                                                )}
                                            </Steps>
                                        ))}
                                    </StepWrapper>
                                )}
                                <Button mode="secondary" onClick={checkDomainStatus}>
                                    Validate
                                </Button>
                            </>
                        )}
                    </Wrapper>
                )}
                {editOption === TABS_IDENTIFIER.EMBED_IFRAME && (
                    <Wrapper>
                        {allValues?.active ? (
                            <ScriptCopyModal
                                name={oldAllValues.name}
                                instruction={
                                    "Instruction: Copy and paste the below code in your webpage"
                                }
                                title={"Embed as iframe"}
                                scriptText={iFrameScriptText}
                                textAreaOptions={{ rows: 10 }}
                            />
                        ) : (
                            <Body_XS_Reg style={{ fontStyle: "italic" }}>
                                Please save the hosted web page setting to get the code snippet
                            </Body_XS_Reg>
                        )}
                    </Wrapper>
                )}
            </PageContent>

            <StickyPageFooter>
                {modalCloseButtonCallback && (
                    <Button
                        mode="secondary"
                        onClick={() => {
                            modalCloseButtonCallback?.();
                        }}
                    >
                        Cancel
                    </Button>
                )}

                <Button
                    onClick={updateHostedWebDeployment}
                    disabled={
                        allValues?.active &&
                        (!(Object.keys(errors).length === 0) || !isSaveChanged || isWesbiteChanged)
                    }
                >
                    Save
                </Button>
            </StickyPageFooter>
        </DialogWrapper>
    );
};
export default HostedWebsite;
