import React, { useEffect, useState, useRef, memo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../common/components/Button";
import {
    SubTitle1,
    Body_M_Bold,
    PageContent,
    Body_XS_Reg,
} from "../../../common/global-styled-components";
import SyncHistory from "../Edit/SyncHistory";
import BasicInfo from "../Edit/BasicInfo";
import Sources from "../Edit/Sources";
import { SOURCE_TYPES } from "pages/DataSources/types";
import {
    getCopilotDataSource,
    updateCopilotDataSources,
} from "../../../common/redux/actions/datasourceActions";
import { pick, omitBy } from "lodash";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Schedule from "../Edit/Schedule";
import { Breakpoints } from "GlobalStyle";
import DividerHr from "common/components/DividerHr";
import NavBar from "common/components/HorizontalNavBar";
import { isEqual } from "lodash";
const Header = {};
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const RootWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    flex-grow: 1;
    max-height: 90vh;
    gap: var(--Size-Gap-XL);
`;

const DetailWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-L);
`;

const HeaderWrapper = styled.div`
    color: var(--Color-Text-Default);
    display: flex;
    align-items: center;
    width: 100%;
    gap: var(--Size-Gap-L);
`;

const SaveButton = styled(Button)`
    margin-right: auto;
    @media (max-width: ${Breakpoints.mobile}px) {
        margin: unset;
    }
`;

const EditOptions = styled.div`
    display: flex;
    border-bottom: 1px solid var(--Color-Border-Subtle);
    align-items: center;
    gap: var(--Size-Gap-L);
    position: fixed;
    z-index: 4;
    background: var(--Color-Background-Default);
    width: inherit;
    @media (max-width: ${Breakpoints.mobile}px) {
        position: absolute;
        margin-top: -6px;
    }
`;

const EditOption = styled(Body_XS_Reg)`
    padding: 12px;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: ${(props) => props.active && "2px solid var(--Color-Border-Action)"};
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: auto;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
        gap: 24px;
    }
    gap: var(--Size-Gap-XXL);
`;
const Detail = styled.div`
    width: 60%;
    overflow: auto;
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
        overflow: initial;
    }
`;

const DividerHrWrapper = styled.div`
    width: 1px;
    background: var(--Color-Border-Subtle);
`;

function DatasourceViewPage({ datasourceId, updateDataSource }) {
    let { orgId, agentId } = useParams();
    const [dataSource, setDataSource] = useState({});
    const [sourceType, setSourceType] = useState({});
    const [editOption, setEditOption] = useState("overview");
    const [uploadedFiles, setUploadedFiles] = useState(dataSource?.meta?.files || []);
    const dispatch = useDispatch();
    const childRef = useRef();
    const [isChanged, setIsChanged] = useState(false);
    const {
        watch,
        register,
        setValue,
        formState: { errors, isDirty, isValid },
        control,
        reset,
        getValues,
        handleSubmit,
        setError,
        clearErrors,
    } = useForm({
        mode: "all",
        defaultValues: {},
    });
    const allValues = watch();
    useEffect(() => {
        getCopilotDataSource(orgId, agentId, datasourceId, dispatch)
            .then((res) => {
                setDataSource(res);
                res.config.url = res?.config.url || "";
                res.config.dataRoot = res?.config?.dataRoot || "";
                res.config.method = res?.config?.method || "get";
                res.config.username = res?.config?.username || "";
                res.config.password = res?.config?.password || "";
                res.config.limit_key = res?.config?.limit_key || "";
                res.config.limit_value = res?.config?.limit_value || "";
                res.config.page_key = res?.config?.page_key || "";
                res.config.page_value = res?.config?.page_value || "";
                res.config.paginationType = res?.config?.paginationType || "";
                res.config.paginationRoot = res?.config?.paginationRoot || "";
                res.config.offset_key = res?.config?.offset_key || "";
                res.config.offset_value = res?.config?.offset_value || "";
                res.config.cursor_key = res?.config?.cursor_key || "";
                res.config.cursor_value = res?.config?.cursor_value || "";
                res.description = res?.description || "";
                res.hour = res?.schedule?.hours?.[0] || 0;
                res.minute = res?.schedule?.minutes?.[0] || 0;
                res.date = res?.schedule?.mdays?.[0] || 0;
                res.weekday = res?.schedule?.wdays?.[0] || 0;
                res.scheduleType = res?.schedule?.type || "";
                res.enableSchedule = res?.schedule?.active || false;
                res.cronExp = res?.schedule?.cronExp || "";
                setUploadedFiles(res.meta.files);
                reset(
                    {
                        ...res,
                        embeddingModelName: res?.configuration?.provider?.embeddingModelName,
                    },
                    { keepDirty: true },
                );
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const checkFields = (fields, source, values) => {
        return fields.every((field) => isEqual(source?.[field], values?.[field]));
    };

    const checkConfigFields = (fields, sourceConfig, valuesConfig) => {
        return fields.every((field) => isEqual(sourceConfig?.[field], valuesConfig?.[field]));
    };

    useEffect(() => {
        if (!dataSource || !allValues) return;

        let fields = ["name", "description"];
        let isChanged = false;

        const allFalse = checkFields(fields, dataSource, allValues);
        switch (dataSource.type) {
            case "text":
                isChanged =
                    !allFalse ||
                    !isEqual(
                        dataSource?.config?.textToBeCrawled,
                        allValues?.config?.textToBeCrawled,
                    );
                break;
            case "files":
                isChanged = !allFalse || !isEqual(dataSource?.meta?.files, allValues?.meta?.files);
                break;
            case "web":
                const webConfigFields = ["domain", "renderType"];
                isChanged =
                    !allFalse ||
                    !checkConfigFields(webConfigFields, dataSource?.config, allValues?.config);
                break;
            case "googleDrive":
                const googleDriveConfigFields = ["domain", "googleDriveLink", "googleTokens"];
                isChanged =
                    !allFalse ||
                    !checkConfigFields(
                        googleDriveConfigFields,
                        dataSource?.config,
                        allValues?.config,
                    );
                break;
            case "git":
                const gitConfigFields = ["gitRepoLink", "gitToken"];
                isChanged =
                    !allFalse ||
                    !checkConfigFields(gitConfigFields, dataSource?.config, allValues?.config);
                break;
            case "quip":
                const quipConfigFields = ["quipLink", "quipToken"];
                isChanged =
                    !allFalse ||
                    !checkConfigFields(quipConfigFields, dataSource?.config, allValues?.config);
                break;
            case "notion":
                const notionConfigFields = ["notionToken", "notionLink"];
                isChanged =
                    !allFalse ||
                    !checkConfigFields(notionConfigFields, dataSource?.config, allValues?.config);
                break;
            case "api":
                const apiConfigFields = [
                    "url",
                    "dataRoot",
                    "method",
                    "username",
                    "password",
                    "limit_key",
                    "limit_value",
                    "page_key",
                    "page_value",
                    "paginationType",
                    "paginationRoot",
                    "offset_key",
                    "offset_value",
                    "cursor_key",
                    "cursor_value",
                ];
                isChanged =
                    !allFalse ||
                    !checkConfigFields(apiConfigFields, dataSource?.config, allValues?.config);
                break;
            default:
                isChanged = false;
        }

        setIsChanged(isChanged);
    }, [dataSource, allValues]);

    useEffect(() => {
        setSourceType(SOURCE_TYPES.find((val) => val.value === dataSource.type));
    }, [dataSource]);

    const saveDataSource = async () => {
        try {
            let data = {
                name: allValues.name,
                description: allValues.description,
                providerId: allValues.providerId,
                configuration: {
                    provider: { embeddingModelName: allValues.embeddingModelName },
                },
            };

            switch (dataSource.type) {
                case "web":
                    data = {
                        ...data,
                        config: {
                            domain: allValues?.config?.domain,
                            renderType: allValues?.config?.renderType,
                        },
                    };
                    break;
                case "quip":
                    data = {
                        ...data,
                        config: {
                            quipToken: allValues?.config?.quipToken,
                            quipLink: allValues?.config?.quipLink,
                        },
                    };
                    break;
                case "notion":
                    data = {
                        ...data,
                        config: {
                            notionToken: allValues?.config?.notionToken,
                            notionLink: allValues?.config?.notionLink,
                        },
                    };
                    break;
                case "git":
                    data = {
                        ...data,
                        config: {
                            gitToken: allValues?.config?.gitToken,
                            gitRepoLink: allValues?.config?.gitRepoLink,
                        },
                    };
                    break;
                case "text":
                    data = {
                        ...data,
                        config: {
                            textToBeCrawled: allValues?.config?.textToBeCrawled,
                        },
                    };
                    break;
                case "googleDrive":
                    data = {
                        ...data,
                        config: {
                            googleTokens: allValues?.config?.googleTokens,
                            googleDriveLink: allValues?.config?.googleDriveLink,
                        },
                    };
                    break;
                case "api":
                    const pagination = [];
                    const paginationTypes = {
                        "page": [
                            {
                                type: "page",
                                keySuffix: "page_key",
                                valueSuffix: "page_value",
                            },
                            {
                                type: "limit",
                                keySuffix: "limit_key",
                                valueSuffix: "limit_value",
                            },
                        ],
                        "offset-limit": [
                            {
                                type: "offset",
                                keySuffix: "offset_key",
                                valueSuffix: "offset_value",
                            },
                            {
                                type: "limit",
                                keySuffix: "limit_key",
                                valueSuffix: "limit_value",
                            },
                        ],
                        "cursor": [
                            {
                                type: "cursor",
                                keySuffix: "cursor_key",
                                valueSuffix: "cursor_value",
                            },
                        ],
                    };

                    const currentPaginationType = allValues.config.paginationType;

                    if (paginationTypes[currentPaginationType]) {
                        paginationTypes[currentPaginationType].forEach((item) => {
                            pagination.push({
                                type: item.type,
                                key: allValues.config[item.keySuffix],
                                value: allValues.config[item.valueSuffix],
                            });
                        });
                    }

                    data = {
                        ...data,
                        config: {
                            body: JSON.parse(
                                childRef?.current?.editorRef?.getValue()?.trim() || "{}",
                            ),
                            params: childRef?.current?.params?.all?.filter((i) => i.key) || [],
                            headers: childRef?.current?.headers?.all?.filter((i) => i.key) || [],
                            url: `https://${allValues.config.url}`.trim() || "",
                            method: allValues.config.method || "get",
                            auth: !childRef?.current?.enableAuth
                                ? {}
                                : {
                                      ...omitBy(
                                          pick(allValues.config, ["username", "password"]),
                                          (value) =>
                                              value === null || value === undefined || value === "",
                                      ),
                                      type:
                                          childRef?.current?.enableAuth &&
                                          childRef?.current?.selectedAuth
                                              ? childRef?.current?.selectedAuth
                                              : null,
                                  },
                            paginationType: childRef?.current?.enablePagination
                                ? allValues.config.paginationType
                                : null,
                            dataRoot: allValues.config.dataRoot?.trim(),
                            paginationRoot: allValues.config?.paginationRoot?.trim(),
                            pagination,
                        },
                    };

                    break;
            }

            const res = await updateCopilotDataSources(
                orgId,
                agentId,
                datasourceId,
                data,
                dispatch,
            );
            setDataSource(res);
            updateDataSource(datasourceId, { name: allValues.name });
            toast.success("Data Source info updated successfully", { autoClose: 2000 });
        } catch (error) {
            let { message } = error?.response?.data;
            // setLoginError(message);f
            console.error(error);
            toast.error(message || "Something went wrong", {
                autoClose: 2000,
            });
        }
    };
    const tabs = [
        {
            displayName: "Overview",
            identifier: "overview",
            disallowedDatasource: [],
        },
        {
            displayName: "Auto Sync Schedule",
            identifier: "schedule",
            disallowedDatasource: ["files", "text"],
        },
    ];

    return (
        <RootWrapper>
            <HeaderWrapper>
                {sourceType?.icon}
                <Body_M_Bold>
                    {sourceType?.label}: {dataSource.name}
                </Body_M_Bold>
            </HeaderWrapper>
            <BodyWrapper>
                <Detail>
                    <NavBar
                        route={false}
                        TABS={tabs?.filter(
                            (val) => !val?.disallowedDatasource?.includes(dataSource.type),
                        )}
                        activeTabIdentifier={editOption}
                        callback={(tab) => {
                            setEditOption(tab?.identifier);
                        }}
                    ></NavBar>
                    <PageContent
                        style={{
                            background: "transparent",
                            flexDirection: "row",
                            alignItems: "unset",
                            // marginTop: "86px",
                            background: "unset",
                            borderRadius: 0,
                            margin: 0,
                            overflowY: "scroll",
                        }}
                    >
                        {editOption === "overview" ? (
                            Object.keys(dataSource).length > 0 && (
                                <DetailWrapper>
                                    <BasicInfo
                                        data={dataSource}
                                        setDataSource={setDataSource}
                                        // fetchModels={fetchModels}
                                        register={register}
                                        allValues={allValues}
                                        setValue={setValue}
                                        errors={errors}
                                    />
                                    <Sources
                                        ref={childRef}
                                        data={dataSource}
                                        setDataSource={setDataSource}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        register={register}
                                        allValues={allValues}
                                        setValue={setValue}
                                        errors={errors}
                                        dsId={datasourceId}
                                    />
                                    <SaveButton
                                        mode="primary"
                                        onClick={handleSubmit(() => saveDataSource())}
                                        disabled={!isChanged || !(Object.keys(errors).length === 0)}
                                    >
                                        Update
                                    </SaveButton>
                                </DetailWrapper>
                            )
                        ) : (
                            <Schedule
                                dataSource={dataSource}
                                setValue={setValue}
                                allValues={allValues}
                                errors={errors}
                                register={register}
                                dsId={datasourceId}
                                control={control}
                                reset={reset}
                                getValues={getValues}
                                handleSubmit={handleSubmit}
                                setError={setError}
                                isDirty={isDirty}
                                isValid={isValid}
                                clearErrors={clearErrors}
                            />
                        )}
                    </PageContent>
                </Detail>
                <DividerHrWrapper />
                <SyncHistory
                    setValue={setValue}
                    allValues={allValues}
                    errors={errors}
                    register={register}
                    dsId={datasourceId}
                    control={control}
                    reset={reset}
                    getValues={getValues}
                    handleSubmit={handleSubmit}
                />
            </BodyWrapper>
        </RootWrapper>
    );
}

export default DatasourceViewPage;
