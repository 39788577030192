import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Breakpoints } from "../../../GlobalStyle";

const NavButton = styled.div`
    background: ${(props) => (props.active ? "var(--dark-900)" : "transparent")};
    box-shadow: 0px 1px 0px 0px rgb(203 205 254 / 16%);
    :hover {
        background-color: ${(props) => !props.active && "var(--dark-900)"};
        border-radius: var(--Size-CornerRadius-M);
    }
    width: 100%;
    cursor: pointer;
`;

const NavItemLink = styled.div`
    text-decoration: none;
    padding: 15px;
    display: flex;
    align-items: center;
    width: fit-content;
`;

const Text = styled.div`
    line-height: var(--line-height-22);
    color: ${(props) => (props.active ? "var(--white)" : "var(--light-300)")};
    padding: auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.01em;
`;

const Wrapper = styled.div`
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
        position: absolute;
        z-index: 10;
    }
`;

export default function CustomList({ tabs = [], onClick, active = "" }) {
    let location = useLocation();
    const checkActive = (identifier) => location?.pathname?.indexOf(identifier) !== -1;
    const navbuttons = tabs.map((elem) => {
        return (
            <NavButton
                key={elem.id}
                active={elem.active ?? checkActive(elem.identifier)}
                onClick={(e) => {
                    onClick(e, elem.identifier, elem.id);
                }}
            >
                {/* Cannot add boolean attribute to Link */}
                <NavItemLink>
                    <Text active={checkActive(elem.identifier)}>{elem.displayName}</Text>
                </NavItemLink>
            </NavButton>
        );
    });

    return <Wrapper>{tabs?.length > 0 && navbuttons}</Wrapper>;
}
