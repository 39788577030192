import { normalizeQueryParam, storeRedirectUrl } from "common/utils/common-utils";
import { useEffect } from "react";

const useRedirectUrl = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUrl = normalizeQueryParam(urlParams.get("redirectUrl"));
        if (redirectUrl) {
            storeRedirectUrl(redirectUrl);
        }
    }, []);
};

export default useRedirectUrl;
