import UserService from "services/user.service";
import OrgService from "services/org.service";
import { OrgAction } from "../common/redux/actions/orgActions";
import store from "../common/redux/store";
import { CopilotAction } from "common/redux/actions/copilotActions";
import CopilotService from "services/copilot.service";
import environment from "environment";

const OrganizationGuard = async () => {
    const storeState = store.getState();
    const path = window.location.pathname;
    const isSetupOrgPath = path.includes("setup-company");
    const isChooseCompanyPath = path.includes("choose-company");
    const isHome = path.includes("org");
    const pathArray = path.split("/");

    if (storeState["organizationDetails"]["organization"]) {
        // if we have list of organizations in store
        if (isHome) {
            const orgId = parseInt(pathArray[pathArray.indexOf("org") + 1]);
            if (orgId !== storeState["organizationDetails"]["organization"]._id) {
                return "/choose-company";
            }
            const userId = storeState["authenticationDetails"]["user"]["_id"];
            const teamResponse = await OrgService.getTeam(orgId, userId, null);
            const copilotAppsResponse = await CopilotService.getCopilots(orgId, "page=1&limit=100");

            store.dispatch({
                type: CopilotAction.GetCopilots,
                payload: {
                    copilotList: {
                        copilots: copilotAppsResponse.data.items,
                        page: copilotAppsResponse.data.page,
                    },
                },
            });
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    member: teamResponse.data.team[0],
                },
            });
        }
        // if (isSetupOrgPath) {
        //   return `/org/${storeState["organizationDetails"]["organization"]["_id"]}/home`;
        // } else {
        return "";
        // }
        // });
    } else if (storeState["organizationDetails"]["organizations"]?.length) {
        // if we have current org in redux store then do not make the API call
        if (isHome) {
            const orgId = parseInt(pathArray[pathArray.indexOf("org") + 1]);
            if (
                !storeState["organizationDetails"]["organizations"].filter(
                    (org) => org._id === orgId,
                ).length
            ) {
                return "/choose-company";
            }
        }
        return "";
    } else {
        if (isChooseCompanyPath) {
            try {
                if (!storeState["authenticationDetails"]["user"]["verfied"]) {
                    return "";
                }
                const res = await UserService.getOrganizationsForUser();
                store.dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
            } catch (err) {
                console.log(err);
                return "/setup-company";
            }
            return "";
        }
        if (isSetupOrgPath) {
            try {
                const res = await UserService.getOrganizationsForUser();
                store.dispatch({
                    type: OrgAction.GetOrganizationsForUser,
                    payload: { organizations: res.data },
                });
            } catch (err) {
                return "";
            }
            return "";
        }
        try {
            const orgId = pathArray[pathArray.indexOf("org") + 1];
            if (!orgId) {
                if (isSetupOrgPath) {
                    return "";
                }
                // on reload if get org by orgId fails, should be
                return "/choose-company";
            }
            const res = await UserService.getOrganizationDetailsById(orgId);
            const userId = storeState["authenticationDetails"]["user"]["_id"];
            const teamResponse = await OrgService.getTeam(orgId, userId, null);
            const copilotAppsResponse = await CopilotService.getCopilots(orgId, "page=1&limit=100");

            store.dispatch({
                type: CopilotAction.GetCopilots,
                payload: {
                    copilotList: {
                        copilots: copilotAppsResponse.data.items,
                        page: copilotAppsResponse.data.page,
                    },
                },
            });
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: {
                    isOrgRegistered: true,
                    organization: res.data.detail,
                    member: teamResponse.data.team[0],
                },
            });
            return "";
        } catch (err) {
            console.error(err);
            if (isSetupOrgPath) {
                return "";
            }
            // on reload if get org by orgId fails, should be
            return "/choose-company";
        }
    }
};

export default OrganizationGuard;
