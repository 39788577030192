import React from "react";

export default function FigmaIcon({ size, ...rest }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={size}
            height={size}
            viewBox="0 0 48 48"
            {...rest}
        >
            <linearGradient
                id="q17mbB32E_FbIzPpfjq_Ta_W0YEwBDDfTeu_gr1"
                x1="16.309"
                x2="23.023"
                y1="1.101"
                y2="19.546"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#f44f5a"></stop>
                <stop offset=".443" stopColor="#ee3d4a"></stop>
                <stop offset="1" stopColor="#e52030"></stop>
            </linearGradient>
            <path
                fill="url(#q17mbB32E_FbIzPpfjq_Ta_W0YEwBDDfTeu_gr1)"
                d="M26,17h-8c-3.866,0-7-3.134-7-7v0c0-3.866,3.134-7,7-7h8V17z"
            ></path>
            <linearGradient
                id="q17mbB32E_FbIzPpfjq_Tb_W0YEwBDDfTeu_gr2"
                x1="15.64"
                x2="22.314"
                y1="14.636"
                y2="32.971"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#ae4cd5"></stop>
                <stop offset="1" stopColor="#9331bf"></stop>
            </linearGradient>
            <path
                fill="url(#q17mbB32E_FbIzPpfjq_Tb_W0YEwBDDfTeu_gr2)"
                d="M25,31h-7c-3.866,0-7-3.134-7-7v0c0-3.866,3.134-7,7-7h7V31z"
            ></path>
            <linearGradient
                id="q17mbB32E_FbIzPpfjq_Tc_W0YEwBDDfTeu_gr3"
                x1="14.81"
                x2="21.821"
                y1="26.357"
                y2="45.617"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#33c481"></stop>
                <stop offset="1" stopColor="#21a366"></stop>
            </linearGradient>
            <path
                fill="url(#q17mbB32E_FbIzPpfjq_Tc_W0YEwBDDfTeu_gr3)"
                d="M18,45L18,45c-3.866,0-7-3.134-7-7v0c0-3.866,3.134-7,7-7h7v7C25,41.866,21.866,45,18,45z"
            ></path>
            <linearGradient
                id="q17mbB32E_FbIzPpfjq_Td_W0YEwBDDfTeu_gr4"
                x1="27.498"
                x2="34.119"
                y1=".512"
                y2="18.702"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#f09ca2"></stop>
                <stop offset="1" stopColor="#eb6773"></stop>
            </linearGradient>
            <path
                fill="url(#q17mbB32E_FbIzPpfjq_Td_W0YEwBDDfTeu_gr4)"
                d="M32,17h-7V3h7c3.866,0,7,3.134,7,7v0C39,13.866,35.866,17,32,17z"
            ></path>
            <linearGradient
                id="q17mbB32E_FbIzPpfjq_Te_W0YEwBDDfTeu_gr5"
                x1="28.714"
                x2="34.857"
                y1="14.972"
                y2="31.85"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#32bdef"></stop>
                <stop offset="1" stopColor="#1ea2e4"></stop>
            </linearGradient>
            <circle
                cx="32"
                cy="24"
                r="7"
                fill="url(#q17mbB32E_FbIzPpfjq_Te_W0YEwBDDfTeu_gr5)"
            ></circle>
        </svg>
    );
}
