import React from "react";
import ReactSwitch from "react-switch";

export default function SlideToggle({ checked, onChange, size = "large", ...rest }) {
    const sizeMap = {
        small: {
            width: 26,
            height: 16,
            diameter: 11,
        },
        large: {
            width: 40,
            height: 24,
            diameter: 16,
        },
        medium: {
            width: 33,
            height: 20,
            diameter: 13,
        },
    };
    return (
        <ReactSwitch
            onChange={onChange}
            checked={checked ?? false}
            uncheckedIcon={false}
            checkedIcon={false}
            className="react-switch"
            handleDiameter={sizeMap[size].diameter || 16}
            height={sizeMap[size].height || 24}
            width={sizeMap[size].width || 40}
            onColor="#059a52" // success color -- from GlobalStyle.js
            activeBoxShadow="0 0 2px 3px #1FBE5F" // success colored box shadow
            offColor="#a4a4a4" // disabled color
            {...rest}
        />
    );
}
