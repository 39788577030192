import React from "react";

function CrossIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
            {...props}
        >
            <path
                stroke={props.color || "#fff"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 1L1 13M1 1l12 12"
            ></path>
        </svg>
    );
}

export default CrossIcon;
