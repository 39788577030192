import React, { useState, useCallback, useImperativeHandle, forwardRef, useEffect } from "react";
import styled from "styled-components";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import TableRow from "./TableRow";
import DragHandle from "common/assets/DragHandle";
import Icon from "../../../common/components/Icon";
import { Add } from "iconsax-react";
import { Body_XS_Reg, Body3 } from "common/global-styled-components";
import TextInput from "../../../common/components/TextInput";
import CustomDropdown from "../../../common/components/CustomDropdown";
import CrossIcon from "common/assets/CrossIcon";
import { useFieldArray, Controller } from "react-hook-form";

const Wrapper = styled.div`
    overflow-x: auto;
    pointer-events: ${(props) => props.disabled && "none"};
    border: ${(props) => props.hasOneLeadError && "1px solid red"};
    border-radius: ${(props) => props.hasOneLeadError && "18px"};
    width: 100%;
    margin: 0 0 0 0;
    flex-grow: 1;
    overflow: auto;
`;

const StyledError = styled(Body3)`
    display: flex;
    height: var(--body-2-d);
    color: var(--Color-Text-Danger);
    margin: 2px 0px;
`;

const TableWrapper = styled.table`
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    white-space: nowrap;
    border-radius: var(--Size-CornerRadius-XL) var(--Size-CornerRadius-XL) 0px 0px;
    overflow: hidden; /* Ensure rounded corners */
    border: 1px solid var(--Color-Border-Subtle);
    border-bottom: 0px;
`;

const Head = styled.th`
    font-size: var(--body-3-d);
    color: var(--Color-Text-Subtle);
    line-height: var(--line-height-16);
    font-weight: var(--bold);
    text-align: start;
    padding: var(--Size-Padding-L); /* Padding for headings */
`;

const Row = styled.td`
    font-size: var(--body-2-d);
    line-height: var(--line-height-20);
    // padding: var(--Size-Padding-L); /* Padding for rows */
    border-top: 1px solid var(--Color-Border-Subtle);
    border-right: 1px solid var(--Color-Border-Subtle);
    :last-child {
        border-right: 0px;
    }
    :nth-last-child(2) {
        border-right: 0px;
    }
    :first-child {
        border-left: 0px;
    }
    // :hover {
    //     background: var(--Color-Background-Subtle);
    // }
`;
const AddFieldButton = styled.div`
    cursor: pointer;
    display: flex;
    width: auto;
    gap: var(--Size-Gap-S);
    padding: var(--Size-Padding-L);
    align-items: center;
    color: var(--Color-Text-Action);
    border: 1px solid var(--Color-Border-Subtle);
    justify-content: center;
    border-radius: 0px 0px var(--Size-CornerRadius-XL) var(--Size-CornerRadius-XL);
`;
const RowActionWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-M);
    align-items: center;
    justify-content: space-between;
`;
const SortableItem = SortableElement((props) => <TableRow {...props} />);
const SortableCont = SortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
});

const DraggableTable = forwardRef(
    (
        {
            data = [],
            columns,
            selectable = true,
            selectKey = "selected",
            draggabable = true,
            disabled,
            register,
            setValue,
            allValues,
            trigger,
            control,
            errors,
            setError,
            clearErrors,
        },
        ref,
    ) => {
        const [version, setUpdateVersion] = useState(0);

        const { append, prepend, remove, swap, move, insert, replace, update } = useFieldArray({
            control,
            name: "items",
        });
        useEffect(() => {
            setValue("items", data);
        }, [data]);

        const onSortEnd = useCallback(
            ({ oldIndex, newIndex }) => {
                const updatedItems = [...(allValues.items || [])];
                const movedItem = updatedItems.splice(oldIndex, 1)[0];
                updatedItems.splice(newIndex, 0, movedItem);
                setValue("items", updatedItems);
                setUpdateVersion((prev) => prev + 1);
            },
            [allValues.items, setValue],
        );
        const addRow = async (data, val) => {
            const isValid = await trigger("items");
            if (isValid) {
                append({ name: "", description: "", required: false, type: "string" });
            }
        };
        const removeRow = (e, index) => {
            e.preventDefault();

            remove(index);
            setUpdateVersion((prev) => prev + 1);
        };
        const selectAllRows = (e) => {
            let items = allValues.items;

            items = items.map((i) => {
                return { ...i, [selectKey]: e.target.checked };
            });
            if (!e.target.checked) {
                setError("hasOneLead", {
                    type: "custom",
                    value: "Please select at least one value or disable data collection.",
                });
            } else {
                clearErrors("hasOneLead");
            }
            setValue("items", items);
            setUpdateVersion((prev) => prev + 1);
        };
        const checkInput = (e, index) => {
            setValue(`items[${index}].required`, e.target.checked);
            if (
                !e.target.checked &&
                allValues.items.every((item, idx) => idx === index || !item.required)
            ) {
                setError("hasOneLead", {
                    type: "custom",
                    value: "Please select at least one value or disable data collection.",
                });
            } else {
                clearErrors("hasOneLead");
            }
        };
        const RowDragHandle = SortableHandle(() =>
            Icon(DragHandle, { size: 16, color: "var(--Color-Icon-Subtle)" }),
        );
        const renderInput = (
            column,
            itemIndex,
            register,
            setValue,
            allValues,
            trigger,
            control,
            errors,
        ) => {
            switch (column.component) {
                case "text":
                    return (
                        <>
                            <TextInput
                                {...column}
                                register={register}
                                name={`items[${itemIndex}].${column.key}`}
                                label={`items[${itemIndex}].${column.key}`}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                    borderRadius: "1px",
                                    border: errors?.items?.[itemIndex]?.[column.key]?.message
                                        ? "1px solid red"
                                        : "none",
                                }}
                                placeholder={column.placeholder || "..."}
                                disabled={disabled}
                            />
                            {errors?.items?.[itemIndex]?.[column.key] && (
                                <StyledError>
                                    {errors?.items?.[itemIndex]?.[column.key]?.message}
                                </StyledError>
                            )}
                        </>
                    );
                case "dropdown":
                    return (
                        <Controller
                            {...column}
                            name={`items[${itemIndex}].${column.key}`}
                            label={`items[${itemIndex}].${column.key}`}
                            control={control}
                            defaultValue={
                                column?.options?.find(
                                    (i) =>
                                        i.value ===
                                        allValues?.items?.[itemIndex]?.[column.optionKey],
                                ) || column?.options?.[0]?.value
                            }
                            rules={{ required: "Hour selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    value={column?.options?.find(
                                        (i) =>
                                            i.value ===
                                                allValues?.items?.[itemIndex]?.[column.optionKey] ||
                                            i.value ===
                                                allValues?.items?.[itemIndex]?.[column.optionKey]
                                                    ?.value,
                                    )}
                                    onChange={(_data) => {
                                        setValue(
                                            `items[${itemIndex}].${column.optionKey}`,
                                            _data.value,
                                        );
                                    }}
                                    register={register}
                                    role="tooltip"
                                    options={column.options || []}
                                    placeholder={""}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            border: "0 !important",
                                            boxShadow: "0 !important",
                                            backgroundColor: "transparent !important",
                                            fontSize: "14px !important",
                                            padding: "0px !important",
                                            "&:hover": {
                                                border: "0 !important",
                                                boxShadow: "0 !important",
                                                backgroundColor: "transparent !important",
                                            },
                                            "@media only screen and (max-width: 768px)": {
                                                ...base[
                                                    "@media only screen and (max-width: 768px)"
                                                ],
                                                fontSize: "14px !important",
                                                fontWeight: "700 !important",
                                            },
                                        }),
                                        menu: (base) => ({
                                            ...base,

                                            width: "max-content",
                                            minWidth: "100%",
                                            height: "max-content",
                                        }),
                                    }}
                                    isDisabled={disabled}
                                />
                            )}
                        />
                    );

                default:
                    <TextInput disabled={disabled}></TextInput>;
            }
        };
        useImperativeHandle(
            ref,
            () => ({
                getData: async () => {
                    const isValid = await trigger("items");
                    if (isValid) {
                        return allValues.items;
                    } else {
                        return { error: true };
                    }
                },
            }),
            [allValues.items],
        );
        return (
            <Wrapper disabled={disabled} hasOneLeadError={!!errors?.hasOneLead?.value}>
                <TableWrapper>
                    <thead>
                        <tr>
                            <Head
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                    paddingRight: "var(--Size-Padding-M)",
                                }}
                            >
                                {selectable && (
                                    <TextInput
                                        width={"auto"}
                                        type={"checkbox"}
                                        checked={
                                            !allValues?.items?.filter?.((i) => !i[selectKey])
                                                ?.length
                                        }
                                        onChange={selectAllRows}
                                        disabled={disabled}
                                    ></TextInput>
                                )}
                            </Head>
                            {columns.map((column, index) => (
                                <Head key={index}>{column.title}</Head>
                            ))}
                        </tr>
                    </thead>
                    <SortableCont
                        onSortEnd={onSortEnd}
                        key={version}
                        axis="y"
                        lockAxis="y"
                        lockToContainerEdges={true}
                        lockOffset={["30%", "50%"]}
                        helperClass="helperContainerClass"
                        useDragHandle={!disabled && draggabable}
                    >
                        {allValues.items?.map?.((item, index) => {
                            return (
                                <SortableItem key={`item-${index}`} index={index} {...item}>
                                    <Row style={{ padding: "var(--Size-Padding-M)" }}>
                                        <RowActionWrapper>
                                            <RowDragHandle />
                                            {selectable && (
                                                <TextInput
                                                    width={"auto"}
                                                    type={"checkbox"}
                                                    register={register}
                                                    name={`items[${index}].required`}
                                                    label={`items[${index}].required`}
                                                    onChange={(e) => checkInput(e, index)}
                                                    disabled={disabled}
                                                ></TextInput>
                                            )}
                                        </RowActionWrapper>
                                    </Row>
                                    {columns.map((column) => (
                                        <Row key={column.key}>
                                            {column.component
                                                ? renderInput(
                                                      column,
                                                      index,
                                                      register,
                                                      setValue,
                                                      allValues,
                                                      trigger,
                                                      control,
                                                      errors,
                                                  )
                                                : item[column.key]}
                                        </Row>
                                    ))}
                                    <Row style={{ borderRight: "0px" }}>
                                        <RowActionWrapper
                                            style={{ padding: "var(--Size-Padding-L)" }}
                                        >
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                onClick={(e) => removeRow(e, index)}
                                            >
                                                {" "}
                                                {Icon(CrossIcon, {
                                                    size: 16,
                                                    color: "var(--Color-Icon-Danger)",
                                                })}
                                            </div>
                                        </RowActionWrapper>
                                    </Row>
                                </SortableItem>
                            );
                        })}
                    </SortableCont>
                </TableWrapper>
                {!disabled && (
                    <AddFieldButton onClick={addRow}>
                        {Icon(Add, {
                            size: 16,
                            color: "var(--Color-Icon-Action)",
                        })}
                        <Body_XS_Reg
                            style={{
                                color: "var(--Color-Text-Action)",
                                whiteSpace: "nowrap",
                            }}
                        >
                            Add Row
                        </Body_XS_Reg>
                    </AddFieldButton>
                )}
            </Wrapper>
        );
    },
);

export default DraggableTable;
