import DataCollectionService from "../../../services/datacollection.service";

export const DataCollectionAction = {
    GetDataCollections: "GET_DATACOLLECTIONS",
    GetMessages: "GET_DATACOLLECTION",
    UpdateDataCollection: "UPDATE_DATACOLLECTION",
    DeleteDataCollection: "DELETE_DATACOLLECTION",
};

export const getDataCollections = (orgId, copilotAppId, queryParams, opts, dispatch) => {
    return new Promise((resolve, reject) => {
        DataCollectionService.getDataCollections(orgId, copilotAppId, queryParams, opts)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: DataCollectionAction.GetDataCollections,
                    payload: {
                        ...payload,
                    },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: DataCollectionAction.GetDataCollections,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};
