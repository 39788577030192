import { UserAgentAction } from "../actions/useragentAction";
let mobileTabletRegex = /android|webos|iphone|ipad|ipod|blackberry|mobile|tablet|kindle|iemobile/i;

const initialState = {
    isMobile: false,
    isFcWidgetLoaded: false,
};

const userAgentReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UserAgentAction.AddAgent:
            return {
                ...state,
                isMobile: mobileTabletRegex.test(payload),
            };
        case UserAgentAction.SetFcWidgetLoaded:
            return {
                ...state,
                isFcWidgetLoaded: payload,
            };
        default:
            return state;
    }
};

export default userAgentReducer;
