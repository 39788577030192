import React, { useState, useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import styled from "styled-components";
import Icon from "./Icon";
import { TickCircle, Record, ArrowLeft2 } from "iconsax-react";
import Button from "./Button";
import { Breakpoints } from "GlobalStyle";

const StyledStepper = styled.div`
    overflow-y: scroll;
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;
    &.no-stepper-header {
        display: none !important;
    }
    margin: auto;
    @media (max-width: ${Breakpoints.mobile}px) {
        padding: 0;
    }
    gap: var(--Size-Gap-XXL);
    ::-webkit-scrollbar {
        display: none !important;
    }
`;

const StepperWrapper = styled(Stepper)`
    padding: 0px !important;
    &.no-stepper-header {
        display: none !important;
    }
`;

const StepWrapper = styled(Step)`
    color: var(--Color-Background-Success);
    margin: 12px 17px;
    &.stepper:hover {
        background-color: transparent !important;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    ${(props) =>
        props.step === 0 ? `justify-content: flex-end;` : `justify-content: space-between;`}
    gap: var(--Size-Gap-XXL);
`;

/**
 * A component that renders a stepper with form steps.
 *
 * @param {Array} steps - An array of steps, each containing a label and a component.
 * @param {function} checkIfNoError - A function to check that there are no errors in form submission.
 * @param {function} onSubmit - A function to handle the form submission.
 * @param {function} submitText - Text to be shown inside submit button.
 * @param {boolean} hideStepperTitle - Toggle to remove/add stepper header .
 * @param {boolean} setTitle - A function to emit current step title.
 * @returns {JSX.Element} - The rendered component.
 *
 * Link & Create Widget
 */
const FormStepper = ({
    steps,
    checkIfNoError,
    onSubmit,
    submitText = "Submit",
    hideStepperTitle = false,
    setTitle,
    FooterComponent,
    disableSubmit = false,
    disableContinue = false,
    cancelButtonCallback,
    changeStepperPosition = null,
}) => {
    const [selectedStepper, setSelectedStepper] = useState(0);

    useEffect(() => {
        // This condition ensures that changeStepperPosition is called only if it's not null
        if (changeStepperPosition !== null) {
            // Add the value provided by changeStepperPosition to the selectedStepper
            setSelectedStepper(changeStepperPosition);
        }
    }, [changeStepperPosition]);
    /**
     * Get the icon component based on the selected stepper.
     *
     * @param {number} index - The index of the stepper.
     * @returns {JSX.Element} - The icon component.
     */
    const getIcon = (index) => {
        let icon;
        if (index === selectedStepper) {
            icon = Icon(Record, {
                size: 24,
                color: "var(--Color-Icon-Disabled)",
            });
            return icon;
        }

        if (index > selectedStepper) {
            icon = Icon(Record, {
                size: 24,
                color: "var(--Color-Border-Subtle)",
            });
        } else {
            icon = Icon(TickCircle, {
                size: 24,
                color: "var(--Color-Icon-Success)",
                variant: "Bold",
            });
        }
        return icon;
    };

    return (
        <StyledStepper>
            <StepperWrapper
                styleConfig={{
                    activeBgColor: "transparent",
                    activeTextColor: "green",
                    inactiveBgColor: "transparent",
                    completedBgColor: "transparent",
                    completedTextColor: "var(--Color-Text-Success)",
                    activeTextColor: "var(--Color-Text-Default)",
                    size: "2em",
                    circleFontSize: "0.1rem",
                    labelFontSize: "0.8rem",
                }}
                connectorStateColors={true}
                connectorStyleConfig={{
                    completedColor: "var(--Color-Background-Success)",
                    disabledColor: "#323648",
                    activeColor: "var(--Color-Background-Success)",
                    size: 2,
                    stepSize: "1.8rem",
                    style: "dotted",
                }}
                activeStep={selectedStepper}
                className={`${hideStepperTitle && "no-stepper-header"}`}
            >
                {steps?.map((val, index) => (
                    <StepWrapper
                        label={val.label}
                        id={val.id}
                        key={val.id}
                        onClick={() => {
                            setSelectedStepper(index);
                        }}
                        children={getIcon(index)}
                        className={"stepper"}
                    />
                ))}
            </StepperWrapper>

            {selectedStepper !== "" && steps[selectedStepper].component}

            <ActionWrapper step={selectedStepper}>
                {selectedStepper === 0 && cancelButtonCallback && (
                    <Button
                        mode="secondary"
                        width="100%"
                        // maxWidth={"800px"}
                        onClick={() => {
                            cancelButtonCallback?.();
                        }}
                        disabled={disableSubmit}
                    >
                        Cancel
                    </Button>
                )}
                {selectedStepper > 0 && (
                    <Button
                        mode="secondary"
                        width="100%"
                        // maxWidth={"800px"}
                        onClick={() => {
                            steps?.[selectedStepper]?.onClickBack?.();
                            setTitle?.(steps[selectedStepper - 1]?.title);
                            setSelectedStepper(selectedStepper - 1);
                        }}
                    >
                        {Icon(ArrowLeft2, { size: 21 })}&nbsp;&nbsp;Go Back
                    </Button>
                )}
                {FooterComponent !== "" ? FooterComponent : null}

                {selectedStepper === steps.length - 1 ? (
                    <Button
                        mode="primary"
                        width="100%"
                        // maxWidth={"800px"}
                        onClick={onSubmit}
                        disabled={disableSubmit}
                    >
                        {submitText}
                    </Button>
                ) : (
                    <Button
                        mode="primary"
                        width="100%"
                        // maxWidth={"800px"}
                        disabled={disableContinue}
                        onClick={checkIfNoError(async () => {
                            try {
                                await steps?.[selectedStepper]?.onClickNext?.();
                                setTitle?.(steps[selectedStepper + 1]?.title);
                                setSelectedStepper(selectedStepper + 1);
                            } catch (error) {
                                console.log(error);
                            }
                        })}
                    >
                        Continue
                    </Button>
                )}
            </ActionWrapper>
        </StyledStepper>
    );
};

export default FormStepper;
