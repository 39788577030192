import ThreadService from "../../../services/thread.service";

export const ThreadAction = {
    GetThreads: "GET_THREADS",
    GetMessages: "GET_MESSAGES",
    GetFilters: "GET_FILTERS",
};

export const getThreads = (orgId, copilotAppId, queryParams, dispatch) => {
    return new Promise((resolve, reject) => {
        ThreadService.getThreads(orgId, copilotAppId, queryParams)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: ThreadAction.GetThreads,
                    payload: {
                        threads: payload,
                    },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ThreadAction.GetThreads,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const patchThread = (orgId, copilotAppId, threadId, type, dispatch) => {
    return new Promise((resolve, reject) => {
        ThreadService.patchThread(orgId, copilotAppId, threadId, type)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};
export const getMessages = (orgId, copilotAppId, threadid, queryParams, dispatch) => {
    return new Promise((resolve, reject) => {
        ThreadService.getMessages(orgId, copilotAppId, threadid, queryParams)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: ThreadAction.GetMessages,
                    payload: {
                        messages: payload,
                    },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: ThreadAction.GetMessages,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};
