import React from "react";
import styled from "styled-components";
import { Body3, PageTitleWrapper } from "common/global-styled-components";
import { usePageTitle } from "common/utils";
import Divider from "common/components/Divider";
import SlideToggle from "common/components/SlideToggle";
import DraggableTable from "../../../pages/DataSources/DraggableTable";
import { Label, BodyWrapper, Title } from "./index.js";

const Error = styled.div`
    color: var(--Color-Text-Danger);
    display: flex;
    justify-content: center;
    margin-top: 12px;
    font-size: var(--body-2-d);
`;

const DataCollectionSettings = ({
    allValues,
    setValue,
    leadTableRef,
    clearErrors,
    setError,
    initialFormValues,
    register,
    trigger,
    control,
    errors,
}) => {
    usePageTitle(`Copilot Platform - Copilot Data Collection`);

    const handleToggleChange = (e) => {
        if (e && allValues.items.every((item) => !item.required)) {
            setError("hasOneLead", {
                type: "custom",
                value: "Please select at least one value or disable data collection.",
            });
        } else {
            clearErrors("hasOneLead");
        }
        setValue("dataCollection.active", e);
    };

    return (
        <>
            <BodyWrapper id="lead-settings-tab">
                <Title>
                    <PageTitleWrapper>
                        <Label>Data Collection</Label>
                        <Body3>
                            Customize preferences to optimize data collection and enhance user
                            interaction effortlessly.
                        </Body3>
                    </PageTitleWrapper>
                    <SlideToggle
                        checked={allValues?.dataCollection?.active}
                        onChange={handleToggleChange}
                    />
                </Title>
                <Divider style={{ margin: "0px" }}></Divider>
                <div>
                    <DraggableTable
                        initialFormValues={initialFormValues}
                        data={allValues?.dataCollection?.fields || []}
                        ref={leadTableRef}
                        register={register}
                        trigger={trigger}
                        control={control}
                        errors={errors}
                        allValues={allValues}
                        setValue={setValue}
                        selectable={true}
                        setError={setError}
                        clearErrors={clearErrors}
                        selectKey={"required"}
                        columns={[
                            {
                                key: "name",
                                title: "Name",
                                component: "text",
                                placeholder: "Add field name",
                                validation: {
                                    required: "Name is required",
                                    maxLength: {
                                        value: 100,
                                        message: "Name cannot be more than 100 characters",
                                    },
                                },
                            },
                            {
                                key: "description",
                                title: "Description",
                                component: "text",
                                placeholder: "Add field description",
                                validation: {
                                    required: "Description is required",
                                    maxLength: {
                                        value: 100,
                                        message: "Description cannot be more than 100 characters",
                                    },
                                },
                            },
                            {
                                key: "type",
                                title: "Type",
                                optionKey: "type",
                                options: [
                                    { label: "String", value: "string" },
                                    { label: "Number", value: "number" },
                                    { label: "Boolean", value: "boolean" },
                                ],
                                default: { label: "String", value: "string" },
                                component: "dropdown",
                                validation: {
                                    required: "Type is required",
                                },
                            },
                        ]}
                        disabled={!allValues?.dataCollection?.active}
                    ></DraggableTable>
                    {errors?.hasOneLead?.value && (
                        <Error role="error">{errors?.hasOneLead?.value}</Error>
                    )}
                </div>
                <Title>
                    <PageTitleWrapper>
                        <Label
                            style={{
                                alignItems: "center",
                                display: "flex",
                                gap: "var(--Size-Gap-XL)",
                            }}
                        >
                            Allow to Skip Answers{" "}
                            <SlideToggle
                                size="medium"
                                checked={allValues?.dataCollection?.skippable}
                                onChange={(e) => setValue("dataCollection.skippable", e)}
                                disabled={!allValues?.dataCollection?.active}
                            />
                        </Label>
                        <Body3 style={{ color: "var(--Color-Text-Disabled" }}>
                            When allowed, users have the option to bypass responding to these
                            questions.
                        </Body3>
                    </PageTitleWrapper>
                </Title>
                <Divider style={{ margin: "0px" }}></Divider>
                <Title>
                    <PageTitleWrapper>
                        <Label
                            style={{
                                alignItems: "center",
                                display: "flex",
                                gap: "var(--Size-Gap-XL)",
                            }}
                        >
                            Ask Questions Sequentially{" "}
                            <SlideToggle
                                size="medium"
                                checked={allValues?.dataCollection?.sequential}
                                onChange={(e) => setValue("dataCollection.sequential", e)}
                                disabled={!allValues?.dataCollection?.active}
                            />
                        </Label>
                        <Body3 style={{ color: "var(--Color-Text-Disabled" }}>
                            When allowed, copilot will prompt questions individually instead of all
                            at once.
                        </Body3>
                    </PageTitleWrapper>
                </Title>
            </BodyWrapper>
        </>
    );
};

export default DataCollectionSettings;
