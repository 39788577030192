import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const CopilotService = {
    getCopilots(orgId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilots}?${queryString}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getCopilot(orgId, copilotId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.copilot.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    getCopilotSkills(orgId, copilotId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotSkills}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    createCopilot(orgId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(URLS.copilots.replace(":orgId", orgId), axiosOptions);
    },

    createCopilotSkills(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            URLS.copilotSkills.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    getCopilotSurfaces(orgId, copilotId) {
        return ApiService.get(
            URLS.copilotSurfaces.replace(":orgId", orgId).replace(":copilotId", copilotId),
        );
    },

    getCopilotStats(orgId, copilotId) {
        return ApiService.get(
            URLS.copilotStats.replace(":orgId", orgId).replace(":copilotId", copilotId),
        );
    },

    getCopilotSurface(orgId, copilotId, surfaceType) {
        return ApiService.get(
            URLS.copilotSurface
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":surfaceType", surfaceType),
        );
    },

    updateCopilotSurface(orgId, copilotId, surfaceType, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.put(
            URLS.copilotSurface
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":surfaceType", surfaceType),
            axiosOptions,
        );
    },

    testingInboundVoiceCall(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            URLS.copilotInboundVoiceCall.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    deleteCopilotSurface(orgId, copilotId, surfaceType, { params }) {
        const axiosOptions = { params };
        return ApiService.delete(
            URLS.copilotSurface
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":surfaceType", surfaceType),
            axiosOptions,
        );
    },

    checkAvailabilityOfDomain(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            URLS.domainAvailability.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    validateDomain(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            URLS.validateDomain.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    updateCopilot(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.put(
            URLS.copilot.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },
    updateCopilotStatus(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.patch(
            URLS.copilotStatus.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    updateCopilotSkills(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.put(
            URLS.copilotSkills.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    deleteCopilot(orgId, copilotId) {
        const axiosOptions = {};
        return ApiService.delete(
            URLS.copilot.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    getCopilotsForSurface(surface, queryParams) {
        const axiosOptions = {};

        queryParams = queryParams ? "&" + queryParams : "";

        return ApiService.get(
            `${URLS.copilotsForSurface}?surface=${surface}${queryParams}`,
            axiosOptions,
        );
    },

    selectCopilot(write_key, token) {
        const axiosOptions = {
            data: {
                write_key,
                token,
            },
        };
        return ApiService.post(`${URLS.selectCopilot}`, axiosOptions);
    },

    saveAnonymousCopilotDetails(orgId, anonymousId) {
        const axiosOptions = {};
        return ApiService.post(
            URLS.anonymousCopilotDetails
                .replace(":orgId", orgId)
                .replace(":anonymousId", anonymousId),
            axiosOptions,
        );
    },

    aiVoices() {
        return ApiService.get(URLS.copilotAIVoices);
    },
};

export default CopilotService;
