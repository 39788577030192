import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect, useParams } from "react-router-dom";

import styled from "styled-components";
import {
    PageContent,
    StickyPageHeader,
    Body_S_Bold,
    Body_XS_Bold,
    Body_XS_Reg,
    SubTitle1,
    Body_XXS_Reg,
    Body_XXS_Med,
    Body_XXXS_Reg,
} from "common/global-styled-components";
import { Breakpoints } from "GlobalStyle";
import NavBar from "common/components/HorizontalNavBar";
import Icon from "common/components/Icon";
import { Location, User, Call, Clock, MonitorMobbile, Global, AddCircle } from "iconsax-react";
import moment from "moment";
import startCase from "lodash/startCase";
import Accordions from "common/components/Accordion";
import Divider from "common/components/Divider";
import EmailIcon from "common/assets/EmailIcon";
import UserConversations from "./UserConversations";
import DataCollections from "./DataCollections";

const Layout = styled.div`
    display: flex;
    grid-gap: var(--Size-Gap-XL);
    grid-template-columns: 3fr 1fr;
    overflow: hidden;
    flex-grow: 1;
`;
const UserInfoTitle = styled.div`
    display: flex;
    gap: var(--Size-Gap-M);
    align-items: center;
`;
const UserSectionPageContent = styled(PageContent)`
    padding: 0px;
    flex-grow: 1;
    overflow-y: scroll;
    background: var(--Color-Background-Default);
    gap: 0px;
    margin: 0px;
    max-width: 340px;
    min-width: 340px;
    border-radius: var(--Size-CornerRadius-XL);
    @media (max-width: ${Breakpoints.tablet}px) {
        display: none;
    }
`;
const InnerPageContent = styled(PageContent)`
    flex-grow: 1;
    overflow-y: scroll;
    background: var(--Color-Background-Default);
    gap: var(--Size-Gap-XL);
`;
const TitleRow = styled.div`
    display: flex;
    gap: var(--Size-Gap-L);
`;
const UserImage = styled.div`
    display: flex;
    gap: var(--Size-Gap-S);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--Color-Background-Accent-Action);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;
const UserImageSrc = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
`;
const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-S);
    padding: var(--Size-Padding-S);
`;
const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-L);
`;
const AdditionalFieldsRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-S);
`;
const Title = styled(Body_XXS_Reg)`
    font-size: 10px;
    color: var(--Color-Text-Disabled);
`;
const Value = styled(Body_XS_Reg)``;
const ContactInfoRow = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-L);
`;
const ThreadRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-M);
`;
const Styled_Body_XS_Reg = styled(Body_XS_Reg)`
    // max-width: 80%;
    // // text-overflow: ellipsis;
    // overflow: scroll;
    // white-space: nowrap;
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const IconText = styled.div`
    display: flex;
    gap: var(--Size-Gap-S);
    align-items: center;
`;
const AddFieldButton = styled.div`
    cursor: pointer;
    display: flex;
    width: auto;
    gap: var(--Size-Gap-L);
    padding: 0px;
    align-items: center;
    color: var(--Color-Text-Action);
`;
const StatusIndicator = styled.div`
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0px;
    top: 38px;

    background: ${(props) =>
        props.$state === "success"
            ? "var(--Color-Icon-Success)"
            : props.$state === "danger"
            ? "var(--Color-Icon-Danger)"
            : "var(--Color-Icon-Warning)"};
    border-radius: 50%;
    border: 2px solid var(--Color-Icon-Inverse);
`;

const NotAvailable = styled(Body_XXS_Med)`
    font-style: italic;
`;

export const getState = (epoch) => {
    if (!epoch) return "danger"; // No epoch provided, consider it dangerous
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - epoch;

    // If time difference is greater than 30 minutes (30 * 60 * 1000 milliseconds)
    if (timeDifference > 1800000) {
        return "danger";
    }
    // If time difference is between 5 and 30 minutes
    else if (timeDifference > 300000) {
        return "warning";
    }
    // If time difference is between 0 and 5 minutes
    else if (timeDifference >= 0) {
        return "success";
    }
    // If none of the above conditions met, consider it dangerous
    else {
        return "danger";
    }
};

const UserDetails = ({ list, user = {}, lastOnline }) => {
    return (
        <UserSectionPageContent style={Object.keys(list).length > 0 ? {} : { display: "none" }}>
            <StickyPageHeader
                style={{
                    gap: "var(--Size-Gap-S)",
                    padding: "calc(var(--Size-Padding-L) - 1px)",
                    borderBottom: "1px solid var(--Color-Background-Subtle-2)",
                }}
            >
                <UserInfoTitle>
                    {Icon(User, { size: 16 })} <Body_S_Bold>User Info</Body_S_Bold>{" "}
                </UserInfoTitle>
            </StickyPageHeader>
            <InnerPageContent
                style={{ gap: "var(--Size-Gap-XXL)", display: "flex", flexDirection: "column" }}
            >
                <TitleRow>
                    <UserImage>
                        {/* <StatusIndicator $state={getState(user?.lastOnline)}></StatusIndicator> */}
                        {user?.profilePicUrl ? (
                            <UserImageSrc src={user?.profilePicUrl}></UserImageSrc>
                        ) : (
                            <SubTitle1 style={{ color: "var(--Color-Text-Action)" }}>
                                {(user?.name ||
                                    user?.email ||
                                    user?.phone ||
                                    "A")?.[0].toUpperCase()}
                            </SubTitle1>
                        )}
                    </UserImage>
                    <UserInfo>
                        <Body_XS_Bold>
                            {startCase(user?.name) || user?.email || user?.phone || "Anonymous"}
                        </Body_XS_Bold>
                        <IconText>
                            {Icon(Location, { size: 12 })}
                            <Body_XS_Reg>
                                {user?.location?.city
                                    ? `${user?.location?.city}, ${user?.location?.country}`
                                    : user?.device?.ipv4 || "..."}
                            </Body_XS_Reg>
                        </IconText>
                    </UserInfo>
                </TitleRow>
                <Accordions
                    allowMultipleOpen={true}
                    mode="secondary"
                    data={[
                        {
                            title: "Contact Info",
                            component: (
                                <InfoDiv>
                                    <ContactInfoRow>
                                        {Icon(Call, { size: 16 })}
                                        <Styled_Body_XS_Reg>
                                            {user?.phone || (
                                                <NotAvailable>Not Available</NotAvailable>
                                            )}
                                        </Styled_Body_XS_Reg>
                                    </ContactInfoRow>
                                    <ContactInfoRow>
                                        {Icon(EmailIcon, { size: 16 })}
                                        <Styled_Body_XS_Reg>
                                            {user?.email || (
                                                <NotAvailable>Not Available</NotAvailable>
                                            )}
                                        </Styled_Body_XS_Reg>
                                    </ContactInfoRow>
                                    <ContactInfoRow>
                                        {Icon(MonitorMobbile, { size: 16 })}
                                        <Styled_Body_XS_Reg>
                                            {user?.device?.platform || (
                                                <NotAvailable>Not Available</NotAvailable>
                                            )}
                                        </Styled_Body_XS_Reg>
                                    </ContactInfoRow>
                                    <ContactInfoRow>
                                        {Icon(Global, { size: 16 })}
                                        <Styled_Body_XS_Reg>
                                            {user?.device?.userAgent || user?.device?.ipv4 || (
                                                <NotAvailable>Not Available</NotAvailable>
                                            )}
                                        </Styled_Body_XS_Reg>
                                    </ContactInfoRow>
                                    <ContactInfoRow>
                                        {Icon(Location, { size: 16 })}
                                        <Styled_Body_XS_Reg>
                                            {user?.location?.city || user?.location?.country
                                                ? `${user?.location?.city || ""}${
                                                      user?.location?.city &&
                                                      user?.location?.country
                                                          ? ", "
                                                          : ""
                                                  }${user?.location?.country || ""}`
                                                : user?.device?.ipv4 || (
                                                      <NotAvailable>Not Available</NotAvailable>
                                                  )}
                                        </Styled_Body_XS_Reg>
                                    </ContactInfoRow>
                                </InfoDiv>
                            ),
                        },
                        {
                            title: "Additional Info",
                            component: (
                                <InfoDiv>
                                    {Object.keys(user?.meta?.additionalFields || {}).length > 0 &&
                                        Object.entries(user?.meta?.additionalFields).map(
                                            ([key, value]) => {
                                                return (
                                                    <AdditionalFieldsRow key={key}>
                                                        <Title>{startCase(key)}</Title>
                                                        <Value>{value || "-"}</Value>
                                                    </AdditionalFieldsRow>
                                                );
                                            },
                                        )}

                                    {/* TODO: implement add fields */}
                                    {/* <AddFieldButton>
                                        {Icon(AddCircle, {
                                            size: 21,
                                            color: "var(--Color-Icon-Action)",
                                            variant: "Bold",
                                        })}
                                        <Body_XS_Reg
                                            style={{
                                                color: "var(--Color-Text-Action)",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Add Field
                                        </Body_XS_Reg>
                                    </AddFieldButton> */}
                                </InfoDiv>
                            ),
                        },
                        {
                            title: "Recent Threads",
                            component: (
                                <InfoDiv>
                                    {list?.threads?.map((i, index) => {
                                        return (
                                            <ThreadRow key={index}>
                                                <Body_XS_Reg>
                                                    {i?.messages?.[0]?.content}
                                                </Body_XS_Reg>
                                                <IconText>
                                                    {Icon(Clock, { size: 12 })}
                                                    <Body_XXXS_Reg
                                                        style={{
                                                            color: "var(--Color-Text-Subtlest)",
                                                        }}
                                                    >
                                                        {moment(i.updatedAt).format(
                                                            "DD MMMM YYYY, HH:mm",
                                                        ) || "..."}
                                                    </Body_XXXS_Reg>
                                                </IconText>
                                                {index !== list?.threads.length - 1 && (
                                                    <Divider style={{ margin: "0px" }} />
                                                )}
                                            </ThreadRow>
                                        );
                                    })}
                                </InfoDiv>
                            ),
                        },
                    ]}
                />
            </InnerPageContent>
        </UserSectionPageContent>
    );
};

const Inbox = (props) => {
    const [tabs, setTabs] = useState([]);
    const [list, setList] = useState([]);
    const [user, setUser] = useState({});
    const { orgId, agentId } = useParams();
    const { path } = useRouteMatch();
    useEffect(() => {
        props?.setShowCopilotPlaygroundWidget?.(false);
        setTabs([
            {
                displayName: "Conversations",
                identifier: "conversations",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/inbox/conversations`,
            },
            {
                displayName: "Data Collection",
                identifier: "data-collection",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/inbox/data-collection`,
            },
        ]);
    }, []);
    return (
        <Layout>
            <PageContent
                style={{
                    padding: "0px",
                    flexGrow: 1,
                    overflowY: "scroll",
                    background: "var(--Color-Background-Default)",
                    gap: "0px",
                    margin: "0px",
                    borderRadius: "var(--Size-CornerRadius-XL)",
                }}
            >
                <StickyPageHeader style={{ gap: "var(--Size-Gap-S)" }}>
                    <NavBar TABS={tabs} />
                </StickyPageHeader>
                <Switch>
                    <Route
                        path={`${path}/conversations`}
                        render={() => (
                            <UserConversations
                                {...props}
                                setUserList={setList}
                                setUser={setUser}
                                chatUser={user}
                            />
                        )}
                    ></Route>
                    <Route
                        path={`${path}/data-collection`}
                        render={() => (
                            <DataCollections {...props} setUserList={setList} setUser={setUser} />
                        )}
                    ></Route>
                    <Redirect to={`${path}/data-collection`} />
                </Switch>
            </PageContent>
            <UserDetails list={list} user={user} />
        </Layout>
    );
};

export default Inbox;
