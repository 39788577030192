import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
    Body_XS_Med,
    Body_S_Bold,
    PageTitleHeader,
    InputLabel,
    Body3,
    Body_2XS_Reg,
} from "common/global-styled-components";
import Button from "common/components/Button";
import NavBar from "common/components/HorizontalNavBar";
import { useForm } from "react-hook-form";
import CheckBoxGroup, { Checkbox } from "./CheckBoxGroup";
import TextInput from "common/components/TextInput";
import PasswordInput from "common/components/PasswordInput";
import { Export, Copy, Danger } from "iconsax-react";
import IconLogo from "common/components/Logo/IconLogo";
import { Breakpoints } from "GlobalStyle";
import Icon from "common/components/Icon";
import { toast } from "react-toastify";
import {
    createIntegration,
    updateIntegration,
    getIntegration,
    refreshToken,
    getEventsAndOperations,
    deleteIntegration,
} from "common/redux/actions/integrationActions";
import { useParams } from "react-router-dom";
import { uploadStorageAssets } from "common/utils";
import copy from "copy-to-clipboard";
import LoaderIcon from "../../../common/components/LoaderIcon";
import TextArea from "common/components/TextArea";
import SlideToggle from "common/components/SlideToggle";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash";
import { useModal } from "../../../common/components/NewModal";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import BackButton from "../../../common/components/BackButton";

const HiddenInput = styled.input`
    display: none;
`;

const InputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;

const Image = styled.div`
    display: flex;
    padding: var(--Size-Padding-L);
    margin: 2px 20px;
    width: 120px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: var(--Size-Gap-L);
    align-self: center;
    border-radius: var(--Size-CornerRadius-L);
    border: 2px dashed var(--Color-Border-Default);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-L);
`;

const ImageContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    background: var(--Color-Background-Subtle);
    border-radius: var(--Size-CornerRadius-L);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--Color-Border-Default);
    &.img {
        width: 100px;
        height: 100px;
    }
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const ImageLabel = styled(Body_XS_Med)`
    margin-top: -12px;
`;

const IconLogoWithRadius = styled(IconLogo)`
    border-radius: var(--Size-CornerRadius-L);
`;

const ButtonTextWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-L);
    cursor: pointer;
    padding: var(--Size-Padding-XXL);
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XL);
    justify-content: space-between;
    gap: var(--Size-Gap-XL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const GeneralWrapper = styled(Wrapper)`
    grid-column: 1;
    grid-row: 1;

    @media (max-width: ${Breakpoints.tablet}px) {
        grid-column: 1;
        grid-row: auto;
    }
`;

const CredentiallWrapper = styled(Wrapper)`
    grid-column: 1;
    grid-row: 2;
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-column: 1;
        grid-row: auto;
    }
`;

const PermissionWrapper = styled(Wrapper)`
    grid-column: 2;
    grid-row: 1 / span 2;
    justify-content: normal;
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-column: 1;
        grid-row: auto;
    }
`;

const InboxWrapper = styled(Wrapper)`
    grid-column: 1;
    grid-row: 3;
    justify-content: normal;
    border: ${(props) => props.error && "1px solid red"};
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-column: 1;
        grid-row: auto;
    }
`;

const WebhookWrapper = styled(Wrapper)`
    grid-column: 2;
    grid-row: 3;
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-column: 1;
        grid-row: auto;
    }
`;
const RefreshToken = styled.div`
    text-decoration: underline;
    cursor: pointer;
`;

const CopyLink = styled.div`
    position: absolute;
    right: 35px;
    height: 55px;
`;

const InnerDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    overflow: scroll;
    @media (max-width: ${Breakpoints.tablet}px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
`;

const LabelWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-L);
    align-items: center;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-M);
`;

const CheckBoxWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: var(--Size-Gap-XL);

    .label {
        color: red;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: sticky;
    flex-wrap: wrap;
    gap: var(--Size-Gap-XL);
`;

const GenerateCredentialBox = styled.div`
    background: var(--Color-Background-Subtle);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--Size-CornerRadius-L);
    padding: var(--Size-Padding-XXL);
    gap: var(--Size-Gap-XXL);
    height: 170px;
    border: 1px solid var(--Color-Border-Subtle);
    text-align: center;
`;
const PermissionChip = styled(Body_XS_Med)`
    display: flex;
    visibility: ${(props) => (props.active ? "" : "hidden")};
    align-items: center;
    justify-content: space-between;
    border-radius: var(--Size-CornerRadius-L);
    padding: var(--Size-Padding-M) var(--Size-Padding-L);
    gap: var(--Size-Gap-M);
    background: var(--Color-Background-Accent-Danger);
    color: var(--Color-Text-Danger);
`;

const Buttons = styled.div`
    display: flex;
    gap: var(--Size-Gap-XL);
`;

const tabs = [
    {
        displayName: "Operations",
        identifier: "operations",
    },
    {
        displayName: "Events",
        identifier: "events",
    },
];
const Events = [
    {
        id: 1,
        title: "Events",
        items: [
            { id: 1, label: "conversation/create", checked: false },
            { id: 2, label: "conversation/message/create", checked: false },
        ],
    },
];

const Operations = [
    {
        id: 1,
        title: "Operations",
        items: [
            { id: 1, label: "conversation/message/reply", checked: false },
            { id: 2, label: "conversation/fetch", checked: false },
        ],
    },
];

const IntegrationDetails = ({ mode = "create", setShowCopilotPlaygroundWidget }) => {
    const history = useHistory();
    const [editOption, setEditOption] = useState("operations");
    const [isOnePermission, setisOnePermission] = useState(true);
    const [currentMode, setCurrentMode] = useState(mode);
    const [loading, setLoading] = useState(mode == "edit");
    const { orgId, agentId, integrationId } = useParams();
    const [events, setEvents] = useState(Events);
    const [operations, setOperations] = useState(Operations);
    const [initialFormValues, setInitialFormValues] = useState({
        name: "",
        description: "",
        inbox: false,
        webhook: {},
        surfaces: [],
    });
    const [isChanged, setIsChanged] = useState(false);
    const hiddenFileInput = useRef(null);
    const {
        show: showRefreshTokenDialog,
        hide: hideRefreshTokenDialog,
        RenderModal: RenderRefreshTokenDialog,
    } = useModal();

    const {
        show: showDeleteAppDialog,
        hide: hideDeleteAppDialog,
        RenderModal: RenderDeleteAppDialog,
    } = useModal();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        setError,
        clearErrors,
        watch,
    } = useForm({
        mode: "all",
        defaultValues: { name: "", description: "", inbox: false, webhook: {}, surfaces: [] },
    });
    const allValues = watch();
    useEffect(() => {
        const fetchData = async () => {
            setShowCopilotPlaygroundWidget?.(false);
            let events = [],
                operations = [];

            try {
                const eventsData = await getEventsAndOperations(orgId, agentId);

                if (eventsData?.events?.length > 0) {
                    events = eventsData.events.map((val, idx) => ({ ...val, id: idx }));
                }
                if (eventsData?.operations?.length > 0) {
                    operations = eventsData.operations.map((val, idx) => ({ ...val, id: idx }));
                }
                setEvents(events);
                setOperations(operations);
                if (currentMode === "edit") {
                    let res = await getIntegration(orgId, agentId, integrationId);
                    res["inbox"] = res?.surfaces?.length > 0 || false;
                    reset({ ...res });
                    setInitialFormValues({ ...res });
                    events = updateCheckedStatus(events, res?.events);
                    operations = updateCheckedStatus(operations, res?.operations);
                    setEvents(events);
                    setOperations(operations);
                }
            } catch (err) {
                toast.error(err?.response?.data?.message || "Error Loading integrations", {
                    autoClose: 2000,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setisOnePermission(
            getCheckedLabels(events)?.length == 0 && getCheckedLabels(operations)?.length == 0,
        );
        setValue(`events`, getCheckedLabels(events));
        setValue(`operations`, getCheckedLabels(operations));
    }, [events, operations]);

    useEffect(() => {
        setIsChanged(!isEqual(allValues, initialFormValues));
    }, [allValues]);
    const handleClick = () => {
        register("icon", "");
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files;
        if (fileUploaded?.[0]?.size > 1048576) {
            toast.error(`The image size should be 1MB or less. Upload reduced image size < 1MB`);
            return;
        }
        setValue("icon", fileUploaded);
    };

    function getCheckedLabels(data) {
        let labels = [];
        data.forEach((category) => {
            category.items.forEach((item) => {
                if (item.checked) {
                    labels.push(item.value);
                }
            });
        });
        return labels;
    }

    function updateCheckedStatus(data, labels) {
        let clonedData = JSON.parse(JSON.stringify(data));

        let labelSet = new Set(labels);

        clonedData.forEach((category) => {
            category.items.forEach((item) => {
                item.checked = labelSet.has(item.value);
            });
        });

        return clonedData;
    }

    const copyToClipboard = (data) => {
        copy(data);
        toast.info("Copied to clipboard", { autoClose: 2000 });
    };

    const updateIntegrationDetails = async () => {
        try {
            if (allValues?.inbox && allValues?.surfaces?.length == 0) {
                setError("hasSurface", {
                    type: "custom",
                    value: "Please select at least one value or disable inbox app.",
                });
                return;
            }
            let data, iconData;
            if (allValues?.icon?.length === 1) {
                data = {
                    namespace: "app_icon",
                    options: {
                        file_name: allValues?.icon?.[0]?.name,
                        file_type: allValues?.icon?.[0]?.type,
                        file_size: allValues?.icon?.[0]?.size,
                    },
                };
                iconData = await uploadStorageAssets(orgId, data, allValues?.icon?.[0]);
            }
            const keysToPick = [
                "name",
                "description",
                "webhook",
                "surfaces",
                "events",
                "operations",
            ];

            let body = keysToPick.reduce((acc, key) => {
                if (key in allValues) {
                    acc[key] = allValues[key];
                }
                return acc;
            }, {});

            body["icon"] = iconData || allValues?.icon;

            const res =
                currentMode == "edit"
                    ? await updateIntegration(orgId, agentId, integrationId, body)
                    : await createIntegration(orgId, agentId, body);

            toast.success(
                `Integration ${currentMode === "edit" ? "updated" : "added"} successfully`,
                {
                    autoClose: 2000,
                },
            );
            setInitialFormValues({ ...allValues, id: res.id, token: res.token });
            reset({ ...allValues, id: res.id, token: res.token });
            setIsChanged(false);
            history.push(`/org/${orgId}/cp/${agentId}/integrations/${res.id}`);
            setCurrentMode("edit");
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message, { autoClose: 2000 });
                return;
            }
            toast.error(`Error adding integrations`, {
                autoClose: 2000,
            });
        }
    };

    const onSurfaceChange = (e, surface) => {
        if (e.target.checked) {
            clearErrors("hasSurface");
            setValue("surfaces", [...(allValues.surfaces || []), surface]);
        } else {
            setValue(
                "surfaces",
                allValues.surfaces ? allValues.surfaces.filter((s) => s !== surface) : [],
            );
        }
    };

    const tokenRefresh = () => {
        refreshToken(orgId, agentId, integrationId)
            .then((res) => {
                setInitialFormValues({ ...initialFormValues, token: res?.token });
                setValue("token", res?.token);
                hideRefreshTokenDialog();
                toast.success("Token refreshed successfully", {
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error generating tokens", {
                    autoClose: 2000,
                });
            });
    };

    const deleteIntegrations = () => {
        deleteIntegration(orgId, agentId, allValues?.id)
            .then(() => {
                toast.success(`Deleted App successfully`, {
                    autoClose: 2000,
                });
                history.push(`/org/${orgId}/cp/${agentId}/integrations`);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error deleting apps", {
                    autoClose: 2000,
                });
            });
    };
    return (
        <>
            <HiddenInput
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                accept="image/*"
                id="inputFile"
                label={`icon`}
            />

            <Wrapper style={{ "overflow": "hidden" }}>
                <TitleWrapper>
                    <PageTitleHeader>
                        {currentMode === "edit" && (
                            <BackButton
                                onClick={() =>
                                    history.push(`/org/${orgId}/cp/${agentId}/integrations`)
                                }
                            />
                        )}
                        {currentMode === "create" ? "Create Integration" : allValues?.name}
                    </PageTitleHeader>
                    <Buttons>
                        {currentMode == "create" ? (
                            <Button
                                mode="secondary"
                                size="small"
                                onClick={() =>
                                    history.push(`/org/${orgId}/cp/${agentId}/integrations`)
                                }
                            >
                                Cancel
                            </Button>
                        ) : (
                            <Button
                                mode="danger"
                                size="small"
                                onClick={() => showDeleteAppDialog()}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            disabled={
                                Object.keys(errors).length > 0 ||
                                isOnePermission ||
                                !isChanged ||
                                !allValues?.name
                            }
                            onClick={handleSubmit(updateIntegrationDetails)}
                            size="small"
                        >
                            Save & Create
                        </Button>
                    </Buttons>
                </TitleWrapper>

                {loading ? (
                    <Loader>
                        <LoaderIcon />
                    </Loader>
                ) : (
                    <InnerDiv>
                        <GeneralWrapper>
                            <ImageWrapper>
                                <Image>
                                    <ImageContainer>
                                        {allValues?.icon ? (
                                            <>
                                                <IconLogoWithRadius
                                                    height="96px"
                                                    width="96px"
                                                    src={
                                                        allValues?.icon?.length === 1
                                                            ? URL.createObjectURL(
                                                                  allValues?.icon[0],
                                                              )
                                                            : typeof allValues?.icon === "string"
                                                            ? allValues?.icon
                                                            : ""
                                                    }
                                                />
                                                {Icon(Export, {
                                                    size: 40,
                                                    onClick: handleClick,
                                                    title: "Upload Logo",
                                                    style: {
                                                        position: "absolute",
                                                        cursor: "pointer",
                                                        padding: "5px",
                                                        border: "1px solid var(--Color-Border-Default)",
                                                        borderRadius: "50%",
                                                        background:
                                                            "var(--Color-Background-Default)",
                                                    },
                                                })}
                                            </>
                                        ) : (
                                            <ButtonTextWrapper
                                                onClick={handleClick}
                                                title="Upload Logo"
                                            >
                                                {Icon(Export, { size: 46 })}
                                            </ButtonTextWrapper>
                                        )}
                                    </ImageContainer>
                                </Image>
                                <ImageLabel>Logo</ImageLabel>
                            </ImageWrapper>

                            <InputWrapper>
                                <InputLabel>Name *</InputLabel>
                                <TextInput
                                    data-testid="name"
                                    role="name"
                                    label="name"
                                    register={register}
                                    name="name"
                                    validation={{ required: true }}
                                    placeholder="Enter Integration Name"
                                />
                                <StyledErrorSkeleton>
                                    {errors?.name?.type === "required" && (
                                        <StyledError role="error">Name is required</StyledError>
                                    )}
                                </StyledErrorSkeleton>
                            </InputWrapper>
                            <InputWrapper>
                                <InputLabel>Description</InputLabel>
                                <TextArea
                                    data-testid="description"
                                    role="description"
                                    label="description"
                                    register={register}
                                    name="description"
                                    placeholder="Enter Integration Description"
                                />
                            </InputWrapper>
                        </GeneralWrapper>
                        <CredentiallWrapper>
                            <Body_S_Bold>Credentials</Body_S_Bold>
                            {currentMode === "create" ? (
                                <GenerateCredentialBox>
                                    To generate credentials, you must first define at least one
                                    permission from operations or events and then click save.
                                </GenerateCredentialBox>
                            ) : (
                                <>
                                    <InputWrapper>
                                        <InputLabel>Integration Id</InputLabel>
                                        <TextInput
                                            value={allValues?.id}
                                            type={"text"}
                                            disabled={true}
                                        >
                                            <div onClick={() => copyToClipboard(allValues?.id)}>
                                                {Icon(Copy, { size: 21 })}
                                            </div>
                                        </TextInput>
                                    </InputWrapper>
                                    <InputWrapper>
                                        <InputLabel
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                            }}
                                        >
                                            Token
                                            <RefreshToken onClick={() => showRefreshTokenDialog()}>
                                                Refresh Token
                                            </RefreshToken>
                                        </InputLabel>

                                        <PasswordInput
                                            value={allValues?.token}
                                            type={"text"}
                                            disabled={true}
                                        >
                                            <CopyLink
                                                onClick={() => copyToClipboard(allValues?.token)}
                                            >
                                                {Icon(Copy, { size: 21 })}
                                            </CopyLink>
                                        </PasswordInput>
                                    </InputWrapper>
                                </>
                            )}
                        </CredentiallWrapper>
                        <PermissionWrapper>
                            <Header style={{ gap: "0px" }}>
                                <LabelWrapper>
                                    <Body_S_Bold>Define Permissions</Body_S_Bold>
                                    <PermissionChip active={isOnePermission}>
                                        Select at least one permission{" "}
                                        {Icon(Danger, {
                                            size: 16,
                                            color: "var(--Color-Icon-Warning)",
                                        })}
                                    </PermissionChip>
                                </LabelWrapper>

                                <NavBar
                                    route={false}
                                    TABS={tabs}
                                    activeTabIdentifier={editOption}
                                    callback={(tab) => {
                                        setEditOption(tab?.identifier);
                                    }}
                                />
                            </Header>
                            {editOption === "events" && (
                                <CheckBoxGroup state={events} setState={setEvents} />
                            )}
                            {editOption === "operations" && (
                                <CheckBoxGroup state={operations} setState={setOperations} />
                            )}
                        </PermissionWrapper>
                        <InboxWrapper error={!!errors?.hasSurface?.value}>
                            <Header>
                                <LabelWrapper>
                                    <Body_S_Bold>Inbox App</Body_S_Bold>
                                    <SlideToggle
                                        checked={allValues.inbox}
                                        onChange={(e) => setValue("inbox", e)}
                                    />
                                </LabelWrapper>
                                <Body_2XS_Reg>Description</Body_2XS_Reg>
                            </Header>
                            {allValues?.inbox && (
                                <CheckBoxWrapper>
                                    <Checkbox
                                        label={"Web Widget"}
                                        checked={allValues?.surfaces?.includes("web")}
                                        onChange={(e) => onSurfaceChange(e, "web")}
                                    />
                                    <Checkbox
                                        label={"WhatsApp"}
                                        checked={allValues?.surfaces?.includes("whatsapp")}
                                        onChange={(e) => onSurfaceChange(e, "whatsapp")}
                                    />
                                    <Checkbox
                                        label={"Public Webpage"}
                                        checked={allValues?.surfaces?.includes("hosted-web")}
                                        onChange={(e) => onSurfaceChange(e, "hosted-web")}
                                    />
                                    <Checkbox
                                        label={"Phone Call"}
                                        checked={allValues?.surfaces?.includes("call")}
                                        onChange={(e) => onSurfaceChange(e, "call")}
                                    />
                                </CheckBoxWrapper>
                            )}
                            {errors?.hasSurface?.value && (
                                <StyledErrorSkeleton>
                                    <StyledError role="error">
                                        {errors?.hasSurface?.value}
                                    </StyledError>
                                </StyledErrorSkeleton>
                            )}
                        </InboxWrapper>
                        <WebhookWrapper>
                            <Header>
                                <LabelWrapper>
                                    <Body_S_Bold>Webhook</Body_S_Bold>
                                    <SlideToggle
                                        checked={Object.keys(allValues?.webhook).length > 0}
                                        onChange={(e) =>
                                            setValue("webhook", e ? { url: "", secret: "" } : {})
                                        }
                                    />
                                </LabelWrapper>
                                <Body_2XS_Reg>Description</Body_2XS_Reg>
                            </Header>
                            {Object.keys(allValues?.webhook).length > 0 && (
                                <>
                                    <InputWrapper>
                                        <InputLabel>Webhook Url *</InputLabel>
                                        <TextInput
                                            data-testid="webhook.url"
                                            role="webhook.url"
                                            label="webhook.url"
                                            register={register}
                                            name="webhook.url"
                                            validation={{
                                                required: "Webhook url is required",
                                                pattern: {
                                                    value: /^(ftp|http|https):\/\/[^ "]+$/,
                                                    message: "Invalid URL",
                                                },
                                            }}
                                            placeholder="Enter webhook url"
                                        />
                                        {errors?.webhook?.url && (
                                            <StyledErrorSkeleton>
                                                <StyledError role="error">
                                                    {errors?.webhook?.url?.message}
                                                </StyledError>
                                            </StyledErrorSkeleton>
                                        )}
                                    </InputWrapper>
                                    <InputWrapper>
                                        <InputLabel>Secret</InputLabel>
                                        <PasswordInput
                                            data-testid="webhook.secret"
                                            role="webhook.secret"
                                            label="webhook.secret"
                                            register={register}
                                            name="webhook.secret"
                                            placeholder="Enter Secret"
                                        />
                                    </InputWrapper>
                                </>
                            )}
                        </WebhookWrapper>
                    </InnerDiv>
                )}
            </Wrapper>
            <RenderRefreshTokenDialog maxWidth="400px" showCancelButton={false}>
                <ConfirmationDialog
                    title="Refresh token"
                    content={<>Are you sure you want to refresh token of the integration ?</>}
                    okText={"OK"}
                    cancelText={"Cancel"}
                    type="danger"
                    handleOkClick={tokenRefresh}
                    handleCancelClick={hideRefreshTokenDialog}
                    data-testid="edit-integration-confirmation-dialog"
                />
            </RenderRefreshTokenDialog>
            <RenderDeleteAppDialog maxWidth="400px" showCancelButton={false}>
                <ConfirmationDialog
                    title="Delete App"
                    content={
                        <>
                            Are you sure you want to delete the integration. All data will be lost ?
                        </>
                    }
                    okText={"OK"}
                    cancelText={"Cancel"}
                    type="danger"
                    handleOkClick={deleteIntegrations}
                    handleCancelClick={hideDeleteAppDialog}
                    data-testid="delete-integration-confirmation-dialog"
                />
            </RenderDeleteAppDialog>
        </>
    );
};

export default IntegrationDetails;
