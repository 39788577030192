import List from "common/components/List";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import SyncService from "services/sync.service";
import Button from "../../../common/components/Button";
import TextInput from "../../../common/components/TextInput";
import { useDispatch } from "react-redux";
import {
    Body3,
    InputLabel,
    PageTitleHeader,
    PageTitleWrapper,
    PageTitleSubtitleWrapper,
} from "../../../common/global-styled-components";
import { CustomRadioButtonGroup } from "../../../common/components/CustomRadioButton";
import SlideToggle from "../../../common/components/SlideToggle";
import CustomDropdown from "../../../common/components/CustomDropdown";
import { Controller } from "react-hook-form";
import { usePageTitle } from "common/utils";
import { Breakpoints } from "GlobalStyle";
import cronstrue from "cronstrue";
import cronParser from "cron-parser";
import { isEqual } from "lodash";
const _TextInput = styled(TextInput)`
    width: 200px;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const _Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 24px;
    gap: var(--Size-Gap-XXL);
    min-height: 40vh;
    @media (max-width: ${Breakpoints.mobile}px) {
        min-height: unset;
    }
`;
const SelectWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: var(--Size-Gap-XXL);
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;
const RadioGroupWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    max-width: 100%;
    overflow-x: scroll;
`;

const InputRowWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;
const LinkWrapper = styled.a`
    background: var(--Color-Border-Subtle);
    color: var(--Color-Text-Default);
    font-size: 10px;
    padding: var(--Size-Padding-XS) 7px 2px 7px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    align-self: flex-start;
    width: fit-content;
    border-radius: var(--Size-CornerRadius-XXL);
    letter-spacing: 0px;
    margin-top: 12px;
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
`;
const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
    margin-top: 4px;
`;

const CronMeaningSkeleton = styled(Body3)`
    height: var(--body-2-d);
    margin-top: 4px;
`;

const UpdateButton = styled(Button)`
    margin-right: auto;
    @media (max-width: ${Breakpoints.mobile}px) {
        margin: unset;
    }
`;

const CronStyle = styled.div`
    @media (max-width: ${Breakpoints.mobile}px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100px;
    }
`;

const weekdays = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
];
const getListOfNumbers = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => ({
        label: (i + start).toString(),
        value: i + start,
    }));

const hours = getListOfNumbers(0, 23);
const _hours = getListOfNumbers(1, 24);
const minutes = getListOfNumbers(0, 59);
const days = getListOfNumbers(1, 31);

const syncBlockedDatasource = ["files"];

const Schedule = ({
    setValue,
    allValues,
    control,
    errors,
    register,
    handleSubmit,
    dsId,
    setError,
    clearErrors,
    dataSource,
}) => {
    const { orgId, agentId } = useParams();
    usePageTitle(`Copilot Platform - Training History`);
    const dispatch = useDispatch();
    const [cronMeaning, setCronMeaning] = useState("");
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (allValues?.enableSchedule === false) {
            setIsChanged(false);
        } else if (allValues?.enableSchedule) {
            let fields = [];
            switch (allValues.scheduleType) {
                case "hourly":
                    fields = ["hour"];
                    break;
                case "daily":
                    fields = ["hour", "minute"];
                    break;
                case "weekly":
                    fields = ["hour", "minute", "weekday"];
                    break;
                case "monthly":
                    fields = ["hour", "minute", "date"];
                    break;
                case "cronExp":
                    fields = ["cronExp"];
                    break;
                default:
                    break;
            }

            if (fields.length) {
                const isStatusEqual = fields.every((field) =>
                    isEqual(dataSource?.[field], allValues?.[field]),
                );
                setIsChanged(!isStatusEqual);
            }
        }
    }, [dataSource, allValues]);

    const isValidCronExpression = (cronExpression) => {
        const cronregex = new RegExp(
            /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/,
        );
        return cronregex.test(cronExpression);
    };

    const isLessThanTwoHours = (cronExpression) => {
        try {
            if (!cronExpression || cronExpression.trim() === "") {
                return false;
            }

            const interval = cronParser.parseExpression(cronExpression);
            const firstOccurrence = interval.next().toDate().getTime();
            const secondOccurrence = interval.next().toDate().getTime();
            const timeBetweenOccurrences = secondOccurrence - firstOccurrence;
            const hoursBetweenOccurrences = timeBetweenOccurrences / (1000 * 60 * 60);
            return hoursBetweenOccurrences > 2;
        } catch (error) {
            return false;
        }
    };

    const handleCronExpChange = (event) => {
        const newCronExpression = event.target.value.trim();
        if (!isValidCronExpression(newCronExpression)) {
            setCronMeaning("");
            setError("cronExp", {
                type: "custom",
                message: "Invalid Cron expression",
            });
            return;
        }

        if (!isLessThanTwoHours(newCronExpression)) {
            setCronMeaning("");
            setError("cronExp", {
                type: "custom",
                message: "Cron expression represents a schedule less than 2 hours",
            });
            return;
        }

        try {
            clearErrors("cronExp");
            const meaning = cronstrue.toString(newCronExpression);
            setCronMeaning(meaning);
        } catch (error) {
            setError("cronExp", {
                type: "custom",
                message: "Invalid Cron expression",
            });
        }
    };

    const saveSchedule = () => {
        try {
            if (syncBlockedDatasource.includes(allValues?.type)) {
                toast.info(`You can't save details for datasource ${allValues?.type}`, {
                    autoClose: 2000,
                });
                return;
            }
            const data = {
                hours: [typeof allValues.hour !== "undefined" ? allValues.hour : "*"],
                minutes: [typeof allValues.minute !== "undefined" ? allValues.minute : "*"],
                mdays:
                    allValues.scheduleType === "monthly"
                        ? [typeof allValues.date !== "undefined" ? allValues.date : "*"]
                        : ["*"],
                wdays:
                    allValues.scheduleType === "weekly"
                        ? [typeof allValues.weekday !== "undefined" ? allValues.weekday : "*"]
                        : ["*"],
                months: [typeof allValues.month !== "undefined" ? allValues.month : "*"],
                type: allValues.scheduleType,
                active: allValues.enableSchedule,
                cronExp: allValues.cronExp,
            };
            SyncService.scheduleDataSourcesSync(orgId, agentId, dsId, data, dispatch)
                .then(() => {
                    toast.success("Sync Schedule updated", {
                        autoClose: 2000,
                    });
                })
                .catch((error) => {
                    let { message } = error?.response?.data;
                    toast.error(message || "Something went wrong", {
                        autoClose: 2000,
                    });
                });
        } catch (error) {
            let { message } = error?.response?.data;
            toast.error(message || "Something went wrong", {
                autoClose: 2000,
            });
        }
    };

    const options = [
        {
            value: "hourly",
            child: <InputLabel>Hourly</InputLabel>,
            component: (
                <>
                    <InputWrapper>
                        <InputLabel>Hour</InputLabel>
                        <Controller
                            name={`hour`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Hour selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select hour"}
                                    options={_hours}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={_hours?.find((i) => i.value === allValues.hour) || null}
                                    register={register}
                                    role="hour"
                                    type="hour"
                                    label="hour"
                                    name="hour"
                                    onChange={(e) => {
                                        setValue("hour", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.hour?.message || errors?.hour?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                </>
            ),
        },
        {
            value: "daily",
            child: <InputLabel>Daily</InputLabel>,
            component: (
                <>
                    <InputWrapper>
                        <InputLabel>Hour</InputLabel>
                        <Controller
                            name={`hour`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Hour selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select hour"}
                                    options={hours}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={hours?.find((i) => i.value === allValues.hour) || null}
                                    register={register}
                                    role="hour"
                                    type="hour"
                                    label="hour"
                                    name="hour"
                                    onChange={(e) => {
                                        setValue("hour", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.hour?.message || errors?.hour?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Minute</InputLabel>
                        <Controller
                            name={`minute`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Hour selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select hour"}
                                    options={minutes}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={
                                        minutes?.find((i) => i.value === allValues.minute) || null
                                    }
                                    register={register}
                                    role="minute"
                                    type="minute"
                                    label="minute"
                                    name="minute"
                                    onChange={(e) => {
                                        setValue("minute", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.minute?.message || errors?.minute?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                </>
            ),
        },
        {
            value: "weekly",
            child: <InputLabel>Weekly</InputLabel>,
            component: (
                <>
                    <InputWrapper>
                        <InputLabel>Weekday</InputLabel>
                        <Controller
                            name={`weekday`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Weekday selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select weekday"}
                                    options={weekdays}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={
                                        weekdays?.find((i) => i.value === allValues.weekday) || null
                                    }
                                    register={register}
                                    role="weekday"
                                    type="weekday"
                                    label="weekday"
                                    name="weekday"
                                    onChange={(e) => {
                                        setValue("weekday", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.weekday && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.weekday?.message || errors?.weekday?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Hour</InputLabel>
                        <Controller
                            name={`hour`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Hour selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select hour"}
                                    options={hours}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={hours?.find((i) => i.value === allValues.hour) || null}
                                    register={register}
                                    role="hour"
                                    type="hour"
                                    label="hour"
                                    name="hour"
                                    onChange={(e) => {
                                        setValue("hour", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.hour?.message || errors?.hour?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Minute</InputLabel>
                        <Controller
                            name={`minute`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Minute selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select Minute"}
                                    options={minutes}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={
                                        minutes?.find((i) => i.value === allValues.minute) || null
                                    }
                                    register={register}
                                    role="minute"
                                    type="minute"
                                    label="minute"
                                    name="minute"
                                    onChange={(e) => {
                                        setValue("minute", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.minute?.message || errors?.minute?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                </>
            ),
        },
        {
            value: "monthly",
            child: <InputLabel>Monthly</InputLabel>,
            component: (
                <>
                    <InputWrapper>
                        <InputLabel>Date</InputLabel>
                        <Controller
                            name={`date`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Date selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select date"}
                                    options={days}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={days?.find((i) => i.value === allValues.date) || null}
                                    register={register}
                                    role="date"
                                    type="date"
                                    label="date"
                                    name="date"
                                    onChange={(e) => {
                                        setValue("date", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.date && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.date?.message || errors?.date?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Hour</InputLabel>
                        <Controller
                            name={`hour`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Hour selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select hour"}
                                    options={hours}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={hours?.find((i) => i.value === allValues.hour) || null}
                                    register={register}
                                    role="hour"
                                    type="hour"
                                    label="hour"
                                    name="hour"
                                    onChange={(e) => {
                                        setValue("hour", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.hour?.message || errors?.hour?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Minute</InputLabel>
                        <Controller
                            name={`minute`}
                            control={control}
                            defaultValue={""}
                            rules={{ required: "Minute selection is required" }}
                            render={({ field }) => (
                                <CustomDropdown
                                    {...field}
                                    ref={null}
                                    placeholder={"Select Minute"}
                                    options={minutes}
                                    showArrow={false}
                                    wrapperHeight="56px"
                                    value={
                                        minutes?.find((i) => i.value === allValues.minute) || null
                                    }
                                    register={register}
                                    role="minute"
                                    type="minute"
                                    label="minute"
                                    name="minute"
                                    onChange={(e) => {
                                        setValue("minute", e.value);
                                    }}
                                    validation={{ required: true }}
                                    width={"200px"}
                                    isDisabled={!allValues.enableSchedule}
                                />
                            )}
                        />
                        {errors.hour && (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.minute?.message || errors?.minute?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        )}
                    </InputWrapper>
                </>
            ),
        },
        {
            value: "cronExp",
            child: <InputLabel>Custom</InputLabel>,
            component: (
                <>
                    <InputWrapper>
                        <InputLabel>Cron Expression*</InputLabel>
                        <_TextInput
                            name="cronExp"
                            role="cronExp"
                            label="cronExp"
                            placeholder="*  /1  *  *  *  * "
                            height="156px"
                            margin="0"
                            register={register}
                            setError={setError}
                            onChange={handleCronExpChange}
                            validation={{
                                required: "expression is required",
                                pattern: {
                                    value: /^[^ \t]+ [^ \t]+ [^ \t]+ [^ \t]+ [^ \t]+$/,
                                    message: "Invalid Cron expression",
                                },
                                validate: {
                                    isValidCron: (value) =>
                                        isValidCronExpression(value) || "Invalid Cron expression",
                                    isLessThanTwoHours: (value) =>
                                        isLessThanTwoHours(value) ||
                                        "Cron expression represents a schedule less than 2 hours",
                                },
                            }}
                            data-testid="datasource-cronExp"
                        ></_TextInput>
                        {errors?.cronExp?.message ? (
                            <StyledErrorSkeleton>
                                <StyledError role="error">
                                    {errors?.cronExp?.message || errors?.cronExp?.label?.message}
                                </StyledError>
                            </StyledErrorSkeleton>
                        ) : (
                            <CronMeaningSkeleton>
                                <CronStyle>{cronMeaning}</CronStyle>
                            </CronMeaningSkeleton>
                        )}
                        <LinkWrapper href="https://crontab.guru/" target="_blank">
                            Cron Explorer
                        </LinkWrapper>
                    </InputWrapper>
                </>
            ),
        },
    ];

    return (
        <>
            {!syncBlockedDatasource.includes(allValues?.type) && (
                <>
                    <_Wrapper>
                        <Title>
                            <PageTitleWrapper>
                                <PageTitleHeader>Auto Sync Schedule</PageTitleHeader>
                                <PageTitleSubtitleWrapper>
                                    Automatically train your datasources at a specific time
                                </PageTitleSubtitleWrapper>
                            </PageTitleWrapper>
                            <SlideToggle
                                onChange={(e) => {
                                    setValue("enableSchedule", e);
                                    if (!allValues.scheduleType) setValue("scheduleType", "hourly");
                                }}
                                checked={allValues.enableSchedule || false}
                            ></SlideToggle>
                        </Title>
                        {allValues.enableSchedule && (
                            <>
                                <RadioGroupWrapper>
                                    <InputRowWrapper>
                                        <CustomRadioButtonGroup
                                            register={register}
                                            name="scheduleType"
                                            label="scheduleType"
                                            elements={options}
                                            disabled={!allValues.enableSchedule}
                                        ></CustomRadioButtonGroup>
                                    </InputRowWrapper>
                                </RadioGroupWrapper>
                                <SelectWrapper>
                                    {allValues.scheduleType &&
                                        options?.find((o) => o?.value === allValues?.scheduleType)
                                            ?.component}
                                </SelectWrapper>
                            </>
                        )}
                        <UpdateButton
                            mode="primary"
                            disabled={!isChanged || !(Object.keys(errors).length === 0)}
                            onClick={handleSubmit(saveSchedule)}
                        >
                            Update
                        </UpdateButton>
                    </_Wrapper>
                </>
            )}
        </>
    );
};
export default Schedule;
