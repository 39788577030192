import React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import styled from "styled-components";
import Icon from "common/components/Icon";
import { ArrowDown2 } from "iconsax-react";

const Wrapper = styled.div`
    width: ${(props) => (props.width ? props.width : "")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "")};
    align-self: ${(props) => (props.align ? props.align : "")};
    font-size: var(--body-2-d);
    @media (max-width: 768px) {
        font-size: var(--body-2-m);
        height: 40px;
    }
    .react-select__control {
        @media (max-width: 768px) {
            font-size: var(--body-2-m);
            height: 40px;
        }
        border-radius: var(--Size-CornerRadius-L);
        border: 1px solid var(--Color-Border-Subtle);
        height: ${(props) => (props.height ? props.height : "40px")};
        min-height: ${(props) => props.minHeight && props.minHeight};
        width: ${(props) => props.width};
        background: ${(props) => (props.background ? props?.background : "transparent")};
        box-shadow: none;
        cursor: pointer;
        // min-height: 32px;
        &--menu-is-open {
            .react-select__dropdown-indicator {
                transform: rotate(180deg);
            }
            border-color: var(--Color-Text-Default) !important;
        }
        :hover {
            border-color: var(--Color-Text-Subtlest);
        }
    }
    .react-select__single-value {
        border: none;
        margin: 0px;
        color: ${(props) =>
            props.isDisabled ? "var(--Color-Text-Disabled)" : "var(--Color-Text-Subtle)"};
    }
    .react-select__indicator-separator {
        display: none;
    }
    .react-select__dropdown-indicator {
        display: ${(props) => (props.hideIndicator ? "none" : "flex")};
        background-image: ${(props) =>
            props.showArrow &&
            `${Icon(ArrowDown2, { size: 15, color: "var(--Color-Text-Default)" })}`};
        background-repeat: no-repeat;
        padding: 8px 12px;
        background-size: contain;
    }
    .react-select__menu-portal {
        left: unset;
        z-index: 5;
    }
    .react-select__menu {
        border: none;
        border-radius: var(--Size-CornerRadius-L);
        backdrop-filter: blur(60px);
        box-shadow: 0 0 15px rgba(102, 102, 102, 0.25);
        background-color: var(--Color-Background-Subtle);
    }
    .react-select__option {
        height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        :hover {
            :first-child {
                border-radius: 5px 5px 0px 0px;
            }
            :last-child {
                border-radius: 0 0px 5px 5px;
            }
        }

        &--is-disabled {
            cursor: not-allowed;
        }
    }

    .react-select__input {
        font-family: var(--body-font);
        color: var(--Color-Text-Subtle);
    }
    .react-select__input-container {
        font-family: var(--body-font);
        color: var(--Color-Text-Defult);
        font-size: var(--body-2-d);
    }
    .react-select__placeholder {
        font-family: var(--body-font);
        font-style: normal;
        font-weight: normal;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: var(--Color-Icon-Subtle);
    }
    .react-select--is-disabled {
        .react-select__control {
            border-color: var(--Color-Border-Subtle);
        }
        .react-select__placeholder {
            color: var(--Color-Text-Disabled);
        }
        .react-select__dropdown-indicator {
            svg {
                fill: var(--Color-Icon-Disabled);
            }
        }
    }
`;

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: "var(--Color-Background-Action)",
        primary75: "var(--Color-Background-Disabled)",
        primary50: "var(--Color-Background-Subtle)",
        primary25: "var(--Color-Background-Subtle-2)",
        danger: "var(--Color-Background-Danger)",
        dangerLight: "var(--Color-Background-Warning)",
    },
});

const CustomDropdown = ({
    loadOptions,
    onChange,
    isSearchable = true,
    placeholder = "Select",
    wrapperWidth,
    wrapperHeight,
    wrapperMinHeight,
    wrapperAlign,
    options,
    showArrow = true,
    hideIndicator,
    async = false,
    disabled = false,
    isLoading = false,
    ...rest
}) => {
    return (
        <Wrapper
            width={wrapperWidth}
            height={wrapperHeight}
            minHeight={wrapperMinHeight}
            align={wrapperAlign}
            showArrow={showArrow}
            hideIndicator={hideIndicator}
            {...rest}
        >
            {async ? (
                <AsyncSelect
                    className={"react-select-container"}
                    classNamePrefix={"react-select"}
                    theme={theme}
                    onChange={onChange}
                    defaultOptions={true}
                    loadOptions={loadOptions}
                    onMenuScrollToBottom={loadOptions}
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isLoading={isLoading}
                    {...rest}
                />
            ) : (
                <Select
                    className={"react-select-container"}
                    classNamePrefix={"react-select"}
                    theme={theme}
                    options={options}
                    onChange={onChange}
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isLoading={isLoading}
                    {...rest}
                />
            )}
        </Wrapper>
    );
};

export default CustomDropdown;
