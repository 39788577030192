import React from "react";
import styled from "styled-components";
import { Body_XXS_Reg } from "common/global-styled-components";
const Wrapper = styled.div`
    display: flex;
    padding: var(--Size-Padding-S) var(--Size-Padding-M);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--Color-Background-Gradient-Action);
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
`;
export default function Tag({ icon, title, mode }) {
    switch (mode) {
        case "primary":
        default:
            return (
                <Wrapper>
                    {icon}
                    <Body_XXS_Reg style={{ color: "var(--Color-Text-Inverse)" }}>
                        {title}
                    </Body_XXS_Reg>
                </Wrapper>
            );
    }
}
