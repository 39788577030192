import React from "react";

function EllipsisIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // width="24"
            // height="24"
            viewBox="0 0 24 24"
            {...props}
        >
            {props.title && <title>{props.title}</title>}
            <path d="M12 6c1.6 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3 1.4 3 3 3zm0 3c-1.6 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.4-3-3-3zm0 9c-1.6 0-3 1.4-3 3s1.3 3 3 3 3-1.4 3-3a3 3 0 0 0-3-3z"></path>
        </svg>
    );
}

export default EllipsisIcon;
