import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import List from "common/components/List";
import Button from "common/components/Button";
import { useParams } from "react-router-dom";
import { Body_XS_Med, Body_M_Reg, Body_M_Med, Body_M_Bold } from "common/global-styled-components";
import LoaderIcon from "common/components/LoaderIcon";
import TextInput from "common/components/TextInput";
import BoxLoader from "common/components/BoxLoader";
import { useDispatch } from "react-redux";
import AdvancedPagination from "common/components/AdvancedPagination";
import NoDataFound from "common/components/NoDataFound";
import debounce from "lodash.debounce";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import { SOURCE_TYPES } from "pages/DataSources/types";
import CopilotService from "services/copilot.service";
import { toast } from "react-toastify";
import { GradientText } from "common/global-styled-components";

const DialogWrapper = styled.div`
    flex: 1;
    padding: var(--Size-Padding-XXL);
    color: var(--white-90);
    max-height: 90vh;
    overflow: auto;
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Subtle);
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
`;

const NonOptedServiceList = styled.label`
    display: flex;
    justify-content: space-between;
    padding: 14px 4px;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid var(--Color-Border-Subtle);
    background-color: var(--Color-Background-Subtle);
`;

const ServiceName = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-L);
    font-size: var(--body-lead-d);
    font-weight: 600;
    line-height: var(--line-height-25);
`;

const NoDataFoundWrapper = styled(NoDataFound)`
    // height: unset;
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Default);
`;

const HeaderWrapper = styled.div`
    margin-left: 20px;
    margin-top: 20px;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ListWrapper = styled.div`
    max-height: 350px;
    overflow-y: scroll;
    padding: var(--Size-Padding-L);
`;
const AddDataSource = styled.div`
    color: var(--Color-Text-Success);
    text-decoration: underline;
    cursor: pointer;
`;

const DatasourceSelectionList = ({ addDatasources }) => {
    const { orgId, agentId } = useParams();
    const [searchName, setSearchName] = useState("");
    const [nonOptedDatasourceList, setNonOptedDatasourcesList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isPageLoading, setPageLoading] = useState(true);
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });

    const debounceFetch = useMemo(() => debounce(fetchDatasourcesServices, 300), []);
    useEffect(() => {
        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetch(orgId, queryString);
        }
    }, [currentPage, searchName]);

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";

        return [pageQuery, pageSizeQuery].join("&");
    };
    const setNonOptedDatasourcesListData = (items, pageNum) => {
        setNonOptedDatasourcesList(items);
    };

    function fetchDatasourcesServices(orgId, queryString) {
        const query = `${queryString}&active=${false}`;
        CopilotService.getCopilotDataSources(orgId, agentId, query)
            .then((res) => {
                setNonOptedDatasourcesListData(res.data.items, res.data.page.page);
                setPageDetails(res.data.page);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong", {
                    autoClose: 2000,
                });
                setNonOptedDatasourcesList([]);
                setPageDetails({});
            })
            .finally(() => {
                setPageLoading(false);
            });
    }

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    return (
        <>
            <DialogWrapper data-testid="upload-datasource">
                <List.ListLayout>
                    <Body_M_Bold>Datasources</Body_M_Bold>

                    <SearchPlaceHolder
                        searchName={searchName}
                        handleSearchChange={handleSearchChange}
                        width="70%"
                    />

                    {isPageLoading ? (
                        <Loader>
                            <LoaderIcon />
                        </Loader>
                    ) : nonOptedDatasourceList.length ? (
                        <>
                            <ListWrapper>
                                {nonOptedDatasourceList.map((service) => (
                                    <List.ListItem key={service.id}>
                                        <NonOptedServiceList>
                                            <ServiceName>
                                                {
                                                    SOURCE_TYPES.find(
                                                        (val) => val.value === service.type,
                                                    )?.icon
                                                }
                                                {service.name}{" "}
                                            </ServiceName>
                                            <AddDataSource onClick={() => addDatasources(service)}>
                                                Connect
                                            </AddDataSource>
                                        </NonOptedServiceList>
                                    </List.ListItem>
                                ))}
                            </ListWrapper>
                            <AdvancedPagination
                                page={pageDetails}
                                pageSize={pageSize}
                                paginationInfo="datasource"
                                setPageSize={setPageSize}
                                setCurrentPage={setCurrentPage}
                            />
                        </>
                    ) : (
                        <NoDataFoundWrapper>
                            <>
                                <DataNotFoundMessage>
                                    Your Copilot is currently set up for new connections, ready to
                                    connect data sources.
                                </DataNotFoundMessage>
                            </>
                        </NoDataFoundWrapper>
                    )}
                </List.ListLayout>
            </DialogWrapper>
        </>
    );
};
export default React.memo(DatasourceSelectionList);
