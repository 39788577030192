import React from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 24px;
    background-color: var(--Color-Background-Subtle-2);
    border-radius: 50px;
    overflow: hidden;
`;

const Progress = styled.div`
    background: var(--Color-Background-Gradient-Action);
    border-radius: 40px;
    width: ${(props) => props.percentage};
    height: 100%;
    transition: width 0.5s ease-in-out;
`;

const ProgressBar = ({ percentage }) => {
    return (
        <ProgressBarContainer>
            <Progress percentage={percentage} />
        </ProgressBarContainer>
    );
};

export default ProgressBar;
