import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import BoxLoader from "common/components/BoxLoader";
import FormFields from "./FormFields";
import CustomSideNavBar from "common/components/CustomList";
import { Breakpoints } from "GlobalStyle";
import Button from "common/components/Button";

const NavBarWrapper = styled.div`
    height: fit-content;
    padding: var(--Size-Padding-XL);
    width: 50%;
    flex-flow: column;
    max-width: 200px;
    @media (min-width: ${Breakpoints.mobile}px) {
        display: flex;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
`;
Form.Subheader = styled.header``;
Form.Subheader.Wrapper = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
Form.Subheader.Title = styled.h2`
    font-size: var(--body-2-d);
    line-height: var(--line-height-19);
    font-weight: 600;
    color: var(--light-200);
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`;
Form.Subheader.Subtitle = styled.p`
    font-size: var(--body-4-d);
    color: var(--light-300);
    line-height: var(--line-height-16);
`;
Form.Subheader.Actions = styled.div`
    display: flex;

    button:first-child {
        margin-right: 16px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ArrayFormWrapper = styled.div`
    display: flex;
    gap: 40px;
    border: 1px solid var(--Color-Border-Default);
    padding: 33px;
`;
Form.Subbody = styled.section`
    width: 100%;
    margin-bottom: 40px;
`;

export default function ArrayForm({
    register,
    handleSubmit,
    errors,
    name,
    allValues,
    onSubmit,
    reset,
    form,
    control,
}) {
    const [tabs, setTabs] = useState([
        {
            id: -1,
            displayName: `Add ${form.displayName}`,
            identifier: "addNodeGroup",
            active: true,
        },
    ]);

    useEffect(() => {
        if (allValues?.[form?.id]?.length > 0) {
            let newTab = allValues?.[form?.id].map((val) => {
                return {
                    "id": val.id,
                    "displayName": val.databaseName,
                    "identifier": val.databaseName,
                    "active": false,
                };
            });
            resetForm();
            setTabs([...tabs, ...newTab]);
        }
    }, []);

    const getGroupCredentials = (fields) => {
        const groupValue = fields.map(({ id }) => {
            return { [id]: allValues?.credentials?.[id] };
        });
        return Object.assign({}, ...groupValue);
    };

    const resetGroupCredentials = (fields) => {
        const resetGroupValue = fields.map(({ id, type, defaultValue }) => {
            if (type === "toggle") {
                return { [id]: defaultValue };
            }
            return { [id]: defaultValue || null };
        });
        return Object.assign({}, ...resetGroupValue);
    };

    const findActiveElement = () => {
        const activeElement = tabs
            .map((val, index) => {
                if (val.active === true) {
                    return {
                        "elementIndex": index,
                        "elementToBeEdited": val,
                    };
                }
            })
            .filter(Boolean);
        return activeElement[0];
    };

    const findActiveElementDetails = (id) => {
        let details = [...allValues[form.id]].find((item) => item.id === id);
        return details;
    };

    const scrollTop = () => {
        document.querySelector("#array-form").scrollIntoView({ behavior: "smooth" });
    };

    const onCreateGroup = () => {
        let newGroupArrayValue = getGroupCredentials(form?.fieldsSchema);
        let arrayValue = [...allValues[form.id]];
        newGroupArrayValue["id"] = arrayValue?.slice(-1)[0]?.["id"] + 1 || 0; // Creating Id of new tab
        arrayValue.push(newGroupArrayValue);

        reset({
            ...allValues,
            [form.id]: arrayValue,
        });

        let newTab = tabs.map((val) => {
            val.active = false;
            return val;
        });
        setTabs([
            ...newTab,
            {
                id: newGroupArrayValue.id,
                displayName: newGroupArrayValue.databaseName,
                identifier: newGroupArrayValue.databaseName,
                active: true,
            },
        ]);
        toast.success(`${newGroupArrayValue?.databaseName} Created Successfully `, {
            autoClose: 2000,
        });
        scrollTop();
    };

    const resetForm = () => {
        let newCredentials = resetGroupCredentials(form?.fieldsSchema);
        reset({
            ...allValues,
            credentials: { ...allValues.credentials, ...newCredentials },
        });
        scrollTop();
    };

    const onUpdateGroup = () => {
        const { elementIndex, elementToBeEdited } = findActiveElement();
        let activeobjIndex = [...allValues[form.id]].findIndex(
            (obj) => obj.id == elementToBeEdited.id,
        );

        let arrayValue = [...allValues[form.id]];
        arrayValue[activeobjIndex] = getGroupCredentials(form?.fieldsSchema);
        arrayValue[activeobjIndex]["id"] = elementToBeEdited.id;
        reset({
            ...allValues,
            [form.id]: [...arrayValue],
        });
        let newTab = [...tabs];
        newTab[elementIndex] = {
            id: elementToBeEdited.id,
            displayName: arrayValue[activeobjIndex].databaseName,
            identifier: arrayValue[activeobjIndex].databaseName,
            active: true,
        };
        setTabs([...newTab]);
        toast.success(`${elementToBeEdited?.displayName} Updated Successfully`, {
            autoClose: 2000,
        });
        scrollTop();
    };

    const onDeleteGroup = (e) => {
        e.preventDefault();
        const { elementIndex, elementToBeEdited } = findActiveElement();
        const newTab = [...tabs];
        newTab.splice(elementIndex, 1);
        newTab[0]["active"] = true;
        setTabs([...newTab]);
        let arrayValue = [...allValues[form.id]].filter(
            (item, id) => item.id !== elementToBeEdited.id,
        );
        let newCredentials = resetGroupCredentials(form?.fieldsSchema);
        reset({
            ...allValues,
            credentials: { ...allValues.credentials, ...newCredentials },
            [form.id]: [...arrayValue],
        });
        toast.success(`NodeGroup ${elementToBeEdited?.displayName} Deleted Successfully`, {
            autoClose: 2000,
        });
        scrollTop();
    };

    const onTabClick = (e, itemIdentifier, id) => {
        let newTab = tabs.map((val) => {
            val.active = Boolean(val.id === id);
            return val;
        });
        if (itemIdentifier === "addNodeGroup") {
            resetForm();
            return;
        }
        let newValue = findActiveElementDetails(id);
        let newCredentials = { ...allValues.credentials, ...newValue };
        reset({
            ...allValues,
            credentials: { ...newCredentials },
        });
    };

    return (
        <Form id="array-form">
            <BoxLoader stretch={true} loading={form.length === 0}>
                {form?.length !== 0 && (
                    <ArrayFormWrapper>
                        <NavBarWrapper>
                            <CustomSideNavBar tabs={tabs} onClick={onTabClick}></CustomSideNavBar>
                        </NavBarWrapper>
                        <Form.Subbody>
                            {form?.fieldsSchema?.length
                                ? form?.fieldsSchema.map((item) => (
                                      <FormFields
                                          key={item.id}
                                          item={item}
                                          register={register}
                                          control={control}
                                          allValues={allValues}
                                          errors={errors}
                                          reset={reset}
                                      />
                                  ))
                                : null}
                            {tabs[0].active ? (
                                <Button
                                    size="small"
                                    marginLeft="auto"
                                    onClick={handleSubmit(onCreateGroup)}
                                >
                                    Add
                                </Button>
                            ) : (
                                <ButtonWrapper>
                                    <Button size="small" onClick={onDeleteGroup}>
                                        Delete
                                    </Button>
                                    <Button size="small" onClick={handleSubmit(onUpdateGroup)}>
                                        Update
                                    </Button>
                                </ButtonWrapper>
                            )}
                        </Form.Subbody>
                    </ArrayFormWrapper>
                )}
            </BoxLoader>
        </Form>
    );
}
