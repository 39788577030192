import React from "react";
import ReactJoyride from "react-joyride";

function JoyRide({ runTourGuide = false, steps, styles = {} }) {
    return (
        <ReactJoyride
            continuous={true}
            run={runTourGuide}
            steps={steps}
            disableScrollParentFix={true}
            styles={{
                options: {
                    arrowColor: "var(--Color-Background-Default)",
                    padding: 16,
                    backgroundColor: "var(--Color-Background-Default)",
                    textColor: "var(--Color-Text-Default)",
                    fontSize: 14,
                    width: 500,
                    zIndex: 1000,
                },

                buttonNext: {
                    backgroundColor: "var(--Color-Background-Action)",
                    borderRadius: 12,
                    color: "var(--Color-Text-Inverse)",
                    padding: "8px 16px",
                },

                spotlight: {
                    borderRadius: 16,
                },
                tooltip: {
                    borderRadius: 32,
                },
                tooltipTitle: {
                    fontSize: 20,
                    textAlign: "left",
                },
                tooltipContent: {
                    fontSize: 16,
                    padding: "20px 0px",
                    textAlign: "left",
                },
                buttonClose: {
                    top: "7px",
                },
                overlay: {
                    // backgroundColor: "var(--Color-Background-Default)",
                },
                ...styles,
            }}
        />
    );
}

export default JoyRide;
