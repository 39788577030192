import React from "react";
import styled from "styled-components";

import LoaderIcon from "common/components/LoaderIcon";

const Wrapper = styled.div`
    position: relative;
    ${(props) => (props.stretch ? "height: 100%; width: 100%;" : "")};
    height: ${(props) => (props.height ? props.height : "100%")};
    // overflow: hidden;
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        -webkit-border-radius: 1px;
        width: 0px;
        // border-radius: 1px;
    }
    overflow: ${(props) => (props.overflow ? props.overflow : "hidden")};
    display: flex;
    align-items: center;
`;

const LoaderContainer = styled.div`
    height: ${(props) => (props.height ? props.height : "100%")};
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: ${(props) => (props.transparent ? "transparent" : props.backgroundColor)};
    opacity: ${(props) => (props.opacity ? props.opacity : "0")};
`;

export default function BoxLoader({
    loading = false,
    stretch = false,
    transparent = false,
    opacity = "0",
    backgroundColor = "#101112",
    children,
    ...restProps
}) {
    return (
        <>
            <Wrapper stretch={stretch} {...restProps}>
                {loading && (
                    <LoaderContainer
                        transparent={transparent}
                        opacity={opacity}
                        backgroundColor={backgroundColor}
                        data-testid="loader-container"
                    >
                        <LoaderIcon />
                    </LoaderContainer>
                )}
                {children}
            </Wrapper>
        </>
    );
}
