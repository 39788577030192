import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import List from "common/components/List";
import Button from "common/components/Button";
import { useParams } from "react-router-dom";
import {
    Body_XXS_Reg,
    Body_M_Reg,
    Body_M_Med,
    Body_S_Med,
    Body_XS_Med,
    Body_XS_Reg,
    Body_L_Bold,
    PageContent,
    StickyPageHeader,
    StickyPageFooter,
    SubTitle1,
    SubTitle2,
} from "common/global-styled-components";
import LoaderIcon from "../../../common/components/LoaderIcon";
import AdvancedPagination from "common/components/AdvancedPagination";
import NoDataFound from "common/components/NoDataFound";
import debounce from "lodash.debounce";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import Icon from "common/components/Icon";
import { AddCircle, CloseCircle, Flash } from "iconsax-react";
import { Breakpoints } from "GlobalStyle";
import IconLogo from "common/components/Logo/IconLogo";
import { useModal } from "../../../common/components/NewModal";
import Credentials from "pages/Skills/Install/Credentials";
import CopilotService from "../../../services/copilot.service";
import SkillsListPage from "pages/Skills/List";
import SlideToggle from "common/components/SlideToggle";
import { toast } from "react-toastify";
import { getSkills } from "common/redux/actions/skillActions";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { usePageTitle } from "common/utils";
import ConfirmationDialog from "common/components/ConfirmationDialog";

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
`;

const NoDataFoundWrapper = styled(NoDataFound)`
    height: 100%;
    width: 100%;
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Subtle);
`;

const NoSkillButton = styled(Button)`
    color: var(--Color-Text-Inverse);
    border-radius: var(--Size-CornerRadius-L);
    padding: var(--Size-Padding-L) 24px 12px 24px;
    display: flex;
    gap: var(--Size-Gap-M);
`;

const HeaderWrapper = styled.div`
    // margin-left: 20px;
    // margin-top: 20px;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--Size-Gap-L);
`;

const SearchPlaceHolderWrapper = styled(SearchPlaceHolder)`
    height: 12px;
`;
const AddSkill = styled.div`
    cursor: pointer;
`;

const SkillsWrapper = styled.div`
    display: grid;
    gap: var(--Size-Gap-XL);
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-content: center;

    @media (max-width: ${Breakpoints.mobile}px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
`;

const Skill = styled.div`
    display: flex;
    cursor: pointer;
    padding: var(--Size-Padding-XXL);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-XL);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const SkillHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const SkillDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-S);
    align-self: stretch;
`;

const SkillDescription = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SkillName = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const SkillGroup = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-S) 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Subtle);
    color: var(--Color-Text-Default);
`;

const SkillsListPageWrapper = styled.div`
    overflow: auto;
    max-height: 95vh;
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
`;

const EditSkillModal = styled.div`
    transform: ${(props) => (props.isVisible ? "translateY(0)" : "translateY(-100vh)")};
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    position: fixed;
    padding: var(--Size-Padding-XL);
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(1px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    background: rgb(16, 17, 18, 0.6);
    z-index: 3;
`;

const EditSkillWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    gap: 20px;
    background: var(--Color-Background-Subtle);
    padding: 22px 40px;
    overflow: var(--Size-Padding-XXL) var(--Size-Padding-XXXL) var(--Size-Padding-XXXL)
        var(--Size-Padding-XXXL);
    border-radius: var(--Size-CornerRadius-XL);
    width: 80vw;
`;

const CloseIcon = styled(Button)`
    position: absolute;
    top: 11px;
    right: 0;
    color: currentcolor;
    border: 0;
    :hover {
        background: var(--Color-Background-Subtle);
    }
`;
const DefaultIcon = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
`;
const StyledPageContent = styled(PageContent)`
    background: transparent;
    margin: var(--Size-Padding-XXL) 0px;
    padding: 0;
    gap: var(--Size-Gap-XXXL);
    width: 100%;
    max-height: 70vh;
    overflow: auto;
    border-radius: 0px;
`;

const HeaderTitle = styled(SubTitle2)`
    @media (max-width: ${Breakpoints.mobile}px) {
        margin-top: -8px;
        margin-left: -12px;
    }
`;
const Skills = ({ setShowCopilotPlaygroundWidget }) => {
    const { orgId, agentId } = useParams();
    const [searchName, setSearchName] = useState("");
    const [copilotSkillsList, setCopilotSkillsList] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [skillsList, setSkillsList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isPageLoading, setPageLoading] = useState(true);
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [editmodalVisible, setEditModalVisible] = useState(false);
    const dispatch = useDispatch();
    usePageTitle(`Copilot Platform - Copilot Skills`);
    const {
        unregister,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
        control,
        watch,
    } = useForm({
        mode: "all",
        defaultValues: { credentials: {}, inactiveSkills: [], type: "" },
    });
    const allValues = watch();

    const debounceFetch = useMemo(() => debounce(fetchCopilotSkills, 300), []);
    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
    }, []);
    useEffect(() => {
        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetch(orgId, queryString);
        }
    }, [currentPage, searchName]);

    const {
        show: showSkillModal,
        hide: hideSkillModal,
        RenderModal: RenderSkillModal,
    } = useModal();

    const {
        show: showSkillUpdateModal,
        hide: hideSkillUpdateModal,
        RenderModal: RenderSkillUpdateModal,
    } = useModal();

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";

        return [pageQuery, pageSizeQuery].join("&");
    };

    function fetchCopilotSkills(orgId, queryString) {
        CopilotService.getCopilotSkills(orgId, agentId, queryString)
            .then((res) => {
                setCopilotSkillsList(res.data.items);
                setPageDetails(res.data.page);
            })
            .catch((err) => {
                setCopilotSkillsList([]);
                setPageDetails({});
            })
            .finally(() => {
                setPageLoading(false);
            });
    }

    const handleEdit = (val) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
    };

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    const addNewSkills = (res) => {
        if (res) {
            setCopilotSkillsList((prevSkills) => {
                const newSkills = [res?.data, ...prevSkills];
                return newSkills;
            });
        }
        hideSkillModal();
    };

    const updateSkillDetails = async () => {
        try {
            if (disableSubmit) return;
            setDisableSubmit(true);
            const body = {
                configuration: {
                    ...allValues.credentials,
                },
                inactiveSkills: allValues.inactiveSkills,
                copilotAppId: agentId,
                skillId: skillsList.id,
                identifier: allValues.identifier,
            };
            const res = await CopilotService.updateCopilotSkills(orgId, agentId, body);
            toast.success(`Skills updated successfully`, {
                autoClose: 2000,
            });
            setEditModalVisible(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message, { autoClose: 2000 });
                return;
            }
            toast.error(`Error adding skills`, {
                autoClose: 2000,
            });
            // callback?.();
        } finally {
            setDisableSubmit(false);
        }
    };

    const inactiveSkill = (e, skillId, active, identifier = "NONE") => {
        e.preventDefault();
        e.stopPropagation();
        let data = {
            active: active,
            skillId: skillId,
            copilotAppId: agentId,
            identifier,
        };
        CopilotService.updateCopilotSkills(orgId, agentId, data)
            .then((res) => {
                setCopilotSkillsList((prevSkills) => {
                    const newSkills = prevSkills.map((val) =>
                        val.skillId === skillId ? { ...val, active: data.active } : val,
                    );
                    return newSkills;
                });
                toast.success(`${data.active ? "Enabled" : "Disabled"} Skill successfully`, {
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error updating skills", {
                    autoClose: 2000,
                });
            })
            .finally(() => {
                setPageLoading(false);
            });
    };

    const getCopilotSkillsDetails = (e, skillId, identifier) => {
        CopilotService.getCopilotSkills(
            orgId,
            agentId,
            `copilotAppId=${agentId}&skillId=${skillId}&showChildren=true&identifier=${identifier}`,
        )
            .then((res) => {
                let item = res.data.items[0];
                reset({
                    credentials: {
                        ...item.configuration,
                    },
                    inactiveSkills: item.inactiveSkills,
                    identifier: item.identifier,
                });
                setSkillsList(item.skill);
                setEditModalVisible(Object.keys(item?.configuration).length > 0);
                showSkillUpdateModal();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error Loading skills", {
                    autoClose: 2000,
                });
                setEditModalVisible(false);
            })
            .finally(() => {
                setPageLoading(false);
            });
    };
    return (
        <Wrapper>
            <List.ComponentHeader
                $padding="0px"
                $overallPadding="0px"
                actions={
                    <ActionWrapper>
                        <SearchPlaceHolderWrapper
                            searchName={searchName}
                            handleSearchChange={handleSearchChange}
                            width="100%"
                        />
                        <AddSkill onClick={showSkillModal}>
                            {Icon(AddCircle, { size: 32, variant: "Bold" })}
                        </AddSkill>
                    </ActionWrapper>
                }
            >
                <HeaderTitle>Installed Skills: {copilotSkillsList?.length || 0}</HeaderTitle>
            </List.ComponentHeader>
            {isPageLoading ? (
                <Loader>
                    <LoaderIcon />
                </Loader>
            ) : copilotSkillsList?.length ? (
                <>
                    <SkillsWrapper>
                        {copilotSkillsList.map((val) => (
                            <Skill
                                key={val.id}
                                onClick={(e) =>
                                    getCopilotSkillsDetails(e, val.skillId, val.identifier)
                                }
                            >
                                <SkillHeader>
                                    {val?.skill?.icon ? (
                                        <IconLogo
                                            src={val?.skill?.icon}
                                            width="48px"
                                            height="48px"
                                        />
                                    ) : (
                                        <DefaultIcon>{Icon(Flash, { size: 31 })}</DefaultIcon>
                                    )}
                                    {/* Added to prevent bubbling of toggle */}
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                    >
                                        <SlideToggle
                                            checked={val.active}
                                            onChange={(value, event) =>
                                                inactiveSkill(
                                                    event,
                                                    val.skillId,
                                                    value,
                                                    val.identifier,
                                                )
                                            }
                                        />
                                    </div>
                                </SkillHeader>
                                <SkillDetails>
                                    <SkillName>
                                        <Body_XS_Med>{val?.skill?.name}</Body_XS_Med>
                                        {val?.skill?.skillCount > 1 && (
                                            <SkillGroup>Group</SkillGroup>
                                        )}
                                    </SkillName>
                                    <SkillDescription>{val?.skill?.description}</SkillDescription>
                                </SkillDetails>
                            </Skill>
                        ))}
                    </SkillsWrapper>
                    <AdvancedPagination
                        page={pageDetails}
                        pageSize={pageSize}
                        paginationInfo="skill"
                        setPageSize={setPageSize}
                        setCurrentPage={setCurrentPage}
                    />
                </>
            ) : (
                <NoDataFoundWrapper>
                    {
                        <>
                            <DataNotFoundMessage>
                                {searchName.length
                                    ? ""
                                    : "Looks like there are no skills attached. Add skills to your Copilot."}
                            </DataNotFoundMessage>
                            <NoSkillButton mode="primary" size="small" onClick={showSkillModal}>
                                {Icon(AddCircle, {
                                    color: "var(--Color-Icon-Inverse)",
                                    size: 20,
                                    variant: "Bold",
                                })}
                                <Body_M_Med style={{ color: "var(--Color-Text-Inverse)" }}>
                                    Link
                                </Body_M_Med>
                            </NoSkillButton>
                        </>
                    }
                </NoDataFoundWrapper>
            )}
            <RenderSkillModal>
                <SkillsListPage copilotId={agentId} callback={addNewSkills} />
            </RenderSkillModal>
            <RenderSkillUpdateModal>
                <StickyPageHeader>
                    <Body_L_Bold>Re-Configure</Body_L_Bold>
                </StickyPageHeader>
                <StyledPageContent>
                    <Credentials
                        skillDetails={skillsList}
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        reset={reset}
                        setValue={setValue}
                    />
                </StyledPageContent>
                <StickyPageFooter>
                    <Button
                        mode="secondary"
                        onClick={(e) => {
                            hideSkillUpdateModal();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(updateSkillDetails)} disabled={disableSubmit}>
                        {disableSubmit ? (
                            <LoaderIcon height="35px" width="35px" type="spinner" />
                        ) : (
                            "Update Details"
                        )}
                    </Button>
                </StickyPageFooter>
            </RenderSkillUpdateModal>
        </Wrapper>
    );
};
export default Skills;
