import React from "react";
import styled from "styled-components";
import CustomDropdown from "common/components/CustomDropdown";
import Tooltip from "common/components/Tooltip";
import KeyboardArrowLeftIcon from "common/assets/KeyboardArrowLeftIcon";
import KeyboardArrowRightIcon from "common/assets/KeyboardArrowRightIcon";
import Icon from "common/components/Icon";
import { Breakpoints } from "GlobalStyle";
import { ArrowRight2, ArrowLeft2 } from "iconsax-react";
import { Body_XS_Reg, Body_XS } from "../global-styled-components";

const PageSizeDropdown = styled(CustomDropdown)`
    color: var(--Color-Text-Default);
    .react-select__control {
        margin: 0;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: ${(props) => (props.width ? props.width : "100%")};
`;

const Info = styled(Body_XS_Reg)`
    display: flex;
    align-items: center;
    color: var(--Color-Text-Default);
`;

const ItemsPerPage = styled.div`
    /* margin-left: auto; */
    display: flex;
    gap: var(--Size-Gap-XXL);
    align-items: center;
    @media (max-width: ${Breakpoints.mobile}px) {
        gap: var(--Size-Gap-XS);
    }
`;

const RowsPerPage = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const PagingButtons = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-XXL);
    // margin-right: 10px;
    @media (max-width: ${Breakpoints.mobile}px) {
        gap: 10px;
    }
    button {
        height: 32px;
        width: 32px;
        border-radius: var(--Size-CornerRadius-M);
        padding: 0;
    }
`;

const IconAction = styled.div`
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    // pointer-events: ${(props) => props.disabled && "none"};
`;

export default function AdvancedPagination({
    page,
    pageSize,
    setPageSize,
    setCurrentPage,
    paginationInfo = "item",
    showInfo,
    width,
    children,
}) {
    const pageSizes = [10, 20, 50, 100];

    if (page.hasPrev === undefined) {
        page.hasPrev = page.page > 1 || !!page.prev;
    }

    const firstItemIdx = page.page * pageSize - pageSize + 1;
    const lastItemIdx =
        firstItemIdx + page.limit - 1 < page.totalItems
            ? firstItemIdx + page.limit - 1
            : page.totalItems;

    return (
        <Wrapper width={width}>
            {/* {showInfo ? <Info>{`${firstItemIdx} - ${lastItemIdx} of ${page.itemTotal} items`}</Info> : ""} */}
            {page?.totalItems > 0 && (
                <Info>{`${firstItemIdx} - ${lastItemIdx} of ${page.totalItems} ${paginationInfo}${
                    page?.totalItems > 1 ? "s" : ""
                }`}</Info>
            )}
            {children}

            <ItemsPerPage>
                {/* Items per page */}
                <RowsPerPage>Items per page</RowsPerPage>
                <PageSizeDropdown
                    wrapperWidth="82px"
                    showArrow={false}
                    wrapperHeight="20px"
                    value={{ label: pageSize, value: pageSize }}
                    onChange={(e) => {
                        setPageSize(e.value);
                        setCurrentPage({ page: 1 });
                    }}
                    options={pageSizes.map((pageSize) => ({
                        label: pageSize,
                        value: pageSize,
                    }))}
                />
                <PagingButtons>
                    {/* <Button
                    onClick={() => setCurrentPage(1)}
                    disabled={!(page.current > 1)}
                    data-tip="First page"
                >
                    <KeyboardDoubleArrowLeftIcon disabled={!(page.current > 1)} />
                </Button> */}
                    <IconAction
                        onClick={() => {
                            if (page.hasPrev)
                                setCurrentPage({ page: page.page - 1, prev: page.prev });
                        }}
                        disabled={!page.hasPrev}
                        data-tip="Previous page"
                    >
                        {Icon(ArrowLeft2, {
                            size: 17,
                            color: "var(--Color-Text-Default)",
                        })}
                    </IconAction>
                    <IconAction
                        onClick={() => {
                            if (page.hasNext)
                                setCurrentPage({ page: page.page + 1, next: page.next });
                        }}
                        disabled={!page.hasNext}
                        data-tip="Next page"
                    >
                        {Icon(ArrowRight2, {
                            size: 17,
                            color: "var(--Color-Text-Default)",
                        })}
                    </IconAction>
                    {/* <Button
                    onClick={() => setCurrentPage(Math.ceil(page.itemTotal / pageSize))}
                    disabled={!page.hasNext}
                    data-tip="Last page"
                >
                    <KeyboardDoubleArrowRightIcon disabled={!page.hasNext} />
                </Button> */}

                    <Tooltip place="top" type="faint" effect="solid" noArrow={true} />
                </PagingButtons>
            </ItemsPerPage>
        </Wrapper>
    );
}
