import IntegrationsService from "../../../services/integrations.service";

export const getIntegrations = (orgId, copilotAppId, queryParams, opts) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.getIntegrations(orgId, copilotAppId, queryParams, opts)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);

                reject(err);
            });
    });
};

export const getIntegration = (orgId, copilotAppId, integrationId) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.getIntegration(orgId, copilotAppId, integrationId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);

                reject(err);
            });
    });
};

export const createIntegration = (orgId, copilotAppId, data) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.createIntegration(orgId, copilotAppId, data)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const updateIntegration = (orgId, copilotAppId, integrationId, data) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.updateIntegration(orgId, copilotAppId, integrationId, data)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const deleteIntegration = (orgId, copilotAppId, integrationId) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.deleteIntegration(orgId, copilotAppId, integrationId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const refreshToken = (orgId, copilotAppId, integrationId) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.refreshToken(orgId, copilotAppId, integrationId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};

export const getEventsAndOperations = (orgId, copilotAppId) => {
    return new Promise((resolve, reject) => {
        IntegrationsService.getEventsAndOperations(orgId, copilotAppId)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
};
