import CopilotService from "../../../services/copilot.service";

export const CopilotAction = {
    CopilotCreateSuccess: "CREATE_COPILOT_SUCCESS",
    CopilotCreateFailed: "CREATE_COPILOT_FAILED",
    CreateCopilot: "CREATE_COPILOT",
    GetCopilots: "GET_COPILOTS",
    GetCopilot: "GET_COPILOT",
    UpdateCopilot: "UPDATE_COPILOT",
    DeleteCopilot: "DELETE_COPILOT",
    EmptyCopilotList: "EMPTY_COPILOT_LIST",
};

export const createCopilot = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        CopilotService.createCopilot(orgId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: CopilotAction.CopilotCreateSuccess,
                    payload: { isZoneRegistered: true, copilot: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: CopilotAction.CopilotCreateFailed,
                    payload: { isZoneRegistered: false, copilot: null },
                });
                reject(err);
            });
    });
};

export const getCopilots = (orgId, queryParams, dispatch) => {
    return new Promise((resolve, reject) => {
        CopilotService.getCopilots(orgId, queryParams) // eslint-disable-line
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: CopilotAction.GetCopilots,
                    payload: {
                        copilotList: { copilots: payload.items, page: payload.page },
                    },
                    // page: payload.data.page
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: CopilotAction.GetCopilots,
                    payload: { items: [] },
                });
                reject(err);
            });
    });
};

export const updateCopilot = (orgId, copilotId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        CopilotService.updateCopilot(orgId, copilotId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: CopilotAction.UpdateCopilot,
                    payload: { isCopilotUpdated: true, copilot: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: CopilotAction.UpdateCopilot,
                    payload: { isCopilotUpdated: false, copilot: null },
                });
                reject(err);
            });
    });
};

export const updateCopilotStatus = (orgId, copilotId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        CopilotService.updateCopilotStatus(orgId, copilotId, data)
            .then((res) => {
                const { data: payload } = res;
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);

                reject(err);
            });
    });
};

export const getCopilot = (orgId, copilotId, dispatch) => {
    return new Promise((resolve, reject) => {
        CopilotService.getCopilot(orgId, copilotId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: CopilotAction.GetCopilot,
                    payload: { exists: true, copilot: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: CopilotAction.GetCopilot,
                    payload: { exists: false, copilot: null },
                });
                reject(err);
            });
    });
};

export const deleteCopilot = (orgId, copilotId, dispatch) => {
    return new Promise((resolve, reject) => {
        CopilotService.deleteCopilot(orgId, copilotId)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: CopilotAction.DeleteCopilot,
                    payload: { exists: true, copilot: copilotId },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: CopilotAction.DeleteCopilot,
                    payload: { exists: false, copilot: null },
                });
                reject(err);
            });
    });
};
