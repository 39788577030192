import { Breakpoints } from "../../../GlobalStyle";
import { startCase, toLower } from "lodash";
import React, { useEffect, useState } from "react";
import {
    SubTitle1,
    Body_XXS_Reg,
    Body_L_Bold,
    Body_S_Bold,
    Body_XS_Reg,
} from "../../../common/global-styled-components";
import styled, { css } from "styled-components";
import FormFields from "../../../common/components/FormFields";
import SlideToggle from "common/components/SlideToggle";
import { formFieldPayloadTransformer } from "../../../common/utils/utils";

const Header = {};
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;

    @media (max-width: ${Breakpoints.mobileXS}px) {
        font-size: var(--subtitle-2-m);
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
    }
`;

const RoundedBorderGeneralDiv = styled.div`
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    display: flex;
    padding: var(--Size-Padding-XL) 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-M);
    align-self: stretch;
`;

const CredsWrapper = styled.div`
    gap: var(--Size-Gap-XXL);
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Form = {};

Form.Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

Form.Desc = styled.div`
    color: var(--Color-Text-Subtlest);
    font-weight: 400;
    font-size: var(--body-3-d);
`;
Form.Title = styled.div`
    color: var(--Color-Text-Default);
    font-weight: 400;
    font-size: var(--caption-1-d);
`;

const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: var(--Size-Gap-XXL);
    flex-direction: ${(props) => (props.row ? `row` : "column")};
`;
function Credentials({ skillDetails, setValue, register, control, allValues, errors, reset }) {
    const addDataToInactiveSkill = (id) => {
        const currentValues = [...allValues?.inactiveSkills];
        let newValues;
        if (currentValues.includes(id)) {
            newValues = currentValues.filter((currentId) => currentId !== id);
        } else {
            newValues = [...currentValues, id];
        }
        setValue("inactiveSkills", newValues);
    };
    return (
        <CredsWrapper>
            <RoundedBorderGeneralDiv>
                <Form.Title>Please fill out the following information in the form below</Form.Title>
                <InputWrapper row={true}>
                    {formFieldPayloadTransformer(skillDetails?.configuration?.inputSchema)?.map(
                        (key) => {
                            return (
                                <FormFields
                                    key={key.name}
                                    item={key}
                                    register={register}
                                    control={control}
                                    allValues={allValues}
                                    errors={errors}
                                    reset={reset}
                                />
                            );
                        },
                    )}
                </InputWrapper>
            </RoundedBorderGeneralDiv>
            {skillDetails?.skills?.map((skill) => {
                return (
                    <RoundedBorderGeneralDiv key={skill.id}>
                        <Form.Header>
                            <Form.Title>{startCase(toLower(skill.name))}</Form.Title>
                            <SlideToggle
                                checked={!allValues.inactiveSkills.includes(skill.id)}
                                onChange={() => addDataToInactiveSkill(skill.id)}
                            />
                        </Form.Header>
                        <Form.Desc>{startCase(toLower(skill.description))}</Form.Desc>
                        <InputWrapper row={true}>
                            {formFieldPayloadTransformer(skill?.configuration?.inputSchema)?.map(
                                (key) => {
                                    return (
                                        <FormFields
                                            key={key.name}
                                            item={key}
                                            register={register}
                                            control={control}
                                            allValues={allValues}
                                            errors={errors}
                                            reset={reset}
                                        />
                                    );
                                },
                            )}
                        </InputWrapper>
                    </RoundedBorderGeneralDiv>
                );
            })}
        </CredsWrapper>
    );
}

export default Credentials;
