import { removeDuplicatedById } from "../../utils/common-utils";
import { CopilotAction } from "../actions/copilotActions";
import { DataSourceAction } from "../actions/datasourceActions";

const initialState = {
    copilotList: {
        copilots: [],
        page: {},
    },
    copilot: {},
};

const copilotReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const newState = { ...state };
    let copilot, datasources;

    switch (type) {
        case CopilotAction.GetCopilots:
            // do not overrider zones list, instead concatenate them
            const prevCopilots = state.copilotList.copilots;
            const nextCopilots = payload.copilotList.copilots;
            const updatedState = { ...state, ...payload };
            updatedState.copilotList.copilots = removeDuplicatedById([
                ...prevCopilots,
                ...nextCopilots,
            ]);
            return updatedState;
        case CopilotAction.CopilotCreateSuccess:
            copilot = payload.copilot;
            newState.copilotList.copilots.push(copilot);
            return {
                ...state,
                ...newState,
            };
        case CopilotAction.CopilotCreateFailed:
            return {
                ...state,
            };
        case CopilotAction.UpdateCopilot:
            if (payload.isCopilotUpdated) {
                copilot = payload.copilot;
                newState.copilotList.copilots[copilot.id] = copilot;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case CopilotAction.GetCopilot:
            if (payload.exists) {
                copilot = payload.copilot;
                newState.copilotList.copilots[copilot.id] = copilot;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case CopilotAction.DeleteCopilot:
            copilot = payload.copilot;
            newState.copilotList.copilots = newState.copilotList.copilots.filter(
                (cp) => cp.id !== copilot,
            );
            return {
                ...state,
                ...newState,
            };
        case CopilotAction.EmptyCopilotList:
            return {
                ...initialState,
            };

        case DataSourceAction.GetDataSources:
            copilot = newState?.copilotList?.copilots?.[payload.copilotId];
            copilot.datasourceList = payload?.datasourceList;
            return { ...newState };

        case DataSourceAction.GetDataSource:
            copilot = newState?.copilotList?.copilots?.[payload.copilotId];
            datasources = copilot?.datasourceList || [];
            datasources[payload?.datasource?.id] = payload?.datasource;
            return { ...newState };

        case DataSourceAction.CreateDataSource:
            copilot = newState?.copilotList?.copilots?.[payload.copilotId];
            datasources = copilot?.datasourceList || [];
            datasources[payload?.datasource?.id] = payload?.datasource;
            return { ...newState };

        case DataSourceAction.UpdateDataSource:
            copilot = newState?.copilotList?.copilots?.[payload.copilotId];
            datasources = copilot?.datasourceList || [];
            datasources[payload?.datasource?.id] = payload?.datasource;
            return { ...newState };

        case DataSourceAction.DeleteDataSource:
            copilot = newState?.copilotList?.copilots?.[payload?.copilotId];
            datasources = copilot?.datasourceList || [];
            copilot.datasourceList =
                datasources?.filter((ds) => ds?.id !== payload?.datasourceId) || [];
            return { ...newState };
        default:
            return state;
    }
};

export default copilotReducer;
