import AuthGuard from "guards/auth.guard";
import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { setShowNavbar } from "../redux/actions/menuActions";
import LoaderIcon from "./LoaderIcon";

const LoaderContainer = styled.div`
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
`;

const PrivateRoute = ({ component: Component, guard, path }) => {
    const dispatch = useDispatch();
    const [isLoaderVisible, setIsLoaderVisible] = useState(true);
    const [redirectTo, setRedirectTo] = useState("");

    const isGlobalOrgPages = (value) => {
        let str = [path, value].join(",");
        return str.includes("choose-company") || str.includes("setup-company"); // || str.includes("profile");
    };

    const storeUtms = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = [
            "utm_source",
            "utm_medium",
            "utm_campaign",
            "utm_term",
            "utm_content",
            "entry_page",
            "ref",
        ];
        const utmData = {};

        utmParams.forEach((param) => {
            const value = urlParams.get(param);
            if (value) {
                utmData[param] = value;
                sessionStorage.setItem(param, value);
            }
        });
    };

    useEffect(async () => {
        const value = await guard();
        setShowNavbar(!isGlobalOrgPages(value), dispatch);
        storeUtms();
        setRedirectTo(value);
        setIsLoaderVisible(false);
    }, []);

    return isLoaderVisible ? (
        <LoaderContainer>
            <LoaderIcon />
        </LoaderContainer>
    ) : redirectTo.length > 0 ? (
        <Redirect to={redirectTo} />
    ) : (
        <Component />
    );
};

export default PrivateRoute;
