import React, { useState } from "react";
import styled from "styled-components";
import { ArrowUp2, ArrowDown2 } from "iconsax-react";
import Icon from "common/components/Icon";
import { Body_S_Bold, Body_XXS_Med } from "../global-styled-components";
import Divider from "./Divider";
import { Breakpoints } from "GlobalStyle";
const AccordionWrapper = styled.div`
    // padding: 1em 0;
    padding: ${(props) => props.mode !== "secondary" && "1em 0"};

    &:not(:last-child) {
        border-bottom: 1px solid var(--Color-Border-Subtle);
        border: ${(props) => props.mode === "secondary" && "none"};
    }
`;

const AccordionDiv = styled.div`
    cursor: pointer;
    margin: 0.5em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${(props) => props.mode === "secondary" && "var(--Size-Gap-L)"};
`;

const AccordionContent = styled.div`
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: ${(props) =>
        props.isOpen ? (props.mode === "secondary" ? "1000px" : "430px") : "0"};
`;

const StyledBody_S_Bold = styled(Body_S_Bold)`
    color: var(--Color-Text-Default);
    white-space: nowrap;
    @media (max-width: ${Breakpoints.mobile}px) {
        white-space: normal;
    }
`;

const StyledBody_XXS_Med = styled(Body_XXS_Med)`
    color: var(--Color-Text-Subtle);
    white-space: nowrap;
    @media (max-width: ${Breakpoints.mobile}px) {
        white-space: normal;
    }
`;
const Accordion = ({ title, children, isOpen, onClick, mode, divider }) => {
    return (
        <AccordionWrapper mode={mode}>
            <AccordionDiv onClick={onClick} mode={mode}>
                {mode !== "secondary" ? (
                    <StyledBody_S_Bold>{title}</StyledBody_S_Bold>
                ) : (
                    <StyledBody_XXS_Med>{title}</StyledBody_XXS_Med>
                )}

                {Icon(isOpen ? ArrowUp2 : ArrowDown2, { size: 21 })}
                {mode === "secondary" && <Divider></Divider>}
            </AccordionDiv>
            <AccordionContent isOpen={isOpen} mode={mode}>
                {children}
            </AccordionContent>
        </AccordionWrapper>
    );
};

const Accordions = ({ data, mode, allowMultipleOpen = false, initialIndex = null }) => {
    const [openIndexes, setOpenIndexes] = useState(() => {
        if (allowMultipleOpen) {
            return initialIndex !== null ? [initialIndex] : Array.from(Array(data.length).keys());
        } else {
            return initialIndex !== null ? [initialIndex] : [];
        }
    });

    const handleClick = async (index, cb) => {
        await cb?.();
        setOpenIndexes((prevIndices) => {
            const selectedIDIndex = prevIndices.indexOf(index);
            if (selectedIDIndex === -1) {
                return [...prevIndices, index];
            } else {
                const updatedIndexes = [...prevIndices];
                updatedIndexes.splice(selectedIDIndex, 1);
                return updatedIndexes;
            }
        });
    };

    return (
        <>
            {data.map((item, index) => (
                <Accordion
                    key={index}
                    mode={mode}
                    title={item.title}
                    onClick={() => handleClick(index, item.cb)}
                    isOpen={openIndexes.includes(index)}
                >
                    {item.component}
                </Accordion>
            ))}
        </>
    );
};

export default Accordions;
