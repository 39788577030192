import React from "react";
import IconButton from "./IconButton";
import Icon from "./Icon";
import { ArrowLeft2 } from "iconsax-react";

const BackButton = (props) => {
    return (
        <IconButton {...props} size="small">
            {Icon(ArrowLeft2, { size: 24 })}
        </IconButton>
    );
};

export default BackButton;
