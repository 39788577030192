import React from "react";
import styled from "styled-components";
import Button from "common/components/Button";
import { Body1 } from "../../common/global-styled-components";

const Wrapper = styled.div`
    border-radius: var(--Size-CornerRadius-M);
`;

const DialogTitle = styled(Body1)`
    color: var(--Color-Text-Default);
    font-size: 20px;
    line-height: 28px;
    font-weight: var(--bold);
`;

const DialogContent = styled.div`
    font-family: var(--body-font);
    font-style: normal;
    letter-spacing: var(--letter-spacing);
    font-weight: normal;
    font-size: 18px;
    line-height: var(--line-height-24);
    color: var(--Color-Text-Subtlest);
    word-break: break-word;
    padding: var(--Size-Padding-L) 0 24px;
`;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-XXL);

    & > * {
        flex: 1;
    }
`;

const ConfirmationDialog = ({
    type,
    title,
    content,
    okText,
    cancelText,
    handleOkClick,
    handleCancelClick,
    disableOKText = false,
    disableCancelText = false,
    ...restProps
}) => {
    return (
        <Wrapper data-testid="confirmation-dialog" {...restProps}>
            {!!title && <DialogTitle>{title}</DialogTitle>}
            {!!content && <DialogContent>{content}</DialogContent>}
            <ActionWrapper>
                {!!cancelText && !!handleCancelClick && (
                    <Button
                        mode="secondary"
                        size="default"
                        disabled={disableCancelText}
                        onClick={handleCancelClick}
                    >
                        {cancelText}
                    </Button>
                )}
                {!!okText && !!handleOkClick && (
                    <Button
                        mode={type === "danger" ? "danger" : "primary"}
                        size="default"
                        marginLeft="8px"
                        disabled={disableOKText}
                        onClick={handleOkClick}
                    >
                        {okText}
                    </Button>
                )}
            </ActionWrapper>
        </Wrapper>
    );
};

export default ConfirmationDialog;
