import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import { useHistory } from "react-router-dom";
import { OrgAction } from "./orgActions";

export const AuthAction = {
    RegisterSuccess: "REGISTER_SUCCESS",
    RegisterFailed: "REGISTER_FAIL",
    LoginSuccess: "LOGIN_SUCCESS",
    LoginFailed: "LOGIN_FAIL",
    Logout: "LOGOUT",
    CheckUserSession: "CHECK_USER_SESSION",
    GoogleLoginSuccess: "GOOGLE_LOGIN_SUCCESS",
    GoogleLoginFailed: "GOOGLE_LOGIN_FAILED",
    UpdateDetailsSuccess: "UPDATE_DETAILS_SUCCESS",
    UpdateDetailsFailed: "UPDATE_DETAILS_FAILED",
};

export const register = (formData, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.register(formData)
            .then((res) => {
                dispatch({
                    type: AuthAction.RegisterSuccess,
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.RegisterFailed,
                });
                reject(err);
            });
    });
};

export const login = (formData, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.login(formData)
            .then((res) => {
                dispatch({
                    type: AuthAction.LoginSuccess,
                    payload: { isUserLoggedIn: true, user: res.data.user },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.LoginFailed,
                });
                reject(err);
            });
    });
};

export const logout = (dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.logout()
            .then((res) => {
                dispatch({
                    type: AuthAction.Logout,
                    payload: {
                        isUserLoggedIn: false,
                        user: null,
                    },
                });
                dispatch({
                    type: OrgAction.Logout,
                    payload: {
                        isOrgRegistered: false,
                        organization: null,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.Logout,
                    payload: {
                        isUserLoggedIn: false,
                        user: null,
                    },
                });
                reject(err);
            });
    });
};

/* export const checkUserSession = (dispatch) => {
  return new Promise((resolve, reject) => {
    UserService.getCurrentUserSession()
      .then((res) => {
        dispatch({
          type: AuthAction.CheckUserSession,
          payload: {
            isUserLoggedIn: true,
            user: res.data.session.passport.user,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: AuthAction.CheckUserSession,
          payload: {
            isUserLoggedIn: false,
            user: null,
          },
        });
        reject(err);
      });
  });
}; */

export const googleLogin = (userInfo, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.loginViaGoogle(userInfo)
            .then((res) => {
                dispatch({
                    type: AuthAction.GoogleLoginSuccess,
                    payload: {
                        isUserLoggedIn: true,
                        user: res.data.user,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.GoogleLoginSuccess,
                    payload: {
                        isUserLoggedIn: false,
                        user: null,
                    },
                });
                reject(err);
            });
    });
};

export const updateProfile = (updateOpts, dispatch) => {
    return new Promise((resolve, reject) => {
        AuthService.updateProfile(updateOpts)
            .then((res) => {
                dispatch({
                    type: AuthAction.UpdateDetailsSuccess,
                    payload: {
                        isUserLoggedIn: true,
                        user: res.data.user,
                    },
                });
                resolve(res);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: AuthAction.UpdateDetailsFailed,
                    payload: {
                        isUserLoggedIn: true,
                    },
                });
                reject(err);
            });
    });
};
