import { PlatformThemeAction } from "../actions/platformthemeAction";

const initialState = {
    platformTheme: "",
};

const platformThemeReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case PlatformThemeAction.AddMode:
            return {
                ...state,
                platformTheme: payload,
            };
        default:
            return state;
    }
};

export default platformThemeReducer;
