import React from "react";
import styled from "styled-components";
// import { SVGIconComponentProps, SVGIconProps } from './assets.types';

const StyledPath = styled.path`
    fill: ${({ active }) => (active ? "url(#paint0_linear)" : "#fff")};
`;

const BolticIcon = ({ height, width, color, ...props }) => {
    return (
        <svg
            height={height}
            width={width}
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_2"
            data-name="Layer 2"
            viewBox="0 0 577.55 164.04"
        >
            <defs></defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path
                        fill="#fd4e1e"
                        className="cls-2"
                        d="M74.24,33.98l-.12-32.77c0-1.05-1.24-1.59-2.02-.89L.4,65.21c-.25.23-.4.55-.4.9v61.55c0,.67.54,1.21,1.21,1.21h72.13c.67,0,1.21.54,1.21,1.2l.12,32.77c0,1.05,1.24,1.59,2.02.89l71.71-64.89c.25-.23.4-.55.4-.9v-61.55c0-.67-.54-1.21-1.21-1.21h-72.13c-.67,0-1.21-.54-1.21-1.2ZM74.52,115.25l-.07-20.4c0-.67-.54-1.2-1.21-1.2H27.43c-1.09,0-1.62-1.33-.84-2.08l45.63-43.64c.77-.73,2.04-.19,2.04.87l.07,20.4c0,.67.54,1.2,1.21,1.2h45.81c1.09,0,1.62,1.33.84,2.08l-45.63,43.64c-.77.73-2.04.19-2.04-.87Z"
                    />
                    <g>
                        <g>
                            <path
                                fill={color}
                                className="cls-1"
                                d="M366.78,68.67c-3.44-5.94-8.23-10.61-14.39-13.99-6.15-3.39-13.43-5.09-21.84-5.09s-15.41,1.7-21.62,5.09c-6.21,3.38-11.02,8.05-14.46,13.99-3.44,5.95-5.16,12.77-5.16,20.47s1.72,14.51,5.16,20.46c3.44,5.95,8.25,10.62,14.46,14,6.21,3.38,13.41,5.08,21.62,5.08s15.69-1.7,21.84-5.08c6.16-3.38,10.95-8.05,14.39-14s5.15-12.77,5.15-20.46-1.71-14.52-5.15-20.47ZM353.78,110.9c-4.93,5.28-12.67,7.92-23.23,7.92s-18.02-2.64-23-7.92c-4.98-5.28-7.46-12.53-7.46-21.76s2.48-16.49,7.46-21.77,12.64-7.92,23-7.92,18.31,2.64,23.23,7.92c4.92,5.28,7.38,12.54,7.38,21.77s-2.46,16.48-7.38,21.76Z"
                            />
                            <path
                                fill={color}
                                className="cls-1"
                                d="M396.12,26.27l-9.55,10.59c-.11.13-.18.29-.18.46v89.11c0,.38.31.69.69.69h9.55c.38,0,.69-.31.69-.69V26.73c0-.63-.78-.93-1.2-.46Z"
                            />
                            <path
                                fill={color}
                                className="cls-1"
                                d="M462.23,117.82v8.62c0,.38-.31.69-.69.69h-15.77c-7.6,0-13.62-1.79-18.08-5.38-4.46-3.58-6.69-9.85-6.69-18.77v-41.77c0-.38-.31-.69-.69-.69h-12.16c-.38,0-.69-.31-.69-.69v-8.01c0-.38.31-.69.69-.69h12.16c.38,0,.69-.3.69-.68v-13.29c0-.17.06-.33.17-.46l9.7-10.75c.43-.47,1.2-.17,1.2.46v19.4c0,.06,0,.11-.02.17v5.13h.02v.02h29.46c.38,0,.69.31.69.69v8.01c0,.38-.31.69-.69.69h-28.77c-.38,0-.69.31-.69.69v42.38c0,5.34,1.33,8.93,4.01,10.77,2.66,1.85,6.77,2.77,12.3,2.77h13.16c.38,0,.69.31.69.69Z"
                            />
                            <path
                                fill={color}
                                className="cls-1"
                                d="M485.15,44.67v81.77c0,.38-.3.69-.68.69h-9.55c-.38,0-.69-.31-.69-.69V44.67h10.92Z"
                            />
                            <path
                                fill={color}
                                className="cls-1"
                                d="M576.85,99.03h-9.42c-.36,0-.65.28-.68.64-.47,5.79-2.82,10.31-7.06,13.54-4.77,3.64-11.46,5.46-20.07,5.46-10.15,0-17.54-2.59-22.15-7.77-4.62-5.17-6.92-12.43-6.92-21.76s2.31-16.59,6.92-21.77,12-7.76,22.15-7.76c8.62,0,15.31,1.84,20.07,5.54,4.14,3.2,6.48,7.56,7.03,13.08.04.35.33.63.68.63h9.43c.4,0,.72-.34.69-.74-.38-5.17-2.06-9.75-4.99-13.75-3.38-4.62-7.92-8.23-13.61-10.85-5.69-2.62-12.08-3.93-19.16-3.93-8.11,0-15.18,1.7-21.23,5.08-6.05,3.38-10.71,8.05-14,14-3.28,5.94-4.93,12.77-4.93,20.47s1.65,14.51,4.93,20.47c3.29,5.94,7.94,10.62,14,14,6.05,3.38,13.12,5.08,21.23,5.08,7.08,0,13.47-1.31,19.16-3.93,5.69-2.62,10.23-6.25,13.61-10.92,3-4.15,4.68-8.83,5.02-14.06.03-.4-.3-.73-.69-.73Z"
                            />
                            <path
                                fill={color}
                                className="cls-1"
                                d="M273.88,71.5c-3.44-5.95-8.23-10.61-14.38-14-6.16-3.38-13.44-5.08-21.84-5.08s-15.41,1.7-21.63,5.08c-6.2,3.39-11.02,8.05-14.46,14-.28.5-.55.99-.81,1.5V26.34c0-.38-.31-.69-.69-.69h-9.54c-.38,0-.69.31-.69.69v101.73c0,.38.31.69.69.69h9.54c.38,0,.69-.31.69-.69v-17.14c.26.51.53,1,.81,1.5,3.44,5.94,8.26,10.61,14.46,13.99,6.21,3.39,13.41,5.09,21.63,5.09s15.69-1.7,21.84-5.09c6.15-3.38,10.94-8.05,14.38-13.99,3.44-5.95,5.16-12.77,5.16-20.47s-1.72-14.5-5.16-20.46ZM260.88,113.73c-4.92,5.28-12.66,7.92-23.22,7.92s-18.03-2.64-23-7.92c-4.98-5.28-7.46-12.54-7.46-21.77s2.48-16.48,7.46-21.76c4.97-5.28,12.64-7.92,23-7.92s18.31,2.64,23.22,7.92c4.93,5.28,7.39,12.54,7.39,21.76s-2.46,16.49-7.39,21.77Z"
                            />
                        </g>
                        <rect className="cls-1" x="474.23" y="24.06" width="10.92" height="13.08" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BolticIcon;
