import { createGlobalStyle, css } from "styled-components";

import CloseIcon from "common/assets/svgs/Cross.svg";
import SuccessIcon from "common/assets/svgs/alerts/Success.svg";
import ErrorIcon from "common/assets/svgs/alerts/Error.svg";
import InfoIcon from "common/assets/svgs/alerts/Info.svg";
import WarningIcon from "common/assets/svgs/alerts/Warning.svg";

export const Breakpoints = {
    mobileXS: 480,
    mobile: 768,
    tablet: 1024,
    laptop: 1200,
    desktop: 1440,
};

const ToastCss = css`
    background: var(--Color-Background-Default) !important;
    box-shadow: var(--Color-Shadow-1);
    @media (min-width: ${Breakpoints.mobile}px) {
        border-radius: var(--Size-CornerRadius-XL) !important;
    }
`;
const ToastIconCss = css`
    position: absolute;
    top: 0;
    left: 14px;
    height: calc(100% + 3px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const GlobalStyle = createGlobalStyle`

    :root {
        // Gradients
        --profile-gradient: linear-gradient(95.78deg, #902EF2 0%, #FF62B7 100%);
        --primary-blur-gradient:linear-gradient(
            107.73deg,
            rgba(209, 209, 209, 0.12) 0%,
            rgba(209, 209, 209, 0.04) 101.24%
        );


        // Secondary Color
        --secondary: #FF62B7;

        // light colors
        --white: #FFFFFF;
        --light-100: #ECECF9;
        --light-200: #C6CFE2;
        --light-300: #AAABC5;
        --light-400: #8A8AA8;

        // dark colors
        --black: #0B0E10;
        --dark-900: #171921;
        --dark-800: #1D202A;
        --dark-801: #0A0D10;
        --dark-700: #242B38;
        --dark-600: #323648;

        // Status Colors
        --success-dark: #1B934B;
        --created: #737278;
        --pending:#ab6100;
        --waiting_for_resource:#a4a3a8;
        --preparing:#ab6100;
        --running:var(--orange-500, #ab6100);
        --success: #1FBE5F;
        --success-light: #24E070;
        --failed: #FF4D4D;
        --danger-dark: #D42B4A;
        --danger: #FF4D4D;
        --danger-light: #FD4969;
        --danger-hover: #FE2F54;
        --info: #87cefa;
        --warning: #ffff99;
        --error: #ff3333;
        --divider: #dbdeff29;

        // Letter spacing
        --letter-spacing-0: 0%;
        --letter-spacing-1: 1%;
        --letter-spacing-15: 1.5%;
        --letter-spacing-1N: -1%;
        --letter-spacing-05N: -0.5%;
        --letter-spacing-15N: -1.5%;
        // Font
        --heading-font : Lato;
        --body-font : Lato;

        /* typography */
        --h1-d: 56px;
        --h1-m: 40px;
        --h2-d: 40px;
        --h2-m: 32px;
        --h3-d: 32px;
        --h3-m: 24px;
        --subtitle-1-d: 24px;
        --subtitle-1-m: 20px;
        --subtitle-2-d: 20px;
        --subtitle-2-m: 18px;
        --lead-body-d: 18px;
        --lead-body-m: 16px;
        --body-lead-d: 18px;
        --body-lead-m: 18px;
        --body-1-d: 16px;
        --body-1-m: 15px;
        --body-2-d: 14px;
        --body-2-m: 13px;
        --body-3-d: 12px;
        --body-3-m: 11px;
        --body-4-d: 12px;
        --body-4-m: 10px;
        --body-5-d: 28px;
        --caption-1-d: 14px;
        --caption-1-m: 12px;
        /* Button font size */
        --button: 16px;
        --button-small: 14px;

        // Font weight
        --bold: 700;
        --semi-bold: 600;
        --regular: 400;

        // Line height
        --line-height-140: 140%;
        --line-height-120: 120%;
        --line-height-62: 62px;
        --line-height-58: 58px;
        --line-height-56: 56px;
        --line-height-49: 49px;
        --line-height-40: 40px;
        --line-height-36: 36px;
        --line-height-34: 34px;
        --line-height-32: 32px;
        --line-height-29: 29px;
        --line-height-28: 28px;
        --line-height-25: 25px;
        --line-height-24: 24px;
        --line-height-22: 22px;
        --line-height-21: 21px;
        --line-height-19: 19px;
        --line-height-20: 20px;
        --line-height-16: 16px;

        // Others
        --secondary-bg-color: rgba(255, 255, 255, 0.02);
        --blue-bg: rgba(94, 177, 255, 1);
        --bg-color-80: rgba(16,17,18,0.8);
        --error: #FF5858;
        --secondary-border-color: #50ABFF;
        --button-border: #d92a7e;
        --secondary-button-border: #EBEBEB;
        --banner-title-max-width: 1039px;
        --banner-description-max-width: 795px;
        --section-description-max-width: 795px;
        --content-max-width: 498px;
        --font-size-base: 14px ; // Assumes the browser, typically 16px
        --font-size-sm: 12px;
        --font-size-lg: 16px;
        --bg-error: #E53F64;
        --error: #E53F64;
        --dropdown-bg: #28292A;
        --dark: #101112;
        --light: var(--white-100);
        --disabled: rgba(97,97,97);

        --large-pad: 24px;
        --mid-pad: 20px;

        --large-gap: var(--Size-Gap-XXL);
        --mid-gap: var(--Size-Gap-XL);

        /* Size CornerRadius */
        --Size-CornerRadius-XXXL: 32px;
        --Size-CornerRadius-XXL: 24px;
        --Size-CornerRadius-XL: 16px;
        --Size-CornerRadius-L: 12px;
        --Size-CornerRadius-M: 8px;
        --Size-CornerRadius-S: 4px;
        --Size-CornerRadius-XS: 2px;

        /* Size Gap */
        --Size-Gap-XXXL: 32px;
        --Size-Gap-XXL: 24px;
        --Size-Gap-XL: 16px;
        --Size-Gap-L: 12px;
        --Size-Gap-M: 8px;
        --Size-Gap-S: 4px;
        --Size-Gap-XS: 2px;

        /* Size Padding */
        --Size-Padding-XXXL: 32px;
        --Size-Padding-XXL: 24px;
        --Size-Padding-XL: 16px;
        --Size-Padding-L: 12px;
        --Size-Padding-M: 8px;
        --Size-Padding-S: 4px;
        --Size-Padding-XS: 2px;
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: var(--body-font);
        letter-spacing: var(--letter-spacing);
    }
    // p {
    //     -webkit-user-select: text;
    //     -khtml-user-select: text;
    //     -moz-user-select: text;
    //     -o-user-select: text;
    //     user-select: text;
    // }
    // code {
    //     -webkit-user-select: text;
    //     -khtml-user-select: text;
    //     -moz-user-select: text;
    //     -o-user-select: text;
    //     user-select: text;
    // }
    html {
        height: 100%;
        overflow: hidden;
    }
    body {
        height: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: var(--Color-Background-Default);
        font-family: var(--body-font);
        color: var(--Color-Text-Default);

    }
    .theme-light {
        transition: background-color 0.3s ease;
        /* Light Mode */

        /* Color Background */
        --Color-Background-Success: #059a52;
        --Color-Background-Accent-Success: rgba(155, 230, 194, 0.15);
        --Color-Background-Accent-Warning: rgba(253, 197, 166, 0.15);
        --Color-Background-Accent-Danger: rgba(255, 184, 184, 0.15);
        --Color-Background-Accent-Action: rgba(204, 153, 255, 0.15);
        --Color-Background-Warning: #fa6e20;
        --Color-Background-Danger: #cc3e3e;
        --Color-Background-Action: #7a5af5;
        --Color-Background-Disabled: #a4a4a4;
        --Color-Background-Subtle: #f6f8f9;
        --Color-Background-Subtle-2: #EBEBEC;
        --Color-Background-Default: #ffffff;

        /* Color Border */
        --Color-Border-Success: #059a52;
        --Color-Border-Warning: #fa6e20;
        --Color-Border-Danger: #cc3e3e;
        --Color-Border-Action: #7a5af5;
        --Color-Border-Disabled: #a4a4a4;
        --Color-Border-Accent-Success: rgba(155, 230, 194, 0.15);
        --Color-Border-Accent-Warning: rgba(253, 197, 166, 0.15);
        --Color-Border-Accent-Danger: rgba(204, 153, 255, 0.15);
        --Color-Border-Accent-Action: rgba(204, 153, 255, 0.15);
        --Color-Border-Selected: #2e2e2e;
        --Color-Border-Subtle: #ebebeb;
        --Color-Border-Default: #d6d6d6;

        /* Color Icon */
        --Color-Icon-Success: #059a52;
        --Color-Icon-Warning: #fa6e20;
        --Color-Icon-Danger: #cc3e3e;
        --Color-Icon-Action: #7a5af5;
        --Color-Icon-Inverse: #ffffff;
        --Color-Icon-Disabled: #5c5c5c;
        --Color-Icon-Subtlest: #2e2e2e;
        --Color-Icon-Subtle: #1f1f1f;
        --Color-Icon-Default: #0a0e18;

        /* Color Text */
        --Color-Text-Success: #059a52;
        --Color-Text-Warning: #fa6e20;
        --Color-Text-Danger: #cc3e3e;
        --Color-Text-Action: #7a5af5;
        --Color-Text-Inverse: #ffffff;
        --Color-Text-Disabled: #5c5c5c;
        --Color-Text-Subtlest: #2e2e2e;
        --Color-Text-Subtle: #1f1f1f;
        --Color-Text-Default: #000000;

        /* Hover */
        --Color-Primary-Hover: linear-gradient(90deg, #A68AFF 0.02%, #511BEB 99.98%);

        --Color-Background-Gradient-Action: linear-gradient(90deg, #9276FF 0.02%, #652FFF 99.98%);

        /* Shadow */
        --Color-Shadow-1:  0px 8px 28px rgba(0, 0, 0, 0.3);
    }
    .theme-dark {
        transition: background-color 0.3s ease;
        /* Dark Mode */

        /* Color Background */
        --Color-Background-Success: #059a52;
        --Color-Background-Accent-Success: rgba(155, 230, 194, 0.15);
        --Color-Background-Accent-Warning: rgba(253, 197, 166, 0.15);
        --Color-Background-Accent-Danger: rgba(255, 184, 184, 0.15);
        --Color-Background-Accent-Action: rgba(204, 153, 255, 0.15);
        --Color-Background-Warning: #c8581a;
        --Color-Background-Danger: #cc3e3e;
        --Color-Background-Action: #7a5af5;
        --Color-Background-Disabled: #414141;
        --Color-Background-Subtle: rgba(32, 32, 32, 0.5);
        --Color-Background-Subtle-2:rgba(32, 32, 32, 0.50);
        --Color-Background-Default: #191919;
        --Color-Background-Gradient-Action: linear-gradient(90deg, #9276FF 0.02%, #652FFF 99.98%);

        /* Color Border */
        --Color-Border-Success: #059a52;
        --Color-Border-Warning: #c8581a;
        --Color-Border-Danger: #cc3e3e;
        --Color-Border-Action: #7a5af5;
        --Color-Border-Disabled: #414141;
        --Color-Border-Accent-Success: rgba(155, 230, 194, 0.15);
        --Color-Border-Accent-Warning: rgba(253, 197, 166, 0.15);
        --Color-Border-Accent-Danger: rgba(255, 184, 184, 0.15);
        --Color-Border-Accent-Action: rgba(204, 153, 255, 0.15);
        --Color-Border-Selected: #d4d4d4;
        --Color-Border-Subtle: #222222;
        --Color-Border-Default: #2d2d2d;

        /* Color Icon */
        --Color-Icon-Success: #059a52;
        --Color-Icon-Warning: #c8581a;
        --Color-Icon-Danger: #cc3e3e;
        --Color-Icon-Action:  #7a5af5;
        --Color-Icon-Inverse: #ffffff;
        --Color-Icon-Disabled: #414141;
        --Color-Icon-Subtlest: #a4a4a4;
        --Color-Icon-Subtle: #d4d4d4;
        --Color-Icon-Default: #ffffff;

        /* Color Text */
        --Color-Text-Success: #059a52;
        --Color-Text-Warning: #c8581a;
        --Color-Text-Danger: #cc3e3e;
        --Color-Text-Action: #7a5af5;
        --Color-Text-Inverse: #ffffff;
        --Color-Text-Disabled: #414141;
        --Color-Text-Subtlest: #a4a4a4;
        --Color-Text-Subtle: #d4d4d4;
        --Color-Text-Default: #ffffff;

        /* Hover */
        --Color-Primary-Hover: linear-gradient(90deg, #A68AFF 0.02%, #511BEB 99.98%);

        /* Shadow */
        --Color-Shadow-1:  4px 4px 16px rgba(0, 0, 0, 0.25);
    }

    a {
        text-decoration: none;
    }
    button {
        outline: 0;
        border: none;
        cursor: pointer;
    }
    input {
        border: 1px solid transparent;
        outline: none;
        letter-spacing: var(--letter-spacing);
    }
    input[type="checkbox"]{
        width: 20px;
        height: 20px;
        accent-color: var(--Color-Background-Subtle);
    }
    input[type="checkbox"]:checked{
        width: 20px;
        height: 20px;
        accent-color: var(--Color-Background-Action);
    }

    /* Scrollbar Styling */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: var(--Color-Background-Subtle);
    }

    *,
    *:before,
    *:after {
        /* outline: 1px solid dodgerblue; // for debugging layout */
    }
    /* Remove Remove Arrows/Spinners */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    input[type=checkbox]:indeterminate {
        content: "";
        display: block;
        color: white;
        width: 20px;
        height: 20px;
        background-color:var(--dark-600);
        position: relative;
    }
    input[type=checkbox]:indeterminate::before {
        content: "";
        display: block;
        color: white;
        width: 20px;
        height: 20px;
        background-color:var(--dark-600);
        border: 1px solid var(--Color-Border-Subtle);
        border-radius: var(--Size-CornerRadius-S);
    }
    input[type=checkbox]:indeterminate::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border: solid white;
        border-width: 2px 0 0 0;
        position: absolute;
        top: 10px;
        left: 6px;
    }


    /* ************************* */
    /* ************************* */
    /* Custom Toast design start */
    /* ************************* */
    /* ************************* */

    .Toastify__toast--success::after, .Toastify__toast--error::after, .Toastify__toast--info::after, .Toastify__toast--warning::after {
        content : url(${CloseIcon}); // Your svg Path
        position: absolute;
        right: 14px;
        top: 0;
        height: calc(100% + 3px);
        color: var(--light-100);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Toastify__toast--success {
        ${ToastCss}
    }
    .Toastify__toast--success::before {
        ${ToastIconCss}
        content: url(${SuccessIcon});
    }
    .Toastify__progress-bar--success{
        background-color: var(--Color-Background-Success);
    }
    /* Info Toast Styles */
    .Toastify__toast--info {
        ${ToastCss}
    }
    .Toastify__toast--info::before {
        ${ToastIconCss}
        content: url(${InfoIcon});
    }
    .Toastify__progress-bar--info{
        background-color: var(--Color-Background-Action);
    }
    /* Error Toast */
    .Toastify__toast--error {
        ${ToastCss}
    }
    .Toastify__toast--error::before {
        ${ToastIconCss}
        content: url(${ErrorIcon});
    }
    .Toastify__progress-bar--error{
        background-color: var(--Color-Background-Danger);
    }
    /* Warn Toast */
    .Toastify__toast--warning {
        ${ToastCss}
    }
    .Toastify__toast--warning::before {
        ${ToastIconCss}
        content: url(${WarningIcon});
    }
    .Toastify__progress-bar--warning{
        background-color: var(--Color-Background-Warning);
    }


    .Toastify__toast{
        border: 1px solid --Color-Border-Subtle;
    }
    .Toastify__toast-body {
        color: var(--Color-Text-Default);
        font-size: 16px;
        padding: 10px 30px 10px 50px;
        font-weight: 500;
    }
    .Toastify__toast > button>  svg {
        display: none;
    }

    /* ************************ */
    /* ************************ */
    /* Custom Toast design ends */
    /* ************************ */
    /* ************************ */


`;
