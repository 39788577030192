import React from "react";

export default function KeyboardArrowRightIcon(props) {
    return (
        <svg
            width="24"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            fill={props.disabled ? "var(--disabled)" : "#fff"}
            {...props}
        >
            <g clipPath="url(#clip0_3292_4552)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7072 12.7071C15.0977 12.3166 15.0977 11.6834 14.7072 11.2929L9.70717 6.29289C9.31664 5.90237 8.68348 5.90237 8.29295 6.29289C7.90243 6.68342 7.90243 7.31658 8.29295 7.70711L12.5858 12L8.29295 16.2929C7.90243 16.6834 7.90243 17.3166 8.29295 17.7071C8.68348 18.0976 9.31664 18.0976 9.70717 17.7071L14.7072 12.7071Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_3292_4552">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
