const { isBrowser } = require("browser-or-node");
// const { transformRequestOptions } = require("./../../../helper/utils");

const { sign } = require("./signature");

function transformRequestOptions(params) {
    let options = "";

    for (const key in params) {
        if (typeof params[key] !== "object" && typeof params[key] !== "undefined") {
            const encodeVal = encodeURIComponent(params[key]);
            options += `${key}=${encodeVal}&`;
        } else if (Array.isArray(params[key])) {
            // eslint-disable-next-line no-loop-func
            params[key].forEach((el) => {
                const encodeVal = encodeURIComponent(params[key]);
                options += `${key}=${encodeVal}&`;
            });
        } else if (typeof params[key] === "object" && params[key]) {
            options += transformRequestOptions(params[key]);
        }
    }
    return options ? options.slice(0, -1) : options;
}

function getTransformer(config) {
    const { transformRequest } = config;

    if (transformRequest) {
        if (typeof transformRequest === "function") {
            return transformRequest;
        } else if (transformRequest.length) {
            return transformRequest[0];
        }
    }

    throw new Error("Could not get default transformRequest function from Axios defaults");
}

export function processQueryParams({ params, search }) {
    let queryParam = "";
    if (params && Object.keys(params).length) {
        if (search && search.trim() !== "") {
            queryParam = `&${transformRequestOptions(params)}`;
        } else {
            queryParam = `?${transformRequestOptions(params)}`;
        }
    }
    return queryParam;
}

function base64Encode(text) {
    return Buffer.from(text).toString("base64");
}

function combineURLs(baseURL, relativeURL) {
    return relativeURL
        ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
        : baseURL;
}

function isAbsoluteURL(url) {
    // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
    // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
    // by any combination of letters, digits, plus, period, or hyphen.
    return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
}

export function addSignatureFn(options) {
    return (config) => {
        if (!config.url) {
            throw new Error("No URL present in request config, unable to sign request");
        }

        let url = config.url;
        if (config.baseURL && !isAbsoluteURL(config.url)) {
            url = combineURLs(config.baseURL, config.url);
        }
        if (url.startsWith("/api") && isBrowser) {
            url = `https://${window.location.host}${url}`;
        }
        const { host, pathname, search } = new URL(url);
        if (pathname.startsWith("/service/panel") || pathname.startsWith("/api/service/panel")) {
            const { data, headers, method, params } = config;
            const queryParam = processQueryParams({ params, search });
            const transformRequest = getTransformer(config);
            const transformedData = transformRequest(data, headers);

            // Remove all the default Axios headers
            const {
                common,
                delete: _delete, // 'delete' is a reserved word
                get,
                head,
                post,
                put,
                patch,
                ...headersToSign
            } = headers;

            const signingOptions = {
                method: method && method.toUpperCase(),
                host: host,
                path: pathname + search + queryParam,
                body: transformedData,
                headers: headersToSign,
            };
            if (signingOptions["body"] && signingOptions["body"] instanceof FormData) {
                delete signingOptions["body"];
            }
            sign(signingOptions);
            config.headers["x-cplt-param"] = base64Encode(signingOptions.headers["x-cplt-param"]);
            config.headers["x-cplt-signature"] = signingOptions.headers["x-cplt-signature"];
        }
        return config;
    };
}

/**
 * Fetch monkey patching
 * NOTE: Following interceptor implementation needs to thoughrly tested with various scenario
 * Currently working for log streaming use case without body and params
 */

function loadSignatureHeaders(url, config) {
    if (!url) {
        throw new Error("No URL present in request config, unable to sign request");
    }
    try {
        const { host, pathname, search } = new URL(url);
        if (pathname.startsWith("/service/panel") || pathname.startsWith("/api/service/panel")) {
            const { data, headers, method, params } = config;
            const queryParam = processQueryParams({ params, search });
            // const transformRequest = getTransformer(config);
            // const transformedData = transformRequest(data, headers);

            // Remove all the default Axios headers ?? check everything here
            const {
                common,
                delete: _delete, // 'delete' is a reserved word
                get,
                head,
                post,
                put,
                patch,
                ...headersToSign
            } = headers;

            const signingOptions = {
                method: method && method.toUpperCase(),
                host: host,
                path: pathname + search + queryParam,
                body: data,
                headers: headersToSign,
            };
            if (signingOptions["body"] && signingOptions["body"] instanceof FormData) {
                delete signingOptions["body"];
            }
            sign(signingOptions);
            config.headers.append(
                "x-cplt-param",
                base64Encode(signingOptions.headers["x-cplt-param"]),
            );
            config.headers.append("x-cplt-signature", signingOptions.headers["x-cplt-signature"]);
            return config;
        }
    } catch (error) {}
}
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
    let [resource, config] = args;
    // request interceptor here
    loadSignatureHeaders(resource, config);
    // original fetch call
    const response = await originalFetch(resource, config);
    // response interceptor here - passed as it is
    return response;
};
