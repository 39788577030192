import { removeDuplicatedById } from "../../utils/common-utils";
import { keyActions } from "../actions/keyActions";

const initialState = {
    list: {
        items: [],
        page: {},
    },
    key: {},
    models: [],
};

const keyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let updatedState = { ...state };
    switch (type) {
        case keyActions.GetKeys:
            updatedState.list.items = payload.items;
            updatedState.list.page = payload.page;
            return updatedState;
        case keyActions.GetKeyModels:
            updatedState.models = payload.models;
            return updatedState;
        case keyActions.CreateKey || keyActions.UpdateKey:
            updatedState.list.items = updateOrPush(state.list.items, payload.key.id, payload.key);
            updatedState.key = payload.key;
            return updatedState;
        case keyActions.DeleteKey:
            updatedState.list.items = removeFromArray(
                state.list.items,
                payload.key.id,
                payload.key,
            );
            updatedState.key = {};
            return updatedState;
        default:
            return state;
    }
};

export default keyReducer;

function updateOrPush(array, id, updatedObject) {
    const index = array.findIndex((obj) => obj.id === id);

    if (index !== -1) {
        array[index] = { ...array[index], ...updatedObject };
    } else {
        array.push(updatedObject);
    }

    return array;
}
function removeFromArray(array, id) {
    const index = array.findIndex((obj) => obj.id === id);
    if (index !== -1) {
        array.splice(index, 1);
    }
    return array;
}
