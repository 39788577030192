import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import List from "common/components/List";
import Button from "common/components/Button";
import { useParams, useHistory } from "react-router-dom";
import {
    Body_M_Reg,
    Body_M_Med,
    Body_XS_Reg,
    Body_2XS_Med,
    Body_S_Bold,
    SubTitle1,
} from "common/global-styled-components";
import LoaderIcon from "../../../common/components/LoaderIcon";
import AdvancedPagination from "common/components/AdvancedPagination";
import NoDataFound from "common/components/NoDataFound";
import debounce from "lodash.debounce";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import Icon from "common/components/Icon";
import { AddCircle, Add } from "iconsax-react";
import { Breakpoints } from "GlobalStyle";
import IconLogo from "common/components/Logo/IconLogo";
import { useModal } from "../../../common/components/NewModal";
import SlideToggle from "common/components/SlideToggle";
import { toast } from "react-toastify";
import { usePageTitle } from "common/utils";
import Tooltip from "common/components/Tooltip";
import { getIntegrations, updateIntegration } from "common/redux/actions/integrationActions";
import ConfirmationDialog from "common/components/ConfirmationDialog";

const NoDataFoundWrapper = styled(NoDataFound)`
    height: 100%;
    width: 100%;
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Subtle);
`;

const NoIntegrationButton = styled(Button)`
    color: var(--Color-Text-Inverse);
    border-radius: var(--Size-CornerRadius-L);
    padding: var(--Size-Padding-L) 24px 12px 24px;
    display: flex;
    gap: var(--Size-Gap-M);
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--Size-Gap-L);
`;

const IntegrationsWrapper = styled.div`
    display: grid;
    gap: var(--Size-Gap-XL);
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-content: center;
    overflow: scroll;
    @media (max-width: ${Breakpoints.mobile}px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    @media (max-width: ${Breakpoints.mobileXS}px) {
        grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    }
`;

const IntegrationBox = styled.div`
    display: flex;
    cursor: pointer;
    padding: var(--Size-Padding-XXL);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-XL);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const IntegrationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const IntegrationDetail = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-S);
    align-self: stretch;
    height: 70px;
`;

const IntegrationDescription = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const HeaderTitle = styled(SubTitle1)`
    @media (max-width: ${Breakpoints.mobile}px) {
        margin-top: -8px;
        margin-left: -12px;
    }
`;
const IntegrationMiscelleneous = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    flex-grow: 1;
    justify-content: space-between;
    overflow: auto;
`;

const PlusIconHeader = styled.div`
    display: flex;
    background: var(--Color-Background-Subtle);
    border-radius: var(--Size-CornerRadius-XXXL);
    padding: var(--Size-Padding-L);
    cursor: pointer;
`;

const InboxSyncWrapper = styled(Body_2XS_Med)`
    display: flex;
    padding: var(--Size-Padding-S) 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 50px;
    background: var(--Color-Background-Subtle);
`;

const NameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--Size-Padding-XS);
`;

const ListIntegtations = ({ setShowCopilotPlaygroundWidget }) => {
    const { orgId, agentId } = useParams();
    const history = useHistory();
    const [searchName, setSearchName] = useState("");
    const [integrationsList, setIntegrationsList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const integrationId = useRef(null);
    const [isPageLoading, setPageLoading] = useState(true);
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });

    usePageTitle(`Copilot Platform - Copilot Integrations`);

    const debounceFetch = useMemo(() => debounce(fetchIntegrationsList, 300), []);
    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
    }, []);
    useEffect(() => {
        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetch(orgId, queryString);
        }
    }, [currentPage, searchName]);

    const {
        show: showEditStatusConfirmationDialog,
        hide: hideEditStatusConfirmationDialog,
        RenderModal: RenderEditStatusDialog,
    } = useModal();

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";

        return [pageQuery, pageSizeQuery].join("&");
    };

    const openCreateIntegration = () => {
        history.push(`/org/${orgId}/cp/${agentId}/integrations/create`);
    };

    function fetchIntegrationsList(orgId, queryString) {
        getIntegrations(orgId, agentId, queryString)
            .then((res) => {
                setIntegrationsList(res.items);
                setPageDetails(res.page);
            })
            .catch((err) => {
                setIntegrationsList([]);
                setPageDetails({});
                toast.error(err?.response?.data?.message || "Error fetching integrations", {
                    autoClose: 2000,
                });
            })
            .finally(() => {
                setPageLoading(false);
            });
    }

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    const inactiveIntegration = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let data = {
            active: !integrationsList?.find((val) => val.id === integrationId?.current)?.active,
        };
        updateIntegration(orgId, agentId, integrationId?.current, data)
            .then(() => {
                setIntegrationsList((prevIntegrations) => {
                    const newIntegrations = prevIntegrations.map((val) =>
                        val.id === integrationId?.current ? { ...val, active: data.active } : val,
                    );
                    return newIntegrations;
                });
                toast.success(`${data.active ? "Enabled" : "Disabled"} Integration successfully`, {
                    autoClose: 2000,
                });
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error updating integrations", {
                    autoClose: 2000,
                });
            })
            .finally(() => {
                setPageLoading(false);
                hideEditStatusConfirmationDialog();
            });
    };

    const showTogglePopup = (id) => {
        integrationId.current = id;

        showEditStatusConfirmationDialog();
    };

    const getIntegrationDetails = (e, id) => {
        e.preventDefault();
        integrationId.current = id;
        history.push(`/org/${orgId}/cp/${agentId}/integrations/${id}`);
    };

    return (
        <>
            <List.ComponentHeader
                $padding="0px"
                $overallPadding="0px"
                justifyContent="end"
                actions={
                    <ActionWrapper>
                        <SearchPlaceHolder
                            searchName={searchName}
                            handleSearchChange={handleSearchChange}
                            width="100%"
                        />

                        <IntegrationMiscelleneous
                            mode="secondary"
                            size="small"
                            data-tooltip-id="create-new-integration"
                            onClick={openCreateIntegration}
                        >
                            {Icon(AddCircle, { size: 32, variant: "Bold" })}
                        </IntegrationMiscelleneous>
                        <Tooltip id="create-new-integration"> Connect new integration</Tooltip>
                    </ActionWrapper>
                }
            >
                <HeaderTitle>Integrations</HeaderTitle>
            </List.ComponentHeader>

            {isPageLoading ? (
                <Loader>
                    <LoaderIcon />
                </Loader>
            ) : integrationsList?.length ? (
                <>
                    <Wrapper>
                        <IntegrationsWrapper>
                            <IntegrationBox key={0} onClick={openCreateIntegration}>
                                <IntegrationHeader>
                                    <PlusIconHeader>
                                        {Icon(Add, {
                                            size: 24,
                                        })}
                                    </PlusIconHeader>
                                </IntegrationHeader>
                                <IntegrationDetail>
                                    <Body_S_Bold>Custom Integration</Body_S_Bold>
                                    <IntegrationDescription>
                                        Dive into our powerful editor and start creating
                                    </IntegrationDescription>
                                </IntegrationDetail>
                            </IntegrationBox>
                            {integrationsList.map((val) => (
                                <IntegrationBox
                                    key={val.id}
                                    onClick={(e) => getIntegrationDetails(e, val.id)}
                                >
                                    <IntegrationHeader>
                                        <IconLogo src={val?.icon} width="48px" height="48px" />

                                        {/* Added to prevent bubbling of toggle */}
                                        <ActionWrapper
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                            }}
                                        >
                                            <SlideToggle
                                                checked={val.active}
                                                onChange={() => showTogglePopup(val.id)}
                                            />
                                        </ActionWrapper>
                                    </IntegrationHeader>
                                    <IntegrationDetail>
                                        <NameWrapper>
                                            <Body_S_Bold>{val?.name}</Body_S_Bold>
                                            {val?.surfaces?.length > 0 && (
                                                <InboxSyncWrapper>Inbox sync</InboxSyncWrapper>
                                            )}
                                        </NameWrapper>
                                        <IntegrationDescription>
                                            {" "}
                                            {val?.description}
                                        </IntegrationDescription>
                                    </IntegrationDetail>
                                </IntegrationBox>
                            ))}
                        </IntegrationsWrapper>
                        <AdvancedPagination
                            page={pageDetails}
                            pageSize={pageSize}
                            paginationInfo="Integration"
                            setPageSize={setPageSize}
                            setCurrentPage={setCurrentPage}
                        />
                    </Wrapper>
                </>
            ) : (
                <NoDataFoundWrapper>
                    {
                        <>
                            <DataNotFoundMessage>
                                {searchName.length
                                    ? ""
                                    : "Looks like there are no integrations attached. Create and add integrations to your Copilot."}
                            </DataNotFoundMessage>
                            <NoIntegrationButton
                                mode="primary"
                                size="small"
                                onClick={openCreateIntegration}
                            >
                                {Icon(AddCircle, {
                                    color: "var(--Color-Icon-Inverse)",
                                    size: 20,
                                    variant: "Bold",
                                })}
                                <Body_M_Med style={{ color: "var(--Color-Text-Inverse)" }}>
                                    Create
                                </Body_M_Med>
                            </NoIntegrationButton>
                        </>
                    }
                </NoDataFoundWrapper>
            )}

            <RenderEditStatusDialog maxWidth="500px" showCancelButton={false}>
                <ConfirmationDialog
                    title={
                        integrationsList?.find((val) => val.id === integrationId.current)?.active
                            ? "De-activate"
                            : "Activate"
                    }
                    content={
                        <>
                            Are you sure you want to change status of the integration{" "}
                            <strong
                                title={
                                    integrationsList?.find(
                                        (val) => val.id === integrationId.current,
                                    )?.name
                                }
                            >
                                {
                                    integrationsList?.find(
                                        (val) => val.id === integrationId.current,
                                    )?.name
                                }
                            </strong>{" "}
                            ?
                        </>
                    }
                    okText={"OK"}
                    cancelText={"Cancel"}
                    type="danger"
                    handleOkClick={inactiveIntegration}
                    handleCancelClick={hideEditStatusConfirmationDialog}
                    data-testid="edit-integration-confirmation-dialog"
                />
            </RenderEditStatusDialog>
        </>
    );
};
export default ListIntegtations;
