import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "common/redux/actions/authActions";
import environment from "environment";
import { MENU_ITEMS } from "./data";
import { Breakpoints } from "../../../GlobalStyle";
import EllipsisIcon from "common/assets/EllipsisIcon";
import Icon from "common/components/Icon.js";

const UserAvatar = styled.div`
    position: relative;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: var(--Color-Background-Action);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const DropdownWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: default;
    display: none;
`;

const Dropdown = styled.div`
    position: absolute;
    z-index: 3;
    bottom: 54px;
    left: 16px;
    overflow: hidden;
    background: var(--Color-Background-Default);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: 30px;
    box-sizing: border-box;
    box-shadow: 0px 1.93006px 6.75521px rgba(0, 0, 0, 0.25);
    width: 216px;
    backdrop-filter: blur(60px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    display: none;
    cursor: default;
    padding: var(--Size-Padding-M) 0px;
    color: var(--Color-Text-Default);

    > :last-child :hover {
        border-radius: 0 0px 18px 18px;
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        left: auto;
        right: 16px;
    }
`;

const DropdownItem = styled.div`
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 48px;
    gap: var(--Size-Gap-M);
    font-family: var(--body-font);
    font-size: var(--body-2-d);
    color: var(--Color-Text-Defaut);
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :hover {
        // background-color: var(--dark-900);
    }
`;

const UserAvatarImage = styled.img`
    height: 32px;
    width: 32px;
    border-radius: 50%;
`;

const UserFirstNameInitial = styled.span`
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: var(--line-height-22);
    color: var(--white-100);
`;

const EllipsisIconWrapper = styled.div`
    display: none;
    @media (max-width: ${Breakpoints.mobile}px) {
        cursor: pointer;
        display: flex;
        position: absolute;
        right: 16px;
    }
`;

const Wrapper = styled.div`
    user-select: none;
    border-radius: var(--Size-CornerRadius-M);
    color: var(--Color-Text-Default);
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    @media (max-width: ${Breakpoints.mobile}px) {
        justify-content: start;
        padding: 12px 0px;
    }

    :hover {
        background: var(--Color-Background-Subtle);
    }
`;

const UserName = styled.p`
    max-width: 146px;
    margin-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
`;

const SidebarUserName = styled(UserName)`
    display: none;
    @media (max-width: ${Breakpoints.mobile}px) {
        display: flex;
    }
`;

const UserDetail = styled.div`
    max-width: 70%;
`;

const Text = styled.p`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const UserEmail = styled(Text)`
    font-size: var(--body-4-d);
`;

const ProfileDropdown = ({ collapse, setCollapse }) => {
    const history = useHistory();
    const dropdown = useRef(null);
    const dropdownWrapper = useRef(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authenticationDetails.user);
    const org = useSelector((state) => state.organizationDetails.organization);
    const { member } = useSelector((state) => state.organizationDetails || {});

    const toggleDropdown = (e, state) => {
        e.preventDefault();
        e.stopPropagation();
        if (dropdownWrapper.current && dropdown.current) {
            if (state) {
                dropdownWrapper.current.style.display = "block";
                dropdown.current.style.display = "block";
            } else {
                dropdownWrapper.current.style.display = "none";
                dropdown.current.style.display = "none";
            }
        }
    };

    useEffect(
        () =>
            history.listen(() => {
                if (dropdownWrapper) {
                    dropdownWrapper.current?.click();
                }
            }),
        [history],
    );

    const handleLogout = () => {
        logout(dispatch)
            .then(() => {
                // history.push("/auth/login");
                window?.customerBot?.("event", "unsetUser");
                window.location.assign(environment.COPILOT_BASE_URL);
            })
            .catch(() => {
                // history.push("/auth/login");
                window.location.assign(environment.COPILOT_BASE_URL);
            });
    };

    const handleMenuClick = (e, item) => {
        const obj = {
            pathname: item.route.replace(":orgId", org?._id),
            ...(item?.searchParams && { search: item?.searchParams }),
        };
        switch (item.identifier) {
            case "documentation":
                window.open(item.route, "_blank");
                break;
            case "logout":
                handleLogout();
                break;
            default:
                history.push(obj);
        }
        toggleDropdown(e, false);
        setCollapse(false);
    };

    return (
        <>
            <Wrapper
                data-testid="dropdown"
                id="UserAvatar"
                // onClick={(e) => history.push(`/org/${org?._id}/settings`)}
                onClick={(e) => toggleDropdown(e, true)}
            >
                <UserAvatar collapse={collapse}>
                    {user?.profilePicUrl ? (
                        <UserAvatarImage
                            src={user?.profilePicUrl}
                            alt={`${user?.firstName}'s profile picture`}
                            title="User Profile"
                        />
                    ) : (
                        <UserFirstNameInitial>
                            {user?.firstName.slice(0, 1).toUpperCase()}
                        </UserFirstNameInitial>
                    )}
                </UserAvatar>
                <SidebarUserName collapse={collapse}>
                    {user?.firstName} {user?.lastName}
                </SidebarUserName>
                <EllipsisIconWrapper onClick={(e) => toggleDropdown(e, true)}>
                    <EllipsisIcon width={18} height={18} fill={"var(--Color-Icon-Default)"} />
                </EllipsisIconWrapper>
            </Wrapper>
            <Dropdown data-testid="dropdown-open" ref={dropdown}>
                {MENU_ITEMS.map((item) => {
                    if (!item.users || item.users.includes(member?.type))
                        return (
                            <DropdownItem
                                key={item.identifier}
                                data-testid={item.identifier}
                                onClick={(e) => handleMenuClick(e, item)}
                            >
                                {Icon(item.icon, { size: 18 })}
                                {item.displayName}
                            </DropdownItem>
                        );
                })}
            </Dropdown>
            <DropdownWrapper
                data-testid="screen-overlay"
                ref={dropdownWrapper}
                onClick={(e) => toggleDropdown(e, false)}
            ></DropdownWrapper>
        </>
    );
};

export default ProfileDropdown;
