import React, { useEffect, useRef, useState } from "react";
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
    useParams,
    useLocation,
    useHistory,
} from "react-router-dom";
import { useModal } from "common/components/NewModal";
import styled from "styled-components";
import Button from "common/components/Button";
import {
    Body3,
    PageTitleHeader,
    PageTitleWrapper,
    PageTitleSubtitleWrapper,
    PageContent,
    StickyPageHeader,
} from "common/global-styled-components";
import { Breakpoints } from "GlobalStyle";
import { usePageTitle } from "common/utils";
import NavBar from "common/components/HorizontalNavBar";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import Appearance from "./Appearance";
import BasicInfo from "./BasicInfo";
import CustomerSupport from "./CustomerSupport";
import DataCollectionSettings from "./DataCollectionSettings";
import WelcomeContent from "./WelcomeContent";
import VoiceSettings from "./VoiceSettings";
import { isEqual } from "lodash";
import ConfirmationDialog from "common/components/ConfirmationDialog";

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    justify-content: space-between;
    gap: var(--Size-Gap-XXL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

export const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-XL);
    padding: var(--Size-Padding-XL);
    flex: 1;
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
    }
`;
export const InputWrapper = styled.div`
    width: 100%;
`;

export const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

export const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
`;
export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    align-self: stretch;
    gap: var(--Size-Gap-L);
`;
export const Row = styled.div`
    display: flex;
    align-items: center;
    grid-gap: var(--Size-Gap-L);
`;
export const RadioGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: var(--Size-Gap-XXL);
    overflow-x: scroll;
`;
export const StyledCheckbox = styled.div`
    display: flex;
    width: auto;
    gap: var(--Size-Gap-M);
    padding: 0px;
    align-items: center;
    cursor: pointer;
`;

export const Label = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: var(--semi-bold);
    color: var(--Color-Text-Default);
`;

export const ResizableIunput = styled.div`
    width: 50%;
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
    }
`;

const Settings = ({
    register,
    allValues,
    errors,
    setValue,
    onSubmit,
    setShowCopilotPlaygroundWidget,
    control,
    initialFormValues,
    reset,
    setError,
    clearErrors,
    trigger,
    isChanged,
    setIsChanged,
}) => {
    const [tabs, setTabs] = useState([]);
    const leadTableRef = useRef(null);
    const { orgId, agentId } = useParams();
    let { path } = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const [nextLocation, setNextLocation] = useState(null);
    const { show: showDialog, hide: hideDialog, RenderModal: RenderConfirmationModal } = useModal();

    useEffect(() => {
        const unblock = history.block((location, action) => {
            return handleBlockNavigation(location);
        });

        return () => {
            unblock();
        };
    }, [isChanged, history]);

    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
        setTabs([
            {
                displayName: "Basic Info",
                identifier: "info",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/settings/info`,
            },
            {
                displayName: "Appearance",
                identifier: "appear",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/settings/appear`,
            },
            {
                displayName: "Welcome Settings",
                identifier: "welcome",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/settings/welcome`,
            },
            {
                displayName: "Voice",
                identifier: "voice",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/settings/voice`,
            },
            {
                displayName: "Data Collection",
                identifier: "data-collection",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/settings/data-collection`,
            },
            {
                displayName: "Human Handoff",
                identifier: "support",
                dropDown: false,
                route: `/org/${orgId}/cp/${agentId}/settings/support`,
            },
        ]);
    }, []);

    useEffect(() => {
        const fields = [
            "icon",
            "name",
            "persona",
            "providerId",
            "titleGreeting",
            "suggestions",
            "titleText",
            "voiceAgent",
            "dataCollection",
            "activeButtonColor",
            "borderColor",
            "primaryColor",
            "primaryFontColor",
            "secondaryColor",
            "secondaryFontColor",
            "customerSupport",
            "items",
        ];
        const allInactive = Object.values(allValues?.customerSupport?.days || {}).every(
            (day) => !day.active,
        );
        const allFalse = fields.every((field) =>
            isEqual(initialFormValues?.[field], allValues?.[field]),
        );
        const validActiveDays = Object.values(allValues?.customerSupport?.days || {}).every(
            (day) => {
                if (day.active) {
                    const fromTime = new Date(`1970-01-01T${day.from}:00`);
                    const toTime = new Date(`1970-01-01T${day.to}:00`);
                    const timeDifference = (toTime - fromTime) / (1000 * 60 * 60);
                    return timeDifference >= 0.5;
                }
                return true;
            },
        );
        if (location.pathname.includes("/support")) {
            if (
                allValues?.customerSupport?.active !== initialFormValues?.customerSupport?.active &&
                !allValues?.customerSupport?.active
            ) {
                setIsChanged(true);
                return;
            }
            setIsChanged(!allFalse && !allInactive && validActiveDays);
            return;
        }
        setIsChanged(!allFalse);
    }, [allValues]);

    const handleConfirmLeave = () => {
        reset(initialFormValues, { keepDirty: true });
        hideDialog();
        setTimeout(() => {
            history.push(nextLocation.pathname);
        }, 0);
    };

    const handleBlockNavigation = (nextLocation) => {
        if (isChanged && location.pathname !== nextLocation.pathname) {
            showDialog();
            setNextLocation(nextLocation);
            return false;
        }
        return true;
    };
    const handleSave = async () => {
        let fields = await leadTableRef?.current?.getData();
        if (fields?.error) return;
        if (fields) {
            fields = fields
                ?.map((i) => {
                    return {
                        name: startCase(i.name),
                        id: camelCase(i.name),
                        description: i.description,
                        required: i.required,
                        type: i?.type,
                    };
                })
                .filter((i) => i.name && i.description && i.type);
            setValue("dataCollection.fields", fields);
        }
        onSubmit?.();
    };
    usePageTitle(`Copilot Platform - Copilot Settings`);
    return (
        <>
            <StickyPageHeader style={{ background: "none", gap: "var(--Size-Gap-S)" }}>
                <Title>
                    <PageTitleWrapper>
                        <PageTitleHeader>Settings</PageTitleHeader>
                        <PageTitleSubtitleWrapper>
                            Note: It might take up to 1 min to reflect any changes on the live
                            copilot.
                        </PageTitleSubtitleWrapper>
                    </PageTitleWrapper>
                    <Button
                        onClick={handleSave}
                        size="small"
                        disabled={!isChanged || !(Object.keys(errors).length === 0)}
                    >
                        Save Changes
                    </Button>
                </Title>
                <NavBar TABS={tabs} />
            </StickyPageHeader>
            <PageContent
                style={{
                    padding: "0px",
                    flexGrow: 1,
                    overflowY: "scroll",
                    borderRadius: "var(--Size-CornerRadius-XL",
                }}
            >
                <Switch>
                    <Route path={`${path}/info`}>
                        <BasicInfo
                            register={register}
                            allValues={allValues}
                            errors={errors}
                            setValue={setValue}
                            setError={setError}
                            clearErrors={clearErrors}
                        ></BasicInfo>
                    </Route>
                    <Route path={`${path}/appear`}>
                        <Appearance
                            register={register}
                            allValues={allValues}
                            errors={errors}
                            setValue={setValue}
                        ></Appearance>
                    </Route>
                    <Route path={`${path}/welcome`}>
                        <WelcomeContent
                            register={register}
                            allValues={allValues}
                            setValue={setValue}
                            errors={errors}
                        />
                    </Route>
                    <Route path={`${path}/voice`}>
                        <VoiceSettings
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            allValues={allValues}
                        />
                    </Route>
                    <Route path={`${path}/support`}>
                        <CustomerSupport
                            register={register}
                            allValues={allValues}
                            errors={errors}
                            setValue={setValue}
                            control={control}
                        />
                    </Route>
                    <Route path={`${path}/data-collection`}>
                        <DataCollectionSettings
                            allValues={allValues}
                            setValue={setValue}
                            leadTableRef={leadTableRef}
                            setIsChanged={setIsChanged}
                            initialFormValues={initialFormValues}
                            control={control}
                            trigger={trigger}
                            register={register}
                            errors={errors}
                            setError={setError}
                            clearErrors={clearErrors}
                        />
                    </Route>
                    <Redirect to={`${path}/info`} />
                </Switch>

                <RenderConfirmationModal maxWidth="500px">
                    <ConfirmationDialog
                        title="Changes will be lost"
                        content="Unsaved changes will be lost. Are you sure you want to leave?"
                        okText={"Ok"}
                        cancelText={"Cancel"}
                        handleOkClick={handleConfirmLeave}
                        handleCancelClick={hideDialog}
                        type="danger"
                        data-testid="edit-agent1-confirmation-dialog"
                    />
                </RenderConfirmationModal>
            </PageContent>
        </>
    );
};

export default Settings;
