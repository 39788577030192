import debounce from "lodash.debounce";
import React, {
    useEffect,
    useMemo,
    useState,
    useRef,
    useImperativeHandle,
    forwardRef,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LoaderIcon from "../../../common/components/LoaderIcon";
import { toast } from "react-toastify";
import moment from "moment";
import SyncService from "services/sync.service";
import Button from "../../../common/components/Button";
import Pagination from "../../../common/components/Pagination";
import BuildStatus from "common/components/BuildStatus";
import { useSortBy, useTable } from "react-table";
import Table from "common/components/Table";
import { useDispatch } from "react-redux";
import { Caption1 } from "../../../common/global-styled-components";
import Icon from "../../..//common/components/Icon";
import { ArrowRotateLeft } from "iconsax-react";
import { useMonaco } from "@monaco-editor/react";
import { getDataSourceLogs } from "../../../common/redux/actions/datasourceActions";
import { Body_M_Bold } from "../../../common/global-styled-components";
import { usePageTitle } from "common/utils";
import { Breakpoints } from "GlobalStyle";

const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: var(--Size-Padding-XXL);
    gap: var(--Size-Gap-XXL);
`;
const _Caption1 = styled(Caption1)`
    text-align: center;
    font-weight: 400;
`;

const Title = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    @media (max-width: ${Breakpoints.mobile}px) {
        z-index: 3;
    }
`;

const PageCodeDivider = styled.div`
    display: flex;
    gap: var(--Size-Gap-XXXL);
    flex-direction: column;
    width: 40%;
    // > div:first-child {
    //     width: 60%;
    // }
    // > div:last-child {
    //     width: 40%;
    // }
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
    }
`;

const EditorContainer = styled.div`
    width: 100%;
    display: block;
`;

const Reason = styled.div`
    display: block;
    cursor: pointer;
    text-decoration: ${(props) => props.isActive && "underline"};
    max-width: 150px;
    color: var(--Color-Text-Default);
`;

const ReasonWrapper = styled.div``;

const weekdays = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
];
const getListOfNumbers = (start, end) =>
    Array.from({ length: end - start + 1 }, (_, i) => ({
        label: (i + start).toString(),
        value: i + start,
    }));

const hours = getListOfNumbers(0, 23);
const _hours = getListOfNumbers(1, 24);
const minutes = getListOfNumbers(0, 59);
const days = getListOfNumbers(1, 31);

const syncBlockedDatasource = ["files"];

const SyncHistory = forwardRef(
    (
        { setValue, allValues, control, errors, register, reset, getValues, handleSubmit, dsId },
        ref,
    ) => {
        const { orgId, datasourceId, agentId } = useParams();
        usePageTitle(`Copilot Platform - Training History`);
        const dispatch = useDispatch();
        const itemsPerPage = 5;
        const isMounted = useRef(null);
        const [searchName, setSearchName] = useState("");
        const [syncHistory, setSyncHistory] = useState([]);
        const [isPageLoading, setPageLoading] = useState(true);
        const [isFetchingData, setFetchingData] = useState(true);
        const [pageDetails, setPageDetails] = useState({});
        const [currentPage, setCurrentPage] = useState({ page: 1 });
        const [tableData, setTableData] = useState([]);
        const [selectedHistory, setSelectedHistory] = useState(null);
        const [refreshDisabled, setRefreshDisabled] = useState(false);
        let monaco = useMonaco();
        const editorRef = useRef(null);

        const debounceFetch = useMemo(() => debounce(fetchDatasources, 300), []);

        const setSyncHistoryData = (items, pageNum) => {
            const isAppend = pageNum && pageNum !== 1;
            setSyncHistory((arr) => (isAppend ? [...arr, ...items] : items));
        };

        const updateSelectedHistory = (data) => {
            const isInPrevious8Days =
                new Date(data?.createdAt) > new Date(new Date().setDate(new Date().getDate() - 8));
            setSelectedHistory(isInPrevious8Days ? data?.id : null);
        };
        function fetchDatasources(orgId, queryString) {
            SyncService.getdatasourceHistory(orgId, agentId, datasourceId ?? dsId, queryString)
                .then((res) => {
                    const { data: payload } = res;
                    setSyncHistoryData(payload.items, payload.page.page);
                    setPageDetails(payload.page);
                    updateSelectedHistory(payload.items[0]);
                })
                .catch((err) => {
                    setSyncHistory([]);
                    setPageDetails({});
                })
                .finally(() => {
                    if (isMounted.current) {
                        setFetchingData(false);
                        setPageLoading(false);
                    }
                    setRefreshDisabled(false);
                });
        }
        useEffect(() => {
            if (!monaco || !editorRef.current) return;
            monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
                noSemanticValidation: true,
                noSyntaxValidation: true,
                onlyVisible: true,
            });

            return () => {
                editorRef.current.dispose();
            };
        }, [editorRef, monaco]);

        const logTypeMap = {
            1: "debug",
            2: "verbose",
            3: "info",
            4: "warning",
            5: "error",
            6: "critical",
        };

        useEffect(() => {
            if (selectedHistory) {
                getDataSourceLogs(orgId, agentId, datasourceId ?? dsId, selectedHistory).then(
                    (res) => {
                        if (res?.items?.length > 0) {
                            editorRef?.current?.setValue(
                                res.items
                                    .map(
                                        (l) =>
                                            `${logTypeMap[l.type]}:\t ${l.timestamp} - ${
                                                l.message
                                            }`,
                                    )
                                    .join("\n"),
                            );
                        } else {
                            editorRef?.current?.setValue("Loading...");
                        }
                    },
                );
            }
        }, [selectedHistory]);

        useEffect(() => {
            isMounted.current = true;
            if (currentPage.page) {
                setFetchingData(true);
                let queryString = `${getQueryParams()}&searchName=${searchName}`;
                debounceFetch(orgId, queryString);
            }
            return () => {
                // executed when unmount
                isMounted.current = false;
            };
        }, [currentPage, searchName]);

        useEffect(() => {
            setTableData(
                pageDetails.limit
                    ? syncHistory?.map((v) => ({
                          id: v.id,
                          status: v.status,
                          result: v.result.message,
                          createdAt: v.createdAt,
                          //   changeLogs: "--",
                      }))
                    : [],
            );
        }, [syncHistory, pageDetails]);

        const getQueryParams = () => {
            const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
            const pageSizeQuery = itemsPerPage ? `limit=${itemsPerPage}` : "limit=";
            return [pageQuery, pageSizeQuery].join("&");
        };

        const columns = useMemo(
            () => [
                {
                    Header: "Id",
                    accessor: "id",
                },
                {
                    Header: "Initiated At",
                    accessor: "createdAt",
                    disableSortBy: true,
                    Cell: (props) => (
                        <span style={{ color: "var(--Color-Text-Default)", "textWrap": "nowrap" }}>
                            {moment(props.value).format("DD/MM/YYYY, h:mm:ss a")}
                        </span>
                    ),
                },
                {
                    Header: "Status",
                    accessor: "status",
                    disableSortBy: true,
                    Cell: (props) => <BuildStatus status={props.value.toLowerCase()} />,
                },
                // {
                //     Header: "Result",
                //     accessor: "result",
                //     disableSortBy: true,
                //     Cell: (props) => (
                //         <Reason isActive={selectedHistory === props.row.values.id}>
                //             {props.value}
                //         </Reason>
                //     ),
                // },
            ],
            [selectedHistory],
        );
        const tableInstance = useTable(
            {
                columns,
                data: tableData,
                autoResetPage: false,
                manualSortBy: true,
                autoResetSortBy: false,
                initialState: {
                    hiddenColumns: ["id"],
                },
            },
            useSortBy,
        );
        useImperativeHandle(ref, () => ({
            submit: handleSubmit(saveSchedule),
            secondarySubmit: syncCall,
        }));

        const syncCall = (e) => {
            if (syncBlockedDatasource.includes(allValues?.type)) {
                toast.info(
                    `You can't manually sync datasource ${allValues?.type}; they only sync when you add new files or remove old ones`,
                    {
                        autoClose: 2000,
                    },
                );
                return;
            }
            SyncService.syncDataSources(orgId, agentId, datasourceId ?? dsId)
                .then((res) => {
                    const { data } = res;
                    setSyncHistory((arr) => [data, ...arr]);
                    toast.success("Sync Started", {
                        autoClose: 2000,
                    });
                })
                .catch((error) => {
                    toast.error(<div>{error?.response?.data?.message}</div>, { autoClose: 3000 });
                });
        };
        const saveSchedule = () => {
            try {
                if (syncBlockedDatasource.includes(allValues?.type)) {
                    toast.info(`You can't save details for datasource ${allValues?.type}`, {
                        autoClose: 2000,
                    });
                    return;
                }
                const data = {
                    hours: [typeof allValues.hour !== "undefined" ? allValues.hour : "*"],
                    minutes: [typeof allValues.minute !== "undefined" ? allValues.minute : "*"],
                    mdays:
                        allValues.scheduleType === "monthly"
                            ? [typeof allValues.date !== "undefined" ? allValues.date : "*"]
                            : ["*"],
                    wdays:
                        allValues.scheduleType === "weekly"
                            ? [typeof allValues.weekday !== "undefined" ? allValues.weekday : "*"]
                            : ["*"],
                    months: [typeof allValues.month !== "undefined" ? allValues.month : "*"],
                    type: allValues.scheduleType,
                    active: allValues.enableSchedule,
                    cronExp: allValues.cronExp,
                };
                SyncService.scheduleDataSourcesSync(orgId, agentId, datasourceId, data, dispatch)
                    .then((res) => {
                        toast.success("Sync Schedule updated", {
                            autoClose: 2000,
                        });
                    })
                    .catch((error) => {
                        let { message } = error?.response?.data;
                        toast.error(message || "Something went wrong", {
                            autoClose: 2000,
                        });
                    });
            } catch (error) {
                let { message } = error?.response?.data;
                toast.error(message || "Something went wrong", {
                    autoClose: 2000,
                });
            }
        };

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            rows,
            state: { sortBy },
        } = tableInstance;

        return (
            <>
                {isPageLoading ? (
                    <Loader>
                        <LoaderIcon />
                    </Loader>
                ) : (
                    <>
                        <PageCodeDivider>
                            <Title>
                                <Body_M_Bold>Training History</Body_M_Bold>
                                {!syncBlockedDatasource.includes(allValues?.type) && (
                                    <Button onClick={syncCall} size="small" mode="secondary">
                                        Train
                                    </Button>
                                )}
                            </Title>
                            {syncHistory.length ? (
                                <Pagination
                                    page={pageDetails}
                                    loadPage={setCurrentPage}
                                    isLoading={isFetchingData}
                                >
                                    <Table
                                        getTableProps={getTableProps}
                                        getTableBodyProps={getTableBodyProps}
                                        headerGroups={headerGroups}
                                        prepareRow={prepareRow}
                                        rows={rows}
                                        noBorder={true}
                                        alignment={"left"}
                                        onRowClick={(row) => {
                                            updateSelectedHistory(row.values);
                                        }}
                                    />
                                </Pagination>
                            ) : (
                                <TextWrapper>
                                    <_Caption1>
                                        Looks like you haven't synced your data sources yet <br />
                                    </_Caption1>
                                    {Icon(ArrowRotateLeft, { size: 48, variant: "TwoTone" })}
                                </TextWrapper>
                            )}
                            {/* <EditorContainer>
                                    <Title>
                                        <List.ComponentHeader
                                            title="Logs"
                                            subtitle="Will available for only 8 days"
                                        />{" "}
                                        <Button
                                            width="100px"
                                            onClick={() => {
                                                setRefreshDisabled(true);
                                                debounceFetch(orgId, "");
                                            }}
                                        >
                                            {refreshDisabled ? (
                                                <LoaderIcon
                                                    height="35px"
                                                    width="35px"
                                                    type="spinner"
                                                />
                                            ) : (
                                                "Refresh"
                                            )}
                                        </Button>
                                    </Title>
                                    {selectedHistory && (
                                        <Editor
                                            height="400px"
                                            width="100%"
                                            theme={"vs-dark"}
                                            defaultLanguage="log"
                                            options={{
                                                readOnly: true,
                                                hideCursorInOverviewRuler: true,
                                                selectionHighlight: false,
                                            }}
                                            onMount={(editor, monaco) => {
                                                editorRef.current = editor;
                                            }}
                                        />
                                    )}
                                    {!selectedHistory && (
                                        <TextWrapper>
                                            <_Caption1>Logs not available after 8 days</_Caption1>
                                            {Icon(Slash, {
                                                size: 48,
                                                variant: "TwoTone",
                                            })}
                                        </TextWrapper>
                                    )}
                                </EditorContainer> */}
                        </PageCodeDivider>
                    </>
                )}
            </>
        );
    },
);
export default SyncHistory;
