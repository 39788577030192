import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import styled from "styled-components";
import { Body3, InputLabel } from "../../../common//global-styled-components";
import TextInput from "../../../common/components/TextInput";
import TextArea from "../../../common/components/TextArea";
import CustomDropdown from "../../../common/components/CustomDropdown";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { updateCopilotDataSources } from "../../../common/redux/actions/datasourceActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { usePageTitle } from "common/utils";

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: var(--Size-Padding-XXL);
    gap: var(--Size-Gap-XXL);
`;
const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;
const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;

const BasicInfo = forwardRef(
    ({ allValues, setValue, register, errors, setDataSource, fetchModels }, ref) => {
        const dispatch = useDispatch();
        const { orgId, agentId, datasourceId } = useParams();
        usePageTitle(`${allValues?.name} datasource`);

        useImperativeHandle(ref, () => ({
            async submit() {
                try {
                    const data = {
                        name: allValues.name,
                        description: allValues.description,
                        providerId: allValues.providerId,
                        configuration: {
                            provider: { embeddingModelName: allValues.embeddingModelName },
                        },
                    };
                    const res = await updateCopilotDataSources(
                        orgId,
                        agentId,
                        datasourceId,
                        data,
                        dispatch,
                    );
                    setDataSource(res);
                    toast.success("Data Source info updated successfully", { autoClose: 2000 });
                } catch (error) {
                    let { message } = error?.response?.data;
                    // setLoginError(message);f
                    toast.error(message || "Something went wrong", {
                        autoClose: 2000,
                    });
                }
            },
        }));
        return (
            <>
                <InputWrapper>
                    <InputLabel background="var(--Color-Background-Default)">Name *</InputLabel>
                    <TextInput
                        name="name"
                        label="name"
                        placeholder="e.g.: Siri/Cortana/Alexa..."
                        height="156px"
                        margin="0"
                        register={register}
                        validation={{
                            maxLength: { value: 30, message: "Maximum 30 characters allowed" },
                            required: { value: true, message: "Name is required" },
                            validate: (value) => value.trim() !== "" || "Name cannot be empty",
                        }}
                        data-testid="datasource-name"
                    ></TextInput>
                    <StyledErrorSkeleton>
                        {errors?.name && (
                            <StyledError role="error">{errors?.name?.message}</StyledError>
                        )}
                    </StyledErrorSkeleton>
                </InputWrapper>
                <InputWrapper>
                    <InputLabel background="var(--Color-Background-Default)">
                        Description (Optional)
                    </InputLabel>
                    <TextArea
                        name="description"
                        label="description"
                        placeholder="E.g. Document collection of the most frequently asked questions related to the company."
                        height="56px"
                        margin="0"
                        register={register}
                        validation={{
                            maxLength: { value: 255, message: "Maximum 255 characters allowed" },
                            validate: (value) =>
                                value === "" ||
                                value?.trim() !== "" ||
                                "Description cannot be empty",
                        }}
                        data-testid="datasource-description"
                    ></TextArea>
                    <StyledErrorSkeleton>
                        {errors?.description && (
                            <StyledError role="error">{errors?.description?.message}</StyledError>
                        )}
                    </StyledErrorSkeleton>
                </InputWrapper>
            </>
        );
    },
);

export default BasicInfo;
