import { AuthAction } from "../actions/authActions";

const initialState = { isUserLoggedIn: false, user: null };

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case AuthAction.LoginSuccess:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.LoginFailed:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.GoogleLoginSuccess:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.GoogleLoginFailed:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.RegisterSuccess:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.RegisterFailed:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.Logout:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.CheckUserSession:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.UpdateDetailsSuccess:
            return {
                ...state,
                ...payload,
            };
        case AuthAction.UpdateDetailsFailed:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default authReducer;
