import React from "react";

export default function AndroidIcon({ color, size, ...rest }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 41 41"
            fill="none"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4844 38C8.68538 38 3.98437 33.299 3.98437 27.5V14.4844C3.98437 8.6854 8.68537 3.9844 14.4844 3.9844H27.5C33.299 3.9844 38 8.6854 38 14.4844V24.6938C38 25.5222 38.6716 26.1938 39.5 26.1938C40.3284 26.1938 41 25.5222 41 24.6938V14.4844C41 7.0286 34.9558 0.984402 27.5 0.984402H14.4844C7.02851 0.984402 0.984375 7.0286 0.984375 14.4844V27.5C0.984375 34.9558 7.02852 41 14.4844 41H24.6938C25.5222 41 26.1938 40.3284 26.1938 39.5C26.1938 38.6716 25.5222 38 24.6938 38H14.4844Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.9479 38C31.7099 38 29.8955 36.1857 29.8955 33.9477C29.8955 31.7096 31.7099 29.8953 33.9479 29.8953C36.1859 29.8953 38.0003 31.7096 38.0003 33.9477C38.0003 36.1857 36.1859 38 33.9479 38ZM26.8955 33.9477C26.8955 37.8426 30.0531 41 33.9479 41C37.8429 41 41.0003 37.8426 41.0003 33.9477C41.0003 30.0528 37.8429 26.8953 33.9479 26.8953C30.0531 26.8953 26.8955 30.0528 26.8955 33.9477Z"
                fill={color}
            />
        </svg>
    );
}
