import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../../GlobalStyle";
import { DEFAULT_ICON } from "../../constants/index";

const StyledLogo = styled.img.attrs((props) => ({
    src: props.src || DEFAULT_ICON,
    alt: "copilot logo",
    onError: (e) => {
        e.target.src = DEFAULT_ICON;
    },
}))`
    height: ${({ height }) => (height ? height : "40px")};
    width: ${({ width }) => (width ? width : "40px")};
    cursor: pointer;
    object-fit: cover;
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "none")};
    @media (max-width: ${Breakpoints.mobile}px) {
        height: ${({ height }) => (height ? height : "40px")};
    }
`;
const IconLogo = (props) => {
    return <StyledLogo {...props} />;
};

export default IconLogo;
