import React, { useState } from "react";
import styled from "styled-components";
import { Body_XS_Reg } from "common/global-styled-components";
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: var(--Size-Padding-XL);
    gap: var(--Size-Gap-XL);
    align-items: center;
    width: ${(props) => (props.width ? props.width : "100%")};
    border: ${(props) =>
        props.selected
            ? "2px solid var(--Color-Border-Action  )"
            : "2px solid var(--Color-Border-Subtle)"};
    border-radius: var(--Size-CornerRadius-XL);
    cursor: ${(props) => (props.enabled ? "pointer" : "not-allowed")};
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    flex-grow: 1;
`;

const TagWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
`;

const Text = {};
Text.Title = styled.div`
    display: flex;
    font-size: var(--font-size-lg);
    font-weight: 400;
`;
Text.Description = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
`;
const Tag = styled.div`
    display: flex;
    flex-direction: row;
`;

export default function Tile({
    icon,
    title,
    description,
    enabled = false,
    selected = false,
    tags = [],
    onClick,
    onDoubleClick,
}) {
    return (
        <Wrapper
            key={title}
            enabled={enabled}
            selected={selected}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
        >
            {icon}
            <ContentWrapper>
                <Text.Title>
                    {title} <TagWrapper>{tags.map((tag) => tag)}</TagWrapper>
                </Text.Title>
                <Text.Description>{description}</Text.Description>
            </ContentWrapper>
        </Wrapper>
    );
}
