import environment from "../../environment";

const URLS = {
    // APOC URLs
    registerUser: `${environment.APOC_MAIN_URL}/v1.0/authentication/register`,
    loginUser: `${environment.APOC_MAIN_URL}/v1.0/authentication/login/password`,
    forgotPassword: `${environment.APOC_MAIN_URL}/v1.0/authentication/forgot-password`,
    resetPassword: `${environment.APOC_MAIN_URL}/v1.0/authentication/set-password`,
    verifyEmail: `${environment.APOC_MAIN_URL}/v1.0/authentication/register/complete`,
    googleLogin: `${environment.APOC_MAIN_URL}/v1.0/authentication/login/google`,
    logoutUser: `${environment.APOC_MAIN_URL}/v1.0/authentication/logout`,
    currentUserSession: `${environment.APOC_MAIN_URL}/v1.0/session`,
    allUserSessions: `${environment.APOC_MAIN_URL}/v1.0/session/list`,
    profileDetails: `${environment.APOC_MAIN_URL}/v1.0/profile/detail`,
    updateEmailInit: `${environment.APOC_MAIN_URL}/v1.0/profile/update-email-init`,
    updateEmailComplete: `${environment.APOC_MAIN_URL}/v1.0/profile/update-email-complete`,
    updatePassword: `${environment.APOC_MAIN_URL}/v1.0/profile/update-password`,
    searchUsers: `${environment.APOC_MAIN_URL}/v1.0/org/:orgId/search?email=:email`,
    googleOAuthLogin: `${environment.APOC_WEBHOOK_URL}/v1.0/authentication/google`,
    updateMeta: `${environment.APOC_MAIN_URL}/v1.0/profile/meta`,
    googleOneTapLogin: `${environment.APOC_WEBHOOK_URL}/v1.0/authentication/google/one-tap`,

    // NIOBE URLs
    createOrganization: `${environment.NIOBE_MAIN_URL}/v1.0/`,
    getOrganizationDetails: `${environment.NIOBE_MAIN_URL}/v1.0/`,
    getOrganizationDetailsById: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/detail`,
    updateOrganizationDetailsById: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/detail`,
    getOrganizationPreference: `${environment.NIOBE_MAIN_URL}/v1.0/`,
    getOrganizationsForUser: `${environment.NIOBE_MAIN_URL}/v1.0/all`,
    connectIntegration: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/integrations/connect/:provider`,
    createApp: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/apps`,
    getApps: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/apps`,
    getAppDetails: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    updateApp: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    deleteApp: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    aiKeys: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/keys`,
    aiKey: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/keys/:keyId`,
    createTeamMember: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/team/`,
    getTeam: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/team/?userId=:userId&page=:page&pageSize=:pageSize`,
    getTeamMember: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    updateTeamMember: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    deleteTeamMember: `${environment.NIOBE_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,

    // NEO URLS
    dashboard: `${environment.NEO_MAIN_URL}/v1/org/:orgId/analytics/dashboard`,
    tryItYourselfCreateFlow: `${environment.NEO_MAIN_URL}/anonymouscopilotdetailscache`,
    tryItYourselfEditFlow: `${environment.NEO_MAIN_URL}/anonymouscopilotdetailscache/:id`,
    realtimeDashboardData: `${environment.NEO_MAIN_URL}/v1/org/:orgId/analytics/dashboard/realtime`,
    copilots: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps`,
    copilot: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId`,
    copilotStatus: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/status`,
    copilotInboundVoiceCall: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/surfaces/call/initiate-phone-call`,
    copilotAIVoices: `https://api.${environment.PRIMARY_DOMAIN}/service/voice/v1/retell/voices`,
    copilotDatasources: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources`,
    copilotDatasource: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/:datasourceId`,
    copilotWorkflows: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/workflows/:provider`,
    copilotworkflow: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/workflows/:provider/:workflowId`,
    copilotDataSourceHistory: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/:datasourceId/history`,
    copilotDataSourceHistoryLogs: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/:datasourceId/history/:syncHistoryId/logs`,
    copilotDataSouceSync: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/:datasourceId/sync`,
    copilotDataSouceSyncSchedule: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/:datasourceId/schedule`,
    copilotDataSouceFileSync: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/:datasourceId/sync-files`,
    copilotSkills: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/skills`,
    copilotSurfaces: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/surfaces`,
    copilotDataCollections: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datacollections`,
    copilotDataCollection: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datacollections/:datacollectionId`,
    copilotIntegration: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/integrations/:integrationId`,
    copilotIntegrationRefreshToken: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/integrations/:integrationId/refresh-token?confirm=true`,
    copilotIntegrations: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/integrations`,
    copilotIntegrationEvents: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/integrations/events`,
    copilotStats: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/stats`,
    copilotSurface: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/surfaces/:surfaceType`,
    copilotEventsNamespace: `/v1/copilotapps/:copilotId/events`,
    domainAvailability: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/surfaces/hosted-web/check-predefined-domain`,
    validateDomain: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/surfaces/hosted-web/validate-dns`,
    conversationThreads: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/threads`,
    conversationThread: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/threads/:threadId`,
    conversationThreadFilters: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/threads/filters`,
    conversationThreadMessages: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/threads/:threadId/messages`,
    uploadAssets: `${environment.NEO_MAIN_URL}/v1/org/:orgId/storage/init`,
    uploadAssetsForPublicNameSpace: `${environment.NEO_MAIN_URL}/v1/storage/init`,
    datasources: `${environment.NEO_MAIN_URL}/v1/org/:orgId/datasources`,
    datasource: `${environment.NEO_MAIN_URL}/v1/org/:orgId/datasources/:datasourceId`,
    dataSourceTokenVerify: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/:copilotId/datasources/verify-token`,
    dataSourceGoogleTokenCreate: `${environment.NEO_WEBHOOKS_URL}/v1/google-token`,
    fetchSignedUrlAssets: `${environment.NEO_MAIN_URL}/v1/org/:orgId/storage/signed-url`,
    plans: `${environment.NEO_MAIN_URL}/v1/org/:orgId/subscriptions/plans`,
    subscriptions: `${environment.NEO_MAIN_URL}/v1/org/:orgId/subscriptions`,
    subscriptionsUsage: `${environment.NEO_MAIN_URL}/v1/org/:orgId/subscriptions/usage`,
    stripePortalUrl: `${environment.NEO_MAIN_URL}/v1/org/:orgId/subscriptions/portal`,
    skills: `${environment.NEO_MAIN_URL}/v1/org/:orgId/skills/mappings`,
    systemSkills: `${environment.NEO_MAIN_URL}/v1/org/:orgId/skills`,
    systemSkill: `${environment.NEO_MAIN_URL}/v1/org/:orgId/skills/:systemSkillId`,
    keys: `${environment.NEO_MAIN_URL}/v1/org/:orgId/keys`,
    keyValidation: `${environment.NEO_MAIN_URL}/v1/org/:orgId/keys/validate?type=:type`,
    key: `${environment.NEO_MAIN_URL}/v1/org/:orgId/keys/:keyId`,
    defaults: `${environment.NEO_MAIN_URL}/v1/org/:orgId/keys/defaults`,
    models: `${environment.NEO_MAIN_URL}/v1/org/:orgId/keys/:keyId/models?type=:type`,
    anonymousCopilotDetails: `${environment.NEO_MAIN_URL}/v1/org/:orgId/copilotapps/anonymouscopilotdetails/:anonymousId`,
    copilotsForSurface: `${environment.NEO_SCRIPT_DOMAIN}/v1/copilots`,
    selectCopilot: `${environment.APOC_WEBHOOK_URL}/v1.0/authentication/google/figma`,
};

export default URLS;
