import { Manager, Socket } from "socket.io-client";
import URLS from "../utils/apiUrls";
let socketManagers = {};
let sockets = {};
function getSocketManager({ url, query = {} }) {
    const _url = new URL(url);
    if (socketManagers[url]) return socketManagers[url];
    socketManagers[url] = new Manager(`${_url.origin}`, {
        reconnectionDelayMax: 10000,
        transports: ["websocket"],
        path: `${_url.pathname}/socket.io/`,
        withCredentials: true,
        retries: 3,
        query,
    });
    return socketManagers[url];
}

export const getSocket = async ({ url, nsp, query }, { DEBUG = false }) => {
    if (sockets[url]) return sockets[url];
    const socket = getSocketManager({ url, query }).socket(nsp, {});
    sockets[url] = socket;
    return new Promise((resolve, reject) => {
        socket.on("connect", () => {
            DEBUG && console.log("resolving ");
            resolve(socket);
        });

        socket.on("disconnect", () => {
            DEBUG && console.log("Disconnected from namespace:", url);
        });

        socket.emit("ping", Date.now());
        socket.on("pong", function (time) {
            DEBUG && console.log("health check passed");
        });
        socket.on("connect_error", (...err) => {
            DEBUG && console.log("rejecting ", err);
            reject("Connection Error: " + err);
        });
    });
};
