import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../GlobalStyle";
import Icon from "./Icon";
import { CloseSquare } from "iconsax-react";

const InputWrapper = styled.div`
    position: relative;
    // width: ${(props) => (props.width ? props.width : "100%")};
    input > svg {
        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(calc(-50% - 0px)); // 5px is bottom margin
    }
    background: var(--Color-Background-Default);
    color: var(--Color-Text-Subtle);
    display: flex;
    align-items: center;
    padding-left: var(--Size-Padding-XS);
`;

const StyledInput = styled.input`
    width: 100%;
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
    /* margin: ${(props) => (props.margin ? props.margin : `0 0 5px`)}; */
    padding: ${({ size }) => (size === "small" ? "12px 25px" : "12px 25px")};
    height: 56px;
    border-radius: var(--Size-CornerRadius-L);
    font-size: var(--body-2-d);
    font-weight: var(--regular);
    line-height: var(--line-height-21);
    color: ${(props) =>
        props.disabled ? "var(--Color-Text-Subtlest)" : "var(--Color-Text-Default)"};
    padding-right: ${(props) => props.paddingRight};

    ::placeholder {
        color: var(--Color-Text-Disabled);
    }

    :-webkit-autofill {
        -webkit-text-fill-color: var(--Color-Text-Default);
        transition: background-color 5000s ease-in-out 0s;
    }

    :focus,
    :active {
        &:not(:disabled) {
            border: 1.5px solid var(--Color-Border-Subtle);
        }
    }

    @media (max-width: ${Breakpoints.mobile}px) {
        font-size: var(--body-2-m);
        height: 48px;
    }
`;
// border: ${(props) => props.errors ? '1px solid red' : 'none'};
const SpanWrapper = styled.div``;
export default function Checkbox({
    children,
    label,
    type,
    size,
    placeholder,
    register,
    validation,
    testId,
    width,
    multi = false,
    removeIndex, // skip
    index, // skip,
    ...restProps
}) {
    return (
        <InputWrapper width={width}>
            <StyledInput
                size={size}
                data-testid={testId ? testId : "inputField"}
                {...(register && register(label, validation))}
                {...restProps}
                type={"checkbox"}
                placeholder={placeholder ? placeholder : ""}
            ></StyledInput>
            {multi ? (
                <SpanWrapper
                    onClick={() => {
                        removeIndex(index);
                    }}
                >
                    {Icon(CloseSquare, { size: 24, color: "var(--Color-Icon-Disabled)" })}
                </SpanWrapper>
            ) : null}

            {children}
        </InputWrapper>
    );
}
