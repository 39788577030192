import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const ThreadService = {
    getThreads(orgId, copilotAppId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.conversationThreads}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotAppId),
            axiosOptions,
        );
    },

    patchThread(orgId, copilotAppId, threadId, type) {
        const axiosOptions = {};
        return ApiService.patch(
            `${URLS.conversationThread}/${type}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotAppId)
                .replace(":threadId", threadId),
            axiosOptions,
        );
    },

    getMessages(orgId, copilotAppId, threadId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.conversationThreadMessages
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotAppId)
                .replace(":threadId", threadId),
            axiosOptions,
        );
    },
    getFilters(orgId, copilotAppId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.conversationThreadFilters
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotAppId),
            axiosOptions,
        );
    },
};

export default ThreadService;
