import { Setting2, User, Buildings2, Document } from "iconsax-react";

export const MENU_ITEMS = [
    {
        users: ["owner", "admin", "member"],
        displayName: "Settings",
        identifier: "settings",
        dropDown: false,
        icon: Setting2,
        route: "/org/:orgId/settings",
    },
    {
        displayName: "Documentation",
        identifier: "documentation",
        dropDown: false,
        icon: Document,
        route: "https://platform.copilot.live/docs/",
    },
    {
        displayName: "Switch Org",
        identifier: "changeOrg",
        route: "/choose-company",
        searchParams: "?donotcheck=true",
        icon: Buildings2,
    },
    {
        displayName: "Profile",
        identifier: "profile",
        route: "/org/:orgId/profile",
        icon: User,
    },
    // {
    //     displayName: "Billing & Payments",
    //     identifier: "billing",
    //     route: "/org/:orgId/billing",
    //     icon: Billing,
    // },
    // {
    //     displayName: "Sign out",
    //     identifier: "logout",
    //     route: "/logout",
    //     icon: Logout,
    // },
];
