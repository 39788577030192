import React from "react";
import styled from "styled-components";

import { Breakpoints } from "../../GlobalStyle";
import { CustomButton } from "./styled-components";

const ButtonContainer = styled.div`
    position: fixed;
    gap: 10px;
    margin: 0px 10px;
    bottom: 20px;
    left: ${({ left }) => left || "0"};
    right: 0;
    display: none;

    @media (max-width: ${Breakpoints.mobile}px) {
        gap: unset;
        margin: unset;
        bottom: 0;
        display: flex;
        background: var(--dark-900);
    }
`;

export default function ActionBar({
    showSaveModal,
    buttonText = "Save Changes",
    left,
    disabled,
    style,
}) {
    return (
        <ButtonContainer left={left}>
            <CustomButton
                onClick={showSaveModal}
                width="100%"
                paddingTopBottom="16px"
                role={"clone-preset"}
                mode="primary"
                size="small"
                disabled={disabled}
                style={style}
            >
                {buttonText}
            </CustomButton>
        </ButtonContainer>
    );
}
