import React, { useEffect, useState } from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import Button from "common/components/Button";
import { useParams } from "react-router-dom";
import {
    Body_XXS_Reg,
    Body_XS_Bold,
    Body_S_Bold,
    Body3,
    InputLabel,
    StickyPageHeader,
    PageContent,
    StickyPageFooter,
    PageTitleWrapper,
    PageTitleHeader,
    PageTitleSubtitleWrapper,
    Body_2XS_Reg,
    Body_2XS_Med,
    VerticalLine,
} from "common/global-styled-components";
import TextInput from "common/components/TextInput";
import PasswordInput from "common/components/PasswordInput";
import Icon from "common/components/Icon";
import CopilotService from "../../../services/copilot.service";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { CallCalling } from "iconsax-react";
import { usePageTitle } from "common/utils";
import { Breakpoints } from "../../../GlobalStyle.js";
import CustomLink from "common/components/CustomLink";
import { FREE_PLANS } from "common/constants";
import Tooltip from "common/components/Tooltip";
import { useSelector } from "react-redux";
import { parsePhoneNumber } from "awesome-phonenumber";
import PhoneNumberInput from "common/components/PhoneNumberInput";
import { isEqual } from "lodash";
const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Size-Gap-XXL);
    ::-webkit-scrollbar {
        display: none !important;
    }
    overflow: auto;
`;

const IconWrapper = styled.div`
    width: 44px;
    background: ${(props) =>
        props.disabled
            ? "var(--Color-Background-Subtle)"
            : "var(--Color-Background-Accent-Action)"};

    display: flex;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Size-CornerRadius-XXXL);
    padding: var(--Size-Padding-M);
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    justify-content: space-between;
    gap: var(--Size-Gap-XL);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;
const InputWrapper = styled.div`
    width: 100%;
    align-items: center;
`;

const Title = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
    text-align: left;
`;

const SubHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CredsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    width: 500px;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    @media (max-width: ${Breakpoints.mobile}px) {
        max-width: 100%;
    }
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.isCallSurfaceActive ? "space-between" : "center")};
    gap: var(--Size-Gap-XXL);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;
const HeaderWrapper = styled.div`
    // margin-left: 20px;
    // margin-top: 20px;
    width: 100%;
`;

const ToggleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    padding: var(--Size-Padding-S);
    align-items: center;
    border-radius: var(--Size-CornerRadius-L);
    width: fit-content;
    background: var(--Color-Background-Subtle);
`;

const Toggle = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-M) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-M);
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: var(--Size-CornerRadius-M);
    background: ${(props) => props.isSelected && "var(--Color-Background-Default)"};
    color: ${(props) => !props.isSelected && "var(--Color-Text-Subtlest)"};
`;

const Opener = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-XL);
    flex-direction: column;
`;

const TestPhoneCallWrapper = styled.div`
    display: flex;
    gap: var(--Size-Gap-L);
    align-items: start;
`;

const TestingPhoneInputWrapper = styled.div`
    align-items: start;
`;
const CallAppSurface = ({
    type,
    oldAllValues,
    oldSetValue,
    modalCloseButtonCallback,
    handleSurfaceUpdate,
}) => {
    const { orgId, agentId } = useParams();
    const [isNewSurface, setIsNewSurface] = useState(true);
    const [isCallSurfaceActive, setCallSurfaceActive] = useState(false);
    const [validTestingPhoneNumber, setValidTestingPhoneNumber] = useState(false);
    const [currentButton, setCurrentButton] = useState("TWILIO");
    const [qrCodeUrl, setQRCodeUrl] = useState("");
    const [isChanged, setIsChanged] = useState(false);
    const [initialFormData, setInitialFormData] = useState({
        accountSid: "",
        authToken: "",
        phoneNumber: "",
    });
    const currentSubscription = useSelector(
        (state) => state?.organizationDetails?.currentSubscription,
    );
    usePageTitle(`Copilot Platform - Call Deployment`);
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        setValue,
        reset,
        control,
    } = useForm({
        mode: "all",
        defaultValues: {
            phoneNumber: "+91",
            testingPhoneNumber: "+91",
        },
    });

    const allValues = watch();
    useEffect(() => {
        CopilotService.getCopilotSurface(orgId, agentId, type)
            .then((val) => {
                let res = val.data;
                if (Object.keys(res?.config || []).length) {
                    setIsNewSurface(false);
                }
                let data = {
                    accountSid: res?.config?.accountSid || "",
                    authToken: res?.config?.authToken || "",
                    phoneNumber: res?.config?.phoneNumber || "",
                };
                setInitialFormData(data);
                setCallSurfaceActive(!!res?.active);
                let phoneNumber = res?.config?.phoneNumber?.replace(/[^0-9+]/g, "");
                setQRCodeUrl(!!res?.active ? `tel:${phoneNumber}` : "");

                reset(
                    {
                        authToken: res?.config?.authToken,
                        accountSid: res?.config?.accountSid,
                        phoneNumber: phoneNumber || "",
                    },
                    { keepDirty: true },
                );
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error getting phone call providers", {
                    autoClose: 2000,
                });
            });

        return () => {
            oldSetValue("isOtherSubmit", null);
        };
    }, []);
    const fields = ["accountSid", "phoneNumber", "authToken"];
    useEffect(() => {
        const allFilled = fields.every((key) => allValues?.[key] && allValues[key].trim() !== "");
        const allEqual = fields.every((key) => isEqual(initialFormData?.[key], allValues?.[key]));
        setIsChanged(allFilled && !allEqual);
    }, [allValues]);
    const updateDeployment = (values) => {
        // console.log(values);
        // remove extra characters from phoneNumber
        const cleanedPhoneNumber = values.phoneNumber.replace(/[^0-9+]/g, "");
        CopilotService.updateCopilotSurface(orgId, agentId, type, {
            active: true,
            config: {
                authToken: values.authToken,
                accountSid: values.accountSid,
                phoneNumber: cleanedPhoneNumber,
                provider: "TWILIO",
            },
        })
            .then((val) => {
                setQRCodeUrl(`tel:${cleanedPhoneNumber}`);
                setCallSurfaceActive(true);
                setInitialFormData(allValues);
                handleSurfaceUpdate(val.data || {});
                toast.success("Phone Call Config added");
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error updating phone call providers", {
                    autoClose: 2000,
                });
            });
    };

    const handleInboundCall = () => {
        if (!validTestingPhoneNumber) return;
        CopilotService.testingInboundVoiceCall(orgId, agentId, {
            phoneNumber: allValues?.testingPhoneNumber,
        })
            .then((val) => {
                toast.success("Phone Call Iniated. Please wait for some time.");
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error deleting Phone call providers", {
                    autoClose: 2000,
                });
            });
    };

    const handleTestingNumberChange = (val) => {
        let { valid } = parsePhoneNumber(val?.replace(/[^0-9+]/g, ""));
        setValidTestingPhoneNumber(!!valid);
    };

    const deleteCallConfig = () => {
        if (isNewSurface) {
            reset({
                authToken: "",
                accountSid: "",
                phoneNumber: "",
            });
            return;
        }
        CopilotService.deleteCopilotSurface(orgId, agentId, type, { params: { confirmed: true } })
            .then((val) => {
                toast.success("Phone Call Config deleted");
                reset({
                    authToken: "",
                    accountSid: "",
                    phoneNumber: "",
                    provider: "TWILIO",
                });
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error deleting Phone call providers", {
                    autoClose: 2000,
                });
            });
    };
    return (
        <DialogWrapper data-testid="edit-skills">
            <StickyPageHeader
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    /* flex-grow: 1, */
                    alignSelf: "flex-start",
                }}
            >
                <PageTitleWrapper>
                    <PageTitleHeader>Phone Call</PageTitleHeader>
                    <PageTitleSubtitleWrapper>
                        Once setup, A phone number is available for users to reach out to copilot.
                    </PageTitleSubtitleWrapper>
                </PageTitleWrapper>
            </StickyPageHeader>
            <PageContent
                style={{
                    background: "transparent",
                    margin: "0px",
                    padding: "0px",
                    height: "480px",
                    overflowY: "scroll",
                    gap: "var(--Size-Gap-L)",
                    width: "100%",
                    borderRadius: "0px",
                }}
            >
                {/* <HeaderWrapper>
                    <ToggleWrapper>
                        <Toggle
                            isSelected={currentButton === "TWILIO"}
                            onClick={() => setCurrentButton("TWILIO")}
                        >
                            Twilio
                        </Toggle>
                        <Toggle
                            isSelected={currentButton === "interakt"}
                            onClick={() => {}}
                            disabled={true}
                            id={"interakt-config"}
                            role="tooltip"
                            data-tip
                            data-tooltip-id={"interakt-config"}
                        >
                            Interakt (Coming Soon)
                        </Toggle>
                        <Tooltip
                            place="top"
                            type="faint"
                            effect="solid"
                            noArrow={true}
                            id={"interakt-config"}
                        >
                            Coming Soon
                        </Tooltip>
                    </ToggleWrapper>
                </HeaderWrapper> */}
                <Wrapper>
                    <Title>
                        <SubHeaderWrapper>
                            <Body_S_Bold>Twilio Credentials</Body_S_Bold>
                            <Body_XXS_Reg
                                style={{ color: "var(--Color-Text-Danger)", cursor: "pointer" }}
                                onClick={deleteCallConfig}
                            >
                                Reset Credentials
                            </Body_XXS_Reg>
                        </SubHeaderWrapper>
                        <Body_2XS_Reg>
                            Log in to your{" "}
                            <CustomLink
                                href="https://console.twilio.com/"
                                openInNew={true}
                                color={"var(--Color-Text-Action)"}
                            >
                                Twilio account
                            </CustomLink>{" "}
                            to get the following details
                        </Body_2XS_Reg>
                    </Title>
                    <RowWrapper isCallSurfaceActive={isCallSurfaceActive}>
                        <CredsWrapper>
                            <InputWrapper>
                                <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                    Enter Account SID*
                                </InputLabel>
                                <PasswordInput
                                    data-testid="accountSid"
                                    role="accountSid"
                                    type="password"
                                    label="accountSid"
                                    register={register}
                                    validation={{ required: true }}
                                    placeholder="User account settings > Account Info section > Account SID"
                                />
                                {errors.accountSid && (
                                    <StyledErrorSkeleton>
                                        {errors.accountSid &&
                                            errors.accountSid?.type === "required" && (
                                                <StyledError role="error">
                                                    Account SID is required
                                                </StyledError>
                                            )}
                                        {errors.accountSid &&
                                            errors.accountSid?.type === "custom" && (
                                                <StyledError role="error">
                                                    {errors.accountSid.message}
                                                </StyledError>
                                            )}
                                    </StyledErrorSkeleton>
                                )}
                            </InputWrapper>
                            <InputWrapper>
                                <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                    Enter Authentication Token*
                                </InputLabel>
                                <PasswordInput
                                    data-testid="authToken"
                                    role="authToken"
                                    type="password"
                                    label="authToken"
                                    register={register}
                                    validation={{ required: true }}
                                    placeholder="User account settings > Account Info section > Auth Token"
                                />
                                {errors.authToken && (
                                    <StyledErrorSkeleton>
                                        {errors.authToken &&
                                            errors.authToken?.type === "required" && (
                                                <StyledError role="error">
                                                    Auth token is required
                                                </StyledError>
                                            )}
                                        {errors.authToken &&
                                            errors.authToken?.type === "custom" && (
                                                <StyledError role="error">
                                                    {errors.authToken.message}
                                                </StyledError>
                                            )}
                                    </StyledErrorSkeleton>
                                )}
                            </InputWrapper>
                            <InputWrapper>
                                <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                    Phone Number*
                                </InputLabel>

                                <PhoneNumberInput label={"phoneNumber"} control={control} />
                                {errors?.phoneNumber ? (
                                    <StyledErrorSkeleton>
                                        {errors.phoneNumber &&
                                            errors.phoneNumber?.type === "required" && (
                                                <StyledError role="error">
                                                    Sender number is required
                                                </StyledError>
                                            )}
                                        {errors.phoneNumber &&
                                            (errors.phoneNumber?.type === "custom" ||
                                                errors.phoneNumber?.type === "validate") && (
                                                <StyledError role="error">
                                                    {errors.phoneNumber.message}
                                                </StyledError>
                                            )}
                                    </StyledErrorSkeleton>
                                ) : (
                                    <Body_2XS_Reg>
                                        This number is enabled for handling incoming and outgoing
                                        calls.
                                    </Body_2XS_Reg>
                                )}
                            </InputWrapper>
                        </CredsWrapper>
                        {isCallSurfaceActive && (
                            <>
                                <VerticalLine />
                                <ActionWrapper>
                                    <Title>
                                        <Body_XS_Bold>Make a Test Phone Call </Body_XS_Bold>
                                        <Body_2XS_Med>
                                            Scan QR to initiate a test call with Copilot
                                        </Body_2XS_Med>
                                    </Title>

                                    <Opener>
                                        <QRCode
                                            size={256}
                                            style={{
                                                height: "auto",
                                                width: "150px",
                                            }}
                                            value={qrCodeUrl}
                                            viewBox={`0 0 256 256`}
                                        />
                                        OR
                                    </Opener>
                                    <TestPhoneCallWrapper>
                                        <TestingPhoneInputWrapper>
                                            <PhoneNumberInput
                                                label={"testingPhoneNumber"}
                                                onChange={handleTestingNumberChange}
                                                control={control}
                                            />
                                            <StyledErrorSkeleton>
                                                {errors.testingPhoneNumber && (
                                                    <StyledError role="error">
                                                        {errors?.testingPhoneNumber?.message}
                                                    </StyledError>
                                                )}
                                            </StyledErrorSkeleton>
                                        </TestingPhoneInputWrapper>
                                        <IconWrapper
                                            disabled={!validTestingPhoneNumber}
                                            onClick={handleInboundCall}
                                        >
                                            {Icon(CallCalling, {
                                                size: 20,
                                                variant: "Bold",
                                                color: !validTestingPhoneNumber
                                                    ? "var(--Color-Icon-Disabled)"
                                                    : "var(--Color-Background-Action)",
                                            })}
                                        </IconWrapper>
                                    </TestPhoneCallWrapper>
                                </ActionWrapper>
                            </>
                        )}
                    </RowWrapper>
                </Wrapper>
            </PageContent>
            <StickyPageFooter>
                {modalCloseButtonCallback && (
                    <Button
                        mode="secondary"
                        onClick={() => {
                            modalCloseButtonCallback?.();
                        }}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    onClick={handleSubmit(updateDeployment)}
                    disabled={!isChanged || !(Object.keys(errors).length === 0)}
                >
                    Save
                </Button>
            </StickyPageFooter>
        </DialogWrapper>
    );
};
export default CallAppSurface;
