import React from "react";

const CopilotIcon = (props) => {
    return (
        <svg
            width={props.size}
            height={props.size}
            viewBox="0 0 30 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="1.08333"
                y="1.01669"
                width="27.8333"
                height="21.9667"
                rx="5.25"
                stroke={props.color}
                strokeWidth="1.5"
            />
            <ellipse cx="9.13333" cy="12" rx="2.93333" ry="3.66667" fill={props.color} />
            <ellipse cx="20.8667" cy="12" rx="2.93333" ry="3.66667" fill={props.color} />
        </svg>
    );
};

export default CopilotIcon;
