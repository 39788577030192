import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";
import Button from "common/components/Button";
import { useParams } from "react-router-dom";
import { Copy as CopyIcon } from "iconsax-react";
import {
    Body_XXS_Reg,
    Body3,
    InputLabel,
    Body_XS_Reg,
    Body_L_Bold,
    StickyPageHeader,
    PageContent,
    StickyPageFooter,
    PageTitleWrapper,
    PageTitleHeader,
    PageTitleSubtitleWrapper,
} from "common/global-styled-components";
import TextInput from "common/components/TextInput";
import PasswordInput from "common/components/PasswordInput";
import Icon from "common/components/Icon";
import CopilotService from "../../../services/copilot.service";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import copy from "copy-to-clipboard";
import environment from "environment";
import { usePageTitle } from "common/utils";
import { useSelector } from "react-redux";
import { FREE_PLANS } from "common/constants";
import Tooltip from "common/components/Tooltip";
import { useModal } from "common/components/NewModal";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import InfoIcon from "common/assets/InfoIcon";
import WhatsappIcon from "common/assets/WhatsappIcon";
import { Breakpoints } from "../../../GlobalStyle.js";
import Divider from "common/components/Divider.js";
import PhoneNumberInput from "common/components/PhoneNumberInput";
import { isEqual } from "lodash";
import { isPhoneValid } from "common/utils/validations";

const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Size-Gap-XXL);
    ::-webkit-scrollbar {
        display: none !important;
    }
    overflow: auto;
`;

const HeaderWrapper = styled.div`
    // margin-left: 20px;
    // margin-top: 20px;
    width: 100%;
`;

const DomainInputWrapper = styled(TextInput)`
    // background: var(--Color-Background-Subtle);
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    justify-content: space-between;
    gap: var(--Size-Gap-XL);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    background: var(--Color-Background-Default);
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
    margin-top: 4px;
    font-size: var(--body-4-m);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;
const InputWrapper = styled.div`
    width: 100%;
    align-items: center;
`;
const Opener = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-XL);
    flex-direction: column;
`;
const Label = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
`;

const Title = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
    text-align: left;
`;

const ToggleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    padding: var(--Size-Padding-S);
    align-items: center;
    border-radius: var(--Size-CornerRadius-L);
    width: fit-content;
    background: var(--Color-Background-Subtle);
`;

const Toggle = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-M) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-M);
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: var(--Size-CornerRadius-M);
    background: ${(props) => props.isSelected && "var(--Color-Background-Default)"};
    color: ${(props) => !props.isSelected && "var(--Color-Text-Subtlest)"};
`;

const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: var(--primary-grey-90);
    gap: var(--Size-Gap-XL);
`;

const SubDescription = styled(Body_XS_Reg)`
    padding-left: 24px;
`;

const Copy = styled.div`
    cursor: pointer;
`;

const Steps = styled(Body_XS_Reg)`
    display: flex;
    gap: var(--Size-Gap-L);
    align-items: center;
    flex-wrap: wrap;
    margin-left: 36px;
`;

const StepsValue = styled.div`
    display: flex;
    padding: var(--Size-Padding-S) 12px;
    align-items: center;
    gap: var(--Size-Gap-M);
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Subtle);
`;

const TitleWrapper = styled(Body_L_Bold)`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
`;
const CredsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
`;
const ActionWrapper = styled.div`
    width: 500px;

    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    @media (max-width: ${Breakpoints.mobile}px) {
        max-width: 100%;
    }
`;
const _Divider = styled(Divider)`
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;
const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: var(--Size-Gap-XXXL);
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;
const DeleteButton = styled(Button)`
    max-width: 200px;
    margin: auto;
    margin-top: var(--Size-Padding-L);
`;

const DOMAIN_VALIDATION_REGEX = /^(?:[a-z0-9-]+\.)+[a-z]{2,}$|^$/;

const WhatsAppSurface = ({
    type,
    oldAllValues,
    oldSetValue,
    modalCloseButtonCallback,
    handleSurfaceUpdate,
}) => {
    const { orgId, agentId } = useParams();
    const [currentButton, setCurrentButton] = useState("TWILIO");
    const [domainsteps, setDomainSteps] = useState([]);
    const [isNewSurface, setIsNewSurface] = useState(true);
    const [qrCodeUrl, setQRCodeUrl] = useState("");
    const [isChanged, setIsChanged] = useState(false);
    const [initialValue, setInitialValue] = useState({
        accountSid: "",
        authToken: "",
        senderNumber: "",
    });
    const currentSubscription = useSelector(
        (state) => state?.organizationDetails?.currentSubscription,
    );
    const {
        show: showSurfaceDeleteConfirmation,
        hide: hideSurfaceDeleteConfirmation,
        RenderModal: RenderSurfaceDeleteConfirmation,
    } = useModal();

    const isFreePlan = FREE_PLANS.includes(currentSubscription?.planId);

    usePageTitle(`Copilot Platform - Manage Webpage Deployment`);
    const {
        register,
        formState: { errors },
        watch,
        reset,
        control,
    } = useForm({
        mode: "all",
        defaultValues: { senderNumber: "+91" },
    });
    const handleChange = (val, error) => {
        if (!error) {
            setQRCodeUrl(`https://wa.me/${val}`);
        }
    };

    const allValues = watch();
    useEffect(() => {
        CopilotService.getCopilotSurface(orgId, agentId, type)
            .then((val) => {
                let res = val.data;
                if (Object.keys(res?.config || []).length) {
                    setIsNewSurface(false);
                }
                const senderNumber = res?.config?.senderNumber;
                let data = {
                    accountSid: res?.config?.accountSid || "",
                    authToken: res?.config?.authToken || "",
                    senderNumber: res?.config?.senderNumber || "",
                };
                setInitialValue(data);
                setQRCodeUrl(`https://wa.me/${senderNumber}`);

                reset(
                    {
                        authToken: res?.config?.authToken,
                        accountSid: res?.config?.accountSid,
                        senderNumber: senderNumber || "",
                        // verificationNumber: res?.config?.verificationNumber?.replace?.(/\D/g, ""),
                    },
                    { keepDirty: true },
                );
                setCurrentButton(res?.config?.provider || "TWILIO");
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error Mapping WhatsApp Providers", {
                    autoClose: 2000,
                });
            });

        return () => {
            oldSetValue("isOtherSubmit", null);
        };
    }, []);
    const fields = ["accountSid", "authToken", "senderNumber"];
    useEffect(() => {
        const allFilled = fields.every((key) => allValues?.[key] && allValues[key].trim() !== "");
        const allEqual = fields.every((key) => isEqual(initialValue?.[key], allValues?.[key]));
        setIsChanged(allFilled && !allEqual);
    }, [allValues]);
    const updateDeployment = () => {
        CopilotService.updateCopilotSurface(orgId, agentId, type, {
            active: true,
            config: {
                authToken: allValues.authToken,
                accountSid: allValues.accountSid,
                senderNumber: allValues.senderNumber,
                provider: currentButton,
            },
        })
            .then((val) => {
                setInitialValue(allValues);
                toast.success("WhatsApp Config added");
                handleSurfaceUpdate(val?.data || {});
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error Mapping WhatsApp Providers", {
                    autoClose: 2000,
                });
            });
    };
    const deleteSurface = () => {
        if (isNewSurface) {
            reset({
                authToken: "",
                accountSid: "",
                senderNumber: "",
                // verificationNumber: "",
                provider: currentButton,
            });
            hideSurfaceDeleteConfirmation();
            return;
        }
        CopilotService.deleteCopilotSurface(orgId, agentId, type, { params: { confirmed: true } })
            .then((val) => {
                toast.success("WhatsApp Config deleted");
                reset({
                    authToken: "",
                    accountSid: "",
                    senderNumber: "",
                    // verificationNumber: "",
                    provider: "TWILIO",
                });
                hideSurfaceDeleteConfirmation();
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error delating WhatsApp providers", {
                    autoClose: 2000,
                });
            });
    };
    const copyToClipboard = (data) => {
        copy(data);
        toast.info("Copied to clipboard", { autoClose: 2000 });
    };

    const isEnterNumberValid = !isPhoneValid(allValues?.senderNumber);

    return (
        <DialogWrapper data-testid="edit-skills">
            <StickyPageHeader
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    /* flex-grow: 1, */
                    alignSelf: "flex-start",
                }}
            >
                <PageTitleWrapper>
                    <PageTitleHeader>WhatsApp</PageTitleHeader>
                    <PageTitleSubtitleWrapper>
                        Deploy a copilot for you whatsapp business account
                    </PageTitleSubtitleWrapper>
                </PageTitleWrapper>
            </StickyPageHeader>
            <PageContent
                style={{
                    background: "transparent",
                    margin: "0px",
                    height: "480px",
                    padding: "0px",
                    overflowY: "scroll",
                    gap: "var(--Size-Gap-L)",
                    width: "100%",
                    borderRadius: "0px",
                }}
            >
                {/* <HeaderWrapper>
                    <ToggleWrapper>
                        <Toggle
                            isSelected={currentButton === "TWILIO"}
                            onClick={() => setCurrentButton("TWILIO")}
                        >
                            Twilio
                        </Toggle>
                        <Toggle
                            isSelected={currentButton === "meta"}
                            onClick={() => {
                                if (!isFreePlan) {
                                    // setCurrentButton("META");
                                }
                            }}
                            disabled={true}
                            id={"meta-config"}
                            role="tooltip"
                            data-tip
                            data-tooltip-id={"meta-config"}
                        >
                            Meta
                        </Toggle>
                        <Tooltip
                            place="top"
                            type="faint"
                            effect="solid"
                            noArrow={true}
                            id={"meta-config"}
                        >
                            Coming Soon
                        </Tooltip>
                    </ToggleWrapper>
                </HeaderWrapper> */}

                <Wrapper>
                    {currentButton === "TWILIO" && (
                        <>
                            <Title>
                                <TitleWrapper>WhatsApp Credentials </TitleWrapper>
                                <Label>
                                    Log in to your Twilio account to get the following details
                                </Label>
                            </Title>
                            <RowWrapper>
                                <CredsWrapper>
                                    <InputWrapper>
                                        <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                            Account SID*
                                        </InputLabel>
                                        <PasswordInput
                                            data-testid="accountSid"
                                            role="accountSid"
                                            type="password"
                                            label="accountSid"
                                            register={register}
                                            validation={{ required: true }}
                                            placeholder="User account settings > Account Info section > Account SID"
                                        />
                                        {errors.accountSid && (
                                            <StyledErrorSkeleton>
                                                {errors.accountSid &&
                                                    errors.accountSid?.type === "required" && (
                                                        <StyledError role="error">
                                                            Account SID is required
                                                        </StyledError>
                                                    )}
                                                {errors.accountSid &&
                                                    errors.accountSid?.type === "custom" && (
                                                        <StyledError role="error">
                                                            {errors.accountSid.message}
                                                        </StyledError>
                                                    )}
                                            </StyledErrorSkeleton>
                                        )}
                                    </InputWrapper>
                                    <InputWrapper>
                                        <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                            Auth Token*
                                        </InputLabel>
                                        <PasswordInput
                                            data-testid="authToken"
                                            role="authToken"
                                            type="password"
                                            label="authToken"
                                            register={register}
                                            validation={{ required: true }}
                                            placeholder="User account settings > Account Info section > Auth Token"
                                        />
                                        {errors.authToken && (
                                            <StyledErrorSkeleton>
                                                {errors.authToken &&
                                                    errors.authToken?.type === "required" && (
                                                        <StyledError role="error">
                                                            Auth token is required
                                                        </StyledError>
                                                    )}
                                                {errors.authToken &&
                                                    errors.authToken?.type === "custom" && (
                                                        <StyledError role="error">
                                                            {errors.authToken.message}
                                                        </StyledError>
                                                    )}
                                            </StyledErrorSkeleton>
                                        )}
                                    </InputWrapper>
                                    <InputWrapper>
                                        <InputLabel style={{ color: "var(--Color-Text-Subtlest" }}>
                                            Sender Phone Number*
                                        </InputLabel>

                                        <PhoneNumberInput
                                            control={control}
                                            label={"senderNumber"}
                                            onChange={handleChange}
                                        />
                                        {errors.senderNumber && (
                                            <StyledErrorSkeleton>
                                                {errors.senderNumber &&
                                                    errors.senderNumber?.type === "required" && (
                                                        <StyledError role="error">
                                                            Sender number with a WhatsApp business
                                                            account is required
                                                        </StyledError>
                                                    )}
                                                {errors.senderNumber &&
                                                    (errors.senderNumber?.type === "custom" ||
                                                        errors.senderNumber?.type ===
                                                            "validate") && (
                                                        <StyledError role="error">
                                                            {errors.senderNumber.message}
                                                        </StyledError>
                                                    )}
                                            </StyledErrorSkeleton>
                                        )}
                                    </InputWrapper>
                                    <InputWrapper>
                                        <InputLabel>
                                            <strong>IMPORTANT:</strong> Configure the following POST
                                            webhook on the{" "}
                                            <a
                                                href={`https://console.twilio.com/us1/develop/sms/senders/whatsapp-senders`}
                                                target="_blank"
                                            >
                                                Twilio Console
                                            </a>{" "}
                                            for your Sender Number.
                                        </InputLabel>
                                        <TextInput
                                            value={`${environment.NEO_WEBHOOKS_URL}/v1/whatsapp/twilio/dm/${agentId}`}
                                            type={"text"}
                                            disabled={true}
                                            style={{
                                                background: "var(--Color-Background-Subtle)",
                                                maxWidth: "90%",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <div
                                                onClick={() => {
                                                    copyToClipboard(
                                                        `${environment.NEO_WEBHOOKS_URL}/v1/whatsapp/twilio/dm/${agentId}`,
                                                    );
                                                }}
                                            >
                                                {Icon(CopyIcon, {
                                                    size: 21,
                                                    color: "var(--Color-Icon-Subtlest)",
                                                })}
                                            </div>
                                        </TextInput>
                                    </InputWrapper>
                                </CredsWrapper>
                                <_Divider
                                    vertical={true}
                                    style={{ width: "2px", height: "auto", margin: "none" }}
                                ></_Divider>
                                <ActionWrapper>
                                    <Title>
                                        <TitleWrapper>Manage </TitleWrapper>
                                        <Label>Test your WhatsApp Sender</Label>
                                    </Title>
                                    <InputWrapper>
                                        <Opener>
                                            <QRCode
                                                size={256}
                                                style={{
                                                    height: "auto",
                                                    width: "150px",
                                                    ...(isEnterNumberValid && {
                                                        filter: "blur(5px)",
                                                    }),
                                                }}
                                                value={qrCodeUrl}
                                                viewBox={`0 0 256 256`}
                                            />
                                            or
                                            <Button
                                                disabled={isEnterNumberValid}
                                                mode="secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (allValues?.senderNumber?.length)
                                                        window.open(qrCodeUrl);
                                                }}
                                            >
                                                Open WhatsApp{Icon(WhatsappIcon, { size: 24 })}
                                            </Button>
                                        </Opener>
                                        <InputWrapper>
                                            <DeleteButton
                                                mode="danger"
                                                onClick={showSurfaceDeleteConfirmation}
                                                style={{}}
                                            >
                                                {isNewSurface ? "Clear" : "Delete"}
                                            </DeleteButton>
                                        </InputWrapper>
                                    </InputWrapper>
                                </ActionWrapper>
                            </RowWrapper>

                            {/* <InputWrapper>
                                <InputLabel
                                    style={{
                                        color: "var(--Color-Text-Subtlest)",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    Verification Number*
                                    <Description
                                        role="tooltip"
                                        onClick={(e) => e.stopPropagation()}
                                        data-tooltip-id={"verificationNumber"}
                                        data-tip
                                    />
                                </InputLabel>
                                <Tooltip
                                    place="right"
                                    type="faint"
                                    effect="solid"
                                    id={"verificationNumber"}
                                >
                                    Any registered WhatsApp number other than the sender to send
                                    test/verification messages{" "}
                                </Tooltip>
                                <div
                                    style={{
                                        display: "flex",
                                        border: "1px solid var(--Color-Border-Subtle)",
                                        color: "var(--Color-Text-Subtle)",
                                        borderRadius: "12px",
                                        alignItems: "center",
                                    }}
                                >
                                    <span
                                        className="unit"
                                        style={{
                                            position: "relative",
                                            left: "13px",
                                            height: "100%",
                                            color: "var(--Color-Text-Disabled)",
                                        }}
                                    >
                                        +
                                    </span>

                                    <TextInput
                                        data-testid="verificationNumber"
                                        role="verificationNumber"
                                        type="number"
                                        label="verificationNumber"
                                        register={register}
                                        onChange={handleChange}
                                        validation={{
                                            required: true,
                                            maxLength: {
                                                value: 12,
                                                message:
                                                    "Verification Number must be under 12 digits including the country code",
                                            },
                                        }}
                                        maxLength={14}
                                        placeholder="91xxxxxxxxxx"
                                        style={{
                                            flex: 1,
                                            border: "none",
                                            outline: "none",
                                            marginLeft: "3%",
                                            paddingLeft: "8px",
                                            width: "-webkit-fill-available",
                                        }}
                                    />
                                </div>
                                {errors.verificationNumber && (
                                    <StyledErrorSkeleton>
                                        {errors.verificationNumber &&
                                            errors.verificationNumber?.type === "required" && (
                                                <StyledError role="error">
                                                    A WhatsApp registered number to send a
                                                    verification message and verify the credentials
                                                    is required
                                                </StyledError>
                                            )}
                                        {errors.verificationNumber &&
                                            errors.verificationNumber?.type === "custom" && (
                                                <StyledError role="error">
                                                    {errors.verificationNumber.message}
                                                </StyledError>
                                            )}
                                    </StyledErrorSkeleton>
                                )}
                            </InputWrapper> */}
                        </>
                    )}
                    {/* {currentButton === "META" && (
                        <>
                            <Title>
                                <Body_L_Bold>Connect Your Domain</Body_L_Bold>
                                <Label>
                                    Enter the web address you want for your site. It can be a domain
                                    you want to buy or one you already own.
                                </Label>
                            </Title>
                            <InputWrapper>
                                <InputLabel style={{ color: "var(--Color-Text-Subtlest)" }}>
                                    Current Domain
                                </InputLabel>
                                <DomainInputWrapper
                                    data-testid="customDomain"
                                    role="customDomain"
                                    type="customDomain"
                                    label="customDomain"
                                    register={register}
                                    style={{ resize: "vertical" }}
                                    placeholder="Enter domain Eg: yourdomain.com"
                                    validation={{
                                        // required: "Domain Name is required",
                                        pattern: {
                                            value: DOMAIN_VALIDATION_REGEX,
                                            message: "Invalid Domain Name",
                                        },
                                    }}
                                >
                                    {allValues?.customDomain && (
                                        <div
                                            onClick={() => {
                                                window.open(
                                                    `//${allValues?.customDomain}`,
                                                    "_blank",
                                                );
                                            }}
                                        >
                                            {Icon(ExportSquare, { size: 21 })}
                                        </div>
                                    )}
                                </DomainInputWrapper>
                                <StyledErrorSkeleton>
                                    {errors.customDomain && (
                                        <StyledError role="error">
                                            {errors.customDomain.message}
                                        </StyledError>
                                    )}
                                </StyledErrorSkeleton>
                            </InputWrapper>
                            {domainsteps?.length > 0 && (
                                <StepWrapper>
                                    <Body_XS_Bold>
                                        Follow this steps to link your domain:
                                    </Body_XS_Bold>
                                    <SubDescription>Open domain settings</SubDescription>
                                    {domainsteps?.map((val) => (
                                        <Steps>
                                            {val?.success ? <SuccessIcon /> : <ErrorIcon />}
                                            {val.label}
                                            {val.value && (
                                                <StepsValue>
                                                    {val.value}
                                                    <Copy onClick={() => {}}>
                                                        {Icon(DocumentCopy, { size: 21 })}
                                                    </Copy>
                                                </StepsValue>
                                            )}
                                        </Steps>
                                    ))}
                                </StepWrapper>
                            )}
                            <Button mode="secondary" onClick={() => {}}>
                                Validate
                            </Button>
                        </>
                    )} */}
                </Wrapper>
            </PageContent>
            <StickyPageFooter>
                {modalCloseButtonCallback && (
                    <Button
                        mode="secondary"
                        onClick={() => {
                            modalCloseButtonCallback?.();
                        }}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    onClick={updateDeployment}
                    disabled={!isChanged || !(Object.keys(errors).length === 0)}
                >
                    Save
                </Button>
            </StickyPageFooter>
            <RenderSurfaceDeleteConfirmation maxWidth="500px" showCancelButton={false}>
                <ConfirmationDialog
                    title="Delete Surface"
                    content={
                        <>
                            Are you sure you want to {isNewSurface ? "clear" : "delete"} the "
                            <strong title={"WhatsApp"}>WhatsApp</strong>" Surface? This irreversible
                            action will remove all provider configurations associated with this
                            Surface.
                        </>
                    }
                    okText={isNewSurface ? "Clear" : "Delete"}
                    cancelText={"Cancel"}
                    handleOkClick={deleteSurface}
                    handleCancelClick={hideSurfaceDeleteConfirmation}
                    type="danger"
                    data-testid="edit-agent-confirmation-dialog"
                />
            </RenderSurfaceDeleteConfirmation>
        </DialogWrapper>
    );
};
export default WhatsAppSurface;
