import React, { FC } from "react";
import styled from "styled-components";
import KeyboardArrowDownIcon from "../../common/assets/KeyboardArrowDownIcon";
import LoaderIcon from "common/components/LoaderIcon";
import { Body2 } from "../global-styled-components";

const Wrapper = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: ${(props) => (props.alignSelf ? props.alignSelf : "initial")};
    ${(props) =>
        props.justifyContent ? `justify-content: ${props.justifyContent}; margin-right: 10px;` : ``}
`;

const ShowMoreButton = styled(Body2)`
    padding: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${(props) => (props.width === "auto" ? "auto" : "200px")};
    height: 100%;
    justify-content: center;
    // background: #151617;
    // border-radius: 5px;

    :hover {
        // background: rgb(30, 31, 32);
    }
`;
const LoadingContainer = styled.div`
    width: 100%;
    padding: var(--Size-Padding-S);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 50px; */
`;

const ShowMoreText = styled.span`
    border-bottom: 2px solid #d92a7e;
`;

const ShowMorePagination = ({
    page,
    loadPage,
    isLoading,
    styled = "arrow",
    width,
    alignSelf,
    justifyContent,
}) => {
    const buttonStyle = {
        arrow: (
            <>
                <span>Show More</span>
                <KeyboardArrowDownIcon />
            </>
        ),
        underlined: (
            <>
                <ShowMoreText>Show More</ShowMoreText>
            </>
        ),
    };
    return (
        <Wrapper alignSelf={alignSelf} justifyContent={justifyContent}>
            {!isLoading ? (
                page?.hasNext ? (
                    <ShowMoreButton
                        onClick={() => loadPage(({ page }) => ({ page: page + 1 }))}
                        width={width}
                    >
                        {buttonStyle[styled]}
                    </ShowMoreButton>
                ) : (
                    <></>
                )
            ) : (
                <LoadingContainer>
                    <LoaderIcon width="50px" height="50px"></LoaderIcon>
                </LoadingContainer>
            )}
        </Wrapper>
    );
};

export default ShowMorePagination;
