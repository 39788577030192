import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const IntegrationsService = {
    getIntegrations(orgId, copilotId, queryString, opts) {
        const axiosOptions = { ...opts };
        return ApiService.get(
            `${URLS.copilotIntegrations}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId),
            axiosOptions,
        );
    },
    getIntegration(orgId, copilotId, integrationId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotIntegration}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":integrationId", integrationId),
            axiosOptions,
        );
    },
    updateIntegration(orgId, copilotId, integrationId, data) {
        const axiosOptions = { data };
        return ApiService.put(
            `${URLS.copilotIntegration}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":integrationId", integrationId),
            axiosOptions,
        );
    },

    createIntegration(orgId, copilotId, data) {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.copilotIntegrations}`.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },
    deleteIntegration(orgId, copilotId, integrationId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.copilotIntegration}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":integrationId", integrationId),
            axiosOptions,
        );
    },

    refreshToken(orgId, copilotId, integrationId) {
        const axiosOptions = {};
        return ApiService.post(
            `${URLS.copilotIntegrationRefreshToken}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":integrationId", integrationId),
            axiosOptions,
        );
    },

    getEventsAndOperations(orgId, copilotId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotIntegrationEvents}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId),
            axiosOptions,
        );
    },
};

export default IntegrationsService;
