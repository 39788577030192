import React from "react";
import styled from "styled-components";
import { Breakpoints } from "../../GlobalStyle";

const StyledLink = styled.a`
    color: ${(props) => (props.color ? props.color : "var(--primary-light)")} !important;
    cursor: pointer;
    font-size: var(--body-2-d);
    font-weight: normal;
    display: inline-flex;
    text-decoration: underline;
    &:hover {
        text-decoration: underline;
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        font-size: var(--body-2-m);
    }
`;

export default function CustomLink({ children, openInNew = false, ...rest }) {
    const openInNewProps = {};

    if (openInNew) {
        openInNewProps.rel = "noopenner noreferrer";
        openInNewProps.target = "_blank";
    }

    return (
        <StyledLink {...rest} {...openInNewProps}>
            {children}
        </StyledLink>
    );
}
