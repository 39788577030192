import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";

const StyledReactTooltip = styled(ReactTooltip)`
    font-style: var(--body-font) !important;
    font-size: 11px !important;
    padding: var(--Size-Padding-M) !important;
    line-height: 16px !important;
    background: var(--Color-Background-Default);
    ${(props) =>
        props.noArrow &&
        `
        ::before,
        ::after {
            content: none !important;
        }
    `};
`;

export default function Tooltip({ children, type = "dark", enableToolTip = true, ...rest }) {
    let textColor = "light";
    let bgColor = type === "faint" ? "disabled" : type;

    switch (bgColor) {
        case "light":
        case "success":
        case "info":
        case "warning":
            textColor = "dark";
            break;
        case "error":
        case "faint":
            textColor = "light";
            break;
    }

    return (
        enableToolTip && (
            <StyledReactTooltip
                backgroundColor={`var(--${bgColor})`}
                textColor={`var(--${textColor})`}
                {...rest}
                style={{
                    backgroundColor: "var(--Color-Text-Default)",
                    color: "var(--Color-Background-Default)",
                    zIndex: 10,
                }}
            >
                {children}
            </StyledReactTooltip>
        )
    );
}
