import React from "react";
import styled from "styled-components";
import LoaderGif from "../assets/loader.gif";
import SpinnerGif from "../assets/Spinner.gif";

const LoaderImg = styled.img`
    width: ${(props) => (props.width ? props.width : "75px")};
    height: ${(props) => (props.width ? props.width : "75px")};
`;

function LoaderIcon(props) {
    return props?.type !== "spinner" ? (
        <LoaderImg data-testid="loader-icon" src={LoaderGif} {...props} />
    ) : (
        <LoaderImg data-testid="loader-icon" src={SpinnerGif} {...props} />
    );
}

export default LoaderIcon;
