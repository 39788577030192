import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import BasicCopilotInfo from "../BasicCopilotInfo";
import Button from "common/components/Button";
import { toast } from "react-toastify";
import { Body3, PageTitleWrapper, Body_XS_Bold } from "common/global-styled-components";
import { usePageTitle } from "common/utils";
import { useModal } from "common/components/NewModal";
import ConfirmationDialog from "common/components/ConfirmationDialog";
import { deleteCopilot } from "common/redux/actions/copilotActions";
import Divider from "common/components/Divider";
import Icon from "common/components/Icon";
import { Label, BodyWrapper } from "./index.js";
import { Trash } from "iconsax-react";

const DeleteButtonText = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
    padding: var(--Size-Padding-XL);
`;

const BasicInfo = ({ register, allValues, errors, setValue, setError, clearErrors }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { agentId, orgId } = useParams();
    usePageTitle(`Copilot Platform - Copilot Basic Info`);
    const {
        show: showCopilotDeleteConfirmation,
        hide: hideCopilotDeleteConfirmation,
        RenderModal: RenderCopilotDeleteConfirmation,
    } = useModal();

    const handleDeleteClick = () => {
        deleteCopilot(orgId, agentId, dispatch)
            .then(() => {
                toast.success("Copilot successfully deleted", { autoClose: 2000 });
                history.push(`/org/${orgId}/dashboard`);
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    toast.error(err?.response?.data?.message, { autoClose: 2000 });
                    return;
                }

                toast.error("Unable to delete Copilot", { autoClose: 2000 });
            });

        hideCopilotDeleteConfirmation();
    };

    return (
        <>
            <BodyWrapper id="basic-info-settings-tab">
                <PageTitleWrapper>
                    <Label>Basic Info</Label>
                    <Body3>Manage basic info and personality traits of your copilot.</Body3>
                </PageTitleWrapper>
                <Divider style={{ margin: "0px" }}></Divider>
                <BasicCopilotInfo
                    mode="edit"
                    register={register}
                    allValues={allValues}
                    errors={errors}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                />
            </BodyWrapper>
            <BodyWrapper id="delete-copilot-tab">
                <PageTitleWrapper>
                    <Label>Delete Copilot</Label>
                    <Body3>
                        Note: Once you delete the Copilot, you will not be able to recover it.
                    </Body3>
                </PageTitleWrapper>
                <Button
                    mode="danger3"
                    onClick={showCopilotDeleteConfirmation}
                    width="fit-content"
                    size="small"
                >
                    <DeleteButtonText>
                        {Icon(Trash, {
                            size: 20,
                            color: "var(--Color-Icon-Danger)",
                        })}
                        <Body_XS_Bold
                            style={{
                                whiteSpace: "nowrap",
                                color: "var(--Color-Text-Danger)",
                            }}
                        >
                            Delete
                        </Body_XS_Bold>
                    </DeleteButtonText>
                </Button>
            </BodyWrapper>
            <RenderCopilotDeleteConfirmation maxWidth="500px" showCancelButton={false}>
                <ConfirmationDialog
                    title="Delete Copilot"
                    content={
                        <>
                            Are you sure you want to delete the "
                            <strong title={allValues.name}>{allValues.name}</strong>" Copilot? This
                            irreversible action will remove all data sources and configurations
                            associated with this Copilot.
                        </>
                    }
                    okText={"Delete"}
                    cancelText={"Cancel"}
                    handleOkClick={handleDeleteClick}
                    handleCancelClick={hideCopilotDeleteConfirmation}
                    type="danger"
                    data-testid="edit-agent-confirmation-dialog"
                />
            </RenderCopilotDeleteConfirmation>
        </>
    );
};

export default BasicInfo;
