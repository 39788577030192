import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const DataCollectionService = {
    getDataCollections(orgId, copilotId, queryString, opts) {
        const axiosOptions = { ...opts };
        return ApiService.get(
            `${URLS.copilotDataCollections}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId),
            axiosOptions,
        );
    },
    getDataCollection(orgId, copilotId, dataCollectionId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotDataCollection}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":dataCollectionId", dataCollectionId),
            axiosOptions,
        );
    },
    updateDataCollection(orgId, copilotId, dataCollectionId, data) {
        const axiosOptions = { data };
        return ApiService.put(
            `${URLS.copilotDataCollection}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":dataCollectionId", dataCollectionId),
            axiosOptions,
        );
    },
    deleteDataCollection(orgId, copilotId, dataCollectionId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.copilotDataCollection}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":dataCollectionId", dataCollectionId),
            axiosOptions,
        );
    },
};

export default DataCollectionService;
