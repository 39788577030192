import styled from "styled-components";
import { startCase, toLower } from "lodash";

const StyledStatus = styled.span`
    text-transform: capitalize;
    padding: 3px 10px;
    font-weight: bold;
    border-radius: var(--Size-CornerRadius-XL);
    font-size: var(--body-4-m);
    border: ${(props) =>
        props.noBorder ? "none;" : `1px solid var(--${props.status || `waiting_for_resource`});`}
    color: var(--${(props) => props.status}, var(--waiting_for_resource));
    border: ${(props) => props.noBorder};
`;

const BuildStatus = ({ status, noBorder }) => {
    status = status.toLowerCase();
    return (
        <StyledStatus status={status} noBorder={noBorder}>
            {startCase(toLower(status))}
        </StyledStatus>
    );
};

export default BuildStatus;
