import React from "react";

function SearchIcon({ ...restProps }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            fill="none"
            viewBox="0 0 11 11"
            {...restProps}
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M10 10L7.825 7.825M9 5a4 4 0 11-8 0 4 4 0 018 0z"
                opacity="0.8"
            ></path>
        </svg>
    );
}

export default SearchIcon;
