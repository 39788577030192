import UserService from "services/user.service";
import store from "../common/redux/store";
import { AuthAction } from "../common/redux/actions/authActions";

/** Urls where you can redirect even if login fails */
const VALID_REDIRECTION = "choose-app";

const AuthGuard = async () => {
    const storeState = store.getState();
    const isAuthPath = window.location.pathname.includes("auth");
    const isTryForFreePath = window.location.pathname.includes("try-for-free");
    const isOrgSetup = window.location.pathname.includes("setup-company");

    // if we have user data in redux store then do not make the API call
    if (storeState["authenticationDetails"]["user"]) {
        // redirect any routes to /auth/* to home if the user is logged in (client-side routing)
        if (isAuthPath) {
            return "/choose-company";
        } else {
            return "";
        }
    } else {
        try {
            const res = await UserService.getCurrentUserSession();
            store.dispatch({
                type: AuthAction.CheckUserSession,
                payload: {
                    isUserLoggedIn: true,
                    user: res.data.session.passport.user,
                },
            });
            // if user is logged in then redirect from /auth/* pages to home OR
            if (isAuthPath) {
                return "/choose-company";
            }
            // if user is logged in then allow the requested path
            return "";
        } catch (err) {
            if (isAuthPath || isTryForFreePath) {
                return "";
            }
            if (window.location.pathname.includes(VALID_REDIRECTION)) {
                return `/auth/login?redirectUrl=${window.location.pathname}${window.location.search}`;
            }
            if (err.response?.status !== 401) {
                console.error(err);
            }
            return "/auth/login";
        }
    }
};

export default AuthGuard;
