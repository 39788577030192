import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";

import styled from "styled-components";
import TextInput from "common/components/TextInput";
import Button from "common/components/Button";
import {
    PageContent,
    Body_XS_Med,
    Body_S_Bold,
    Loader,
    Body_M_Reg,
    Body_S_Reg,
} from "common/global-styled-components";
import Icon from "common/components/Icon";
import { DocumentUpload, Profile2User } from "iconsax-react";
import List from "common/components/List";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import { usePageTitle } from "common/utils";
import { getDataCollections } from "common/redux/actions/dataCollectionActions";
import { getThreads } from "common/redux/actions/threadActions";
import { useSortBy, useTable } from "react-table";
import AdvancedPagination from "common/components/AdvancedPagination";
import Table from "common/components/Table";
import LoaderIcon from "common/components/LoaderIcon";
import moment from "moment";
import NoDataFound from "common/components/NoDataFound";
import startCase from "lodash/startCase";
import camelCase from "lodash/camelCase";
import pick from "lodash/pick";
import mapValues from "lodash/mapValues";
import { Breakpoints } from "GlobalStyle";

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--Size-Gap-XL);
`;

const ExportButton = styled(Button)`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-S);
    padding: var(--Size-Padding-M) var(--Size-Padding-L);
`;

const ExportButtonText = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;
const Layout = styled.div`
    display: flex;
    grid-gap: var(--Size-Gap-XL);
    grid-template-columns: 3fr 1fr;
    overflow: hidden;
    flex-grow: 1;
`;

const InnerPageContent = styled(PageContent)`
    flex-grow: 1;
    overflow-y: scroll;
    background: var(--Color-Background-Default);
    gap: var(--Size-Gap-XL);
`;

const CopyCSVText = styled(Body_XS_Med)`
    white-space: "nowrap";
    color: "var(--Color-Text-Default)";

    @media screen and (max-width: ${Breakpoints.mobileXS}px) {
        display: none;
    }
`;
const DataNotFoundMessage = styled(Body_S_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Default);
`;
const jsonToCsvDataCollections = (jsonArray, headers = []) => {
    if (jsonArray.length === 0) return "";

    // Flatten each object in the array
    const flattenedArray = jsonArray.map((obj) => {
        const data = {};
        if (obj?.meta?.fields?.length) {
            obj.meta.fields.forEach((field) => {
                data[camelCase(field.name)] = field.value;
            });
        }
        const device = obj?.meta?.device || {};
        const surfaces = Array.from(new Set(obj?.surfaces || [])).join(",");
        obj = pick(obj, ["name", "email", "phone"]);
        obj = { ...obj, ...data, channels: surfaces, userPlatform: device.platform };
        return obj;
    });

    // Determine which headers to use
    const selectedHeaders = headers.length > 0 ? headers.map((header) => camelCase(header)) : [];

    // Gather all unique keys from all objects or use selected headers
    const allKeys =
        selectedHeaders.length > 0
            ? selectedHeaders
            : [...new Set(flattenedArray.flatMap(Object.keys))];

    // Convert headers to sentence case
    const sentenceCaseHeaders = allKeys.map((header) => startCase(header));

    // Generate CSV rows
    const csv = flattenedArray.map((row) =>
        sentenceCaseHeaders
            .map((key) => JSON.stringify(row[camelCase(key)] || "")?.toString())
            .join(","),
    );

    // Add headers as the first row
    csv.unshift(sentenceCaseHeaders.join(","));

    // Join CSV rows with line breaks
    return csv.join("\r\n");
};

const DataCollections = ({ allValues, setUserList, setUser }) => {
    const { orgId, agentId } = useParams();
    const dispatch = useDispatch();
    const isMounted = useRef(null);
    const [searchName, setSearchName] = useState("");
    const [list, setList] = useState([]);
    const [pageDetails, setPageDetails] = useState({});
    const [pageSize, setPageSize] = useState(30);
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [tableData, setTableData] = useState([]);
    const [isPageLoading, setPageLoading] = useState(true);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [currentLeadIndex, setCurrentLeadIndex] = useState(-1);
    const [selectAllDataCollections, setSelectAllDataCollections] = useState(false);
    const [dataCollectionsEnabled, setDataCollectionsEnabled] = useState(false);
    const [availableHeaders, setAvailableHeaders] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([]);

    const history = useHistory();
    const handleCheckboxChange = (id) => {
        setSelectedIDs((prevIndices) => {
            const selectedIDIndex = prevIndices.indexOf(id);
            if (selectedIDIndex === -1) {
                if (prevIndices.length + 1 === list.length) {
                    setSelectAllDataCollections(true);
                } else {
                    setSelectAllDataCollections(false);
                }

                return [...prevIndices, id];
            } else {
                const updatedIDs = [...prevIndices];
                updatedIDs.splice(selectedIDIndex, 1);
                setSelectAllDataCollections(false);
                return updatedIDs;
            }
        });
    };

    const debounceFetchDataCollections = useMemo(() => debounce(fetchDataCollections, 300), []);

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";
        return [pageQuery, pageSizeQuery].join("&");
    };
    const getRTQueryParams = (userId) => {
        const pageQuery = "page=1";
        const pageSizeQuery = "limit=10";
        userId = userId ? `userId=${encodeURIComponent(userId)}` : "limit=";
        return [pageQuery, pageSizeQuery, userId].join("&");
    };

    usePageTitle("Copilot Inbox - Data Collections");
    useEffect(() => {
        setDataCollectionsEnabled(allValues?.dataCollection?.active || false);

        const { fields } = allValues?.dataCollection || {};
        if (fields?.length) {
            const dataCollectionEnabledColumns = fields.reduce((acc, _field) => {
                acc.push({ accessor: _field.id, name: _field.name });

                return acc;
            }, []);
            setVisibleColumns(dataCollectionEnabledColumns);
        }
    }, [allValues?.dataCollection]);

    useEffect(() => {
        isMounted.current = true;

        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            debounceFetchDataCollections(queryString);
        }
        return () => {
            // executed when unmount
            isMounted.current = false;
        };
    }, [currentPage, searchName]);
    useEffect(() => {
        setTableData(
            pageDetails?.limit
                ? list?.map((v) => {
                      const { fields, surfaces } = v.meta || {};
                      const collectionFields = {};

                      fields.forEach((field) => {
                          collectionFields[field.id] = field.value;
                      });

                      return {
                          name: v.name,
                          phone: v.phone,
                          email: v.email,
                          createdAt: v.createdAt,
                          surfaces: v.surfaces || surfaces || "",
                          userId: v.userId,
                          id: v.id,
                          ...collectionFields,
                      };
                  })
                : [],
        );
    }, [list, pageDetails]);

    function fetchDataCollections(queryString = {}) {
        getDataCollections(orgId, agentId, queryString, {}, dispatch)
            .then((res) => {
                const _list = res.items?.map((i) => {
                    return mapValues(i, (value) => (value === null ? "" : value));
                });
                setAvailableHeaders(res?.exportFilters?.headers);
                setList(_list);
                // show dataCollection if captured
                if (res?.items?.length) setDataCollectionsEnabled(true);
                setPageDetails(res.page);
                if (res?.items?.[0]) {
                    let queryString = `${getRTQueryParams(res?.items?.[0]?.userId)}`;
                    fetchThreads(queryString, res?.items?.[0]);
                }
            })
            .catch(() => {
                setList([]);
                setPageDetails({});
            })
            .finally(() => {
                if (isMounted.current) {
                    setPageLoading(false);
                }
            });
    }
    function fetchThreads(queryString, details) {
        getThreads(orgId, agentId, queryString, dispatch)
            .then((res) => {
                setUserList({ ...details, threads: res.items });
                setUser(res?.user);
            })
            .catch(() => {
                setUserList([]);
            })
            .finally(() => {
                if (isMounted.current) {
                    setPageLoading(false);
                }
            });
    }
    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };
    const selectAll = (e) => {
        if (!e.target.checked) {
            setSelectedIDs([]);
            setSelectAllDataCollections(false);
        } else {
            const newArr = list.map((i) => i.id);
            setSelectedIDs(newArr);
            setSelectAllDataCollections(true);
        }
    };

    const columns = useMemo(
        () => [
            {
                accessor: "id",
                disableSortBy: true,
                Header: (
                    <TextInput
                        width="auto"
                        type="checkbox"
                        name={``}
                        label={``}
                        checked={selectAllDataCollections}
                        onChange={selectAll}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        padding="0px var(--Size-Padding-L)"
                    ></TextInput>
                ),
                border: "none",
                padding: "0px var(--Size-Gap-L)",
                Cell: (props) => (
                    <TextInput
                        key={`lead_${props.row.values.id}`}
                        width="auto"
                        type="checkbox"
                        name={``}
                        label={``}
                        checked={selectedIDs.indexOf(props.row.values.id) > -1}
                        onChange={() => {
                            handleCheckboxChange(props.row.values.id);
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "var(--Size-Padding-L)",
                        }}
                    ></TextInput>
                ),
            },
            ...visibleColumns.map((_columnData) => ({
                Header: <>{_columnData.name}</>,
                accessor: _columnData.accessor,
                minWidth: 140,
                width: 100,
                Cell: ({ value }) => value?.toString?.() || "",
            })),
            {
                Header: <>Channel</>,
                accessor: "surfaces",
                minWidth: 140,
                width: 100,
                Cell: (props) => (
                    <>
                        {Array.from(new Set(props.row.values.surfaces))
                            ?.map((i) => startCase(i))
                            .join(", ")}
                    </>
                ),
            },
            {
                Header: <>Created At</>,
                accessor: "createdAt",
                minWidth: 140,
                width: 100,
                Cell: (props) => (
                    <>{moment(props.row.values.createdAt).format("MM/DD/YYYY [at] h:mmA z")}</>
                ),
            },
        ],
        [selectedIDs, selectAllDataCollections, list, visibleColumns],
    );

    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 400,
        }),
        [],
    );

    const tableInstance = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            autoResetPage: true,
            manualSortBy: false,
            autoResetSortBy: false,
            initialState: {
                hiddenColumns: ["userId"],
            },
        },
        useSortBy,
    );

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = tableInstance;

    const convertToCSV = () => {
        const selectedData = selectedIDs
            .map((i) => {
                return list.find((obj) => obj.id === i);
            })
            .filter((i) => i);
        const csv = jsonToCsvDataCollections(selectedData, availableHeaders || []);
        return csv;
    };

    const downloadCSV = () => {
        let exportFilename = `collected-data-${orgId}-${agentId}`;
        if (!selectedIDs.length) {
            getDataCollections(
                orgId,
                agentId,
                `page=1&limit=100000&export=true&q=${searchName}`,
                { responseType: "blob" },
                dispatch,
            )
                .then((res) => {
                    const blob = new Blob([res], { type: "text/csv" });

                    // Create a temporary anchor element
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${exportFilename}.csv`; // Set the filename for the download
                    document.body.appendChild(a);

                    // Trigger the download
                    a.click();
                    // Clean up: remove the temporary anchor and URL object
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                })
                .catch((error) => {
                    console.log("error", error);
                });
            return;
        }
        const csvContent = convertToCSV();
        const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${exportFilename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Layout>
            <PageContent
                style={{
                    padding: "0px",
                    flexGrow: 1,
                    overflowY: "scroll",
                    background: "var(--Color-Background-Default)",
                    gap: "0px",
                    margin: "0px",
                }}
            >
                <InnerPageContent>
                    {!dataCollectionsEnabled ? (
                        <NoDataFound>
                            {!searchName.length && (
                                <>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            gap: "8px",
                                        }}
                                    >
                                        <Body_S_Bold>
                                            Enable Data Collection in Settings
                                        </Body_S_Bold>
                                        <DataNotFoundMessage>
                                            Go to, Settings {">"} Data Collection {">"} Turn ON.{" "}
                                        </DataNotFoundMessage>
                                    </div>
                                    <Button
                                        mode="tertiary"
                                        size="small"
                                        onClick={() => {
                                            history.push(
                                                `/org/${orgId}/cp/${agentId}/settings/data-collection`,
                                            );
                                        }}
                                    >
                                        Go to Settings
                                    </Button>
                                </>
                            )}
                        </NoDataFound>
                    ) : (
                        <>
                            <List.ComponentHeader
                                $padding="0px"
                                $overallPadding="0px"
                                justifyContent="end"
                                actions={
                                    <ActionWrapper>
                                        <SearchPlaceHolder
                                            searchName={searchName}
                                            handleSearchChange={handleSearchChange}
                                            width="100%"
                                        />
                                        <ExportButton
                                            disabled={!list.length}
                                            mode="secondary"
                                            background="var(--Color-Background-Default)"
                                            size="small"
                                            onClick={() => {
                                                downloadCSV();
                                            }}
                                        >
                                            <ExportButtonText>
                                                {Icon(DocumentUpload, {
                                                    size: 16,
                                                    color: "var(--Color-Icon-Default)",
                                                })}
                                                <CopyCSVText>Export to CSV</CopyCSVText>
                                            </ExportButtonText>
                                        </ExportButton>
                                    </ActionWrapper>
                                }
                            >
                                <Body_S_Bold>
                                    Total Entries: {pageDetails.totalItems || 0}
                                </Body_S_Bold>
                            </List.ComponentHeader>

                            {isPageLoading ? (
                                <Loader>
                                    <LoaderIcon />
                                </Loader>
                            ) : (
                                <List.ListLayout style={{ padding: "0px" }}>
                                    {list?.length ? (
                                        <>
                                            <Table
                                                getTableProps={getTableProps}
                                                getTableBodyProps={getTableBodyProps}
                                                headerGroups={headerGroups}
                                                prepareRow={prepareRow}
                                                rows={rows}
                                                onRowClick={(e) => {
                                                    if (currentLeadIndex === e.index) return;
                                                    setCurrentLeadIndex(e.index);
                                                    // setUser(list[e.index]);
                                                    let queryString = `${getRTQueryParams(
                                                        e.original.userId,
                                                    )}`;
                                                    fetchThreads(queryString, list[e.index]);
                                                }}
                                            />
                                            <AdvancedPagination
                                                page={pageDetails}
                                                pageSize={pageSize}
                                                paginationInfo="Entries"
                                                setPageSize={setPageSize}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </>
                                    ) : (
                                        <NoDataFound>
                                            <Body_M_Reg>
                                                Looks like you have no relevant lead yet
                                            </Body_M_Reg>
                                        </NoDataFound>
                                    )}
                                </List.ListLayout>
                            )}
                        </>
                    )}
                </InnerPageContent>
            </PageContent>
        </Layout>
    );
};

export default DataCollections;
