import styled from "styled-components";

import Button from "common/components/Button";
import { Breakpoints } from "../../GlobalStyle";

export const CustomButton = styled(Button)`
    @media (max-width: ${Breakpoints.mobile}px) {
        border-radius: 0;
    }
`;
