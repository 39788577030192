import React from "react";
import styled from "styled-components";

const Wrapper = styled.ul`
    display: flex;
    flex-direction: column;
    padding: ${(props) => (props?.$padding ? props?.$padding : "0px 24px 24px 24px")};
    list-style: none;
    height: inherit;
    width: 100%;
    flex-grow: 1;
    gap: var(--Size-Gap-M);
`;

const ListLayout = ({ children, $padding, ...props }) => (
    <Wrapper $padding={$padding} {...props}>
        {children}
    </Wrapper>
);

export default ListLayout;
