import List from "../../../common/components/List";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import LoaderIcon from "common/components/LoaderIcon";
import {
    Body_M_Reg,
    Body_XXS_Reg,
    Body_XS_Med,
    Body_XS_Reg,
    PageContent,
} from "common/global-styled-components";
import Button from "common/components/Button";
import NoDataFound from "common/components/NoDataFound";
import { Add, Magicpen, Flash } from "iconsax-react";
import Icon from "common/components/Icon";
import { getSystemSkills, getSystemSkillDetails } from "common/redux/actions/skillActions";
import SearchPlaceHolder from "common/components/SearchPlaceHolder";
import AdvancedPagination from "common/components/AdvancedPagination";
import SkillInstall from "../Install";
import { useDispatch } from "react-redux";
import { Breakpoints } from "GlobalStyle";
import IconLogo from "common/components/Logo/IconLogo";
import { useModal } from "../../../common/components/NewModal";
import { usePageTitle } from "common/utils";

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
`;

const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 216px);
`;

const DataNotFoundMessage = styled(Body_M_Reg)`
    max-width: 450px;
    color: var(--Color-Text-Default);
`;

const StatusWrapper = styled.div`
    background: ${(props) =>
        props.default
            ? "var(--Color-Text-Default)"
            : props.active
            ? "var(--Color-Text-Success)"
            : "var(--Color-Text-Danger)"};
    width: 7px;
    height: 7px;
    border-radius: 50%;
`;

const BodyUpperWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-right: var(--Size-Padding-XXXL);
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const DropdownSubtitle = styled(Body_XXS_Reg)`
    color: var(--Color-Text-Subtlest);
    @media (max-width: ${Breakpoints.mobile}px) {
        display: none;
    }
`;

const TypeWrapper = styled(Body_XS_Reg)`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 12px;
`;

const CopilotStateDropDownText = styled(Body_XS_Reg)`
    text-overflow: ellipsis;
    overflow: hidden;
`;

const SkillsWrapper = styled.div`
    display: grid;
    gap: var(--Size-Gap-XXL);
    max-height: calc(100vh - 216px);
    overflow-y: auto;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-content: center;
`;

const Skill = styled.div`
    display: flex;
    padding: var(--Size-Padding-XL);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-M);
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    height: 170px;
    overflow: hidden;
`;

const SkillHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`;

const SkillDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-M);
    align-self: stretch;
`;

const SkillDescription = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SkillName = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const SkillGroup = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-S) 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Subtle);
    color: var(--Color-Text-Default);
`;

const InstallButton = styled.div`
    display: flex;
    padding: var(--Size-Padding-M) 12px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Size-CornerRadius-L);
    border: 1px solid var(--Color-Border-Subtle);
    cursor: pointer;
`;

const PlusIconHeader = styled.div`
    background: var(--Color-Background-Subtle);
    border-radius: 14px;
    padding: var(--Size-Padding-M);
    padding-bottom: 5px;
    cursor: pointer;
`;

const CustomSkillWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;
const DefaultIcon = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
`;

const ComingSoonWrapper = styled.div`
    display: flex;
    padding: var(--Size-Padding-S) 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: var(--Color-Background-Subtle);
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
`;

const sillTypes = [
    {
        label: (
            <TypeWrapper>
                <StatusWrapper default={true} />
                <CopilotStateDropDownText>Public</CopilotStateDropDownText>
            </TypeWrapper>
        ),
        value: "all",
    },
    {
        label: (
            <TypeWrapper>
                <StatusWrapper active={true} />
                <CopilotStateDropDownText>Org</CopilotStateDropDownText>
            </TypeWrapper>
        ),
        value: "active",
    },
];

export default function SkillsListPage({ copilotId = "", callback = () => {} }) {
    const { orgId } = useParams();
    const history = useHistory();
    const isMounted = useRef(null);
    const [searchName, setSearchName] = useState("");
    const [skillsList, setSkillsList] = useState([]);
    const [isPageLoading, setPageLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [pageDetails, setPageDetails] = useState({});
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [skillDetails, setSkillDetails] = useState({});
    const [skillTypeState, setSkillTypeState] = useState(sillTypes[0]);
    usePageTitle("Copilot Platform - Skills");
    const debounceFetch = useMemo(() => debounce(fetchSystemSkills, 300), []);
    const dispatch = useDispatch();

    const setSkillsListData = (items, pageNum) => {
        setSkillsList(items);
    };

    function fetchSystemSkills(orgId, queryString) {
        getSystemSkills(orgId, queryString, dispatch)
            .then((res) => {
                setSkillsListData(res.items, res.page.page);
                setPageDetails(res.page);
            })
            .catch((err) => {
                setSkillsListData({});
                setPageDetails({});
            })
            .finally(() => {
                if (isMounted.current) {
                    setPageLoading(false);
                }
            });
    }

    function fetchSystemSkillDetails(systemSkillId) {
        getSystemSkillDetails(orgId, systemSkillId, dispatch)
            .then((res) => {
                setSkillDetails(res);
                showSkillInstallDialog();
            })
            .catch((err) => {
                console.error(err);
                setSkillDetails([]);
            })
            .finally(() => {
                if (isMounted.current) {
                    setPageLoading(false);
                }
            });
    }

    useEffect(() => {
        isMounted.current = true;

        if (currentPage.page) {
            setPageLoading(true);
            let queryString = `${getQueryParams()}&q=${searchName}`;
            queryString = getSkillsWithstates(queryString);
            debounceFetch(orgId, queryString);
        }
        return () => {
            // executed when unmount
            isMounted.current = false;
        };
    }, [currentPage, searchName, skillTypeState]);

    const {
        show: showSkillInstallDialog,
        hide: hideSkillInstallDialog,
        RenderModal: RenderSkillInstall,
    } = useModal();

    const getQueryParams = () => {
        const pageQuery = currentPage.page ? `page=${currentPage.page}` : "page=1";
        const pageSizeQuery = pageSize ? `limit=${pageSize}` : "limit=";
        return [pageQuery, pageSizeQuery].join("&");
    };

    const getSkillsWithstates = (queryString) => {
        let modifiedQueryString;
        if (copilotId) {
            modifiedQueryString = `${queryString}&copilotAppId=${copilotId}`;
            return modifiedQueryString;
        }
        switch (skillTypeState.value) {
            case "public":
                modifiedQueryString = `${queryString}&active=true`;
                break;
            case "org":
                modifiedQueryString = `${queryString}&active=false`;
                break;

            default:
                modifiedQueryString = queryString;
                break;
        }
        return modifiedQueryString;
    };

    const handleSearchChange = (e) => {
        setPageLoading(true);
        setCurrentPage({ page: 1 });
        setSearchName(e.target.value);
    };

    return (
        <>
            {!copilotId ? (
                <List.ComponentHeader
                    $padding="0px"
                    title="Skills & Integrations"
                    subtitle="Supercharge your Copilot by giving more skills to it"
                >
                    {/* <CustomLink openInNew={true} href={datasourceConstants.docs.introduction}>
                    <InfoIcon style={{ fill: "var(--light-300)" }} />
                </CustomLink> */}
                </List.ComponentHeader>
            ) : (
                ""
            )}
            <PageContent
                style={{ background: "var(--Color-BAackground-Default)", margin: 0, padding: 0 }}
            >
                <BodyUpperWrapper>
                    <TitleWrapper>
                        Explore Skills
                        <DropdownSubtitle>Explore all skill groups & skills</DropdownSubtitle>
                    </TitleWrapper>
                    <SearchPlaceHolder
                        searchName={searchName}
                        handleSearchChange={handleSearchChange}
                    />
                </BodyUpperWrapper>

                {isPageLoading ? (
                    <Loader>
                        <LoaderIcon />
                    </Loader>
                ) : skillsList.length > 0 ? (
                    <Wrapper>
                        <SkillsWrapper>
                            {!searchName.length && (
                                <Skill>
                                    <SkillHeader>
                                        <PlusIconHeader>
                                            {Icon(Add, {
                                                size: 35,
                                            })}
                                        </PlusIconHeader>
                                    </SkillHeader>
                                    <SkillDetails>
                                        <CustomSkillWrapper>
                                            <Body_XS_Med>Create Custom Skill </Body_XS_Med>
                                            <ComingSoonWrapper>
                                                {Icon(Magicpen, { size: 11 })}Coming Soon
                                            </ComingSoonWrapper>
                                        </CustomSkillWrapper>
                                        <SkillDescription>
                                            Dive into our powerful editor and start creating
                                        </SkillDescription>
                                    </SkillDetails>
                                </Skill>
                            )}
                            {skillsList.map((val) => (
                                <Skill key={val.id}>
                                    <SkillHeader>
                                        {val?.icon ? (
                                            <IconLogo src={val?.icon} width="48px" height="48px" />
                                        ) : (
                                            <DefaultIcon>{Icon(Flash, { size: 31 })}</DefaultIcon>
                                        )}
                                        <InstallButton
                                            onClick={() => fetchSystemSkillDetails(val.id)}
                                        >
                                            <Body_XS_Med>Install</Body_XS_Med>
                                        </InstallButton>
                                    </SkillHeader>
                                    <SkillDetails>
                                        <SkillName>
                                            <Body_XS_Med>{val?.name} </Body_XS_Med>
                                            {!val?.public && <SkillGroup>Private</SkillGroup>}
                                            {val?.skillCount > 1 && <SkillGroup>Group</SkillGroup>}
                                        </SkillName>
                                        <SkillDescription>{val?.description}</SkillDescription>
                                    </SkillDetails>
                                </Skill>
                            ))}
                        </SkillsWrapper>
                        <AdvancedPagination
                            page={pageDetails}
                            pageSize={pageSize}
                            paginationInfo="skill"
                            setPageSize={setPageSize}
                            setCurrentPage={setCurrentPage}
                        />
                    </Wrapper>
                ) : (
                    <NoDataFound>
                        {
                            <>
                                <DataNotFoundMessage>No Skills found.</DataNotFoundMessage>
                                {/* <NoSkillCreateButton
                                    mode="primary"
                                    size="small"
                                    onClick={() => showSkillInstallDialog()}
                                >
                                    {Icon(AddCircle, {
                                        size: 20,
                                        variant: "Bold",
                                    })}
                                    Create New
                                </NoSkillCreateButton> */}
                            </>
                        }
                    </NoDataFound>
                )}
            </PageContent>
            <RenderSkillInstall>
                <SkillInstall
                    copilotId={copilotId}
                    skillDetails={skillDetails}
                    hide={hideSkillInstallDialog}
                    callback={callback}
                />
            </RenderSkillInstall>
        </>
    );
}
