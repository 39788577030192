import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import NavTab from "./NavTab";
import { Breakpoints } from "GlobalStyle";

const StyledNavbar = styled.nav`
    // height: 100%;
    display: flex;
    // justify-content: space-between;
    border-bottom: ${(props) =>
        props.mode !== "secondary" && "1px solid var(--Color-Border-Subtle)"};
    border: ${(props) => props.mode === "secondary" && "1px solid var(--Color-Border-Subtle)"};

    align-items: center;
    gap: var(--Size-Gap-L);
    // margin-bottom: 20px;
    ::-webkit-scrollbar {
        height: 1px;
    }
    overflow: auto;
    flex-grow: ${(props) => props.mode === "secondary" && "1"};
    background: ${(props) => props.mode === "secondary" && "var(--Color-Background-Subtle)"};
    border-radius: ${(props) => props.mode === "secondary" && "var(--Size-CornerRadius-M)"};
    padding: ${(props) => props.mode === "secondary" && "var(--Size-Padding-S)"};
    display: ${(props) => (props.mode === "secondary" ? "grid" : "flex")};
    grid-template-columns: ${(props) => props.mode === "secondary" && "1fr 1fr 1fr"};
`;

export default function NavBar({
    TABS,
    callback,
    activeTabIdentifier,
    mode,
    border,
    route = true,
    allowWidlcard = false,
}) {
    const location = useLocation();
    const tabItems = TABS.map((el) => {
        return (
            <NavTab
                mode={mode}
                route={route}
                border={border}
                id={el?.displayName + "__Header"}
                key={el?.identifier}
                tab={el}
                isActive={
                    activeTabIdentifier
                        ? activeTabIdentifier === el.identifier
                        : location?.pathname?.indexOf?.(`/${el?.identifier}`) !== -1
                }
                callback={callback}
            />
        );
    });
    return <StyledNavbar mode={mode}>{tabItems}</StyledNavbar>;
}
