import React, { useCallback, useEffect, useState } from "react";
import { useRouteMatch, useHistory, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import CopilotService from "services/copilot.service";
import { Breakpoints } from "GlobalStyle";
import { toast } from "react-toastify";
import LoaderIcon from "common/components/LoaderIcon";
import { Body_XS_Reg, Body_S_Med, Body_XXS_Reg } from "common/global-styled-components";
import SlideToggle from "common/components/SlideToggle";
import Button from "common/components/Button";
import Website from "./Website";
import HostedWebsite from "./HostedWeb";
import { SubTitle1, SubTitle2 } from "common/global-styled-components";
import { useModal } from "common/components/NewModal";
import VsCodeSurface from "./VsCodeSurface";
import { usePageTitle } from "common/utils";
import FigmaSurface from "./FigmaSurface";
import WhatsAppSurface from "./WhatsAppSurface";
import CallAppSurface from "./CallAppSurface";
import Icon from "common/components/Icon";
import { SURFACES_ICON } from "../Icons";

const PlatformWrapper = styled.div`
    gap: var(--Size-Gap-XL);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const Loader = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
`;

const SurfacesWrapper = styled.div`
    display: grid;
    gap: var(--Size-Gap-XL);
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    justify-content: center;
    @media (max-width: ${Breakpoints.mobile}px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    @media (max-width: ${Breakpoints.mobileXS}px) {
        grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
    }
`;

const Surface = styled.div`
    display: flex;
    padding: var(--Size-Padding-XXL);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-XL);
    border-radius: var(--Size-CornerRadius-XL);
    background: var(--Color-Background-Default);
    border: 1px solid var(--Color-Border-Subtle);
    cursor: ${(props) => (props.isLive ? "pointer" : "default")};
`;

const SurfaceHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

const SurfaceDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-M);
    align-self: stretch;
`;
const CustomModalContent = css`
    width: 845px;
    height: 670px;
    max-height: unset;
    max-width: unset;
    @media (max-width: ${Breakpoints.mobile}px) {
        height: auto;
    }
`;
const SurfaceDescription = styled(Body_XS_Reg)`
    color: var(--Color-Text-Subtlest);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SurfaceName = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const ComingSoon = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-S) 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 28px;
    background: var(--Color-Background-Subtle);
`;

export const DeploymentSurfaces = {
    HOSTED_WEB: "hosted-web",
    WEB: "web",
    WHATSAPP: "whatsapp",
    CALL: "call",

    // TODO coming soon
    // SLACK: "slack",
    // MOBILE: "mobile",
};

function Surfaces({
    register,
    token,
    allValues,
    errors,
    setValue,
    setError,
    mode,
    playgroundRef,
    setShowCopilotPlaygroundWidget,
}) {
    const { orgId, agentId } = useParams();

    usePageTitle(`Copilot Platform - Deployments`);
    const {
        show: showDeployments,
        hide: hideDeployments,
        RenderModal: RenderDeployments,
    } = useModal();
    const [isPageLoading, setPageLoading] = useState(true);
    const [surfaces, setSurfaces] = useState([]);
    const [selectedSurface, setSelectedSurface] = useState("web");

    const handleSurfaceUpdate = (surfaceType) => (data) => {
        setSurfaces((prevState) =>
            prevState.map((item) => (item.type === surfaceType ? { ...item, ...data } : item)),
        );
    };

    useEffect(() => {
        setShowCopilotPlaygroundWidget?.(true);
        CopilotService.getCopilotSurfaces(orgId, agentId)
            .then((res) => {
                let surfaces = res?.data?.items;
                setSurfaces(surfaces);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Error loading deployments", {
                    autoClose: 2000,
                });
            })
            .finally(() => {
                setPageLoading(false);
            });
    }, []);

    const openDeploymentModals = (val) => {
        if (!val?.isLive) return;
        setSelectedSurface(val.type);
        showDeployments();
    };
    const openPreferedDeploymentModal = useCallback(
        (val) => {
            let preferedDeploymentModal;
            switch (val) {
                case DeploymentSurfaces.HOSTED_WEB:
                    preferedDeploymentModal = (
                        <HostedWebsite
                            type={DeploymentSurfaces.HOSTED_WEB}
                            oldAllValues={allValues}
                            oldSetValue={setValue}
                            modalCloseButtonCallback={hideDeployments}
                            handleSurfaceUpdate={handleSurfaceUpdate(DeploymentSurfaces.HOSTED_WEB)}
                        />
                    );
                    break;
                case DeploymentSurfaces.WEB:
                    preferedDeploymentModal = (
                        <Website
                            token={token}
                            playgroundRef={playgroundRef}
                            type={DeploymentSurfaces.WEB}
                            oldAllValues={allValues}
                            oldSetValue={setValue}
                            modalCloseButtonCallback={hideDeployments}
                            handleSurfaceUpdate={handleSurfaceUpdate(DeploymentSurfaces.WEB)}
                        />
                    );
                    break;
                case "vscode-extension":
                    preferedDeploymentModal = (
                        <VsCodeSurface
                            type={"/vscode-extension"}
                            oldAllValues={allValues}
                            oldSetValue={setValue}
                            modalCloseButtonCallback={hideDeployments}
                        />
                    );
                    break;
                case "figma-plugin":
                    preferedDeploymentModal = (
                        <FigmaSurface
                            type={"/figma-plugin"}
                            oldAllValues={allValues}
                            oldSetValue={setValue}
                        />
                    );
                    break;
                case "mobile":
                    preferedDeploymentModal = null;
                    break;
                case DeploymentSurfaces.WHATSAPP:
                    preferedDeploymentModal = (
                        <WhatsAppSurface
                            type={DeploymentSurfaces.WHATSAPP}
                            oldAllValues={allValues}
                            oldSetValue={setValue}
                            modalCloseButtonCallback={hideDeployments}
                            handleSurfaceUpdate={handleSurfaceUpdate(DeploymentSurfaces.WHATSAPP)}
                        />
                    );
                    break;
                case DeploymentSurfaces.CALL:
                    preferedDeploymentModal = (
                        <CallAppSurface
                            type={DeploymentSurfaces.CALL}
                            oldAllValues={allValues}
                            oldSetValue={setValue}
                            modalCloseButtonCallback={hideDeployments}
                            handleSurfaceUpdate={handleSurfaceUpdate(DeploymentSurfaces.CALL)}
                        />
                    );
                    break;
                case "slack":
                    preferedDeploymentModal = null;
                    break;
                default:
                    break;
            }
            return preferedDeploymentModal;
        },
        [token],
    );

    const changeSurfaceStatus = (val, value) => {
        CopilotService.updateCopilotSurface(orgId, agentId, val.type, {
            ...val,
            active: value,
        })
            .then((res) => {
                let updatedSurface = surfaces.map((surface) => {
                    if (surface.type == val.type) {
                        return {
                            ...surface, // spread out the current surface properties
                            active: res?.data?.active, // override the 'active' property
                        };
                    }
                    return surface;
                }); // if not the matched surface, return it unchanged
                setSurfaces(updatedSurface);
                toast.success("Deployment updated succesfully");
            })
            .catch((e) => {
                console.error(e);
                toast.error(e?.response?.data?.message || "Error Updating Status", {
                    autoClose: 2000,
                });
            });
    };
    const surfaceStatus = (val) => {
        if (!val.isLive) return <ComingSoon>Coming Soon</ComingSoon>;
        if (!val.config)
            return (
                <Button
                    type="button"
                    mode="primary"
                    size="small"
                    onClick={() => openDeploymentModals(val)}
                >
                    Setup Now
                </Button>
            );
        return (
            <SlideToggle
                checked={val.active}
                onChange={(value, event) => changeSurfaceStatus(val, value)}
            />
        );
    };
    return (
        <>
            <PlatformWrapper>
                <SubTitle2>Deployments</SubTitle2>

                {isPageLoading ? (
                    <Loader>
                        <LoaderIcon />
                    </Loader>
                ) : (
                    surfaces?.length && (
                        <SurfacesWrapper>
                            {surfaces.map((val) => (
                                <Surface
                                    key={val?.name}
                                    isLive={val?.isLive}
                                    onClick={(e) => openDeploymentModals(val)}
                                >
                                    {SURFACES_ICON[val.type] && (
                                        <SurfaceHeader>
                                            <div>
                                                {Icon(SURFACES_ICON[val.type], {
                                                    size: 48,
                                                    color: "var(--Color-Icon-Subtle)",
                                                })}
                                            </div>
                                            {/* Added to prevent bubbling of toggle */}
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                            >
                                                {surfaceStatus(val)}
                                            </div>
                                        </SurfaceHeader>
                                    )}{" "}
                                    <SurfaceDetails>
                                        <SurfaceName>
                                            <Body_S_Med>{val?.name}</Body_S_Med>
                                        </SurfaceName>
                                        <SurfaceDescription>{val?.description}</SurfaceDescription>
                                    </SurfaceDetails>
                                </Surface>
                            ))}
                        </SurfacesWrapper>
                    )
                )}
            </PlatformWrapper>
            <RenderDeployments CustomModalContent={CustomModalContent} showCancelButton={true}>
                {openPreferedDeploymentModal(selectedSurface)}
            </RenderDeployments>
        </>
    );
}

export default Surfaces;
