import React from "react";

function Play(props) {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.1666 5.40258C14.1666 6.55728 14.1666 9.44403 12.1666 10.5987L5.16662 14.6402C3.16662 15.7949 0.666625 14.3515 0.666625 12.0421L0.666626 3.9592C0.666626 1.64979 3.16663 0.206422 5.16663 1.36112L12.1666 5.40258Z"
                fill={props.color}
            />
        </svg>
    );
}

export default Play;
