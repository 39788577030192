import React from "react";
import styled from "styled-components";
import SlideToggle from "common/components/SlideToggle";
import { CallCalling, Microphone2 } from "iconsax-react";
import Icon from "common/components/Icon";
import { Body_2XS_Reg, Body_XS_Bold, Body_2XS_Med } from "common/global-styled-components";

import { Breakpoints } from "../../../GlobalStyle.js";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: var(--Size-Gap-XXL);
    justify-content: space-between;
    @media (max-width: ${Breakpoints.mobile}px) {
        flex-direction: column;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XL);
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-XL);
    padding: var(--Size-Padding-XL);
    flex: 1;
    @media (max-width: ${Breakpoints.mobile}px) {
        width: 100%;
    }
`;

const IconWrapper = styled.div`
    width: 44px;
    background: var(--Color-Background-Subtle);
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    border-radius: var(--Size-CornerRadius-XXXL);
    padding: var(--Size-Padding-M);
`;

const CallActivity = styled.div`
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-L);
`;

const ActvityChip = styled(Body_2XS_Med)`
    border-radius: var(--Size-CornerRadius-L);
    padding: var(--Size-Padding-M) var(--Size-Padding-L);
    gap: var(--Size-Gap-M);
    background: ${(props) =>
        props.active
            ? "var(--Color-Background-Accent-Success)"
            : "var(--Color-Background-Accent-Danger)"};
    color: ${(props) => (props.active ? "var(--Color-Text-Success)" : "var(--Color-Text-Danger)")};
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 70px;
    gap: var(--Size-Gap-S);
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SubText = styled(Body_2XS_Reg)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

function VoiceInfo({ nativeAudio, aiWebCall, setValue, allValues }) {
    return (
        <Wrapper>
            <ActionWrapper>
                <HeaderWrapper>
                    <IconWrapper>{Icon(Microphone2, { size: 24 })}</IconWrapper>
                    <CallActivity>
                        <ActvityChip active={nativeAudio}>
                            {nativeAudio ? "Active" : "Inactive"}
                        </ActvityChip>
                        <SlideToggle
                            checked={nativeAudio}
                            onChange={(e) => setValue("nativeAudio", e)}
                        />
                    </CallActivity>
                </HeaderWrapper>
                <TitleWrapper>
                    <Body_XS_Bold>Audio Transcription </Body_XS_Bold>
                    <SubText>
                        Enable Audio Transcription to empower users with the ability to effortlessly
                        convert voice input into text format.
                    </SubText>
                </TitleWrapper>
            </ActionWrapper>
            <ActionWrapper>
                <HeaderWrapper>
                    <IconWrapper>{Icon(CallCalling, { size: 24 })}</IconWrapper>
                    <CallActivity>
                        <ActvityChip active={aiWebCall}>
                            {aiWebCall ? "Active" : "Inactive"}
                        </ActvityChip>
                        <SlideToggle
                            checked={aiWebCall}
                            onChange={(e) => setValue("aiWebCall", e)}
                        />
                    </CallActivity>
                </HeaderWrapper>
                <TitleWrapper>
                    <Body_XS_Bold>AI Web Call </Body_XS_Bold>

                    <SubText>
                        Allow users to make voice calls directly from the web widget with the Web
                        Call feature. Configure from voice settings in the settings tab.
                    </SubText>
                </TitleWrapper>
            </ActionWrapper>
        </Wrapper>
    );
}
export default VoiceInfo;
