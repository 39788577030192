export const MenuAction = {
    SetShowTopNavBar: "SET_SHOW_TOP_NAVBAR",
    UpdateScrollTopVisible: "UPDATE_SCROLL_TOP_VISIBLE",
};

export const setShowNavbar = (showTopNavbar, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: MenuAction.SetShowTopNavBar,
            payload: { showTopNavbar },
        });
        resolve();
    });
};

export const updateScrollTopVisible = (data, dispatch) => {
    dispatch({
        type: MenuAction.UpdateScrollTopVisible,
        payload: data,
    });
};
