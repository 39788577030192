import { countries } from "country-data";
import stateCountry from "state-country";

export const COUNTRIES = countries.all
    .filter((country) => country.status === "assigned")
    .map((country) => ({
        label: country.name,
        identifier: country.alpha2,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const COUNTRIES_V2 = countries.all
    .filter((country) => country.status === "assigned")
    .map((country) => ({
        label: country.name,
        value: { code: country.alpha2, name: country.name },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getStatesInCountry = (country) => {
    return stateCountry.getAllStatesInCountry(country).map((c) => {
        return {
            label: c.name,
            value: c.name,
        };
    });
};

export const MAX_UPLOAD_LIMIT = 21000;
export const DEFAULT_ICON =
    "https://cdn.copilot.live/v2/original/web/icons/dark_theme/dark_bg_logo.png";

export const FREE_PLANS = ["FREE_PLAN", "WELCOME_PLAN"];
