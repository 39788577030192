import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const SubscriptionService = {
    getPlans(orgId) {
        const axiosOptions = {};
        return ApiService.get(URLS.plans.replace(":orgId", orgId), axiosOptions);
    },
    getStripePortal(orgId) {
        const axiosOptions = {};
        return ApiService.get(URLS.stripePortalUrl.replace(":orgId", orgId), axiosOptions);
    },
    getSubscriptionDetail(orgId) {
        const axiosOptions = {};
        return ApiService.get(URLS.subscriptions.replace(":orgId", orgId), axiosOptions);
    },
    cancelSubscriptionDetail(orgId) {
        const axiosOptions = {};
        return ApiService.delete(`${URLS.subscriptions}?confirmed=true`.replace(":orgId", orgId));
    },
    getSubscriptionUsageDetail(orgId) {
        const axiosOptions = {};
        return ApiService.get(URLS.subscriptionsUsage.replace(":orgId", orgId), axiosOptions);
    },
    createSubscription(orgId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(URLS.subscriptions.replace(":orgId", orgId), axiosOptions);
    },
};

export default SubscriptionService;
