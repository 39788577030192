import Icon from "common/components/Icon";
import RestAPI from "../../common/assets/RestAPI";
import { Global, DocumentText1, GoogleDrive, Smallcaps } from "iconsax-react";
import QuipIcon from "common/assets/Quip";
import GitHubIcon from "common/assets/GithubIcon";
import NotionIcon from "common/assets/NotionIcon";
export const SOURCE_TYPES = [
    {
        icon: Icon(Global, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Websites",
        value: "web",
    },
    {
        icon: Icon(DocumentText1, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Files",
        value: "files",
    },
    {
        icon: Icon(QuipIcon, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Quip",
        value: "quip",
    },
    {
        icon: Icon(RestAPI, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Rest API",
        value: "api",
    },
    {
        icon: Icon(GoogleDrive, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Google Drive",
        value: "googleDrive",
    },
    {
        icon: Icon(GitHubIcon, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Git",
        value: "git",
    },
    {
        icon: Icon(Smallcaps, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Text",
        value: "text",
    },
    {
        icon: Icon(NotionIcon, { size: 21.5, color: "var(--Color-Icon-Subtlest)" }),
        label: "Notion",
        value: "notion",
    },
];
