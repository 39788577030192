import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
    position: relative;
    width: 100%;
    flex-grow: 1;
    svg {
        position: absolute;
        right: 15px;
        top: 18px;
        color: gray;
    }
`;

const StyledTextArea = styled.textarea`
    width: 100%;
    max-width: 100%;
    resize: vertical;
    padding: var(--Size-Padding-L);
    border: 1px solid var(--Color-Border-Subtle);
    background: transparent;
    border-radius: var(--Size-CornerRadius-L);
    font-size: var(--body-2-d);
    line-height: var(--line-height-21);
    letter-spacing: var(--letter-spacing);
    color: ${(props) =>
        props.disabled ? "var(--Color-Text-Subtlest)" : "var(--Color-Text-Default)"};
    min-height: ${(props) => (props.height ? props.height : "100%")};

    ::placeholder {
        color: var(--Color-Text-Disabled);
        font-size: var(--body-2-d);
        font-weight: 400;
        line-height: var(--line-height-21);
    }

    :focus,
    :active {
        &:not(:disabled) {
            border: 1px solid var(--Color-Border-Subtle);
            outline: 0;
        }
    }

    @media (max-width: 768px) {
        font-size: var(--body-2-m);
    }
`;

export default function TextArea({
    children,
    label,
    placeholder,
    register,
    validation,
    ...restProps
}) {
    return (
        <InputWrapper>
            <StyledTextArea
                data-testid="inputField"
                {...(register && register(label, validation))}
                {...restProps}
                placeholder={placeholder ? placeholder : ""}
            ></StyledTextArea>
            {children}
        </InputWrapper>
    );
}
