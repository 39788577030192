import KeyService from "../../../services/key.service";

export const keyActions = {
    GetKeys: "_GET_KEYS",
    GetKey: "_GET_KEY",
    GetKeyModels: "_GET_KEY_MODELS",
    CreateKey: "_CREATE_KEY",
    ValidateKey: "_VALIDATE_KEY",
    UpdateKey: "_UPDATE_KEY",
    SetDefaults: "_SET_DEFAULTS",
    DeleteKey: "_DELETE_KEY",
    GetKeysError: "_GET_KEY_ERROR",
};

export const getKeys = (orgId, queryString, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.getKeys(orgId, queryString)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: keyActions.GetKeys,
                    payload: { items: payload.items, page: payload.page },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const createKey = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.createKey(orgId, data)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: keyActions.CreateKey,
                    payload: { key: payload },
                });
                resolve({ key: payload });
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const validateKey = (orgId, data, type = "completion", dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.validateKey(orgId, data, type)
            .then((res) => {
                const { data: payload } = res;
                dispatch({
                    type: keyActions.ValidateKey,
                    payload: { models: res.data },
                });
                resolve({ models: payload });
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const updateKey = (orgId, keyId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.updateKey(orgId, keyId, data)
            .then((res) => {
                let { data: payload } = res;
                payload = { ...data, organizationId: orgId, id: keyId };
                dispatch({
                    type: keyActions.UpdateKey,
                    payload: { key: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const updateDefaults = (orgId, data, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.updateDefaults(orgId, data)
            .then((res) => {
                let { data: payload } = res;
                payload = { defaults: data };
                dispatch({
                    type: keyActions.SetDefaults,
                    payload,
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const deleteKey = (orgId, keyId, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.deleteKey(orgId, keyId)
            .then((res) => {
                let { data: payload } = res;
                payload = { organizationId: orgId, id: keyId };
                dispatch({
                    type: keyActions.DeleteKey,
                    payload: { key: payload },
                });
                resolve(payload);
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const getKey = (orgId, keyId, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.getKey(orgId, keyId)
            .then((res) => {
                let { data: payload } = res;
                dispatch({
                    type: keyActions.GetKey,
                    payload: { key: payload },
                });
                resolve({ key: payload });
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
export const getModels = (orgId, keyId, dispatch) => {
    return new Promise((resolve, reject) => {
        KeyService.getModels(orgId, keyId)
            .then((res) => {
                const { data: payload } = res;
                const chat = payload.filter((i) => i.type === "completion");
                const code = payload.filter((i) => i.type === "completion");
                const embedding = payload.filter((i) => i.type === "embedding");
                resolve({ models: { chat, embedding, code } });
            })
            .catch((err) => {
                console.error(err);
                dispatch({
                    type: keyActions.GetKeysError,
                });
                reject(err);
            });
    });
};
