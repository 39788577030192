import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Overview from "../Overview";
import Surfaces from "../Surfaces";
import DatasourceListPage from "../Datasources/EditCopilotPage";
import Settings from "../Settings";
import AppAccessManagement from "../AppAccessManagement";
import Skills from "../Skills";
import Workflows from "../Workflows";
import { useSelector } from "react-redux";
import { PageContent } from "common/global-styled-components";
import styled from "styled-components";
import { Breakpoints } from "GlobalStyle";
import Inbox from "../Inbox";
import Integrations from "../Integrations";

const StyledPageContent = styled(PageContent)`
    margin: 0px 10px 10px 0px;
    flex: unset;
    width: ${(props) => !props?.$fullWidth && "calc(100% - 391px)"};
    flex-grow: 1;
    overflow-y: scroll;
    @media (max-width: ${Breakpoints.tablet}px) {
        width: calc(100% - 20px);
        margin: 0px 10px 10px 10px;
    }
    @media (max-width: ${Breakpoints.mobile}px) {
        margin: 0px 10px 10px 10px;
    }
`;

export default function CopilotEditPage({
    token,
    register,
    control,
    allValues,
    errors,
    agentId,
    setValue,
    setError,
    clearErrors,
    onSubmit,
    setShowCopilotPlaygroundWidget,
    playgroundRef,
    showCopilotPlaygroundWidget,
    initialFormValues,
    reset,
    trigger,
    setIsChanged,
    isChanged,
}) {
    const organizationId = useSelector(
        (state) => state.organizationDetails.organization?._id || -1,
    );
    const { path } = useRouteMatch();
    return (
        <StyledPageContent $fullWidth={!showCopilotPlaygroundWidget}>
            <Switch>
                <Route path={`${path}/deploy`}>
                    <Surfaces
                        mode="edit"
                        token={token}
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        setValue={setValue}
                        setError={setError}
                        playgroundRef={playgroundRef}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>

                <Route path={`${path}/datasources`}>
                    <DatasourceListPage
                        mode="edit"
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        setValue={setValue}
                        setError={setError}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>
                <Route path={`${path}/linkedSkills`}>
                    <Skills
                        mode="edit"
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        setValue={setValue}
                        setError={setError}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>
                {/* <Route path={`${path}/workflows`}>
                    <Workflows
                        mode="edit"
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        setValue={setValue}
                        setError={setError}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route> */}
                <Route path={`${path}/settings`}>
                    <Settings
                        trigger={trigger}
                        reset={reset}
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        onSubmit={onSubmit}
                        initialFormValues={initialFormValues}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                        setIsChanged={setIsChanged}
                        isChanged={isChanged}
                    />
                </Route>
                <Route path={`${path}/users`}>
                    <AppAccessManagement
                        mode="edit"
                        register={register}
                        control={control}
                        allValues={allValues}
                        errors={errors}
                        setValue={setValue}
                        onSubmit={onSubmit}
                        setError={setError}
                    />
                </Route>
                <Route path={`${path}/overview`}>
                    <Overview
                        allValues={allValues}
                        setValue={setValue}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>
                <Route path={`${path}/inbox`}>
                    <Inbox
                        allValues={{ ...allValues, token: token }}
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>
                <Route path={`${path}/integrations`}>
                    <Integrations setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget} />
                </Route>
                <Route path={`${path}`}>
                    <Redirect to={`/org/${organizationId}/cp/${agentId}/overview`} />
                </Route>
                <Route path="*">
                    <Redirect to={`/org/${organizationId}`} />
                </Route>
            </Switch>
        </StyledPageContent>
    );
}
