import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";
import CustomDropdown from "common/components/CustomDropdown";
import { Body3, SubTitle1, InputLabel, Body_XS_Reg } from "common/global-styled-components";
import TextArea from "common/components/TextArea";
import psl from "psl";
import List from "common/components/List";
import TextInput from "common/components/TextInput";
import { usePageTitle } from "common/utils";

const CheckboxInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
        border: 0;
    }
    gap: var(--Size-Gap-L);
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    input {
        border: 0;
    }
`;

const Label = styled(Body_XS_Reg)`
    color: var(--Color-Text-Default);
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Size-Gap-XXL);
    flex: 1 0 0;
`;

const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--Size-Padding-XXL);
    margin: 2px 20px;
    justify-content: space-between;
    gap: var(--Size-Gap-XXL);
    align-self: stretch;
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
`;

const StyledErrorSkeleton = styled(Body3)`
    height: var(--body-2-d);
`;

const StyledError = styled.div`
    color: var(--Color-Text-Danger);
`;

const AgentNameWrapper = styled.div`
    width: 100%;
    padding: 1px 20px;
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 20px;
    align-self: stretch;
`;

function CopilotAppAccess({ mode = "create", register, allValues, errors, setValue, setError }) {
    const handleDomainChange = (e) => {
        if (e.target.value.trim() === "") {
            setError("allowedEmailDomains", { type: "custom", message: `Domain Name is required` });
        }
        const domains = e.target.value.split("\n");
        let error = [];

        domains.map((val) => {
            if (!psl.isValid(val)) {
                error.push(val);
            }
        });
        if (error.length === 0) {
            setValue("public", false);
        } else {
            setError("allowedEmailDomains", {
                type: "custom",
                message: `Invalid domain(s): ${error.flat()}`,
            });
        }
    };
    usePageTitle(`Copilot Platform - Copilot Users`);
    return (
        <Wrapper>
            <Title>
                <List.ComponentHeader
                    title="Team Management"
                    subtitle="Manage User Permissions and Access"
                />
            </Title>

            <BodyWrapper>
                <CheckboxInputWrapper>
                    <TextInput
                        data-testid="edit-access-type"
                        width="20px"
                        height="60px"
                        name="public"
                        role="public"
                        label="public"
                        type="checkbox"
                        register={register}
                        placeholder="Access"
                        // onChange={handleAccessChange}
                    />
                    <Label>Make this app public</Label>
                </CheckboxInputWrapper>
                {allValues.public === false && (
                    <AgentNameWrapper>
                        <InputWrapper>
                            <InputLabel>Allowed Email Domains*</InputLabel>
                            <TextArea
                                data-testid="domain"
                                role="allowedEmailDomains"
                                type="allowedEmailDomains"
                                label="allowedEmailDomains"
                                rows="3"
                                onBlur={handleDomainChange}
                                register={register}
                                style={{ resize: "vertical" }}
                                validation={{ required: true }}
                                placeholder="platform.fynd.com&#10;pixelbin.io"
                            />
                            <StyledErrorSkeleton>
                                {errors.allowedEmailDomains &&
                                    errors.allowedEmailDomains.type === "required" && (
                                        <StyledError role="error">
                                            Domain Name is required
                                        </StyledError>
                                    )}
                                {errors.allowedEmailDomains &&
                                    errors.allowedEmailDomains.type === "custom" && (
                                        <StyledError role="error">
                                            {errors.allowedEmailDomains.message}
                                        </StyledError>
                                    )}
                            </StyledErrorSkeleton>
                        </InputWrapper>
                    </AgentNameWrapper>
                )}
            </BodyWrapper>
        </Wrapper>
    );
}

export default CopilotAppAccess;
