import { css } from "styled-components";

export const disabledStyles = (disabled) => css`
    opacity: ${disabled ? 0.5 : 1};
    pointer-events: ${disabled && "none"};
    /* transition: opacity 0.2s ease-out; */
`;

export const activeStyles = (selected) => css`
    opacity: ${selected ? 0.5 : 1};
    pointer-events: ${selected && "none"};

    background: "linear-gradient(120.55deg, #FF4040 18.56%, #A20BD8 98.01%)";
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transition: opacity 0.2s ease-out; */
`;
