import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Icon from "./Icon";
import CrossIcon from "../assets/CrossIcon";
import TextInput from "./TextInput";
import { StyledErrorSkeleton, StyledError } from "../../pages/CopilotApp/Settings/index";

const ChipInputWrapper = styled.div`
    background: var(--Color-Background-Subtle);
    padding: var(--Size-Padding-L);
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: var(--Size-CornerRadius-L);
    border-radius: var(--Size-CornerRadius-L);
    border: 1px solid var(--Color-Border-Subtle);
    background-color: var(--Color-Background-Default);
`;

const ChipContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Chip = styled.div`
    display: flex;
    gap: var(--Size-Gap-S);
    margin: 4px;
    height: fit-content;
    color: var(--Color-Text-Default);
    padding: var(--Size-Padding-S) var(--Size-Padding-M);
    border-radius: var(--Size-CornerRadius-M);
    border: 1px solid var(--Color-Border-Subtle);
    align-items: center;
    background: var(--Color-Background-Subtle);
`;

const Wrapper = styled.form`
    width: 100%;
`;

const _TextInput = styled(TextInput)`
    background: transparent;
    border: 0px;
    width: 100%;
    padding: 1px;
`;

const ChipInput = ({ cb, chipValues, maxChip = 10 }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        mode: "all",
    });
    const chips = watch("chips", chipValues || []);

    const addChip = (data) => {
        if (data.newChip.trim()) {
            let newData = [...chips, data.newChip.trim()];
            setValue("chips", newData);
            setValue("newChip", "");
            cb(newData);
        }
    };

    const removeChip = (indexToRemove) => {
        let newFilteredData = chips.filter((_, index) => index !== indexToRemove);
        setValue("chips", newFilteredData);
        cb(newFilteredData);
    };

    const handleBlur = (e) => {
        const value = e.target.value.trim();
        if (value && (!maxChip || chips.length < maxChip) && Object.keys(errors).length === 0) {
            addChip({ newChip: value });
        }
    };

    return (
        <Wrapper onSubmit={handleSubmit(addChip)}>
            <ChipInputWrapper>
                <ChipContainer>
                    {chips.map((chip, index) => (
                        <Chip key={index}>
                            {chip}
                            {Icon(CrossIcon, {
                                width: "10px",
                                height: "10px",
                                onClick: () => removeChip(index),
                                style: {
                                    cursor: "pointer",
                                },
                            })}
                        </Chip>
                    ))}
                    {(!maxChip || chips.length < maxChip) && (
                        <_TextInput
                            label={"newChip"}
                            autoComplete="off"
                            register={register}
                            name={"newChip"}
                            placeholder="Type here..."
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleSubmit(addChip)();
                                }
                            }}
                            onBlur={handleBlur}
                            disabled={maxChip && chips.length >= maxChip}
                            validation={{
                                maxLength: {
                                    value: 50,
                                    message: "Content length must be 50 characters or less",
                                },
                            }}
                        />
                    )}
                    {errors?.newChip && (
                        <StyledErrorSkeleton>
                            <StyledError role="error">{errors?.newChip?.message}</StyledError>
                        </StyledErrorSkeleton>
                    )}
                </ChipContainer>
            </ChipInputWrapper>
        </Wrapper>
    );
};

export default ChipInput;
