import React from "react";

const Icon = (
    Component,
    { size = 32, color = "var(--Color-Icon-Default)", variant = "Linear", ...props },
) => {
    return <Component color={color} variant={variant} size={size} {...props} />;
};

export default Icon;
