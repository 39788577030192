import environment from "../../environment";
import store from "../redux/store";

export const EventEmitter = {
    events: {},
    dispatch(event, data) {
        const state = store.getState();
        if (!this.events[event]) return;
        this.events[event].forEach((callback) => {
            let newData = {
                ...data,
                authenticationDetails: store?.authenticationDetails,
                organizationDetails: store?.organizationDetails,
                teamDetails: store?.teamDetails,
            };
            if (environment.GTM_CONTAINER_ID) callback(event, newData);
        });
    },
    on(event, callback) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(callback);
    },
};

if (typeof window !== "undefined") window.EventEmitter = EventEmitter;
