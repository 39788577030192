import React from "react";
import styled from "styled-components";
import validator from "validator";
import { Controller } from "react-hook-form";
import InfoIcon from "common/assets/InfoIcon";
import IconLogo from "common/components/Logo/IconLogo";
import PasswordInput from "common/components/PasswordInput";
import TextArea from "common/components/TextArea";
import SlideToggle from "common/components/SlideToggle";
import TextInput from "common/components/TextInput";
import Tooltip from "common/components/Tooltip";
import CustomDropdown from "common/components/CustomDropdown";
import ArrayForm from "common/components/ArrayForm";
import AddKeyValuePair from "common/components/AddKeyValuePair";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import SuccessIcon from "../assets/SuccessIcon";
import ErrorIcon from "../assets/ErrorIcon";
import Icon from "./Icon";

const Label = styled.label`
    margin-bottom: 8px;
    font-size: var(--body-3-d);
    font-weight: 400;
    color: var(--Color-Text-Subtle);
    display: flex;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    float: ${(props) => props.type === "file" && "left"};
    // margin-right: ${(props) => props.type === "file" && "var(--line-height-22)"};
    flex-grow: 1;
    // margin-bottom: ${(props) => props.type !== "file" && "var(--line-height-32)"};
    position: relative;
`;

const StyledError = styled.div`
    // position: absolute;
    top: 100%;
    margin-top: 4px;
    font-size: var(--body-4-m);
    color: var(--Color-Text-Danger);
    display: flex;
    align-items: center;
    gap: var(--Size-Gap-M);
`;

const ImageLabel = styled.label`
    width: 150px;
    height: 150px;
    border: 1px solid var(--Color-Border-Subtle);
    border-radius: var(--Size-CornerRadius-S);
    &.img {
        width: 100px;
        height: 100px;
    }
`;

const Description = styled(InfoIcon)`
    margin-left: 4px;
    width: var(--body-1-d);
    fill: var(--light-300);
    cursor: help;
    height: auto;
`;

const HrefButton = styled.div`
    position: absolute;
    right: 0;
    text-decoration: underline;
    cursor: pointer;
`;

const HrefSelectDropdownButton = styled.div`
    text-decoration: underline;
    cursor: pointer;
`;

export default function FormFields({
    item,
    handleSubmit,
    register,
    allValues,
    setValue,
    mode = "",
    errors,
    reset,
    control,
    renderOptions,
    onHrefClick,
    keyValueRef,
    noOptionSelectTextCallback,
    syncData,
    hasLabelMapped = false,
}) {
    const history = useHistory();
    const isURL = (url) => {
        return validator.isURL(url, { require_protocol: true, protocols: ["http", "https"] });
    };

    const resetToggle = (label) => {
        let newValues = { ...allValues.credentials };
        newValues[label] = !newValues[label];

        reset({
            ...allValues,
            credentials: { ...newValues },
        });
    };

    const prepareDefaultValue = (item) => {
        if (!!allValues["credentials"][`${item.id}`]) {
            return allValues["credentials"][`${item.id}`];
        }
        if (item.defaultValue) {
            return item.defaultValue;
        }

        return "";
    };

    const callBackAfterLoadingOptions = (options, id) => {
        if (item.prePopulate && !allValues?.credentials?.[item.id] && mode !== "edit") {
            setValue(
                `credentials.${item.id}`,
                options && (item?.prePopulatedValue ?? options?.[0]?.value),
            );
        }

        return options;
    };
    const loadOptions = React.useCallback(
        _.debounce((inputText, callback) => {
            getAsyncOptions(inputText)
                .then((options, id) => callback(callBackAfterLoadingOptions(options, id)))
                .catch((e) => {
                    console.log(e);
                });
        }, 500),
        [allValues],
    );

    const getAsyncOptions = (inputText) => {
        return new Promise((resolve, reject) => {
            setTimeout(async () => {
                // call api if data is not present or api cache flag in not set
                let response =
                    item.data && item.apiCache
                        ? item.data
                        : await renderOptions[item?.dropDownId](item.id);

                // if error from api response throw Error
                if (response instanceof Error) {
                    item.data = [];
                    throw new Error(response);
                }
                // used to filter data based on api response
                if (item.hasFilterData) {
                    item.data = item.filterData[response];
                    response = item.data;
                } else {
                    item.data = response;
                }
                // search based on input text from item.data
                if (inputText) {
                    response = item.data.filter((val) =>
                        val.label.toLowerCase().includes(inputText?.toLowerCase()),
                    );
                }
                resolve(response, item.id);
            }, 1000);
        });
    };
    const noOptionValue = (e, attribute = false) => {
        return attribute ? (
            <HrefSelectDropdownButton onClick={noOptionSelectTextCallback}>
                {item.noOptionSelectText}
            </HrefSelectDropdownButton>
        ) : (
            "No options"
        );
    };

    const getDropDownValue = (item) => {
        let newValue = allValues["credentials"][`${item.id}`];
        if (!newValue) return;
        if (item.selectType === "multiple") {
            let state = newValue.map((e) => {
                const selected = item?.data?.filter((option) => option.value === e);
                return { label: selected?.[0]?.label || e, value: e };
            });
            return state;
        }

        const selected = item?.data?.filter((option) => option.value === newValue);
        return {
            label: selected?.[0]?.label ?? newValue?.label ?? newValue,
            value: newValue.value ?? newValue,
        };
    };

    const setDropDownOpen = (e, id, mode) => {
        let newValues = { ...allValues.credentials };
        newValues[id] = mode === "multiple" ? e.map(({ value }) => value) : e.value;

        if (item.editOtherField) {
            newValues["name"] = e.label.split("/").pop(); // make it dynamic for other fields
        }
        reset({
            ...allValues,
            credentials: { ...newValues },
        });
    };

    return (
        <InputWrapper type={item.type}>
            <Label>
                {item.displayName}
                {item?.validations?.required ? " *" : null}
                <Description
                    role="tooltip"
                    onClick={(e) => e.stopPropagation()}
                    disabled={true}
                    data-tooltip-id={item.id}
                    data-tip
                />
                <Tooltip id={item.id} noArrow={true}>
                    {item.description}
                </Tooltip>
                {item?.href && <HrefButton onClick={onHrefClick}>{item?.href}</HrefButton>}
            </Label>
            {item.type === "file" ? (
                <ImageLabel htmlFor="inputFile">
                    <IconLogo
                        height="150px"
                        width="150px"
                        src={
                            // allValues?.credentials?.file
                            allValues?.credentials?.file?.length === 1
                                ? URL.createObjectURL(allValues?.credentials?.file[0])
                                : typeof allValues?.credentials?.file === "string"
                                ? allValues?.credentials?.file
                                : ""
                        }
                    />
                </ImageLabel>
            ) : null}
            {item.type === "url" || item.type === "text" || item.type === "number" ? (
                <TextInput
                    label={`credentials.${item.id}`}
                    register={register}
                    type={item.type}
                    validation={{
                        ...item.validations,
                        pattern: {
                            value: item?.validations?.pattern
                                ? new RegExp(item.validations.pattern.value)
                                : null,
                            message: item?.validations?.pattern
                                ? item.validations.pattern.message
                                : null,
                        },
                        validate: item.type === "url" ? isURL : null,
                    }}
                    placeholder={`Enter ${item.displayName.toLowerCase()}`}
                    defaultValue={prepareDefaultValue(item)}
                    maxLength={item.validations.maxLength || Infinity}
                    disabled={item.disabled}
                />
            ) : null}
            {item.type === "password" ? (
                <PasswordInput
                    label={`credentials.${item.id}`}
                    register={register}
                    validation={{
                        ...item.validations,
                        validate: null,
                    }}
                    placeholder={`Enter ${item.displayName.toLowerCase()}`}
                    defaultValue={allValues[`credentials.${item.id}`]}
                    maxLength={item.validations.maxLength || Infinity}
                    disabled={item.disabled}
                />
            ) : null}
            {item.type === "textarea" ? (
                <TextArea
                    label={`credentials.${item.id}`}
                    register={register}
                    validation={{
                        ...item.validations,
                        validate: item.accept === "json" ? isJSON : null,
                    }}
                    type="textarea"
                    placeholder={item.placeholder}
                    defaultValue={allValues[`credentials.${item.id}`]}
                    style={{ resize: "vertical" }}
                    rows="5"
                    disabled={item.disabled}
                />
            ) : null}
            {item.type === "multi-text" ? (
                // Change this once multitext component is created
                // <MultiText />
                <TextInput
                    label={`credentials.${item.id}`}
                    register={register}
                    type={item.type}
                    validation={{
                        ...item.validations,
                    }}
                    placeholder={`Enter ${item.displayName.toLowerCase()}`}
                    defaultValue={allValues[`credentials.${item.id}`] || item.defaultValue}
                    maxLength={item.validations.maxLength || Infinity}
                    disabled={item.disabled}
                />
            ) : null}
            {item.type === "key-value" ? (
                <AddKeyValuePair
                    pair={
                        keyValueRef?.current.id ? keyValueRef?.current?.value : keyValueRef?.current
                    }
                    validation={item.validations}
                    label={item.label}
                    syncData={syncData}
                    hasLabelMapped={hasLabelMapped}
                    keyValueRef={keyValueRef}
                    renderOptions={renderOptions}
                    allValues={allValues}
                />
            ) : null}
            {item.type === "array-form" ? (
                <ArrayForm
                    form={item}
                    register={register}
                    allValues={allValues}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    reset={reset}
                    control={control}
                />
            ) : null}
            {item.type === "dropdown" ? (
                <Controller
                    name={`credentials.${item.id}`}
                    control={control}
                    defaultValue={item?.defaultValue?.value ?? ""}
                    rules={item.validations}
                    render={({ field }) => (
                        <CustomDropdown
                            {...field}
                            ref={null}
                            showArrow={false}
                            // key={_.get(allValues, item?.["dependentFieldId"])}
                            wrapperHeight="56px"
                            async={true}
                            register={register}
                            noOptionsMessage={(e) => noOptionValue(e, item?.noOptionSelectText)}
                            placeholder={item.placeholder}
                            loadOptions={loadOptions}
                            defaultValue={item?.defaultValue?.value ?? getDropDownValue(item)}
                            value={getDropDownValue(item)}
                            disabled={item.disabled}
                            isMulti={item.selectType === "multiple"}
                            onChange={(e) => setDropDownOpen(e, item.id, item.selectType)}
                            // styles={{
                            //     // multiValue: (base) => ({
                            //     //     ...base,
                            //     //     // backgroundColor: "var(--dark-600)",
                            //     //     borderRadius: "var(--Size-CornerRadius-S)",
                            //     //     height: "24px",
                            //     // }),
                            //     // multiValueLabel: (base) => ({
                            //     //     ...base,
                            //     //     color: "var(--Color-Text-Default)",
                            //     //     fontSize: "var(--body-3-d)",
                            //     //     display: "flex",
                            //     //     alignItems: "center",
                            //     // }),
                            // }}
                        />
                    )}
                />
            ) : null}
            {item.type === "toggle" ? (
                <Controller
                    name={`credentials.${item.id}`}
                    control={control}
                    defaultValue={allValues["credentials"][`${item.id}`] ?? true}
                    render={({ field }) => (
                        <SlideToggle
                            {...field}
                            label={`credentials.${item.id}`}
                            placeholder={item.placeholder}
                            checked={allValues["credentials"][`${item.id}`] ?? true}
                            onChange={() => resetToggle(item.id)}
                        />
                    )}
                />
            ) : null}
            {item.type === "file" ? (
                <TextInput
                    id="inputFile"
                    label={`credentials.${item.id}`}
                    register={register}
                    type={item.type}
                    accept="image/*"
                    validation={{
                        ...item.validations,
                        validate: getFileType,
                    }}
                    style={{ display: "none" }}
                    placeholder={`Enter ${item.displayName.toLowerCase()}`}
                    defaultValue={allValues[`credentials.${item.id}`]}
                    maxLength={item.validations.maxLength || Infinity}
                    disabled={item.disabled}
                />
            ) : null}
            {getErrorText(errors, item) ? (
                <StyledError>
                    {Icon(ErrorIcon, {
                        size: 16,
                        color: "var(--Color-Icon-Danger)",
                        width: "16px",
                        height: "16px",
                    })}
                    {getErrorText(errors, item)}
                </StyledError>
            ) : null}
        </InputWrapper>
    );
}

function getFileType(item) {
    return item && item[0]["type"].split("/")[0] === "image";
}

function getErrorText(errors, item) {
    const { type: itemType, displayName, id, validations } = item;
    const { type: errorType, message } = errors.credentials?.[id] || {};
    if (errorType === "required") {
        return `${displayName} is required`;
    }

    if (errorType === "maxLength") {
        return `${displayName} cannot be more than ${validations.maxLength} characters long`;
    }

    if (errorType === "validate") {
        if (itemType === "textarea") {
            if (item.accept === "json") {
                return `${displayName} is not a valid JSON object`;
            }
        }

        if (itemType === "file") {
            return `Only Image files are supported`;
        }

        if (itemType === "url") {
            return `${displayName} is not valid. hint: https://www.domain.com/uri`;
        }
    }

    if (message) return `${message}`;
}

function isJSON(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}
