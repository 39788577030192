import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import IntegrationDetails from "./IntegrationDetails";
import ListIntegrations from "./ListIntegrations";

export default function Integrations({ setShowCopilotPlaygroundWidget }) {
    const { path } = useRouteMatch();
    const { orgId, agentId } = useParams();

    return (
        <>
            <Switch>
                <Route path={`${path}/create`}>
                    <IntegrationDetails
                        mode="create"
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>
                <Route path={`${path}/:integrationId`}>
                    <IntegrationDetails
                        mode="edit"
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                    />
                </Route>
                <Route path={`${path}`}>
                    <ListIntegrations
                        setShowCopilotPlaygroundWidget={setShowCopilotPlaygroundWidget}
                        to={`/org/${orgId}/cp/${agentId}/integrations`}
                    />
                </Route>
            </Switch>
        </>
    );
}
