import React from "react";
import ComponentHeader from "../ComponentHeader";
import ListItem from "./ListItem";
import ListLayout from "./ListLayout";

function List() {}

List.ComponentHeader = ComponentHeader;
List.ListLayout = ListLayout;
List.ListItem = ListItem;

export default List;
