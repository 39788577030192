import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--Size-Gap-XXL);
    justify-content: center;
    height: 100%;
`;

const StyledImg = styled.img`
    max-width: 200px;
`;

export default function NoDataFound({ children, iconProps, ...restProps }) {
    const platformTheme = useSelector((state) => state?.platformThemeDetails?.platformTheme);
    return (
        <>
            <Wrapper {...restProps} style={{ flexGrow: 1 }}>
                <StyledImg
                    src={
                        platformTheme === "light"
                            ? "https://cdn.pixelbin.io/v2/copilot/original/web/platform/EmptyState_White.png" // change this once correct image is given
                            : "https://cdn.pixelbin.io/v2/copilot/original/web/platform/EmptyState_Black.png"
                    }
                    {...iconProps}
                />
                {children}
            </Wrapper>
        </>
    );
}
