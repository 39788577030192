import React, { memo, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

export const ModalWrapper = styled.div`
    position: fixed;
    padding: var(--Size-Padding-XL);
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    transition: all 0.3s ease-in-out;
    transform: translateY(-100vh);
    opacity: 0;
    &.active {
        transform: translateY(0);
        opacity: 1;
    }
    background: rgb(48 48 48 / 80%);
    ${(props) => props.CustomModalWrapper};
`;

export const ModalContent = styled.div`
    padding: var(--Size-Padding-XXL);
    overflow: hidden;
    position: relative;
    //   padding: 40px;
    width: 100vw;
    max-height: 98vh;
    height: auto;
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "900px")};
    border-radius: var(--Size-CornerRadius-XL);
    background: ${(props) =>
        props.background ? props.background : "var(--Color-Background-Default)"};

    ${(props) => props.CustomModalContent};
`;

const Modal = memo(({ children, isVisible, maxWidth, CustomModalWrapper, CustomModalContent }) => {
    const domEl = document.getElementById("modal-root");

    if (!domEl) return null;

    useEffect(() => {
        if (isVisible) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "unset";
    }, [isVisible]);

    return ReactDOM.createPortal(
        <>
            <ModalWrapper
                className={isVisible ? "active" : ""}
                CustomModalWrapper={CustomModalWrapper}
            >
                <ModalContent maxWidth={maxWidth} CustomModalContent={CustomModalContent}>
                    {children}
                </ModalContent>
            </ModalWrapper>
        </>,
        domEl,
    );
});

export * from "./useModal";

export default Modal;
