import { Breakpoints } from "../../../GlobalStyle";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import {
    SubTitle1,
    Body_XXS_Reg,
    Body_L_Bold,
    Body_S_Bold,
    Body_XS_Reg,
} from "../../../common/global-styled-components";
import styled, { css } from "styled-components";
import IconLogo from "common/components/Logo/IconLogo";
import { Flash } from "iconsax-react";
import Icon from "common/components/Icon";

const Header = {};
Header.Title = styled(SubTitle1)`
    font-weight: 600;
    line-height: var(--line-height-34);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;

    @media (max-width: ${Breakpoints.mobileXS}px) {
        font-size: var(--subtitle-2-m);
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
    }
`;

const RoundedBorderGeneralDiv = css`
    border-radius: var(--Size-CornerRadius-XL);
    border: 1px solid var(--Color-Border-Subtle);
    display: flex;
    padding: var(--Size-Padding-XL) 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Size-Gap-M);
    align-self: stretch;
`;

const SkillDescriptionWrapper = styled.div`
    ${RoundedBorderGeneralDiv}
`;

const LogoWrapper = styled.div`
    ${RoundedBorderGeneralDiv}
    justify-content: center;
    align-items: center;
`;

const SkillDetailsWrapper = styled.div`
    ${RoundedBorderGeneralDiv}
`;

const SkillDescription = styled(Body_XS_Reg)`
    color: var(--Color-Text-Default);
`;

const SkillName = styled(Body_L_Bold)`
    display: flex;
    gap: var(--Size-Gap-M);
`;

const SkillGroup = styled(Body_XXS_Reg)`
    display: flex;
    padding: var(--Size-Padding-S) 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: var(--Size-CornerRadius-M);
    background: var(--Color-Background-Subtle);
    color: var(--Color-Text-Default);
`;

const OuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Size-Gap-XXL);
`;

const SkillList = styled(Body_XS_Reg)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-bottom: ${(props) => (props.last ? "none" : "1px solid var(--Color-Border-Subtle)")};
    padding-bottom: 12px;
    margin-left: 12px; //Remove this once icon is added for skill
`;

const SkillListDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

const SkillIcon = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
`;

const SkillDetailsDescription = styled.div`
    color: var(--Color-Text-Subtlest);
`;

function SkillDetails({ skillDetails }) {
    return (
        <OuterWrapper>
            <LogoWrapper>
                <Body_S_Bold>
                    <IconLogo src={skillDetails?.icon} width="120px" height="120px" />
                </Body_S_Bold>
                <SkillName>
                    {skillDetails?.name}{" "}
                    {skillDetails?.skills?.length > 0 && <SkillGroup>Group</SkillGroup>}
                </SkillName>
            </LogoWrapper>
            <SkillDescriptionWrapper>
                <Body_S_Bold>Skill Description</Body_S_Bold>
                <SkillDescription>{skillDetails?.description}</SkillDescription>
            </SkillDescriptionWrapper>
            {skillDetails?.skills?.length > 0 ? (
                <SkillDetailsWrapper>
                    <Body_S_Bold>Included Skills</Body_S_Bold>
                    <SkillDescription>
                        Following skills are included in this skillgroup. You can turn off each
                        skill manually once it is added to Copilot.
                    </SkillDescription>
                    {skillDetails?.skills?.map((val, index) => (
                        <SkillList key={val?.id} last={index === skillDetails?.skills.length - 1}>
                            {val?.icon ? (
                                <IconLogo src={val.icon} />
                            ) : (
                                <SkillIcon>{Icon(Flash, { size: 31 })}</SkillIcon>
                            )}

                            <SkillListDetails>
                                {startCase(val.name)}
                                <SkillDetailsDescription>
                                    {val?.description}
                                </SkillDetailsDescription>
                            </SkillListDetails>
                        </SkillList>
                    ))}
                </SkillDetailsWrapper>
            ) : (
                ""
            )}{" "}
        </OuterWrapper>
    );
}

export default SkillDetails;
