import { SkillAction } from "../actions/skillActions";

const initialState = {
    skillList: {
        skills: [],
        page: {},
    },
    skill: {},
};

const skillReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const newState = { ...state };
    let skill;

    switch (type) {
        case SkillAction.GetCopilots:
            // do not overrider zones list, instead concatenate them
            const prevCopilots = state.skillList.skills;
            const nextCopilots = payload.skillList.skills;
            const updatedState = { ...state, ...payload };
            updatedState.skillList.skills = [...prevCopilots, ...nextCopilots];
            return updatedState;
        case SkillAction.CopilotCreateSuccess:
            skill = payload.skill;
            newState.skillList.skills[skill.id] = skill;
            return {
                ...state,
                ...newState,
            };
        case SkillAction.CopilotCreateFailed:
            return {
                ...state,
            };
        case SkillAction.UpdateCopilot:
            if (payload.isCopilotUpdated) {
                skill = payload.skill;
                newState.skillList.skills[skill.id] = skill;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case SkillAction.GetCopilot:
            if (payload.exists) {
                skill = payload.skill;
                newState.skillList.skills[skill.id] = skill;
                return {
                    ...state,
                    ...newState,
                };
            }
            return state;
        case SkillAction.DeleteCopilot:
            skill = payload.skill;
            newState.skillList.skills = newState.skillList.skills.filter(
                (skill) => skill.id !== skill,
            );
            return {
                ...state,
                ...newState,
            };

        default:
            return state;
    }
};

export default skillReducer;
