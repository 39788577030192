import URLS from "../common/utils/apiUrls";
import ApiService from "./api.service";

const DataSourceService = {
    getCopilotDataSources(orgId, copilotId, queryString) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotDatasources}?${queryString}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    getCopilotDataSource(orgId, copilotId, datasourceId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.copilotDatasource}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },

    createCopilotDataSources(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            `${URLS.copilotDatasources}`.replace(":orgId", orgId).replace(":copilotId", copilotId),
            axiosOptions,
        );
    },

    updateCopilotDataSources(orgId, copilotId, datasourceId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.put(
            `${URLS.copilotDatasource}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    deleteCopilotDataSources(orgId, copilotId, datasourceId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.copilotDatasource}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId),
            axiosOptions,
        );
    },
    getDataSourceLogs(orgId, copilotId, datasourceId, syncHistoryId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.copilotDataSourceHistoryLogs
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId)
                .replace(":datasourceId", datasourceId)
                .replace(":syncHistoryId", syncHistoryId),
            axiosOptions,
        );
    },
    verifyDatasourceToken(orgId, copilotId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.post(
            `${URLS.dataSourceTokenVerify}`
                .replace(":orgId", orgId)
                .replace(":copilotId", copilotId),
            axiosOptions,
        );
    },
};

export default DataSourceService;
